import React, { Component } from 'react';
import { Card, Dialog, Paper } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import FaClose from 'react-icons/lib/fa/close';
import AttachFile2 from '@common/AttachFile2';
import CommonPage from '@common/CommonPage';
import { Visible } from 'react-grid-system';
import { observer } from 'mobx-react';
import crypto from 'crypto';
var CryptoJS = require('crypto-js');
import moment from 'moment';

@observer
export default class SlaForm extends CommonPage {
  constructor() {
    super();
    this.state = {
      category: '',
      subCategoryOptions: [],
      selectedCatalog: undefined,
      selectedCompany: 0,
      companyOptions: [],
      filesremove: [],
      historicUpdates: [],
    };
    this.onChangeField = this.onChangeField.bind(this);
    this.remove = this.remove.bind(this);
  }
  remove(id) {
    let values = this.state.filesremove;
    values.push(id);
    this.setState({ filesremove: values });
  }

  decryp(aMsg) {
    try {
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  }

  async loadSubCategoriesByCompany(companyId) {
    companyId && this.action.execute(
      'get',
      `${this.APIEndpoints.CATALOG}/company/distinct/get/${companyId}`,
    ).then((g) => {
      this.setState({ subCategoryOptions: g.data });
    }).catch((e) => {
      console.log({ e });
    });
  }

  onChangeField(field, value) {
    if (field.name === 'fk_id_category') {
      this.setState({ category: value });
    } else if (field.name === 'fk_id_company') {
      this.loadSubCategoriesByCompany(value);
      this.setState({ selectedCatalog: null });
      this.setState({selectedCompany: value});
    } else if (field.name === 'fk_id_catalog_service') {
      this.setState({ selectedCatalog: value });
    }
  }

  async componentWillMount() {
    const fk_id_company = this.props.values?.fk_id_company;
    const fk_id_catalog_service = this.props.values?.fk_id_catalog_service;

    fk_id_company && await this.loadSubCategoriesByCompany(fk_id_company);
    this.setState({ selectedCatalog: fk_id_catalog_service });

    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/20',
      'type_solution'
    );

    if( this.props.values ) {
      this.action
        .execute('get', this.APIEndpoints.SOLUTION + `hist?id=${this.props.values.id_solution}`, '')
        .then((g) => {
          console.log(g.data);
          this.setState({ historicUpdates: g.data });
        });
    }
  }

  render() {
    let { category, subCategoryOptions, selectedCatalog, historicUpdates } = this.state;
    let {
      categories,
      language,
      handlePage,
      onFormSubmit,
      type_solution,
      values,
      redirect,
      departments,
    } = this.props;
    let {
      catalogs,
      //    companies
    } = this.store;

    const initialState = {
      ...(values || {}),
      fk_id_catalog_service: selectedCatalog,
    };

    let fields = [
      {
        col: 12,
        name: 'subject',
        label: language.TITLE,
        type: 'text',
        required: true,
      },
      {
        col: 4,
        name: 'author',
        label: language.AUTHOR,
        type: 'text',
        required: true,
      },
      {
        col: 4,
        type: 'autocomplete',
        name: 'fk_id_company',
        label: language.COMPANY,
        method: 'POST',
        route: `${this.APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel: language.COMPANY,
        textinit: (values && values.name_company) || language.COMPANY,
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        idinit: (values && values.fk_id_company) || 0,
        routeAll: `${this.APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        required: true
      },
      {
        col: 4,
        type: 'autocomplete',
        name: 'fk_id_catalog_service',
        type: 'select',
        label: language.CATALOG_LIST,
        textlabel: language.CATALOG_LIST,
        textinit: values&&values.fk_id_catalog_service > 0 ? values.subcategory : '',
        idinit: values&&values.fk_id_catalog_service,
        detail: false,
        required: true,
        value: selectedCatalog,
        options: subCategoryOptions.map((s) => {
          return { value: s.id_catalog_service, label: s.name };
        })
      },
      {
        col: 4,
        name: 'fk_id_type_solution',
        label: language.SOLUTION,
        type: 'select',
        options: [
          { value: 1, label: language.PERMANENT },
          { value: 2, label: language.NOT_PERMANENT },
          { value: 3, label: language.PARTIAL },
        ],
        required: true,
      },
      {
        col: 4,
        name: 'fk_id_department',
        label: language.DEPARTMENT,
        textlabel: language.DEPARTMENT,
        type: 'autocomplete',
        method: 'POST',
        route: this.state.selectedCompany === 0 ? null : `${this.APIEndpoints.DEPARTMENT}/filter/`,
        filter: { fk_id_company: this.state.selectedCompany || 0 },
        routeAll: this.state.selectedCompany === 0 ? null : `${this.APIEndpoints.DEPARTMENT}/filter?`,
        filterAll: { fk_id_company: this.state.selectedCompany || 0 },
        routeGetCount: this.state.selectedCompany === 0 ? null : `${this.APIEndpoints.DEPARTMENT}/filter?count=0`,

        fieldquery: 'search',
        fieldvaluedb: 'id_department',
        fieldlabeldb: 'name',
        textinit: (values && values.name_department) || language.DEPARTMENT,
        idinit: (values && values.fk_id_department) || 0,
        methodGetAll: 'POST',
        detail: true,
        visible: true,
        required: true,
        disabled: !this.state.selectedCompany || this.state.selectedCompany === 0
      },
      {
        col: 4,
        name: 'type',
        label: language.TYPE,
        type: 'select',
        options: [
          {
            value: 'Solicitant',
            label: language.SOLICITANT,
          },
          {
            value: 'Tech',
            label: language.TECH,
          },
        ],
        required: true,
      },
      {
        col: 12,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'textEditor',
        required: true,
      },
      {
        col: 12,
        type: 'element',
        content: (v, h, submit) => (
          <div>
            {values && values.nameuser && (
              <div>
                <div style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold', color: '#009fc2' }}>{language.HISTORY_BACK_TO_EDITION}</div>

                <ul>
                  {historicUpdates && historicUpdates.length > 0 && (
                    historicUpdates.map((historic, i) => (
                      <li key={i}><strong>{language.EDITOR}:</strong> {historic.changeuser} | {moment(historic.dt_up).format('DD/MM/YYYY HH:mm')}</li>
                    ))
                  )}
                  <li><strong>{language.CREATOR}:</strong> {values.nameuser} | {moment(values.dt_cad).format('DD/MM/YYYY HH:mm')}</li>
                </ul>
              </div>
            )}
          </div>
      ),
      }
    ];

    let button = {
      md: 12,
      label: language.SAVE,
      primary: true,
      style: { marginTop: 10 },
    };

    let files = this.props.files;

    if (values) {
      values.files && values.files.push(values.files);
      values.files &&
        values.files.map((g) => {
          if (
            files.filter((h) => h.name == g.name).length == 0 &&
            g.name != undefined &&
            this.state.filesremove.includes(g.name) == false
          )
            files.push({
              name: g.name,
              size: '',
              type: '',
              id_attach: g.id_attach,
            });
        });
    }

    return (
      <PageLayout
        icon={
          <div
            className="incidentManagementIcon"
            style={{ width: 44, height: 44 }}
          />
        }
        title={values ? language.EDIT : language.ADD}
        subTitle={
          language.DASHBOARD_PAGE.DM + '/' + language.KNOWLEDGE_BASE + '/'
        }
        rightElements={[]}
      >
        <Card className="content ">
          <div
            className="titleContractList mb-3"
            style={{ display: 'block', marginLeft: 12 }}
          >
            <AttachFile2
              label={language.ATTACH}
              subLabel={language.DRAG_THE_FILE}
              onRemove={(f) => {
                this.props.removeFile(f);
                this.remove(f.name);
              }}
              files={files}
              onDrop={this.props.onDrop}
              accept={
                '.jpeg, .jpg, .png, .webm, .gif, .tiff, .zip, .7Z, .json, .VBS, .sql , .html, .csv, .tif, .pdf, .doc, .docx, .xls, .xlsx, .xml, .ppt, .csv, .txt , .zip, .rar, .msg , audio/, image/'
              }
            />
          </div>

          <CommonForm
            language={language}
            button={button}
            onSubmit={onFormSubmit}
            values={initialState}
            fields={fields}
            loading="post-solution-form"
            onChangeField={this.onChangeField}
          />
        </Card>
      </PageLayout>
    );
  }
}
