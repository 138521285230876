import Tag from '@common/Tag';
import Store from '@data/Store';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from '../../common/Button';
import CommonForm from '../../common/CommonForm2';
import DialogConfirm from '../../common/DialogConfirm';
import { translateFromPtBR } from '../../common/languages/Dictionary';
import Constants from '../../data/Constants';
import decrypt from '../../util/decrypt';
import FormStart from './FormStart';
import Fields from './fields';
import './index.css';
import { resolveConditionValue } from './services';
import Actions from '@data/Actions';

const action = new Actions();

const { language } = Store

const logicalOpt = {
  equals: language.EQUAL_TO,
  notequal: language.DIFFERENT_FROM,
  contains: language.COUNT,
  notcontain: language.DONT_COUNT,
  starts: language.START_WITH,
  end: language.ENDS_WITH,
  gt: language.ITS_GREATER_THAN,
  gte: language.IS_GREATER_OR_EQUAL_TO,
  lt: language.IS_LESS_THAN,
  lte: language.IS_LESS_THAN_OR_EQUAL_TO,
  isnull: language.IS_NULL,
  isnotnull: language.IS_NOT_NULL
}

const defaultPageDelete = { open: false, values: {} };


const convertJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

const scale = {
  'low': language.LOW,
  'medium': language.MEDIUM,
  'high': language.HIGH
}


const getValueJson = async (slaItem) => {

  let value = null;
  if (slaItem[slaItem.slf_alias]) {
    value = convertJson(slaItem[slaItem.slf_alias])?.name;
  }

  if (!value) {
   // console.log('getValueJson', { value, slaItem })
    value = await resolveConditionValue(slaItem.slf_alias, slaItem.slr_value);
  }

  return Promise.resolve(value ?? scale[slaItem.slr_value] ?? slaItem.slr_value);
};

const FormRules = (props) => {
  const [type, setType] = useState('')
  const [logics, setLogics] = useState([])
  const [result, setResult] = useState([])
  const [signal, SetSignal] = useState('')
  const [condition, setCondition] = useState([])
  const [condFilter, setCondFilter] = useState(0)
  const [value_form, setValue_form] = useState([])
  const [templateFormData, setTemplatesData] = useState([])
  const [pageDelete, setPageDelete] = useState(defaultPageDelete)
  const [selectFieldFormData, setselectFieldFormData] = useState([])
  const [row, setRow] = useState({ fk_id_slarule: '', id_slacondition: '' })
  const [locationSla, setLocationSla] = useState([])
  const [contractData, setContractData] = useState([])
  const [cmdbOptions, setCMDBFields] = useState([
    { value: 'app_port', label: language.CMDB_APP_PORT },
    { valeu: 'app_name', label: language.CMDB_APP_NAME },
    { valeu: 'asset_dc_asset', label: language.CMDB_ASSET_DC },
    { valeu: 'asset_collocation', label: language.CMDB_ASSET_COLLOCATION },
    { value: 'serial_number', label: language.CMDB_SERIAL_NUMBER },
    { value: 'asset_asset_tag', label: language.CMDB_ASSET_TAG },
    { value: 'asset_asset_owner', label: language.CMDB_ASSET_OWNER },
    { value: 'asset_asset_type', label: language.CMDB_ASSET_TYPE },
    { value: 'batchjob_conf_dir', label: language.CMDB_BATCHJOB_CONF_DIR },
    { value: 'batchjob_ins_dir', label: language.CMDB_BATCHJOB_INS_DIR },
    { value: 'batchjob_int_ref', label: language.CMDB_BATCHJOB_INT_REF },
    { value: 'batchjob_justify', label: language.CMDB_BATCHJOB_JUSTIFY },
    { value: 'batchjob_run_proc_com', label: language.CMDB_BATCHJOB_RUN_PROC_COM },
    { value: 'batchjob_run_proc_com_hash', label: language.CMDB_BATCHJOB_RUN_PROC_COM_HASH },
    { value: 'batchjob_run_proc_key_param', label: language.CMDB_BATCHJOB_RUN_PROC_KEY_PARAM },
    { value: 'batchjob_run_proc_key_param_hash', label: language.CMDB_BATCHJOB_RUN_PROC_KEY_PARAM_HASH },
    { value: 'batchjob_tcp_ports', label: language.CMDB_BATCHJOB_TCP_PORTS },
    { value: 'ci_base_code', label: language.CMDB_CI_BASE_CODE },
    { value: 'ci_status', label: language.CMDB_CI_STATUS },
    { value: 'ci_base_assign_group', label: language.CMDB_CI_BASE_ASSIGN_GROUP },
    { value: 'ci_base_version', label: language.CMDB_CI_BASE_VERSION },
    { value: 'ci_base_supported', label: language.CMDB_CI_BASE_SUPPORTED },
    { value: 'ci_base_acn', label: language.CMDB_CI_BASE_ACN },
    { value: 'ci_base_env', label: language.CMDB_CI_BASE_ENV },
    { value: 'ci_base_oper_status', label: language.CMDB_CI_BASE_OPER_STATUS },
    { value: 'ci_base_cdpt', label: language.CMDB_CI_BASE_CDPT },
    { value: 'ci_base_fqdn', label: language.CMDB_CI_BASE_FQDN },
    { value: 'ci_base_sup_group', label: language.CMDB_CI_BASE_SUP_GROUP },
    { value: 'ci_base_ci_type', label: language.CMDB_CI_BASE_CI_TYPE },
    { value: 'ci_base_location', label: language.CMDB_CI_BASE_LOCATION },
    { value: 'ci_base_oper_date', label: language.CMDB_CI_BASE_OPER_DATE },
    { value: 'db_tcp', label: language.CMDB_DB_TCP },
    { value: 'db_instance', label: language.CMDB_DB_INSTANCE },
    { value: 'ip_owner', label: language.CMDB_IP_OWNER },
    { value: 'ip_managed_group', label: language.CMDB_IP_MANAGED_GROUP },
    { value: 'ip_type', label: language.CMDB_IP_TYPE },
    { value: 'srv_os', label: language.CMDB_SRV_OS },
    { value: 'srv_localization', label: language.CMDB_SRV_LOCALIZATION },
    { value: 'srv_is_virtual', label: language.CMDB_SRV_IS_VIRTUAL },
    { value: 'srv_host', label: language.CMDB_SRV_HOST },
    { value: 'srv_object_id', label: language.CMDB_SRV_OBJECT_ID },
    { value: 'srv_host_id', label: language.CMDB_SRV_HOST_ID },
    { value: 'srv_cpu', label: language.CMDB_SRV_CPU },
    { value: 'srv_ram', label: language.CMDB_SRV_RAM },
    { value: 'srv_disk_space', label: language.CMDB_SRV_DISK_SPACE },
    { value: 'ci_bs_service_name', label: language.CMDB_CI_BS_SERVICE_NAME },
    { value: 'ci_bs_business_criticality', label: language.CMDB_CI_BS_BUSINESS_CRITICALITY },
    { value: 'ci_bs_business_impact', label: language.CMDB_CI_BS_BUSINESS_IMPACT },
    { value: 'ci_bs_business_process', label: language.CMDB_CI_BS_BUSINESS_PROCESS },
    { value: 'mon_policy_id', label: language.CMDB_MON_POLICY_ID },
    { value: 'mon_tool', label: language.CMDB_MON_TOOL },
    { value: 'bkp_client_host', label: language.CMDB_BKP_CLIENT_HOST },
    { value: 'bkp_master_client', label: language.CMDB_BKP_MASTER_CLIENT },
    { value: 'bkp_job_typ', label: language.CMDB_BKP_JOB_TYP },
    { value: 'bkp_schedules', label: language.CMDB_BKP_SCHEDULES },
    { value: 'ref_id', label: language.CMDB_REF_ID },
    { value: 'ref_tool', label: language.CMDB_REF_TOOL },
    { value: 'endpoint_port', label: language.CMDB_ENDPOINT_PORT },
    { value: 'endpoint_protocol', label: language.CMDB_ENDPOINT_PROTOCOL },
    { value: 'used_for', label: language.USED_FOR },
    { value: 'escalation_information', label: language.ESCALATION_INFORMATION },
    { value: 'criticalities', label: language.CRITICALITY },
    { value: 'priorities', label: language.PRIORITY }])

  useEffect(() => {
    action
      .execute('get', `${Constants.APIEndpoints.SLA_WORK}/slafield`, '')
      .then((res) => {
        setCondition(res.data)
      })

    action
      .execute('get', `${Constants.APIEndpoints.SLA_WORK}/slaparam/${props.value.id_slaparam}/condition?condition=${props.type}`, '')
      .then((res) => {
        setLogics(res.data)
      })
  }, [props.type])

  useEffect(() => {
    const processLogics = async (logics) => {
      const result = {};

      for (const rule of logics) {
        const id = rule.id_slacondition;
        result[id] = result[id] || [];

        const idx = result[id].findIndex(
          (sub) => sub.map((s) => s.id_slarule).indexOf(rule.fk_id_slarule) !== -1
        );

        rule._label = await getRuleLabel(rule)

        if (idx > -1) {
          result[id][idx].push(rule);
        } else {
          result[id].push([rule]);
        }
      }

      setResult(result);
    };

    processLogics(logics);

  }, [logics]);

  async function getRuleLabel(rule) {
    if (rule.slf_alias === 'created') {
      return moment(rule.slr_value).format('DD/MM/YYYY');
    } else if (['equals', 'notequal'].includes(rule.slr_logical) && rule.slf_alias !== 'form') {
      return await getValueJson(rule);
    }
    return rule.slr_value || '--';
  }

  const getLocationSla = (id) => {
    action
      .execute('get', `${Constants.APIEndpoints.LOCATION}/sla/${id}`, '')
      .then((res) => {
        setLocationSla(res.data)
      })
  }

  const dataForm = (value) => {
    action
      .execute('get', `${Constants.APIEndpoints.TASK}/getforms?type=kv`, '')
      .then((res) => {
        setTemplatesData(
          JSON.parse(
            decrypt(
              res.data.filter((item) => item.fk_id_template_forms == value)[0].data
            )
          )
        )
      })
  }

  const dataContract = (value) => {
    if (value !== 0) {
      action
        .execute('post', `${Constants.APIEndpoints.CONTRACT}/list`, '', { fk_id_out_company: value })
        .then((res) => setContractData(res.data || []));
    }
  };

  const getFieldForm = (field) => {
    if (field) {
      let array = [];
      if (field.type == 'select') {
        array = {
          col: 12,
          type: 'select',
          name: 'value_form',
          options: field.values.map((item) => ({
            value: item.label,
            label: item.label
          })),
          label: field.label
        }
      }

      if (field.type == 'checkbox-group') {
        array = {
          col: 12,
          name: 'value_form',
          type: 'listCheckbox',
          label: field.label,
          options: field.values.map((item) => ({
            value: item.label,
            label: item.label
          })),
        };
      }

      if (field.type == 'radio-group') {
        array = {
          col: 12,
          name: 'value_form',
          type: 'listCheckboxSingle',
          label: field.label,
          options: field.values.map((item) => ({
            value: item.label,
            label: item.label,
          }))
        }
      }

      if (field.type.indexOf('text') > -1) {
        array = {
          col: 12,
          name: 'value_form',
          type: 'text',
          label: field.label
        };
      }

      return (
        <div style={{ minWidth: '300px' }}>
          <CommonForm
            onChangeField={(field, value) => {
              setValue_form(value)
            }}
            fields={[array]}
          ></CommonForm>
        </div>
      )
    }
  }

  const saveSlaLogic = (obj) => {
    //console.log('saveSlaLogic', obj)
    let con = condition.filter((g) => g.slf_alias == condFilter)[0];

    if (obj.condFilter == 'form') {
      obj.fk_id_template_forms = obj.templateForm;
      obj.slr_form_field = templateFormData[obj.fieldFormData].name
    }

    let payload = {
      fk_id_slaparam: props.value.id_slaparam,
      sc_condition: props.type,
      fk_id_slafield: con.id_slafield,
      slr_logical: obj.signal,
      slr_value: obj.condFilter === 'form' ? value_form : (obj.condFilter === 'cmdb' ? obj.cmdbFields + '|' + obj[condFilter] : obj[condFilter]),
      fk_id_template_forms: obj.fk_id_template_forms,
      slr_form_field: obj.slr_form_field
    };

    action
      .execute('post', `${Constants.APIEndpoints.SLA_WORK}/slaparam/${props.value.id_slaparam}/condition`, '', payload)
      .then((res) => {
        setLogics(res.data)
      })
  }

  const saveSlaLogicAppend = (obj) => {
    //console.log('saveSlaLogicAppend', obj)
    let con = condition.filter((g) => g.slf_alias == condFilter)[0]
    let last = logics[logics.length - 1].id_slacondition

    if (row.id_slacondition !== '') {
      last = row.id_slacondition
    }

    if (obj.condFilter == 'form') {
      obj.fk_id_template_forms = obj.templateForm;
      obj.slr_form_field = templateFormData[obj.fieldFormData].name
    }

    let payload = {
      fk_id_slacondition: last,
      fk_id_slafield: con.id_slafield,
      slr_logical: obj.signal,
      slr_value: obj.condFilter === 'form' ? value_form : (obj.condFilter === 'cmdb' ? obj.cmdbFields + '|' + obj[condFilter] : obj[condFilter]),
      fk_id_slarule: row.fk_id_slarule,
      fk_id_template_forms: obj.fk_id_template_forms,
      slr_form_field: obj.slr_form_field
    };

    action
      .execute('post', `${Constants.APIEndpoints.SLA_WORK}/slaparam/${props.value.id_slaparam}/condition/${last}`, '', payload)
      .then((res) => {
        setLogics(res.data)
      })
  }

  const deleteRule = () => {
    const { id_slaparam, id_slacondition, id_slarule } = pageDelete.values;
    
    action
      .execute('delete', `${Constants.APIEndpoints.SLA_WORK}/slaparam/${id_slaparam}/condition/${id_slacondition}/rule/${id_slarule}`, '')
      .then((res) => {
        setLogics(res.data)
      })

    handlePageDelete(defaultPageDelete);
  }

  const handlePageDelete = (data) => {
    setPageDelete(data)
  }

  let fields_con = undefined;

  if (condition.find((g) => g.slf_alias == condFilter)) {
    fields_con = JSON.parse(
      condition.find((g) => g.slf_alias == condFilter).condiction_options
    );
  }

  return (
    <div className='tw-p-4'>
      <div
        style={{
          marginBottom: '20px',
          overflowY: 'auto',
          overflowX: 'hidden',
          //padding: '4px',
        }}
      >
        {props.tab === 0 && props.value.sla_target === 'resolution' && (
          <FormStart sla={props} loadSla={props.loadSla} />
        )}

        {Object.keys(result || []).map((key, index) => (
          <>
            <div className="slaRule tw-rounded">
              {result[key].map((line) => (
                <div
                  className='slaRuleItem tw-flex tw-flex-col tw-w-full tw-items-center tw-rounded'
                  style={{
                    background: row.fk_id_slarule == line[0].id_slarule ? '#DAA520' : '',
                  }}
                  onClick={() =>
                    setRow({
                      fk_id_slarule: line[0].id_slarule,
                      id_slacondition: line[0].id_slacondition,
                    })
                  }
                >
                  {
                    line.map((r, i) => (
                      <div className='tw-flex tw-items-center tw-w-full'>
                        <span className='tw-flex-grow'>
                          <div style={{ fontStyle: 'normal', paddingLeft: i > 0 ? 15 : 0 }} className='tw-flex tw-items-center tw-flex-1'>
                            <div style={{ minWidth: 180 }} className='tw-flex tw-items-center'>
                              {i > 0 && (
                                <Tag
                                  label={'OU'}
                                  color={'#525252'}
                                  style={{
                                    height: 20,
                                    fontSize: 11,
                                    marginRight: 5,
                                    lineHeight: '11px',
                                    textTransform: 'uppercase',
                                  }}
                                />
                              )}
                              <span>
                                {condition.find((g) => g.id_slafield == r.fk_id_slafield)?.slf_description}:
                              </span>
                            </div>
                            <Tag
                              color={'#888888'}
                              label={logicalOpt[r.slr_logical]}
                              style={{ fontSize: 11, lineHeight: '11px', textTransform: 'uppercase', height: 20, marginLeft: 6 }}
                            />
                            {!['isnotnull', 'isnull'].includes(r.slr_logical) && r.slr_form_field &&
                              <Tag
                                color={'var(--primaryGradient)'}
                                label={`${r.slr_form_field}: `}
                                style={{ fontSize: '13px', lineHeight: '14px', height: 20, marginLeft: 6 }}
                              />
                            }
                            {!['isnotnull', 'isnull'].includes(r.slr_logical) &&
                              <Tag
                                color={'var(--success)'}
                                label={r._label
                                  // r.slf_alias === 'created'
                                  //   ? moment(r.slr_value).format('DD/MM/YYYY')
                                  //   : ['equals', 'notequal'].includes(r.slr_logical) && r.slf_alias !== 'form'
                                  //     ? getValueJson(r)
                                  //     : (r.slr_value || '--')
                                }
                                style={{ fontSize: '13px', lineHeight: '14px', height: 20, marginLeft: 6 }}
                              />

                            }
                          </div>
                        </span>
                        {
                          ((line.length > 1 && !r.fk_id_slarule) || (
                            props.value?.sla_problem_steps_enabled &&
                            line[0]?.slf_alias == "type_tickets" &&
                            line[0]?.slr_value == "2"
                          )) ? null : (
                            <span>
                              <Button
                                variant="circle"
                                icon="fas fa-trash-alt tw-text-base"
                                color="danger"
                                label={language.DELETE}
                                onClick={() =>
                                  handlePageDelete({ open: true, values: r })
                                }
                              />{' '}
                            </span>
                          )
                        }
                      </div>
                    ))
                  }
                </div>
              ))}
            </div>
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {Object.keys(result).length > 1 &&
                Object.keys(result).length > index + 1
                ? 'OU'
                : ''}
            </div>
          </>
        ))}
      </div>
      <CommonForm
        values={[]}
        hiddenSubmit={true}
        onChangeField={(field, value) => {
          if (field.name === 'cmdb') {
            setCMDBFields(cmdbOptions)
          }
          if (field.name == 'condFilter') {
            setCondFilter(value);
          }
          if (field.name == 'signal') {
            SetSignal(value);
          }

          if (field.name == 'templateForm') {
            dataForm(value);
          }

          if (field.name == 'company') {
            if (condFilter == 'contract') {
              dataContract(value)
            }
          }

          if (field.name !== 'contract') {
            setContractData([])
          }

          if (field.name == 'fieldFormData') {
            setselectFieldFormData(value);
            //setValue_form();
          }
          if (condFilter == 'locality' && field.name == 'company') {
            getLocationSla(value);
          }
        }}
        fields={[
          {
            col: 4,
            name: 'condFilter',
            label: language.FILTER_CONDITION,
            type: 'select',
            options: condition.map((item) => ({
              value: item.slf_alias,
              label: translateFromPtBR(item.slf_description, language),
            })),
            required: true
          },
          {
            col: 4,
            name: 'cmdbFields',
            label: 'CMDB',
            type: 'select',
            options: cmdbOptions,
            visible: condFilter === 'cmdb'
          },
          {
            col: 4,
            name: 'signal',
            label: language.LOGICAL_CONDITIONS,
            type: 'select',
            options: fields_con
              ? Object.keys(fields_con).map((l) => ({
                value: l,
                label: translateFromPtBR(fields_con[l], language),
              }))
              : [],
            required: true,
          },
          {
            ...Fields(condFilter, signal, {
              countryId: props?.value?.fk_id_country,
            })
          },
          {
            col: condFilter === 'locality' ? 4 : 0,
            name: 'locality',
            label: language.LOCATION,
            type: 'select',
            options: locationSla.map((g, index) => ({
              label: g.name,
              value: g.id_rel_company_location
            })),
            visible: condFilter == 'locality' ? true : false
          },
          {
            col: templateFormData.length > 0 ? 4 : 0,
            name: 'fieldFormData',
            label: language.FORM_FIELD,
            type: 'select',
            options: templateFormData.map((g, index) => ({
              label: g.label,
              value: index
            })),
            visible: templateFormData.length > 0 ? true : false,
          },
          {
            col: templateFormData.length > 0 ? 4 : 0,
            type: 'element',
            name: 'value_form',
            content: (v, h, submit) =>
              getFieldForm(templateFormData[selectFieldFormData]),
          },
          {
            col: condFilter == 'contract' && ['equals', 'notequal'].includes(signal) ? 4 : 0,
            name: 'contract',
            label: language.CONTRACTS,
            type: 'select',
            options: contractData.length > 0 && contractData.map((g, index) => ({
              label: g.name,
              value: g.id_contract
            })),
            visible: condFilter == 'contract' && ['equals', 'notequal'].includes(signal),
          },
          {
            col: 4,
            type: 'element',
            content: (v, h, submit) => (
              <div style={{ display: 'flex' }}>
                {row.fk_id_slarule !== '' ? (
                  <Button
                    label={language.ADD_RULE}
                    style={{ marginTop: '15px' }}
                    color="success"
                    onClick={() => {
                      setType('E');
                      setTimeout(() => {
                        submit();
                      }, 1000);
                    }}
                  />
                ) : null}
                {row.fk_id_slarule !== '' ? (
                  <Button
                    label={language.LOGOFF}
                    style={{ marginTop: '15px', marginLeft: '2px' }}
                    color="danger"
                    onClick={() => {
                      setRow({ fk_id_slarule: '', id_slacondition: '' });
                    }}
                  />
                ) : null}

                {row.fk_id_slarule == '' && logics.length >= 1 ? (
                  <Button
                    label={language.AND}
                    variant="normal"
                    style={{ marginTop: '15px' }}
                    color="success"
                    onClick={() => {
                      setType('E');
                      setTimeout(() => {
                        submit();
                      }, 1000);
                    }}
                  />
                ) : null}
                {row.fk_id_slarule == '' && logics.length >= 1 ? (
                  <Button
                    label={language.OR}
                    variant="normal"
                    style={{ marginTop: '15px', marginLeft: '2px' }}
                    color="success"
                    onClick={() => {
                      setType('');
                      setTimeout(() => {
                        submit();
                      }, 1000);
                    }}
                  />
                ) : null}

                {logics.length == 0 ? (
                  <Button
                    icon={'fas fa-plus'}
                    variant="circle"
                    style={{ marginTop: '15px', marginLeft: '2px' }}
                    color="success"
                    onClick={() => submit()}
                  />
                ) : null}
              </div>
            ),
          },
        ]}
        onSubmit={(v) => {
          if (type == 'E') {
            saveSlaLogicAppend(v);
          } else {
            saveSlaLogic(v);
          }
        }}
      />
      {pageDelete.open && (
        <DialogConfirm
          open={pageDelete.open}
          language={props.language}
          confirmProps={{ color: 'danger' }}
          onConfirm={deleteRule}
          onClose={() => handlePageDelete(defaultPageDelete)}
          title={props.language.CONFIRM_DELETE}
          message={
            condition.find(
              (g) => g.id_slafield == pageDelete.values.fk_id_slafield
            ) &&
            condition.find(
              (g) => g.id_slafield == pageDelete.values.fk_id_slafield
            ).slf_description
          }
        />
      )}
    </div>
  )
}
export default FormRules
