import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import moment from 'moment';
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import { Card } from '@material-ui/core';
import Button from '@common/Button';
import DialogForm from './DialogForm';
import Constants from '../../../data/Constants';
import CommonForm from '@common/CommonForm2';
import TicketManagement from '../../TicketManagement/index';
import RequestContainer from '../../request/RequestContainer';
import SearchInput from '@common/SearchInput';
import Header from '@common/Header';
import CommonDialog from '@common/Dialog';
import { setInjetSearchColumn, getInjetSearchColumn } from '@common/Func';
import { translateFromPtBR } from '@common/languages/Dictionary';

import './GSDPage.css';

import 'react-alice-carousel/lib/alice-carousel.css';

import GSDGridList from './GSDGridList';
import GSDGridListRequest from './GSDGridListRequest';

const left = () => {
  var leftPos = $('#_gsdcarrosell').scrollLeft();
  $('#_gsdcarrosell').animate(
    {
      scrollLeft: leftPos - 600,
    },
    800
  );
};

const right = () => {
  var leftPos = $('#_gsdcarrosell').scrollLeft();
  $('#_gsdcarrosell').animate(
    {
      scrollLeft: leftPos + 600,
    },
    800
  );
};
@observer
class DashboardPage extends CommonPage {
  constructor() {
    super();
    this.state = {
      department: '',
      zerarempresa: true,
      search: '',
      delayOpen: [],
      delayOpenUnico: [],
      delayInprogress: [],
      delayInSolicitation: [],
      delayInClosed: [],
      delayInResolved: [],
      month: '',
      year: '',
      position: 0,
      st: false,
      dialog: false,
      filter: {},
      dtinicial: '',
      dtfinal: '',
      search_: '',
      dashlist: [],
      params: [],
      ds: true,
      openTicket: false,
      ticketToOpen: undefined,
      userTech: '',
      category: '',
      serviceCatalog: '',
      cleanValueSolicitant: false,
      categoryValue: [],
      id_complexity: null,
      namesFilter: []
    };

    const objctfilterDashboard = getInjetSearchColumn(window.location.pathname + '/gsd');
    if (objctfilterDashboard?.filterDashboard) {
      this.state.namesFilter = objctfilterDashboard.filterDashboard;
      this.state.filter = { ...this.state.namesFilter, search: this.state.search };
    }

    this.handleValues = this.handleValues.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.delayOpen = this.delayOpen.bind(this);

    this.handleOrderBy = this.handleOrderBy.bind(this);
    this.onFormSubmitItem = this.onFormSubmitItem.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.sendvalues = this.sendvalues.bind(this);
    this.searchfind = this.searchfind.bind(this);
    this.filterDashboard = this.filterDashboard.bind(this);
    this.updateTicket = this.updateTicket.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.windowReaload = this.windowReaload.bind(this);
    this.clearticket = this.clearticket.bind(this);
    this.Updash = this.Updash.bind(this);

    this.onChangeField = this.onChangeField.bind(this);
  }

  sendvalues() { }

  onFormSubmitItem(values) {
    this.store.loading = false;
    this.setState({ sendFile: true });
    let filesSend = [];
    let cont = 0;
    if (values.file && values.file.length > 0) {
      let total = Array.from(values.file).length;
      Array.from(values.file).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });

        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
            this.setState({ sendFile: false });
            filesSend = [];
            this.state.files = [];
            cont = 0;
            setTimeout(
              function () {
                //8this.redirect('/dash/gsd');
              }.bind(this),
              1000
            );
          }
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            let host = Constants.APIRoot;
            values.file = filesSend;
            this.action
              .execute(
                'post',
                `${host}/api/request/`,
                '',
                values
              )
              .then((data) => {
                this.setState({ ds: true });
                setTimeout(
                  function () {
                    this.redirect('/dash/gsd');
                  }.bind(this),
                  1000
                );
              });
          }
        });
      });
    } else {
      this.action
        .execute(
          'post',
          this.APIEndpoints.REQUEST,
          'requests',
          values,
          'id_request'
        )
        .then((e) => {
          // this.redirect2('/dash/gsd')
          this.setState({ ds: true });

          //this.props.getDashboard();
          this.filterDashboard()
          this.setState({ dialog: false });
        });

      this.setState({ values: '' });
    }
  }

  clearticket() {
    this.store.ticket = null;
  }

  createRandomToken(text) {
    const aSecret = 'Orch3str0!@forever!';
    const crypto = require('crypto');

    let cipher, tRet;

    cipher = crypto.createCipher('aes-256-cbc', aSecret);
    tRet = cipher.update(text.toString(), 'utf8', 'base64');
    tRet += cipher.final('base64');
    return tRet;
  }

  handleDialog = (n = false) => {
    this.action.execute('get', this.APIEndpoints.TASK, 'tasks');
    this.action
      .execute('get', this.APIEndpoints.TASK + '/getforms', 'formstemplate')
      .then((g) => {
        this.setState({ dialog: n });
      });
    // this.action.execute('get', this.APIEndpoints.CATALOG, 'catalogs');

    //this.action.execute('get', this.APIEndpoints.CATEGORY, 'categories');
  };

  onFormSubmit(type, values, general_parameters) {
    let contador = 0;
    let fields = [];
    let camposobrigatorios = false;
    this.store.loading = true;
    let camposobrigatorioscheck = false;
    let camposobrigatoriosradio = false;
    if (
      type == '' &&
      ![true].includes(general_parameters.ticket_simplified) &&
      (values.fk_id_category == undefined ||
        values.fk_id_catalog_service == undefined ||
        values.fk_id_catalog_task == undefined)
    ) {
      this.store.toggleAlert(true, language.INFORM_ALL_FIELDS, 'error');
    } else {
      if (type === 'ticket') {
        if (values.ticketArray != undefined && values.ticketArray.length > 1) {
          values.ticketArray.forEach((num) => {
            contador++;

            let item = values.req.filter((x) => x.id_request === num)[0];

            values.fk_id_request = num;
            values.description = item.description;
            values.subject = item.subject;

            this.action
              .execute('post', this.APIEndpoints.TICKET, 'requests', values)
              .then((e) => {
                if (contador == values.ticketArray.length) {
                  this.redirect('/dash/request');
                }
              });
          });
        } else {
          if (
            values.fk_id_user_sponsor == null ||
            values.fk_id_user_sponsor <= 0
          ) {
            this.store.toggleAlert(
              true,
              language.RESPONSIBLE_NOT_REGISTERED,
              'error'
            );
          }

          this.action
            .execute('post', this.APIEndpoints.TICKET, 'requests', values)
            .then((e) => this.redirect('/dash/request'));
        }
      } else if (type === 'reject') {
        values.values.action = 'approval';
        values.values.status = 'Rejected';

        let contador = 0;

        if (values.id_request.length > 1) {
          values.id_request.forEach((num) => {
            contador++;
            this.store.loading++;

            this.action
              .execute(
                'post',
                this.APIEndpoints.REQUEST + '/' + num,
                'requests',
                values.values
              )
              .then((e) => {
                if (contador == values.id_request.length) {
                  this.redirect('/dash/request');
                }
              });
            this.store.loading = false;
          });
        } else {
          this.action
            .execute(
              'post',
              this.APIEndpoints.REQUEST + '/' + values.id_request,
              'requests',
              values.values
            )
            .then(
              (e) => { }
              //this.redirect('/dash/request')
            );
        }
      } else {
        let campossize = false;
        if ($('#template').html() != undefined) {
          $('#template textarea').each(function () {
            $(this).attr('value', $(this).val());

            $(this).html($(this).val());
            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: $(this).val(),
            });
          });

          $('#template input').each(function () {
            if ($(this).attr('type') == 'radio') {
              if (
                $('input[name=' + $(this).attr('name') + ']:checked').val() ==
                $(this).val()
              ) {
                $(this).attr('checked', true);
                let value = undefined;
                try {
                  value = $(this).parent().children()[1].textContent;
                } catch (e) { }
                fields.push({
                  name: $(this).attr('name'),
                  value: value || $(this).val(),
                });
              } else {
                $(this).removeAttr('checked');
              }
              let ip = $(this);

              $('#template  label').each(function () {
                var for_ = '';
                try {
                  for_ = $(this).attr('for');
                } catch (e) { }

                if (for_ === ip.attr('name')) {
                  if ($(this).text().split('*')[1] != undefined) {
                    if ($(this).text().split('*')[1].length >= 0) {
                      camposobrigatoriosradio = true;
                      $('[name=' + for_ + ']').each(function () {
                        try {
                          if ($(this).attr('checked')) {
                            camposobrigatoriosradio = false;
                          }
                        } catch (e) { }
                      });
                    }
                  }
                }
              });
            } else if ($(this).attr('type') == 'checkbox') {
              if (
                $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
                $(this).attr('id')
              ) {
                $(this).attr('checked', true);
                fields.push({
                  name: $(this).attr('name'),
                  value: $(this).val(),
                });
              } else {
                $(this).removeAttr('checked');
              }
              let ip = $(this);
              $('#template  label').each(function () {
                var for_ = ' ';
                try {
                  for_ = $(this).attr('for');
                } catch (e) { }

                if (ip.attr('name').indexOf(for_) > -1) {
                  if ($(this).text().split('*')[1] != undefined) {
                    if ($(this).text().split('*')[1].length >= 0) {
                      camposobrigatorioscheck = true;
                      $('#template  input').each(function () {
                        try {
                          if ($(this).attr('name').indexOf(for_) > -1) {
                            try {
                              if ($(this).attr('checked')) {
                                camposobrigatorioscheck = false;
                              }
                            } catch (e) { }
                          }
                        } catch (e) { }
                      });
                    }
                  }
                }
              });
            } else if ($(this).attr('type') == 'datetime-local') {
              const userLogin = Store.getUserLoged();
              const timezone = userLogin?.timezone?.value;

              fields.push({
                name: $(this).attr('name') || $(this).attr('id'),
                value: moment($(this).val()).tz(timezone).utc().toISOString(),
              });
              $(this).attr('value', $(this).val());
            } else {
              $(this).attr('value', $(this).val());
              fields.push({
                name: $(this).attr('name') || $(this).attr('id'),
                value: $(this).attr('value'),
              });
              let siz = $(this).attr('max');
              let min = $(this).attr('min');
              if (siz) {
                if (
                  parseInt($(this).val().length) > parseInt(siz) ||
                  parseInt($(this).val().length) < parseInt(min)
                ) {
                  $(this).css('border-color', 'red');
                  $(this).before(
                    `<label  id="formtemplabel__" style='color:red'>  ${min || 0
                    } á ${siz || 0} ${language.characters}</label>`
                  );

                  campossize = true;
                }
              }

              let ip = $(this);
              $('#template  label').each(function () {
                if ($(this).attr('for') === ip.attr('id')) {
                  if ($(this).text().split('*')[1] != undefined) {
                    if ($(this).text().split('*')[1].length >= 0) {
                      if (ip.attr('value').length == 0) {
                        camposobrigatorios = true;
                      }
                    }
                  }
                }
              });
            }
          });

          $('#template select').each(function () {
            $('#' + $(this).children('option:selected').attr('id')).attr(
              'selected',
              'selected'
            );

            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: $(this).find(':selected').text(),
            });
          });

          $('#template label').each(function () {
            try {
              let textArea = $(this)
                .parent()
                .parent()
                .nextAll()
                .slice(2, 3)
                .children()[0];
              if (
                textArea &&
                textArea.firstChild &&
                textArea.firstChild.tagName == 'P' &&
                textArea.firstChild.textContent.length > 0
              ) {
                fields.push({
                  name: $(this).attr('for'),
                  value: textArea.firstChild.textContent,
                });
              }

              if ($(this).attr('for').indexOf('textarea') > -1) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    let node_ = $(this)
                      .parent()
                      .parent()
                      .nextAll()
                      .slice(2, 3)
                      .children()[0];
                    if (
                      (node_ &&
                        node_.firstChild.tagName == 'P' &&
                        node_.firstChild.textContent.length == 0) ||
                      (!node_ &&
                        $('#' + $(this).attr('for')).text().length == 0)
                    ) {
                      camposobrigatorios = true;
                    }
                  }
                }
              }
            } catch (e) { }
          });

          values.template = this.createRandomToken($('#template').html());
          values.fields = fields;
        }

        setTimeout(function () {
          $('#formtemplabel__').fadeOut().empty();
          $('#template .form-control').css('border-color', '#019fc2');
        }, 9000);
        if (
          camposobrigatorios == false &&
          campossize == false &&
          camposobrigatorioscheck == false &&
          camposobrigatoriosradio == false
        ) {
          setTimeout(
            function () {
              this.onFormSubmitItem(values);
            }.bind(this),
            1000
          );
        } else {
          this.setState({ ds: true });
          if (
            camposobrigatorios ||
            camposobrigatoriosradio ||
            camposobrigatorioscheck
          )
            this.store.toggleAlert(
              true,
              language.INFORM_REQUIRED_FIELDS,
              'error'
            );

          if (campossize)
            this.store.toggleAlert(true, language.CHECK_FIELD_SIZES, 'error');
        }
      }
    }
  }

  componentWillMount() {
    this.ondonwFile = this.ondonwFile.bind(this);
    try {
      let item = this.props.location.state.page;
      this.setState({ page: 'form' });
    } catch (r) { }

    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/60',
      'priorities'
    );

    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/130',
      'complexities'
    );

    this.action
      .execute('get', this.APIEndpoints.GENERAL_PARAMETERS, 'general_params')
      .then((result) => {
        this.setState({ params: result.data });
      })
      .catch((error) => { });
  }

  onChangeField(field, value) {
    if (field?.name === 'category') {
      this.setState({
        categoryValue: value,
      });
    }

    if ( field?.name === 'id_complexity' ) {
      console.log('here', value);
      this.setState({
         id_complexity: value,
       });
    }
  }

  ondonwFile(id, file, type) {
    if (type == 'request') {
      window.open(
        this.APIEndpoints.REQUESTS + '/downloadrequest/' + id + '&' + file + '',
        '_blank'
      );
    } else {
      window.open(
        this.APIEndpoints.REQUESTS + '/downloadticket/' + id + '&' + file + '',
        '_blank'
      );
    }
  }

  handleOrderBy(va) {
    this.setState({ st: true });
  }

  delayOpen(tipo, id_tickets) { }

  handlePosition = (p) => {
    this.setState((state) => ({
      position: Math.max(0, state.position + p),
    }));
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      search: value,
    });
    this.setState({ filter: { ...this.state.filter, search: value } });
  };

  Updash() {
    this.setState({ openTicket: false });

    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/60',
      'priorities'
    );

    this.action
      .execute('get', this.APIEndpoints.GENERAL_PARAMETERS, 'general_params')
      .then((result) => {
        this.setState({ params: result.data });
      });
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  searchfind(value) {
    this.setState({ search: value });
    this.setState({ filter: { ...this.state.filter, search: value } });
  }

  clearFilter() {

    this.setState({ cleanValueSolicitant: true });
    setInjetSearchColumn(window.location.pathname + '/gsd', { 'filterDashboard': '' })
    // this.props.getDashboard(null);
    this.setState({ filter: { ...[], search: null } });

    this.filterDashboard('')
    this.setState({ namesFilter: [] });
    this.setState({ filter: [] });
    this.setState({ search: '' });
    this.setState({ cleanValueSolicitant: true });

    this.setState({ filter: { dtfinal: "" } });
    this.setState({ namesFilter: { departament_: '' } });
    this.setState({ namesFilter: { departament: [] } });
  }
  clearSearch() {
    this.setState({ search: '' });
    this.setState({ filter: { ...this.state.filter, search: '' } });
  }

  windowReaload() {
    const reload = !this.state.filter?.reload;
    this.setState({ filter: { ...this.state.filter, reload } });
  }

  updateTicket() {
    let update = true;

    let item = this.props.dashboard.map((obj) => {
      if (this.store.ticket.id_tickets === obj.id_tickets) {
        let t = this.store.ticket;

        t.progress = this.store.ticket.config[0].progress;
        t.stop_ticket = this.store.ticket.config[0].stop_ticket;
        if (t.progress == obj.progress && t.stop_ticket == obj.stop_ticket) {
          update = false;
        }
        return t;
      } else {
        return obj;
      }
    });

    if (update == true) {
      //this.setState({dashlist:item});
    }
  }

  handleValues(value, type) {
    this.setState({ zerarempresa: true });
    if (type == 'department') {
      this.setState({ department: value, totalLigado: [], totalDesligado: [] });
    }
  }

  filterDashboard(values) {
    if (values) {
      setInjetSearchColumn(window.location.pathname + '/gsd', { 'filterDashboard': values })
    }
    this.props.getDashboard(values);
    this.setState({ filter: { ...values, search: this.state.search } });
  }


  handleNewRequest() {
    if (this.state.params.request_by_portal) {
      this.props.redirect2('/dash/portal');
    } else {
      this.handleDialog(1);
    }
  }

  render() {
    const {
      departments,
      devices,
      language,
      redirect,
      users,
      itensgsd,
      requests,
      dt_inicial,
      dt_final,
    } = this.props;
    const { department, filter, dialog, month, year, params } = this.state;
    let { GSD } = this.store.theme;
    let request = requests;

    if (params.length > 0) {
      request = request.filter(
        (request) =>
          (moment(request.dt_cad).add(
            this.state.params[0].vision_request,
            'days'
          ) > moment() &&
            (request.status == 'Cancelled' || request.status == 'Reject')) ||
          request.status == 'Open' ||
          request.status == 'Approve' ||
          request.status == 'Approved' ||
          request.status == null
      );
    }

    let dashboard_ = this.state.dashlist.length > 0 ? this.state.dashlist : [];

    if (this.state.search != '' && this.state.search != undefined) {
      request = request.filter((item) => {
        let data = JSON.stringify(item, function (k, v) {
          if (!k) return v;
          if (typeof v != 'object') return v;
        });
        return (
          data.toUpperCase().indexOf(this.state.search.toUpperCase()) != -1
        );
      });

      dashboard_ = dashboard_.filter((item) => {
        let data = JSON.stringify(item, function (k, v) {
          if (!k) return v;
          if (typeof v != 'object') return v;
        });
        return (
          data.toUpperCase().indexOf(this.state.search.toUpperCase()) != -1
        );
      });
    }

    if (dt_final != '' && dt_inicial != '') {
      request = request.filter(
        (g) =>
          moment(g.dt_cad).format('YYYYMMDD') >=
          moment(dt_inicial).format('YYYYMMDD') &&
          moment(g.dt_cad).format('YYYYMMDD') <=
          moment(dt_final).format('YYYYMMDD')
      );
    }

    let item = [];

    const statusList = [
      {
        title: language.TICKET_WORKFLOW_OF_CHANGE.OPEN,
        index: 1,
        status: 'Open',
      },
      {
        title: language.TICKET_STATUS.IN_PROGRESS,
        index: 2,
        status: 'In Progress',
      },
      {
        title: language.WAITING,
        index: 6,
        status: 'Aguardando',
      },
      {
        title: language.TICKET_STATUS.REOPENED,
        index: 3,
        status: 'Reopened',
      },
      {
        title: language.TICKET_STATUS.RESOLVED,
        index: 4,
        status: 'Resolved',
      },
      {
        title: language.TICKET_STATUS.FINALIZED,
        index: 5,
        status: 'Closed',
      },
    ];

    item.push({ value: 0, label: 'TODOS' });

    return (

      <PageLayout
        icon="imgGsd titleIconSize"
        title={language.DASHBOARD}
        subTitle={`${language.DASHBOARD}/`}
        smallTitle={true}
        rightElements={[
          <div
            style={{ width: '100%', zoom: 0.8, marginTop: 25, height: '50px' }}
            zoom={0.8}
          >
            <CommonForm
              onSubmit={this.filterDashboard}
              values={this.state.namesFilter}
              onChangeField={(f, v) => {
                this.onChangeField(f, v)
                this.setState({ cleanValueSolicitant: false });
              }}
              individual
              hiddenSubmit
              contentProps={{
                style: {
                  'white-space': 'nowrap',
                },
              }}
              fields={[
                {
                  // visible: this.store.getUserLoged().role != 'solicitant',
                  col: 1.5,
                  name: 'fk_id_department',
                  textlabel: language.DEPARTMENT,
                  type: 'autocomplete',
                  name: 'departament',
                  label: language.DEPARTMENT,
                  method: 'POST',
                  route: `${this.APIEndpoints.DEPARTMENT}/filter`,
                  routeGetCount: `${this.APIEndpoints.DEPARTMENT}/count?`,
                  fieldquery: 'search',
                  textinit: this.state.namesFilter['departament_'] ? this.state.namesFilter['departament_'].label : '',
                  fieldvaluedb: 'id_department',
                  fieldlabeldb: 'name',
                  idinit: this.state.namesFilter['departament'] ? this.state.namesFilter['departament'] : [],
                  routeAll: `${this.APIEndpoints.DEPARTMENT}/list?`,
                  filterAll: { orderby: 'name' },
                  methodGetAll: 'POST',
                  detail: true,
                  isMulti: true,
                  loadOnFocus: true,
                  cleanValue: this.state.cleanValueSolicitant,
                  fromCommonTable: true,
                },
                {
                  // visible: this.store.getUserLoged().role != 'solicitant',

                  col: 1.5,
                  type: 'autocomplete',
                  name: 'category',
                  label: language.CATEGORY,
                  textlabel: language.CATEGORY,
                  method: 'POST',
                  route: `${this.APIEndpoints.CATEGORY}/list`,
                  fieldquery: 'search',
                  textinit: this.state.namesFilter['category_'] ? this.state.namesFilter['category_'].label : '',
                  fieldvaluedb: 'id_category',
                  fieldlabeldb: 'name',
                  idinit: this.state.namesFilter['category'] ? this.state.namesFilter['category'] : [],
                  routeAll: `${this.APIEndpoints.CATEGORY}/list?`,
                  methodGetAll: 'POST',
                  isMulti: true,
                  detail: true,
                  loadOnFocus: true,
                  cleanValue: this.state.cleanValueSolicitant,
                  fromCommonTable: true,
                },
                {
                  // visible: this.store.getUserLoged().role != 'solicitant',
                  visible: this.store.getUserLoged().role != 'solicitant',
                  col: 1.5,
                  type: 'autocomplete',
                  name: 'serviceCatalog',
                  label: language.SERVICE_CATALOG,
                  textlabel: language.SERVICE_CATALOG,
                  method: 'POST',
                  route: `${this.APIEndpoints.CATALOG}/category/all`,
                  fieldquery: 'search',
                  textinit: this.state.namesFilter['serviceCatalog_'] ? this.state.namesFilter['serviceCatalog_'].label : '',
                  fieldvaluedb: 'id_catalog_service',
                  fieldlabeldb: 'name',
                  idinit: this.state.namesFilter['serviceCatalog'] ? this.state.namesFilter['serviceCatalog'] : [],
                  routeAll: `${this.APIEndpoints.CATALOG}/category/all?`,
                  methodGetAll: 'POST',
                  detail: true,
                  isMulti: true,
                  cleanValue: this.state.cleanValueSolicitant,
                  filterAll: { id_category: this.state.categoryValue },
                  loadOnFocus: true,
                  fromCommonTable: true,
                },
                {
                  // visible: this.store.getUserLoged().role != 'solicitant',
                  col: 1.5,
                  type: 'autocomplete',
                  name: 'groupTech',
                  label: language.GROUP_TECH,
                  className: 'tech-group-ticket',
                  required: false,
                  method: 'POST',
                  textlabel: language.GROUP_TECH,
                  route: `${this.APIEndpoints.GROUP}/filter`,
                  fieldquery: 'search',
                  textinit: this.state.namesFilter['groupTech_'] ? this.state.namesFilter['groupTech_'].label : '',
                  fieldvaluedb: 'id_group_users',
                  fieldlabeldb: 'name',
                  idinit: this.state.namesFilter['groupTech'] ? this.state.namesFilter['groupTech'] : [],
                  routeAll: `${this.APIEndpoints.GROUP}/all?`,
                  detail: true,
                  methodGetAll: 'POST',
                  filterAll: {
                    id_user: this.store.getUserLoged().id,
                    orderby: 'name',
                    type_search: 1,
                  },
                  routeGetCount: `${this.APIEndpoints.GROUP}/count`,
                  isMulti: true,
                  cleanValue: this.state.cleanValueSolicitant,
                  loadOnFocus: true,
                  fromCommonTable: true,
                },
                {
                  // visible: this.store.getUserLoged().role != 'solicitant',
                  col: 1.5,
                  name: 'id_user',
                  label: language.USER,
                  textlabel: language.USER,
                  type: 'autocomplete',
                  name: 'userTech',
                  label: language.TECH,
                  className: 'tech-group-ticket',
                  required: false,
                  method: 'POST',
                  route: `${this.APIEndpoints.USER}/filter/notsolicitant`,
                  routeGetCount: `${this.APIEndpoints.USER}/active/count/notsolicitant`,
                  fieldquery: 'search',
                  textinit: this.state.namesFilter['userTech_'] ? this.state.namesFilter['userTech_'].label : '',
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: this.state.namesFilter['userTech'] ? this.state.namesFilter['userTech'] : [],
                  routeAll: `${this.APIEndpoints.USER}/filter/notsolicitant?`,
                  detail: true,
                  methodGetAll: 'POST',
                  isMulti: true,
                  loadOnFocus: true,
                  cleanValue: this.state.cleanValueSolicitant,
                  fromCommonTable: true,
                },
                {
                  col: 1.5,
                  name: 'id_complexity',
                  type: 'select',
                  options: this.store.complexities.map((complexity) => {
                     return {
                       value: complexity.id_table,
                       label: translateFromPtBR(complexity.description, language)
                     };
                  }),
                  label: language.COMPLEXITY,
                  cleanValue: this.state.cleanValueSolicitant,
                },
                {
                  col: 1,
                  name: 'dtinicial',
                  type: 'date',
                  label: language.INITIAL_DATE,
                  endIcon: 'fas fa-calendar-alt',
                  cleanValue: this.state.cleanValueSolicitant,
                },
                {
                  col: 1,
                  name: 'dtfinal',
                  type: 'date',
                  label: language.FINAL_DATE,
                  endIcon: 'fas fa-calendar-alt',
                  cleanValue: this.state.cleanValueSolicitant,
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      icon="fas fa-search"
                      color="success"
                      variant="normal"
                      label=""
                      style={{
                        marginTop: 13,
                      }}
                      onClick={() => {
                        submit();
                        this.setState({ cleanValueSolicitant: false });
                      }}
                    />
                  ),
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      onClick={() => {
                        this.clearFilter();
                      }}
                      variant="circle"
                      icon="fas fa-eraser"
                      color="success"
                      label={language.CLEAR}
                      style={{
                        fontSize: 20,
                        marginLeft: 5,
                        marginRight: -200,
                        marginTop: 13,
                      }}
                    />
                  ),
                },
              ]}
            />
          </div>,
        ]}
      >
        {this.state.openTicket && (
          <CommonDialog
            title={''}
            open={this.state.openTicket}
            maxWidth="xl"
            onClose={() => {
              this.Updash();
            }}
          >
            <TicketManagement
              detail={true}
              id_ticket_detail={this.state.ticketToOpen}
              isHistory
            ></TicketManagement>
          </CommonDialog>
        )}
        <div id="GSDPage">
          <Header>
            <Button
              variant="circle"
              icon="fas fa-sync"
              color="warning"
              className="mr-3"
              onClick={() => this.windowReaload()}
              tooltipLabel={language.REFRESH_PAGE}
            />
            <Button
              icon="fas fa-plus"
              color="warning"
              label={language.ADD}
              variant="circle"
              className="mr-3"
              onClick={() => this.handleNewRequest()}
            />

            <SearchInput
              onChange={(v) => {
                this.searchfind(v.target.value);
              }}
              clearSeach={() => this.clearSearch()}
            />

            <div style={{ textAlign: 'right', width: '100%' }}>
              <Button
                variant="circle"
                color="warning"
                icon="fas fa-arrow-left"
                label={language.PREV}
                className="mx-1"
                onClick={() => left()}
              />

              <Button
                variant="circle"
                color="warning"
                icon="fas fa-arrow-right"
                label={language.NEXT}
                className="mx-1"
                onClick={() => right()}
              />
            </div>
          </Header>
          <Card className="content">
            {dialog == 1 ? (
              <DialogForm
                className="block"
                onFormSubmit={this.onFormSubmit}
                redirect={redirect}
                language={language}
                categories={this.store.categories}
                catalogs={this.store.catalogs}
                tasks={this.store.tasks}
                formstemplate={this.store.formstemplate}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                store={this.store}
                open={dialog == 1}
                onClose={() => this.handleDialog()}
                ds={this.state.ds}
                setDs={(state) => this.setState({ ds: state })}
              />
            ) : null}

            <div
              id="_gsdcarrosell"
              style={{ flex: `1`, display: `flex`, overflow: `auto` }}
            >
              <div id=" " style={{ display: 'flex' }}>
                {
                  <GSDGridListRequest
                    getDashboard={() => null}
                    priorities={this.store.priorities}
                    TicketManagement={TicketManagement}
                    RequestContainer={RequestContainer}
                    user={this.store.getUserLoged().id}
                    title={language.CATEGORIZATION}
                    clearticket={this.clearticket}
                    redirect={redirect}
                    index={0}
                    users={users}
                    language={language}
                    status={status.status}
                    APIEndpoints={this.APIEndpoints}
                    tokenApi={this.store.getToken()}
                    filter={this.state.filter}
                    GSD_THEME={GSD}
                    filterUserGroup={this.store.getUserLoged().id}
                    setOpenTicket={(state) =>
                      this.setState({ openTicket: state })
                    }
                    setTicketToOpen={(state) =>
                      this.setState({ ticketToOpen: state })
                    }
                  />
                }
                {statusList.map((status) => (
                  <GSDGridList
                    getDashboard={this.filterDashboard}
                    RequestContainer={RequestContainer}
                    priorities={this.store.priorities}
                    TicketManagement={TicketManagement}
                    user={this.store.getUserLoged().id}
                    title={status.title}
                    redirect={redirect}
                    index={status.index}
                    msn={this.store.new_msg_notifications}
                    socket={this.store.socket}
                    clearticket={this.clearticket}
                    language={language}
                    status={status.status}
                    APIEndpoints={this.APIEndpoints}
                    tokenApi={this.store.getToken()}
                    filter={this.state.filter}
                    GSD_THEME={GSD}
                    filterUserGroup={this.store.getUserLoged().id}
                  />
                ))}
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center justify-content-center py-2">
                <Button
                  variant="circle"
                  outlined
                  icon="fas fa-arrow-left"
                  label={language.PREV}
                  className="mx-1"
                  onClick={() => left()}
                />

                <Button
                  variant="circle"
                  outlined
                  icon="fas fa-arrow-right"
                  label={language.NEXT}
                  className="mx-1"
                  onClick={() => right()}
                />
              </div>
            </div>
          </Card>
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(DashboardPage);
