import React, { Component, Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Dictionary from '@common/languages/Dictionary';
import Moment from 'moment-timezone';
import {es, en, ptBR} from 'date-fns/locale';
import Store from '@data/Store';
const { language } = Store;

export default class Pickers extends React.Component {
  dateTimeFormat(locale, options) {
    let language = sessionStorage.getItem('sensr_lang');
    language = !language
      ? (navigator.language || navigator.userLanguage)
          .toUpperCase()
          .replace('-', '_') == 'PT_BR'
        ? 'PT_BR'
        : (navigator.language || navigator.userLanguage)
              .toUpperCase()
              .replace('-', '_') == 'EN'
          ? 'EN'
          : 'ES'
      : language;

    let dayAbbreviation = Dictionary[language].DATE_LANG.dayAbbreviation;
    let dayList = Dictionary[language].DATE_LANG.dayList;
    let monthList = Dictionary[language].DATE_LANG.monthList;
    let monthLongList = Dictionary[language].DATE_LANG.monthLongList;

    this.format = function (date) {
      if (
        options.month === 'short' &&
        options.weekday === 'short' &&
        options.day === '2-digit'
      ) {
        return (
          dayList[date.getDay()] +
          ', ' +
          monthList[date.getMonth()] +
          ' ' +
          date.getDate()
        );
      } else if (
        options.year === 'numeric' &&
        options.month === 'numeric' &&
        options.day === 'numeric'
      ) {
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        );
      } else if (options.year === 'numeric' && options.month === 'long') {
        return monthLongList[date.getMonth()] + ' ' + date.getFullYear();
      } else if (options.weekday === 'narrow') {
        return dayAbbreviation[date.getDay()];
      } else if (options.year === 'numeric') {
        return date.getFullYear().toString();
      } else if (options.day === 'numeric') {
        return date.getDate();
      } else {
        process.env.NODE_ENV !== 'production'
          ? (0, _warning2.default)(
              false,
              'Material-UI: Wrong usage of DateTimeFormat',
            )
          : void 0;
      }
    };
  }
  render() {
    let { type, format, id, ...other } = this.props;

    const valueTime = new Date();
    if (other.value != null && other.value != undefined) {
      let valueSplit = String(other.value)?.split(' ')[1]?.split(':') || null;

      if (valueSplit?.length < 2) {
        const formated = Moment(other.value).format('YYYY-MM-DD HH:mm:ss')
        valueSplit = String(formated).split(' ')[1].split(':');
      }

      try {
        valueTime.setHours(valueSplit[0]);
        valueTime.setMinutes(valueSplit[1]);
      } catch {}
    }

    if (other.f.cleanValue == true) {
      other.onChange('');
    }

    //Solução paleativa para deslocamento de hora no browser
    // if (other.value != null)
    //   other.value = other.value.replace(/-04(?=:00$)/, '-03');

    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={sessionStorage.getItem('sensr_lang') === "ES" ? es : sessionStorage.getItem('sensr_lang') === "EN" ? enUS : ptBR}>
          {type == 'date' ? (
            <KeyboardDatePicker
              {...other}
              format={this.dateTimeFormat}
              allowKeyboardControl={true}
              DateTimeFormat={this.dateTimeFormat}
              okLabel={<span style={{ color: "var(--primary)" }}>{language.CONFIRM}</span>}
              cancelLabel={<span style={{ color: "var(--danger)" }}>{language.CANCEL}</span>}
              firstDayOfWeek={0}
              animateYearScrolling
              autoOk={true}
              fullWidth={true}
              format={format || 'dd/MM/yyyy'}
              disableOpenOnEnter
              inputVariant="outlined"
              keyboard
              id={id}
              keyboardIcon={<i className="fas fa-calendar-alt" />}
            />
          ) : type == 'time' ? (
            <KeyboardTimePicker
              {...other}
              value={other.value ? valueTime : null}
              okLabel={<span style={{ color: "var(--primary)" }}>{language.CONFIRM}</span>}
              cancelLabel={<span style={{ color: "var(--danger)" }}>{language.CANCEL}</span>}
              clearLabel={<span style={{ color: "var(--warning)" }}>{language.CLEAR}</span>}
              clearable
              ampm={false}
              id={id}
              fullWidth={true}
              disableOpenOnEnter
              keyboard
              inputVariant="outlined"
              keyboardIcon={<i className="fas fa-clock" />}
              onChange={(v) => {
                other.onChange(v);
              }}
            />
          ) : null}
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
}
