import CommonTable from '../../common/CommonTable';
import Tag from '../../common/Tag';
import Button from '../../common/Button';
import { useEffect } from 'react';

export default function AutomationList({
  language,
  handlePage,
  onSubmitDelete,
  valuesTable,
}) {

  return (
    <CommonTable
      searchColumn
      beforeBar={
        <Button
          icon="fas fa-plus"
          variant="circle"
          color="warning"
          label={language.ADD}
          className="mr-3"
          onClick={() => handlePage('create')}
        />
      }
      col={[
        { key: 'id', label: 'ID', thConfig: true, style: { width: '1%' } },
        {
          key: 'rule',
          label: language.RULE,
          thConfig: true,
          style: { width: '5%' },
        },
        {
          key: 'company',
          label: language.COMPANY,
          thConfig: true,
          style: { width: '10%' },
        },
        {
          key: 'desc',
          label: language.DESCRIPTION,
          thConfig: true,
          style: { width: '10%' },
        },
        {
          key: 'recurrent',
          label: language.RECURRENCE,
          thConfig: true,
          style: { width: '5%' },
        },
        {
          key: 'action',
          label: language.ACTION,
          thConfig: true,
          style: { width: '5%' },
        },
      ]}
      data={valuesTable.map((e) => ({
        id: e.fk_id_automation,
        rule: e.rule,
        company: e.namecompany,
        desc: e.description,
        recurrent: (
          <Tag
            label={e.recurrence ? language.YES : language.NOT}
            color={e.recurrence ? 'var(--success)' : 'var(--primary)'}
          />
        ),
        action: (
          <div className="d-flex align-items-center">
            <Button
              variant="circle"
              icon="fas fa-edit"
              label={language.EDIT}
              color="default"
              style={{ fontSize: 13 }}
              size={0.7}
              className="mr-2"
              onClick={() => handlePage('edit', e)}
            />
            <Button
              variant="circle"
              icon="far fa-trash-alt"
              label={language.DELETE}
              color="danger"
              style={{ fontSize: 14 }}
              size={0.7}
              onClick={() => onSubmitDelete(e.fk_id_automation)}
            />
          </div>
        ),
      }))}
    />
  );
}
