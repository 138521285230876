import React, { Component, Fragment, useEffect, useState } from 'react';

import Header from '../../../common/Header';
import Button from '../../../common/Button';
import PageLayout from '../../../common/PageLayout';
import CommonPage from '../../../common/CommonPage';
import CommonForm from '../../../common/CommonForm2';
import Constants from '../../../data/Constants';
import Actions from '@data/Actions';
import './index.css';
import Store from '@data/Store';
const action = new Actions();
const { language_type } = Store;

const index = (props) => {
  const { language } = props;
  const [data, setData] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersInitName, setUsersInitName] = useState(null);
  const [groupsInitName, setGroupsInitName] = useState(null);
  const [roleOptions, setGroups] = useState([]);
  const [modules, setModules] = useState([]);
  const [events, setEvents] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [values, setValues] = useState([]);
  const [valuesEndpoint, setValuesEndpoint] = useState(null);
  const [tags, setTags] = useState([]);
  const [isUsersRequired, setUsersRequired] = useState(true);
  const [isGroupsRequired, setGroupsRequired] = useState(true);
  const [isIncludeSetted, setIncludeSetted] = useState(true);
  const [conditionsList, setConditionsList] = useState([]);
  const [type, setType] = useState(null);
  const [firstAndConditions, setFirstAndConditions] = useState([]);
  const [andConditions, setAndConditions] = useState([]);
  const [firstOrConditions, setFirstOrConditions] = useState([]);
  const [orConditions, setOrConditions] = useState([]);
  const [moduleId, setModuleId] = useState();
  const [errorNoConditions, setErrorNoConditions] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isTicketManagement, setIsTicketManagement] = useState(false);

  const [loadingRule, setLoadingRule] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingConditionals, setLoadingConditionals] = useState(false);

  const loadNotificationData = (id) => {
    action
      .execute('get', `${Constants.APIEndpoints.NOTIFICATION}/rule/${id}`, '')
      .then((res) => {
        res = res.data;
        setLoadingRule(false);

        let notification = res.data;
        notification.module = notification.module_id;
        notification.eventId = notification.module_event_id;

        if (!notification.priority) notification.priority = 'normal';

        setModuleId(notification.module_id);

        setData(notification);

        if (notification.users.length > 0) {
          loadUsers(notification.users);
        } else {
          setLoadingUser(false);
        }

        if (notification.groups.length > 0) {
          loadGroups(notification.groups);
        } else {
          setLoadingGroup(false);
        }

        setGroupsRequired(notification.users.length === 0);
        setUsersRequired(notification.groups.length === 0);

        loadEvents(notification.module);

        if (notification.module === 1) {
          setIsTicketManagement(true);
        }

        loadTags(notification.eventId);

        action
          .execute('get', `${Constants.APIEndpoints.NOTIFICATION}/module/${notification.module}/conditional`, '')
          .then((res) => {
            res = res.data;
            setLoadingConditionals(false);
            let conditions = res.data.map((condition) => ({
              label: language_type.includes('PT')
                ? condition.description
                : language_type.includes('EN')
                  ? condition.en_description
                  : condition.es_description,
              value: condition.id,
              is_related_value: condition.is_related_value,
              is_related_table: condition.is_related_table,
            }));
            setConditions(conditions);

            if (notification.condition.and) {
              notification.condition.and.map((cond) => {
                cond.conditional_id = cond.condition_id;
                delete cond.condition_id;

                let value = conditions.filter((val) => {
                  return val.value === cond.conditional_id;
                });
                cond.conditional_label = value[0]?.label;

                cond.operator = cond.logical_operator;
                delete cond.logical_operator;

                return cond;
              });

              if (notification.condition.and.length > 0) {
                setFirstAndConditions(notification.condition.and);
              }
            }

            if (notification.condition.or) {
              notification.condition.or.map((cond) => {
                cond.conditional_id = cond.condition_id;
                delete cond.condition_id;

                let value = conditions.filter((val) => {
                  return val.value === cond.conditional_id;
                });
                cond.conditional_label = value[0]?.label;

                cond.operator = cond.logical_operator;
                delete cond.logical_operator;

                return cond;
              });

              if (notification.condition.or.length > 0) {
                setFirstOrConditions(notification.condition.or);
              }
            }
          });
      });
  };

  const loadUsers = (data) => {
    let items = {
      fk_id_user: data,
    };
    action
      .execute('post', `${Constants.APIEndpoints.USER}/filter/all`, '', items)
      .then((res) => {
        res = res.data;
        setLoadingUser(false);
        let users = res.filter((userResponse) =>
          data.some((userData) => userResponse.id_user === userData),
        );
        setUsers(users);

        if (users.length > 0) {
          setUsersInitName(
            users.map((user, index) => {
              return user.name;
            }),
          );
        }
      });
  };

  const loadGroups = (data) => {
    const fetchGroup = (id) => {
      let items = {
        id: id, // Define o valor de id com o valor atual do array data
        name: '',
        description: '',
        name_company: '',
        type: '',
        actions: '',
      };

      return action
        .execute('post', `${Constants.APIEndpoints.GROUP}/all`, '', items)
        .then((res) => {
          return res.data
        });
    };

    const fetchAllGroups = async () => {
      const groupPromises = data.map((id) => fetchGroup(id));

      try {
        const responses = await Promise.all(groupPromises);

        const filteredGroups = [];
        responses.map((group) => {
          group.map((g) => {
            filteredGroups.push(g);
          });
        });

        setGroups(filteredGroups);
        setLoadingGroup(false);

        if (filteredGroups.length > 0) {
          let dataList = data.map((d) => {
            return d.toString();
          });

          let groups = filteredGroups.filter((groupResponse) =>
            dataList.some(
              (userData) => groupResponse.id_group_users === userData,
            ),
          );

          setGroupsInitName(
            groups.map((group, index) => {
              const isLast = index === filteredGroups.length - 1;
              return isLast ? group.name : group.name;
            }),
          );
        }
      } catch (error) {
        console.error('Erro ao carregar grupos:', error);
      }
    };

    // Chama a função para fazer todas as solicitações
    fetchAllGroups();
  };

  const loadModules = () => {
    action
      .execute('get', `${Constants.APIEndpoints.NOTIFICATION}/module`, '')
      .then((res) => {
        res = res.data;
        let modules = res.data.map((module) => ({
          label: language_type.includes('PT')
            ? module.description
            : language_type.includes('EN')
              ? module.en_description
              : module.es_description,
          value: module.id,
        }));

        setModules(modules);
      });
  };

  const loadEvents = (id) => {
    action
      .execute('get', `${Constants.APIEndpoints.NOTIFICATION}/module/${id}/event`, '')
      .then((res) => {
        res = res.data;
        setLoadingEvents(false);
        let events = res.data.map((event) => ({
          label: language_type.includes('PT')
            ? event.description
            : language_type.includes('EN')
              ? event.en_description
              : event.es_description,
          value: event.id,
        }));
        setEvents(events);
      });
  };

  const loadTags = (id) => {
    action
      .execute('get', `${Constants.APIEndpoints.NOTIFICATION}/event/${id}/tag`, '')
      .then((res) => {
        res = res.data;
        setLoadingTags(false);
        const tagObject = {};
        res.data.forEach((tag) => {
          tagObject[tag.name] = language_type.includes('PT')
            ? tag.description
            : language_type.includes('EN')
              ? tag.en_description
              : tag.es_description;
        });
        setTags(tagObject);
      });
  };

  const loadConditions = (id) => {
    action
      .execute('get', `${Constants.APIEndpoints.NOTIFICATION}/module/${id}/conditional`, '')
      .then((res) => {
        res = res.data;
        let conditions = res.data.map((condition) => ({
          label: language_type.includes('PT')
            ? condition.description
            : language_type.includes('EN')
              ? condition.en_description
              : condition.es_description,
          value: condition.id,
          is_related_value: condition.is_related_value,
          is_related_table: condition.is_related_table,
        }));
        setConditions(conditions);
      });
  };

  const loadValues = (id) => {
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.NOTIFICATION}/conditional/${id}/value?`,
        '',
        { search: '' },
      )
      .then((item) => {
        setValues(item.data.data);
      });
  };

  const updateAndConditions = () => {
    firstAndConditions.map((cond) => {
      if (!cond.value_label) {
        action
          .execute(
            'post',
            `${Constants.APIEndpoints.NOTIFICATION}/conditional/${cond.conditional_id}/value?`,
            '',
            { id: parseInt(cond.value) },
          )
          .then((item) => {
            if (item.data.data.length === 1) {
              cond.value_label =
                language_type.includes('PT') && item.data.data[0].description
                  ? item.data.data[0].description
                  : language_type.includes('EN') &&
                      item.data.data[0].en_description
                    ? item.data.data[0].en_description
                    : language_type.includes('EN') &&
                        item.data.data[0].es_description
                      ? item.data.data[0].es_description
                      : item.data.data[0].value;
            } else if (item.data.data.length > 1) {
              let value = item.data.data.filter((val) => {
                return val.id === parseInt(cond.value);
              });
              if (value && value.length > 0) {
                cond.value_label =
                  language_type.includes('PT') && value[0]?.description
                    ? value[0]?.description
                    : language_type.includes('EN') && value[0].en_description
                      ? value[0].en_description
                      : language_type.includes('EN') && value[0].es_description
                        ? value[0].es_description
                        : value[0].value;
              } else {
                cond.value_label = cond.value;
              }
            } else {
              cond.value_label = cond.value;
            }

            setAndConditions((andConditions) => [...andConditions, cond]);
            return cond;
          });
      }
    });
  };

  const updateOrConditions = () => {
    firstOrConditions.map((cond) => {
      if (!cond.value_label) {
        action
          .execute(
            'post',
            `${Constants.APIEndpoints.NOTIFICATION}/conditional/${cond.conditional_id}/value?`,
            '',
            { id: parseInt(cond.value) },
          )
          .then((item) => {
            if (item.data.data.length === 1) {
              cond.value_label =
                language_type.includes('PT') && item.data.data[0].description
                  ? item.data.data[0].description
                  : language_type.includes('EN') &&
                      item.data.data[0].en_description
                    ? item.data.data[0].en_description
                    : language_type.includes('EN') &&
                        item.data.data[0].es_description
                      ? item.data.data[0].es_description
                      : item.data.data[0].value;
            } else if (item.data.data.length > 1) {
              let value = item.data.data.filter((val) => {
                return val.id === parseInt(cond.value);
              });
              if (value && value.length > 0) {
                cond.value_label =
                  language_type.includes('PT') && value[0]?.description
                    ? value[0]?.description
                    : language_type.includes('EN') && value[0].en_description
                      ? value[0].en_description
                      : language_type.includes('EN') && value[0].es_description
                        ? value[0].es_description
                        : value[0].value;
              } else {
                cond.value_label = cond.value;
              }
            } else {
              cond.value_label = cond.value;
            }

            setOrConditions((orConditions) => [...orConditions, cond]);
            return cond;
          });
      }
    });
  };

  useEffect(() => {
    loadModules();
    if (props.value?.id) {
      setLoadingRule(true);
      setLoadingUser(true);
      setLoadingGroup(true);
      setLoadingEvents(true);
      setLoadingTags(true);
      setLoadingConditionals(true);

      loadNotificationData(props.value.id);
    }
  }, [props.value]);

  useEffect(() => {
    Store.spinner =
      loadingRule ||
      loadingUser ||
      loadingGroup ||
      loadingEvents ||
      loadingTags ||
      loadingConditionals;
  }, [
    loadingRule,
    loadingUser,
    loadingGroup,
    loadingEvents,
    loadingTags,
    loadingConditionals,
  ]);

  useEffect(() => {
    updateAndConditions();
  }, [firstAndConditions]);

  useEffect(() => {
    updateOrConditions();
  }, [firstOrConditions]);

  const onChangeField = (field, value, selected, handle, fields, values) => {
    setData({ ...data, [field.name]: value });
    switch (field.name) {
      case 'module_id':
        setAndConditions([]);
        setOrConditions([]);
        loadEvents(value);
        loadConditions(value);
        if (value === 1) {
          setIsTicketManagement(true);
        } else {
          setIsTicketManagement(false);
        }
        setModuleId(value);
        setData({ ...data, eventId: undefined });
        break;
      case 'eventId':
        loadTags(value);
        break;
      case 'conditional_id':
        setValuesEndpoint(null);
        if (
          conditions.filter(
            (condition) =>
              condition.value === value &&
              (condition.is_related_value || condition.is_related_table),
          ).length > 0
        ) {
          loadValues(value);
          setTimeout(() => {
            setValuesEndpoint(
              `${Constants.APIEndpoints.NOTIFICATION}/conditional/${value}/value?`,
            );
          }, 500);
        } else {
          setValues([]);
        }
        break;
      case 'users':
        if (value.length === 0) {
          setUsersInitName(null);
        }
        setGroupsRequired(value.length === 0);
        break;
      case 'groups':
        if (value.length === 0) {
          setGroupsInitName(null);
        }
        setUsersRequired(value.length === 0);
        break;
    }

    if (values['module_id']) {
      switch (values['module_id']) {
        case 1:
          if (
            !values['include_designated_analyst'] &&
            !values['include_group_tech'] &&
            !values['include_requester']
          ) {
            setGroupsRequired(!(values['users']?.length > 0));
            setUsersRequired(!(values['groups']?.length > 0));
          } else {
            setGroupsRequired(false);
            setUsersRequired(false);
          }
          break;
        case 2:
          if (
            !values['include_change_manager'] &&
            !values['include_group_gmud'] &&
            !values['include_requester']
          ) {
            setGroupsRequired(!(values['users']?.length > 0));
            setUsersRequired(!(values['groups']?.length > 0));
          } else {
            setGroupsRequired(false);
            setUsersRequired(false);
          }
          break;
        case 3:
          setGroupsRequired(!(values['users']?.length > 0));
          setUsersRequired(!(values['groups']?.length > 0));
          break;
        case 4:
          if (
            !values['include_assigned_group'] &&
            !values['include_designated_analyst']
          ) {
            setGroupsRequired(!(values['users']?.length > 0));
            setUsersRequired(!(values['groups']?.length > 0));
          } else {
            setGroupsRequired(false);
            setUsersRequired(false);
          }
          break;
      }
    }
  };

  const saveCondition = (cond) => {
    let conditionFiltered = conditions.filter((val) => {
      return val.value === cond.conditional_id;
    });
    cond.conditional_label = conditionFiltered[0].label;

    if (values.length > 0) {
      action
        .execute(
          'post',
          `${Constants.APIEndpoints.NOTIFICATION}/conditional/${cond.conditional_id}/value?`,
          '',
          { id: cond.value },
        )
        .then((item) => {
          if (item.data.data.length === 1) {
            cond.value_label =
              language_type.includes('PT') && item.data.data[0].description
                ? item.data.data[0].description
                : language_type.includes('EN') &&
                    item.data.data[0].en_description
                  ? item.data.data[0].en_description
                  : language_type.includes('EN') &&
                      item.data.data[0].es_description
                    ? item.data.data[0].es_description
                    : item.data.data[0].value;
          } else {
            let valueFiltered = values.filter((val) => {
              return val.id === cond.value;
            });
            cond.value_label =
              valueFiltered.length > 0
                ? language_type.includes('PT') && valueFiltered[0].description
                  ? valueFiltered[0].description
                  : language_type.includes('EN') &&
                      valueFiltered[0].en_description
                    ? valueFiltered[0].en_description
                    : language_type.includes('EN') &&
                        valueFiltered[0].es_description
                      ? valueFiltered[0].es_description
                      : valueFiltered[0].value
                : cond.value;
          }

          if (type === 'E') {
            setAndConditions([...andConditions, cond]);
          } else if (type === 'OR') {
            setOrConditions([...orConditions, cond]);
          }
        });
    } else {
      cond.value_label = cond.value;

      if (type === 'E') {
        setAndConditions([...andConditions, cond]);
      } else if (type === 'OR') {
        setOrConditions([...orConditions, cond]);
      }
    }
  };

  const handleConditionDeleteAnd = (itemToDelete) => {
    const updatedConditionsList = andConditions.filter(
      (item) => item !== itemToDelete,
    );
    setAndConditions(updatedConditionsList);
  };

  const handleConditionDeleteOr = (itemToDelete) => {
    const updatedConditionsList = orConditions.filter(
      (item) => item !== itemToDelete,
    );
    setOrConditions(updatedConditionsList);
  };

  const saveNotification = (obj) => {
    let payload = {
      module_id: moduleId,
      module_event_id: obj.eventId,
      include_requester: !!obj.include_requester,
      include_group_tech: !!obj.include_group_tech,
      include_designated_analyst: !!obj.include_designated_analyst,
      include_group_leader: !!obj.include_group_leader,
      include_service_manager: !!obj.include_service_manager,
      include_change_manager: !!obj.include_change_manager,
      include_group_gmud: !!obj.include_group_gmud,
      include_assigned_group: !!obj.include_assigned_group,
      name: obj.name,
      description: obj.description,
      subject: obj.subject,
      priority: obj.priority,
      eventId: obj.eventId,
      message: obj.message,
      condition: {},
      users: obj.users,
      groups: obj.groups,
      recipients: obj.recipients || "",
      include_chat_history:
        obj.module_id === 1 && obj.include_chat_history
          ? obj.include_chat_history
          : false,
      include_technical_notes:
        obj.module_id === 1 && obj.include_technical_notes
          ? obj.include_technical_notes
          : false,
      include_ticket_history:
        obj.module_id === 1 && obj.include_ticket_history
          ? obj.include_ticket_history
          : false,
      include_form_fields:
        obj.module_id === 1 && obj.include_form_fields
          ? obj.include_form_fields
          : false,
    };

    if (andConditions.length > 0) {
      payload.condition.and = andConditions.map((cond) => {
        const clonedCond = { ...cond }; // Cria um clone do objeto cond
        delete clonedCond.conditional_label;
        delete clonedCond.value_label;
        return clonedCond;
      });
    }

    if (orConditions.length > 0) {
      payload.condition.or = orConditions.map((cond) => {
        const clonedCond = { ...cond }; // Cria um clone do objeto cond
        delete clonedCond.conditional_label;
        delete clonedCond.value_label;
        return clonedCond;
      });
    }

    if (!data.eventId) {
      setErrorMessage(language.FILL_REQUIRED);
      return;
    } else {
      setErrorMessage(null);
    }

    if (andConditions.length === 0 && orConditions.length === 0) {
      setErrorNoConditions(true);
    } else {
      setErrorNoConditions(false);

      let requestType = 'post';
      let endpoint = `${Constants.APIEndpoints.NOTIFICATION}/rule`;

      if (props.value?.id) {
        requestType = 'put';
        endpoint = `${Constants.APIEndpoints.NOTIFICATION}/rule/${props.value.id}`;
      }

      Store.spinner = true;
      action.execute(requestType, endpoint, '', payload).then(
        (res) => {
          Store.spinner = false;
          Store.toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');
          props.loadNotification();
          props.onClose();
        },
        (error) => {
          let messageError = language.ERROR_REQUEST;

          if (error.data?.message === 'E500') {
            messageError = language.ERROR_CODE.E500;
          } else if (
            error.data?.id_error === '85d74d15-02fc-414f-bf0a-d6d9c229a36f'
          ) {
            messageError = language.NAME_IS_IN_USE;
          }
          if (
            error.data?.id_error === 'e777f19e-0c1e-4702-80c2-bc96f80d1479' ||
            error.data?.id_error === 'b0e383c8-590f-4180-8b9a-2fb7ab8d1fbf'
          ) {
            messageError =
              language.TAG_TAGNAME_DOES_NOT_BELONG_TO_EVENT.replace(
                'tagName',
                error.data.tagName,
              );
          }
          Store.spinner = false;
          Store.toggleAlert(true, messageError, 'error');
        },
      );
    }
  };

  let fieldsByModule = [];
  if (moduleId === 1) {
    // Gestão de tickets
    fieldsByModule = [
      { col: 3, name: 'include_requester', label: language.REQUESTER, type: 'switch' },
      { col: 3, name: 'include_group_tech', label: language.GROUP_TECH, type: 'switch' },
      { col: 3, name: 'include_designated_analyst', label: language.DESIGNATED_ANALYST, type: 'switch' },
      { col: 3, name: 'include_group_leader', label: language.GROUP_LEADER, type: 'switch' },
      { col: 12, name: 'include_service_manager', label: 'Service Manager', type: 'switch' },
    ];
  } else if (moduleId === 2) {
    // Gestão de Mudanças
    fieldsByModule = [
      {
        col: 4,
        name: 'include_requester',
        label: language.REQUESTER,
        type: 'switch',
      },
      {
        col: 4,
        name: 'include_change_manager',
        label: language.CHANGE_MANAGER,
        type: 'switch',
      },
      {
        col: 4,
        name: 'include_group_gmud',
        label: language.GROUP_GMUD,
        type: 'switch',
      },
    ];
  } else if (moduleId === 4) {
    // Gestão de Tarefas
    fieldsByModule = [
      {
        col: 4,
        name: 'include_assigned_group',
        label: language.ASSIGNED_GROUP,
        type: 'switch',
      },
      {
        col: 4,
        name: 'include_designated_analyst',
        label: language.DESIGNATED_ANALYST,
        type: 'switch',
      },
    ];
  }

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <CommonForm
        tags={tags}
        values={data}
        fields={[
          {
            col: 6,
            name: 'name',
            label: language.NAME,
            type: 'text',
            required: true,
            disabled: !!props.value?.id,
          },
          {
            col: 6,
            name: 'description',
            label: language.DESCRIPTION,
            type: 'text',
            required: true,
          },
          {
            col: 6,
            name: 'module_id',
            label: language.MODULE,
            type: 'select',
            options: modules,
            required: true,
          },
          {
            col: 6,
            name: 'eventId',
            label: language.EVENT,
            type: 'select',
            options: events,
            required: true,
            disabled: events.length === 0,
          },
          {
            col: 6,
            name: 'subject',
            label: language.SUBJECT,
            type: 'text',
            placeholder: language.NOTIFICATION_SUBJECT_HOLDER,
            required: true,
          },
          {
            col: 6,
            name: 'priority',
            label: language.PRIORITY,
            type: 'select',
            options: [
              { label: 'High', value: 'high' },
              { label: 'Normal (default)', value: 'normal' },
              { label: 'Low', value: 'low' },
            ],
            required: true,
          },
          {
            col: 12,
            type: 'element',
            content: (v, h, submit) => (
              <div>
                <CommonForm
                  values={[]}
                  fields={[
                    {
                      col: 4,
                      name: 'conditional_id',
                      label: language.FILTER_CONDITION,
                      type: 'select',
                      options: conditions,
                      required: true,
                      disabled: conditions.length === 0,
                    },
                    {
                      col: 4,
                      name: 'operator',
                      label: language.LOGICAL_CONDITIONS,
                      type: 'select',
                      options: [
                        {
                          label: language.EQUAL_TO,
                          value: 'Equals',
                        },
                        {
                          label: language.DIFFERENT_FROM,
                          value: 'Notequals',
                        },
                      ],
                      required: true,
                    },
                    {
                      col: 4,
                      name: 'value',
                      label: language.VALUE,
                      type: valuesEndpoint ? 'autocomplete' : 'text',
                      required: true,

                      textinit: language.VALUE,
                      method: 'POST',
                      route: valuesEndpoint,
                      fieldquery: 'search',
                      textlabel: language.VALUE,
                      fieldvaluedb: 'id',
                      fieldlabeldb: 'value',
                      routeAll: valuesEndpoint,
                      methodGetAll: 'POST',
                      routeGetCount: valuesEndpoint,
                      detail: true,
                    },
                    {
                      col: 4,
                      type: 'element',
                      content: (v, h, submit) => (
                        <div
                          style={{
                            display: 'flex',
                            marginBottom: '32px',
                            marginTop: '-20px',
                          }}
                        >
                          {
                            <Button
                              label={language.AND}
                              variant="normal"
                              style={{ marginTop: '15px' }}
                              color="success"
                              onClick={() => {
                                setType('E');
                                setTimeout(() => {
                                  submit();
                                }, 1000);
                              }}
                            />
                          }
                          {
                            <Button
                              label={language.OR}
                              variant="normal"
                              style={{ marginTop: '15px', marginLeft: '2px' }}
                              color="success"
                              onClick={() => {
                                setType('OR');
                                setTimeout(() => {
                                  submit();
                                }, 1000);
                              }}
                            />
                          }
                        </div>
                      ),
                    },
                  ]}
                  onChangeField={onChangeField}
                  hiddenSubmit={true}
                  button={{
                    icon: 'fas fa-plus',
                    label: language.ADD,
                  }}
                  onSubmit={(v) => {
                    saveCondition(v);
                  }}
                />
                {andConditions.length > 0 && (
                  <div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                      {language.AND}
                    </div>

                    <div
                      className="slaRule"
                      style={{
                        marginTop: '16px',
                        marginBottom: '8px',
                      }}
                    >
                      {andConditions.map((r, i) => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottom:
                              i + 1 !== andConditions.length
                                ? '1px solid #fff'
                                : 'none',
                            width: '100%',
                            alignItems: 'center',
                            background: '',
                            padding:
                              i + 1 !== andConditions.length
                                ? '1px 1px 8px'
                                : '1px 1px 0px',
                            marginBottom:
                              i + 1 !== andConditions.length ? '8px' : '0px',
                          }}
                        >
                          <div style={{ width: '100%', display: 'flex' }}>
                            <span style={{ width: '98%' }}>
                              <div>
                                {r.conditional_label}{' '}
                                <strong>
                                  {r.operator === 'Equals'
                                    ? language.EQUAL_TO.toLowerCase()
                                    : language.DIFFERENT_FROM.toLowerCase()}
                                </strong>{' '}
                                {r.value_label}
                              </div>
                            </span>

                            <span style={{ width: '2%' }}>
                              <Button
                                variant="circle"
                                icon="fas fa-trash-alt"
                                color="danger"
                                label={language.DELETE}
                                onClick={() => handleConditionDeleteAnd(r)}
                              />{' '}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {orConditions.length > 0 && (
                  <div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                      {language.OR}
                    </div>

                    <div
                      className="slaRule"
                      style={{
                        marginTop: '16px',
                        marginBottom: '8px',
                      }}
                    >
                      {orConditions.map((r, i) => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottom:
                              i + 1 !== orConditions.length
                                ? '1px solid #fff'
                                : 'none',
                            width: '100%',
                            alignItems: 'center',
                            background: '',
                            padding:
                              i + 1 !== orConditions.length
                                ? '1px 1px 8px'
                                : '1px 1px 0px',
                            marginBottom:
                              i + 1 !== orConditions.length ? '8px' : '0px',
                          }}
                        >
                          <div style={{ width: '100%', display: 'flex' }}>
                            <span style={{ width: '98%' }}>
                              <div>
                                {r.conditional_label}{' '}
                                <strong>
                                  {r.operator === 'Equals'
                                    ? language.EQUAL_TO.toLowerCase()
                                    : language.DIFFERENT_FROM.toLowerCase()}
                                </strong>{' '}
                                {r.value_label}
                              </div>
                            </span>

                            <span style={{ width: '2%' }}>
                              <Button
                                variant="circle"
                                icon="fas fa-trash-alt"
                                color="danger"
                                label={language.DELETE}
                                onClick={() => handleConditionDeleteOr(r)}
                              />{' '}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {errorNoConditions && (
                  <div>
                    <p
                      style={{
                        fontSize: '0.75rem',
                        color: '#f44336',
                        marginLeft: '14px',
                        marginRight: '14px',
                      }}
                    >
                      Preencha o campo
                    </p>
                  </div>
                )}
              </div>
            ),
          },
          ...fieldsByModule,
          {
            col: 6,
            name: 'users',
            label: language.USERS,
            type: 'autocomplete',
            isMulti: true,
            options: users,
            required: isUsersRequired,

            method: 'POST',
            route: `${Constants.APIEndpoints.USER}/filter`,
            routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
            fieldquery: 'search',
            textlabel: language.USERS,
            fieldvaluedb: 'id_user',
            fieldlabeldb: 'name',
            routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
            detail: true,
            methodGetAll: 'POST',
            textinit: usersInitName,
            idinit: data?.users,
          },
          {
            col: 6,
            name: 'groups',
            label: language.GROUPS,
            type: 'autocomplete',
            isMulti: true,
            options: roleOptions,
            required: isGroupsRequired,

            textlabel: language.GROUPS,
            method: 'POST',
            route: `${Constants.APIEndpoints.GROUP}/filter`,
            fieldquery: 'search',
            fieldvaluedb: 'id_group_users',
            fieldlabeldb: 'name',
            routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
            detail: true,
            methodGetAll: 'POST',
            routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
            textinit: groupsInitName,
            idinit: data?.groups,
          },
          {
            col: 12,
            name: 'recipients',
            label: language.ADDITIONAL_RECIPIENTS,
            type: 'text',
            placeholder: language.ADDITIONAL_RECIPIENTS_HOLDER,
            required: isGroupsRequired && isUsersRequired,
          },
          {
            col: 12,
            name: 'message',
            label: language.MESSAGE,
            type: 'tagTextEditor',
            required: true,
          },
          {
            col: 12,
            name: 'include_chat_history',
            label: language.INCLUDE_CHAT_HISTORY,
            type: 'check',
            visible: isTicketManagement,
            required: false,
          },
          {
            col: 12,
            name: 'include_technical_notes',
            label: language.INCLUDE_TECHNICAL_NOTES,
            type: 'check',
            visible: isTicketManagement,
            required: false,
          },
          {
            col: 12,
            name: 'include_ticket_history',
            label: language.INCLUDE_TICKET_HISTORY,
            type: 'check',
            visible: isTicketManagement,
            required: false,
          },
          {
            col: 12,
            name: 'include_form_fields',
            label: language.INCLUDE_FORM_FIELDS,
            type: 'check',
            visible: isTicketManagement,
            required: false,
          },
          {
            col: 4,
            type: 'element',
            visible: errorMessage,
            content: (v, h, submit) => (
              <div>
                <p
                  style={{
                    fontSize: '0.75rem',
                    color: '#f44336',
                    marginLeft: '14px',
                    marginRight: '14px',
                  }}
                >
                  {errorMessage}
                </p>
              </div>
            ),
          },
        ]}
        onChangeField={onChangeField}
        onSubmit={(v) => saveNotification(v)}
      />
    </CommonPage>
  );
};

export default index;
