import React, { useEffect } from 'react';
import { Card } from '@material-ui/core';
import Button from '../../common/Button';
import Header from '../../common/Header';
import CommonForm from '../../common/CommonForm2';
import Constants from '../../data/Constants';

export default function AutomationLogicalConditionsForm({
  language,
  actionsAutomation = [{ type: undefined, action: undefined, description: undefined }],
  handleValue,
  setValue,
  options,
  execute,
  spinner,
  toggleAlert,
}) {

  let op = {
    mail: {
      label: language.EMAIL,
    },
    subject: {
      label: language.SUBJECT,
    },
    solicitant: {
      label: language.SOLICITANT,
      options: options.solicitant(),
    },
    category: {
      label: language.CATEGORY,
      options: options.categories(),
    },
    subcategory: {
      label: language.CATALOG,
      options: options.subCategory(),
    },
    company: {
      label: language.COMPANY,
    },
  };

  function setState(newValue){
    handleValue(newValue, 'actions_automation');
    setValue('actions_automation', newValue);
  }

  function onChangeField(f, v, index) {
    actionsAutomation[index][f.name] = v;
    setState(actionsAutomation);
  }

  function onAddCondition() {
    setState([...actionsAutomation, { type: undefined, action: undefined, description: undefined }]);
  }

  function onRemoveAction(fk_id_automation, id_action_automation, index) {
    let delItem = actionsAutomation.filter((e, i) => i != index);
    spinner = true;
    if (fk_id_automation && id_action_automation) {
      execute(
        'delete',
        `${Constants.APIEndpoints.AUTOMATION}/${fk_id_automation}/action/${id_action_automation}`,
        ''
      )
        .then(() => {
          spinner = false;
          setState([]);
          setState(delItem);
          toggleAlert(true, language.ALERT_DELETE_SUCCESS, 'success');
        })
        .catch((error) => {
          let messageError = language.ERROR_REQUEST;          
          if( error.data?.id_error === "92d94145-70f0-4608-9731-e22551fef3e3" ) {
            messageError = language.THERE_ARE_NO_RECORDS_TO_DELETE;
          }else if(error.data?.id_error === "e8593f9c-c16f-4b78-8c81-bd29e777d8c1"){
            messageError = language.THERE_IS_ONLY_ONE_RECORD_MODIFY_THE_CURRENT_ONE;
          }
          spinner = false;
          toggleAlert(true, messageError, 'error');
        });
    } else {
      setState([]);
      setTimeout(() => {
        spinner = false;
        setState(delItem);
        toggleAlert(true, language.ALERT_DELETE_SUCCESS, 'success');
      }, 100);
      
    }
  }

  return (
    <div style={{ zoom: 0.8 }}>
      <Header
        className="justify-content-start"
        style={{
          height: 24,
          borderRadius: '7px 7px 0 0',
        }}
      >
        <Button
          icon="fas fa-plus"
          variant="circle"
          color="warning"
          label={language.ADD}
          className="mr-3"
          onClick={onAddCondition}
        />
      </Header>
      <Card style={{ overflow: 'visible' }} className="content mb-3">
        {actionsAutomation.map((e, index) => (
          <div className="w-100 mb-2">
            <CommonForm
              individual
              values={{
                type: e.type,
                action: e.action,
                description: parseInt(e.description) || e.description,
              }}
              fields={[
                {
                  col: 3.85,
                  type: 'select',
                  name: 'action',
                  label: language.CONDITION,
                  options: options.action(),
                },
                {
                  col: 3.85,
                  type: 'select',
                  name: 'type',
                  label: language.OPERATOR,
                  options:
                    e.action === 'description'
                      ? options
                          .type()
                          .filter(
                            (op) => op.value !== 'in' && op.value !== 'no_in'
                          )
                      : options.type(),
                },
                {
                  col: 3.85,
                  type: 'text',
                  name: 'description',
                  label: language.DESCRIPTION,
                  visible: !!(
                    ['mail', 'subject', 'description'].includes(e.action) &&
                    !!e.action
                  ),
                },

                e.action == 'solicitant'
                  ? {
                      col: 3.85,
                      type: 'autocomplete',
                      name: 'description',
                      label: language.SOLICITANT,
                      className: 'tech-group-ticket',
                      required: true,
                      method: 'POST',
                      route: `${Constants.APIEndpoints.USER}/filter`,
                      routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                      fieldquery: 'search',
                      textinit: e.name,
                      fieldvaluedb: 'id_user',
                      fieldlabeldb: 'name',
                      idinit: '',
                      routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                      loadOnFocus: true,
                      detail: true,
                      methodGetAll: 'POST',
                    }
                  : e.action === 'company'
                  ? {
                      col: 3.85,
                      type: 'autocomplete',
                      name: 'description',
                      label: language.COMPANY,
                      className: 'tech-group-ticket',
                      required: true,
                      method: 'POST',
                      route: `${Constants.APIEndpoints.COMPANY}/filter`,
                      routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                      fieldquery: 'search',
                      textinit: e.name,
                      fieldvaluedb: 'id_company',
                      fieldlabeldb: 'name',
                      idinit: '',
                      routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                      detail: true,
                      loadOnFocus: true,
                      methodGetAll: 'POST',
                    }
                  : {
                      col: 3.85,
                      name: 'description',
                      type: 'select',
                      visible: !!(
                        !['mail', 'subject', 'description'].includes(
                          e.action
                        ) && !!e.action
                      ),
                      ...op[e.action],
                    },
                {
                  col: 0.45,
                  type: 'element',
                  content: () => (
                    <Button
                      variant="circle"
                      icon="fas fa-trash-alt"
                      color="danger"
                      label={language.DELETE}
                      className="mt-4 -ml-3"
                      size={0.7}
                      onClick={() =>
                        onRemoveAction(e.fk_id_automation, e.id_action_automation, index)
                      }
                    />
                  ),
                },
              ]}
              onChangeField={(f, v) => onChangeField(f, v, index)}
            />
          </div>
        ))}
      </Card>
    </div>
  );
}
