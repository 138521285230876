import React, { useEffect, useState } from 'react';
import { Chip, Switch } from '@material-ui/core';
import Button from '../../common/Button';
import Dialog from '../../common/Dialog';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import DialogConfirm from '../../common/DialogConfirm';
import Store from '../../data/Store';
import Constants from '../../data/Constants';
import SlaForm from '../slaForm';
import FormConditions from '../slaForm/FormConditions';
import Actions from '@data/Actions';

const action = new Actions();

import './index.css'

const defaultPageDelete = { open: false, values: {} };

const { language } = Store;

const targetOpt = {
  response: language.ANSWER,
  resolution: language.RESOLUTION,
  ola: language.OLA
}

const slaFixedOpt = {
  true: language.YES,
  false: language.NO,
};

const index = () => {
  const [page, setPage] = useState('list');
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [slaParams, setSlaParams] = useState([]);
  const [pageDelete, setPageDelete] = useState(defaultPageDelete);

  const loadSla = () => {
    action
      .execute('get', `${Constants.APIEndpoints.SLA_WORK}/slaparam`)
      .then((res) => {
        setSlaParams(res.data);
      });
  };

  const UpdateSla = ({id, active, fixed}) => {
    let payload = {
      id_slaparam: id,
      sla_active: active,
      sla_fixed_start: fixed,
      status: 'active',
    };
    action
      .execute('put', `${Constants.APIEndpoints.SLA_WORK}/slaparam/${id}/condition`, '', payload)
      .then((res) => {
        loadSla();
      });
  };

  useEffect(() => {
    loadSla();
  }, []);

  const handlePageDelete = (data) => {
    setPageDelete(data);
  };

  const deleteSla = () => {
    action
      .execute('delete', `${Constants.APIEndpoints.SLA_WORK}/slaparam/${pageDelete.values.id_slaparam}`, '')
      .then((res) => {
        loadSla();
        handlePageDelete(defaultPageDelete);
      });
  };

  let col = [
    {
      key: 'sla_active',
      label: '',
      style: { width: 1 },
      thConfig: true,
      search: false
    },
    {
      key: 'id',
      label: language.ID,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'name',
      label: language.NAME,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'sla_target',
      label: language.TARGET,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'sla_fixed_start',
      label: language.SLA_FIXED,
      style: { width: 1 },
      thConfig: true,
      search: false
    },
    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
      search: false
    },
  ];

  const data = slaParams.map((item) => ({
    sla_active: (
      <Switch
        checked={item.sla_active}
        onChange={(v) => UpdateSla({
          id: item.id_slaparam,
          active: !item.sla_active,
          fixed: item.sla_fixed_start
        })}
        value="checkedB"
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    ),
    id: item.id_slaparam,
    name: item.sla_name,
    sla_target: targetOpt[item.sla_target],
    sla_fixed_start: (
      <Switch
        checked={item.sla_fixed_start}
        onChange={(v) => UpdateSla({
          id: item.id_slaparam,
          active: item.sla_active,
          fixed: !item.sla_fixed_start
        })}
        value="checkedB"
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    ),
    action: (
      <React.Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="circle"
                icon="fas fa-plus"
                label={language.SLA_CONDITION}
                color="default"
                style={{ fontSize: 13 }}
                size={0.7}
                className="mr-2"
                onClick={() => {
                  setValue(item);
                  setPage('condition');
                }}
              />
              <Button
                variant="circle"
                icon="far fa-trash-alt"
                label={language.DELETE}
                color="danger"
                style={{ fontSize: 14 }}
                size={0.7}
                onClick={() => handlePageDelete({ open: true, values: item })}
              />
            </div>
          }
        />
      </React.Fragment>
    ),
  }));

  return (
    <div>
      <CommonTable
        title=""
        index=""
        data={data}
        columns={col}
        language={language}
        searchColumn
        orderColumn
        beforeBar={
          <RaisedButton
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            key="02"
            circleButton
            icon="fas fa-plus"
            label={language.ADD}
            primary={true}
            onClick={() => {
              setValue(null);
              setPage('sla');
            }}
          />
        }
      />
      {page !== 'list' ? (
        <Dialog
          title={
            page === 'condition'
              ? language.SLA_CONDITION
              : language.SLA_REGISTRATION
          }
          open={true}
          onClose={() => setPage('list')}
          maxWidth="md"
          miniVersion = {true}
        >
          {page === 'condition' ? (
            <FormConditions
              loadSla={loadSla}
              language={language}
              value={value}
              onClose={() => setPage('list')}
            />
          ) : (
            <SlaForm
              loadSla={loadSla}
              language={language}
              value={value}
              onClose={() => setPage('list')}
            />
          )}
        </Dialog>
      ) : null}
      {pageDelete.open && (
        <DialogConfirm
          open={pageDelete.open}
          language={language}
          confirmProps={{ color: 'danger' }}
          onConfirm={deleteSla}
          onClose={() => handlePageDelete(defaultPageDelete)}
          title={language.CONFIRM_DELETE}
          message={pageDelete.values.sla_name}
        />
      )}
    </div>
  );
};

export default index;
