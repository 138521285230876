import Store from '../data/Store';

export default (url, file) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': Store.getToken(),
    },
  };

  fetch(url, options)
    .then((results) => {
      return results.blob();
    })
    .then((response) => {
      let data = response;
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(data);
      elm.setAttribute('download', file);
      elm.click();
      elm.remove();
    });
};
