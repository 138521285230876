import Dialog from '@common/Dialog';
import Store from '@data/Store';
import { Grid } from '@material-ui/core';
import crypto from 'crypto';
import moment from 'moment';
import React, { Fragment, useEffect, useReducer, useState } from 'react';
import AppBar from '../../../common/AppBar2';
import CommonForm from '../../../common/CommonForm2';
import CommonTable from '../../../common/CommonTable';
import DialogConfirm from '../../../common/DialogConfirm';
import DownloadFiles from '../../../util/DownloadFiles';
import { translateFromPtBR } from '@common/languages/Dictionary';
import $ from 'jquery';

const { language } = Store;

const MyHeader = ({ title, subTitle, className }) => (
  <AppBar
    contentProps={{
      className: 'pl-0 ' + className,
    }}
    titleProps={{
      style: { color: 'var(--primary)' },
    }}
    style={{
      padding: '0px',
    }}
    title={title}
    subTitle={subTitle}
  />
);
export default function ({
  open,
  onClose,
  onSave,
  values,
  companies,
  page,
  users,
  tickets,
  store,
  action,
  APIEndpoints,
  devices,
  actionsOptions,
  enable,
  stand,
  onSaveS,
  ticketparam,
  params,
  toggleAlert,
  ondeletefile,
  createToTemplate,
  typeTemp,
  createToTemplate_fields,
  modalConfirmBlackout,
  setModalConfirmBlackout,
  errorToShow,
  verifyBlackout,
  modalConfirmOutage,
  setModalConfirmOutage,
  verifyOutages
}) {
  /*#####################################################-STATES-#########################################################################*/
  const reducer = (state, value) => ({ ...state, ...value });

  if (typeTemp == true) {
    if (values) {
      values.templateChange = true;
      values.outage = false;

    } else {
      values = {
        templateChange: true,
        outage: false,
        approve: false
      }
    }

  }



  if (values) {
    // values.file =[];
  }



  let [company, SetCompany] = useState(0);
  let [contract, SetContract] = useState({});
  let [typechange, Settypechange] = useState(1);
  let [departments, SetDepartments] = useState([]);
  let [contracts, setContracts] = useState([]);
  let [formstemplate, setFormsTemplate] = useState([]);
  const [templatesForm, setTemplateForms] = useState([]);
  const [subdptName, setSubdptName] = useState('Subdepartamento');
  const [dptName, setDptName] = useState('Departamento');
  const [chieftDptName, setChieftDptName] = useState('Chefe de Departamento');
  const [allDptName, setAllDptName] = useState('Departamento');
  const [chiefSubDptName, setChiefSubDptName] = useState('Chefe de Departamento');
  const [allChiefName, setAllChieftName] = useState('Chefe de Departamento');

  const [groupCompany, setGroupCompany] = useState({});
  const [outage, setOutage] = useState(false);
  const [modalOutage, setModalOutage] = useState(false);
  const [modalHistory, setModalHistory] = useState(false);
  const [modalJustify, setModalJustify] = useState(false);
  let [log, setLog] = useState('');

  const [groupRequired, setgroupRequired] = useState(true);
  const groupCompany_ = (id) => {
    action
      .execute('get', APIEndpoints.CHANGE + '/group/' + id)
      .then((i) => {
        setGroupCompany(i.data[0]);
      });
  };
  const ondonwFile_ = (id, file, type) => {
    DownloadFiles(APIEndpoints.CHANGE + '/downloadchange/' + id + '&' + file, file)
  }
  const getDeviceOutage = (id) => {
    action
      .execute('get', APIEndpoints.CHANGE + '/outage/' + id)
      .then((i) => {
        if (i.data.length > 0) {
          toggleAlert(
            true,
            language.DEVICE_ASSOCIATED_WITH_ANOTHER_GMUD,
            'error'
          );
        }
      });
  };
  const changeContract = (id) => {
    action
      .execute('get', APIEndpoints.CONTRACT + 'companylow/' + id)
      .then((i) => {
        setContracts(i.data);
      });
  };
  const initialState = {};
  const [json_template_solicitation, updateState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState
  );

  const formshtml = (t) => {
    let data = [];
    let names_to_scape = []

    try {
      String(t).split('<div').map((i, key) => {
        let name_to_scape = 'hours_var'
        let nameField = 'hours_var'
        const required = (i || '').indexOf('formbuilder-required') > -1;
        const addEmptyOption = i.indexOf('addemptyoption') > -1 || i.indexOf('add-empty-option') > -1;
        const labelMatch = (i || '').match(/label=\"([^\"]+)\"/);
        const label = labelMatch ? labelMatch[1] : undefined;
        //a variável hours_var pode ter outros nomes, é de escolha do usuário, então é necessário verificar se existe alguma variável que se enquadre nas condições do hours_var
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex)
          nameField = textWithoutTags[1].split('|')[1] ? textWithoutTags[1].split('|')[1] : 'hours_var'
        } catch (e) {
          try {
            nameField = i.split(' ')[1].split('|')[1] ? i.split(' ')[1].split('|')[1].replace(/"/g, '') : 'hours_var'
          } catch (e) {
            nameField = 'hours_var'
          }
        }
        if (nameField !== 'hours_var') {
          names_to_scape.push(nameField)
        }
        name_to_scape = nameField;

        if( addEmptyOption && i.indexOf('<select') > -1 ) {
          i.replace(/>Selecione</, function(match) {
            let originalText = match.slice(1, -1);
            let newText = translateFromPtBR(originalText, language);
            return `>${newText}<`;
          });
        }

        if (i.indexOf('companies_var') > -1) {
          if (i[1] == 's' || i[1] == 'i') {
            let textInit = '';
            try {
              textInit =
                values && values.html_values
                  ? JSON.parse(values.html_values).find(
                    (v) => v.name == 'companies_var'
                  ).value
                  : '';
            } catch (e) { }

            data.push(
              <div id="companies_var" style={{ margin: '15px 0px 30px 0px' }}>
                <CommonForm
                  onChangeField={(field, value) => {
                    updateState({ companies_var: value });
                  }}
                  fields={[
                    {
                      col: 12,
                      type: 'autocomplete',
                      name: 'companies_var',
                      label: label || language.COMPANY,
                      method: 'POST',
                      showEmptyFilter: addEmptyOption,
                      route: `${APIEndpoints.COMPANY}/filter`,
                      fieldquery: 'search',
                      textlabel: label || language.COMPANY,
                      textinit: textInit.length > 0 ? textInit : '',
                      fieldvaluedb: 'name',
                      fieldlabeldb: 'name',
                      idinit: 0,
                      routeAll: `${APIEndpoints.COMPANY}/all?`,
                      methodGetAll: 'POST',
                      routeGetCount: `${APIEndpoints.COMPANY}/count`,
                      detail: true,
                      visible: true,
                      required,
                      showId: true,
                      idFieldToShow: 'id_company',
                    },
                  ]}
                />
              </div>
            )
          }
        } else if (i.indexOf('locations_var') > -1) {
          let textInit = '';
          try {
            textInit =
              values && values.html_values
                ? JSON.parse(values.html_values).find(
                  (v) => v.name == 'locations_var'
                ).value
                : '';
          } catch (e) { }
          data.push(
            <div id="locations_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ locations_var: value });
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'locations_var',
                    label: label || language.LOCATION,
                    method: 'POST',
                    showEmptyFilter: addEmptyOption,
                    route: `${APIEndpoints.LOCATION}/filter`,
                    fieldquery: 'search',
                    textlabel: label || language.LOCATION,
                    textinit: textInit.length > 0 ? textInit : '',
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    routeAll: `${APIEndpoints.LOCATION}/filter?`,
                    methodGetAll: 'POST',
                    detail: true,
                    visible: true,
                    required,
                    disablePortal: true,
                    showId: true,
                    idFieldToShow: 'id_location',
                    // value:
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('allusers_var') > -1) {
          let textInit = '';
          try {
            textInit =
              values && values.html_values
                ? JSON.parse(values.html_values).find(
                  (v) => v.name == 'users'
                ).value
                : '';
          } catch (e) { }
          data.push(
            <div id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ allusers_var: value });
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'users',
                    label: '',
                    method: 'POST',
                    showEmptyFilter: addEmptyOption,
                    route: `${APIEndpoints.USER}/filter`,
                    fieldquery: 'search',
                    textlabel: label || language.USER,
                    textinit: label || language.USER,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    textinit: textInit.length > 0 ? textInit : '',
                    routeAll: `${APIEndpoints.USER}/filter/all?`,
                    methodGetAll: 'POST',
                    routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                    detail: true,
                    visible: true,
                    required,
                    showId: true,
                    idFieldToShow: 'id_user',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf(`id="${name_to_scape}"`) > -1 || i.indexOf(`>${name_to_scape}`) > -1 || i.indexOf(`|${name_to_scape}`) > -1) {
          let nameField = 'hours_var_name'
          try {
            const regex = /<p(.*?)<\/p>/;
            const textWithoutTags = i.match(regex)
            nameField = textWithoutTags[1].split('|')[1]
          } catch (e) {
            try {
              nameField = i.split(' ')[1].split('|')[1] ? i.split(' ')[1].split('|')[1].replace(/"/g, '') : 'hours_var_name'
            } catch (e) {
              nameField = 'hours_var_name'
            }
          }
          name_to_scape = nameField ? nameField : 'hours_var'
          let hour_init = ''
          try {
            hour_init = values && values.html_values
              ? JSON.parse(values.html_values).find(
                (v) => v.name == nameField
              ).value : ''
            hour_init = moment(hour_init, 'HH:mm')
          } catch (e) {
            hour_init = ''
          }

          const values_to_form = {
            [name_to_scape]: hour_init
          }
          const id_div = i.indexOf('|') > -1 ? `hours_var|${name_to_scape}` : 'hours_var'
          data.push(
            <div id={id_div} style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ hours_var: value });
                }}
                values={values_to_form}
                fields={[
                  {
                    col: 12,
                    name: nameField ? nameField : 'hours_var_name',
                    label: label || language.HOURS,
                    type: 'time',
                    format: '##:##',
                    required
                  },
                ]}
              />
            </div>
          );
        }
        else if (i.indexOf('hidden_var') > -1) {
          let value_field = ''
          let name_field = ''
          try {
            const regex = /<p(.*?)<\/p>/;
            const textWithoutTags = i.match(regex)
            name_field = textWithoutTags[1].split('|')[1]
            value_field = textWithoutTags[1].split('|')[2]
          } catch (e) { }
          const html_field = `<div hidden><input value = ${value_field} name = ${name_field} /></div>`
          data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
        }
        else if (i.indexOf('subsdpt_var') > -1) {
          let textInit = '';
          try {
            textInit =
              values && values.html_values
                ? JSON.parse(values.html_values).find(
                  (v) => v.name == 'subdepartments'
                ).value
                : '';
          } catch (e) { }
          data.push(
            <div id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ subdepartments_var: value });
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'subdepartments',
                    label: label || language.SUBDEPARTMENT,
                    textlabel: label || language.SUBDEPARTMENT,
                    method: 'POST',
                    filterAll: { fk_id_department: 1 },
                    showEmptyFilter: addEmptyOption,
                    filter: { fk_id_department: 1 },
                    route: `${APIEndpoints.DEPARTMENT}/filter/`,
                    routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                    fieldquery: 'search',
                    textinit: textInit.length > 0 ? textInit : '',
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: 0,
                    routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                    methodGetAll: 'POST',
                    required,
                    showId: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('departments_var') > -1) {
          let textInit = '';
          try {
            textInit =
              values && values.html_values
                ? JSON.parse(values.html_values).find(
                  (v) => v.name == 'departments'
                ).value
                : '';
          } catch (e) { }
          data.push(
            <div id="departments_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ departments_var: value });
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'departments',
                    label: label || language.DEPARTMENT,
                    textlabel: label || language.DEPARTMENT,
                    method: 'POST',
                    route: `${APIEndpoints.DEPARTMENT}/filter/`,
                    routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                    showEmptyFilter: addEmptyOption,
                    fieldquery: 'search',
                    textinit: textInit.length > 0 ? textInit : '',
                    fieldvaluedb: 'name',
                    showEmail: false,
                    fieldlabeldb: 'name',
                    idinit: 0,
                    routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                    methodGetAll: 'POST',
                    required,
                    showId: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('contracts_var') > -1) {
          let textInit = '';
          try {
            textInit =
              values && values.html_values
                ? JSON.parse(values.html_values).find(
                  (v) => v.name == 'contracts'
                ).value
                : '';
          } catch (e) { }
          data.push(
            <div style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ contracts_var: value });
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'contracts',
                    label: label || language.CONTRACTS,
                    method: 'POST',
                    route: `${APIEndpoints.CONTRACT}/filter`,
                    fieldquery: 'search',
                    textlabel: label || language.CONTRACTS,
                    textinit: textInit.length > 0 ? textInit : '',
                    fieldvaluedb: 'id_contract',
                    fieldlabeldb: 'name',
                    showEmptyFilter: addEmptyOption,
                    idinit: 0,
                    routeAll: `${APIEndpoints.CONTRACT}/all?`,
                    methodGetAll: 'POST',
                    routeGetCount: `${APIEndpoints.CONTRACT}/count?`,
                    showId: true,
                    idFieldToShow: 'id_contract',
                    required
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('chieftdpt_var') > -1) {
          let textInit = '';
          try {
            textInit =
              values && values.html_values
                ? JSON.parse(values.html_values).find(
                  (v) => v.name == 'boss'
                ).value
                : '';
          } catch (e) { }
          data.push(
            <div id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ chieftdpt_var: value });
                  setChieftDptName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'boss',
                    label: label || language.DEPARTMENT_BOSS,
                    textlabel: label || language.DEPARTMENT_BOSS,
                    method: 'POST',
                    filter: { fk_id_company: 1 },
                    route: `${APIEndpoints.USER}/filter`,
                    routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                    fieldquery: 'search',
                    textinit: textInit.length > 0 ? textInit : '',
                    showEmptyFilter: addEmptyOption,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: language.DEPARTMENT_BOSS,
                    routeAll: `${APIEndpoints.USER}/filter/all?`,
                    filterAll: 0,
                    methodGetAll: 'POST',
                    detail: true,
                    visible: true,
                    required,
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('chiefsubdpt_var') > -1) {
          let textInit = '';
          try {
            textInit =
              values && values.html_values
                ? JSON.parse(values.html_values).find(
                  (v) => v.name == 'boss_sub_dpt'
                ).value
                : '';
          } catch (e) { }

          data.push(
            <div id="chiefsubdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ chiefsubdpt_var: value });
                  setChiefSubDptName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'boss_sub_dpt',
                    label: label || language.SUBDEPARTMENT_BOSS,
                    textlabel: label || language.SUBDEPARTMENT_BOSS,
                    method: 'POST',
                    filter: { fk_id_company: 1 },
                    filterAll: { chiefsub: true },
                    route: `${APIEndpoints.USER}/filter`,
                    showEmptyFilter: addEmptyOption,
                    routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                    fieldquery: 'search',
                    textinit: textInit.length > 0 ? textInit : '',
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    routeAll: `${APIEndpoints.USER}/filter/all?`,
                    methodGetAll: 'POST',
                    required,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('chief_var') > -1) {
          let textInit = '';
          try {
            textInit =
              values && values.html_values
                ? JSON.parse(values.html_values).find(
                  (v) => v.name == 'all_boss_dpt'
                ).value
                : '';
          } catch (e) { }

          data.push(
            <div id="chief_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ chief_var: value });
                  setAllChieftName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'all_boss_dpt',
                    label: label || language.DEPARTMENT_BOSS,
                    textlabel: label || language.DEPARTMENT_BOSS,
                    method: 'POST',
                    filter: { fk_id_company: 1 },
                    filterAll: { all_chief: true },
                    route: `${APIEndpoints.USER}/filter`,
                    routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                    showEmptyFilter: addEmptyOption,
                    fieldquery: 'search',
                    textinit: textInit && textInit.length > 0 ? textInit : '',
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    routeAll: `${APIEndpoints.USER}/filter/all?`,
                    methodGetAll: 'POST',
                    required,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('departmentall_var') > -1) {
          let textInit = '';
          try {
            textInit =
              values && values.html_values
                ? JSON.parse(values.html_values).find(
                  (v) => v.name == 'all_departments'
                ).value
                : '';
          } catch (e) { }

          data.push(
            <div id="departmentall_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ departmentall_var: value });
                  setAllDptName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'all_departments',
                    label: label || language.DEPARTMENT,
                    textlabel: label || language.DEPARTMENT,
                    method: 'POST',
                    route: `${APIEndpoints.DEPARTMENT}/filter/`,
                    routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                    fieldquery: 'search',
                    showEmptyFilter: addEmptyOption,
                    textinit: textInit && textInit.length > 0 ? textInit : '',
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                    methodGetAll: 'POST',
                    required,
                    showId: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          )
        }

        else {
          if (i.length > 1) {
            //é preciso eliminar quaisquer trechos que não sejam úteis, caso contrario, esse template ficará maior a cada vez que for editado na tela, podendo travar o navegador do usuário
            let g = '';
            let has_names_to_scape = 'hours_var'
            names_to_scape.map(name => {
              if (i.indexOf(name) > -1 && name !== 'hours_var') {
                has_names_to_scape = name
              }
            })

            if (i.indexOf('ql-tooltip ql-hidden') > -1) {
              g = `<div style = "display : none" ${i}`;
            } else if (
              i.indexOf('ql-preview') > -1 ||
              i.indexOf('ql-clipboard') > -1 ||
              i.indexOf('ql-snow') > -1
            ) {
              g = `<div  ${i}`;
            } else if (i.indexOf('ql-editor') > -1) {
              g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
            } else if (
              i.indexOf('id="boss_dpt"') > -1 ||
              i.indexOf('id="boss_dpt-label"') > -1 ||
              i.indexOf('id="boss_sub_dpt"') > -1 ||
              i.indexOf('id="boss_sub_dpt-label"') > -1 ||
              i.indexOf('id="all_boss_dpt"') > -1 ||
              i.indexOf('id="all_boss_dpt-label"') > -1 ||
              i.indexOf('id="subdepartments"') > -1 ||
              i.indexOf('id="subdepartments-label"') > -1 ||
              i.indexOf('id="departments"') > -1 ||
              i.indexOf('id="departments-label"') > -1 ||
              i.indexOf('id="all_departments-label"') > -1 ||
              i.indexOf('id="all_departments"') > -1 ||
              i.indexOf('id="allusers_var"') > -1 ||
              i.indexOf('id="boss"') > -1 ||
              i.indexOf(' *</span>') > -1 ||
              i.indexOf('class="CommonForm2"') > -1 ||
              i.indexOf('Autocomplete') > -1 ||
              i.indexOf('hours_var') > -1 ||
              // i.indexOf('>Usuário<') > -1 ||
              i.indexOf(has_names_to_scape) > -1 ||
              //i.indexOf('>Horas</label>') > -1 ||
              i.indexOf('type="button"') > -1
            ) {
            }
            else {
              g = `<div class = "template-dinamic" ${i}`;
            }
            const div_with_no_children = /<div [^>]+>(.*?)<\/div>/g.exec(g)
            if (div_with_no_children && div_with_no_children.length > 1 && div_with_no_children[1] == '') {
              g = ''
            }

            g = g.replace(/<div class = "template-dinamic"  class="template-dinamic"><\/div><\/div>/g, '')
            g = g.replace(/<div class = "template-dinamic" >/g, '')
            g = g.replace(/<div><\/div>/g, '')
            g = g.replace(/<div><\/div>/g, '')
            g = g.replace(/<div><div class="template-dinamic"><\/div><\/div>/g, '')

            const html = $('<div />', { html: g });
            const el = $(html).find('[name]');
            const elType = $(el).attr('type');
            const elName = $(el).attr('name');
            const elValue = values && values.html_values ?
              JSON.parse(values.html_values).find((h) => h.name === elName)?.value : '';

            if (elType === 'time') {
              const hour = (elValue || '').split(':')[0];
              const min = (elValue || '').split(':')[1];
              const date = new Date(2000, 1, 1, hour, min, 0);

              if (isNaN(date) == false) {
                $(el).attr('value', moment(date).format('HH:mm'));
              }
            } else if (elType === 'datetime-local') {
              const date = new Date(elValue);
              elValue && $(el).attr('value', moment(date).format('YYYY-MM-DD HH:mm'));
            } else if (elType === 'radio') {
              const opt = $(el).parent().find('input[value="' + elValue + '"]');
              $(opt).attr('checked', true);
            } else if (elType === 'checkbox') {
              if (elValue) {
                $(el).attr('checked', true);
              }
            } else if ($(el).is('select')) {
              const selectedValues = elValue ? elValue.split(',') : [];
              selectedValues.forEach((selectedItemText) => { 
                $(el).find('option').filter(function () {
                  return $(this).text() == selectedItemText;
                }).attr('selected', 'selected');
              })
            } else if ($(el).is('textarea')) {
              $(el).html(elValue);
            } else {
              $(el).attr('value', elValue);
            }

            if (g.length > 0) {
              data.push(<div dangerouslySetInnerHTML={{ __html: $(html).html() }} />);
            }
          }
        }
      });
    } catch (e) {
      console.log({ e });
      return ' '
    }

    return data;
  };

  const decryp = (aMsg) => {
    var aSecret = 'Orch3str0!@forever!';
    try {
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);

      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');

      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  };
  /*##########################################-CHAMADA INICIAL DO COMPONENTE-##############################################################*/
  useEffect(() => {

    if (values) {
      SetCompany(values.fk_id_company)
      action
        .execute(
          'get',
          APIEndpoints.CONTRACT + 'companylow/' + values.fk_id_company
        )
        .then((i) => {
          setContracts(i.data);
        });

      setOutage(values.outage);
    }
    if (ticketparam) {
      SetCompany(ticketparam.fk_id_company);
    }
  }, []);

  useEffect(() => {
    action
      .execute('get', APIEndpoints.TASK + `/getform?companyId=${company}&origin=gmud`)
      .then((i) => {
        setTemplateForms(i.data);
      });
  }, [company])

  if (ticketparam != undefined && values == undefined) {
    values = {
      tickets: [ticketparam.id_tickets],
      name_ticket: ticketparam.subject,
      fk_id_company: ticketparam.fk_id_company,
      fk_id_contract: ticketparam.fk_id_contract,
      company: ticketparam.name_company,
      solicitants: ticketparam.name_solicit,
      fk_id_solicit: ticketparam.fk_id_user,
      tickets_labels: [ticketparam.real_id + ' - ' + ticketparam.subject],
    };
  }

  /*##########################################-OPÇÕES PARA O FORMULÁRIO-##############################################################*/

  var options = {
    companyOptions: () =>
      companies.map((obj) => ({ value: obj.id_company, label: obj.name })),
    contractOptions: () =>
      contracts
        .filter(
          (c) =>
            c[company == 1 ? 'fk_id_company' : 'fk_id_out_company'] ===
            company || c.global == true
        )
        .map((ctr) => ({ value: ctr.id_contract, label: ctr.name })),

    involvedAreasOptions: (id) => {
      action
        .execute('get', APIEndpoints.DEPARTMENT + '/' + id, '')
        .then((v) => {
          let dados = v.data
            .filter((v) => v.deleted == false)
            .map((c) => ({
              value: parseInt(c.id_department),
              label: c.name,
            }));

          SetDepartments(dados);
        });
    },
    solicitantOptions: () => {
      let itens = [];
      users
        .filter((c) => c.id_company === company && c.deleted == false)
        .map((u) => {
          if (itens.filter((h) => h.value == u.id_user).length == 0) {
            itens.push({ value: u.id_user, label: u.name + ' - ' + u.email });
          }
        });
      try {
        let con = contracts.filter((g) => g.id_contract === contract)[0];

        users
          .filter(
            (c) => c.id_company === con.company_provider && c.deleted == false
          )
          .map((u) => {
            if (itens.filter((h) => h.value == u.id_user).length == 0) {
              itens.push({ value: u.id_user, label: u.name + ' - ' + u.email });
            }
          });
      } catch (e) { }
      return itens;
    },
    ticketsList() {
      try {
        return tickets.map((u) => ({
          value: u.id_tickets,
          label: u.realid + ' - ' + u.subject,
        }));
      } catch (g) {
        return [];
      }
    },
    deviceOptions: () =>
      devices.map((d) => ({
        value: d.id_device,
        label: d.name + ' - ' + d.namecompany,
      })),
  };

  /*var items =
  values.files != undefined
    ? values.files.map((f) => ({
        name: f.split('|')[0] + ' - ' + moment(f.split('|')[3]).format('L'),
        size: '',
        type: '',
        nn: f.split('|')[0],
        id_tickets_file: f.split('|')[2],
        id_request_file: f.split('|')[1],
        fk_id_request: values.fk_id_request,
        user_cad: '',
        id_user: '',
      }))
    : null;*/

  return (
    <Fragment>
      {/*##########################################-FORMULÁRIO PRINCIPAL-##############################################################*/}
      <div id={'changeform'} style={{ zoom: 0.9, marginTop: '5px' }} zoom={0.9}>
        {modalConfirmBlackout && (
          <DialogConfirm
            open={true}
            language={language}
            confirmProps={{ color: 'primary' }}
            onConfirm={() => {
              setModalConfirmBlackout(false);
              verifyOutages()
            }
            }
            onClose={() => setModalConfirmBlackout(false)}
            title={language.IC_WITHIN_BLACKOUT_RANGE_WANT_TO_CONTINUE}
            message={errorToShow}
          />
        )}

        {modalConfirmOutage && (
          <DialogConfirm
            open={true}
            language={language}
            confirmProps={{ color: 'primary' }}
            onConfirm={() => onSave()}
            onClose={() => setModalConfirmOutage(false)}
            title={language.IC_WITHIN_OUTAGE_RANGE_WANT_TO_CONTINUE}
            message={errorToShow}
          />
        )}
        <br></br>
        <CommonForm
          sensitive
          spacing={8}
          values={values}
          fields={
            stand == true
              ? [
                {
                  col: 4,
                  type: 'element',
                  content: <b></b>,
                },
                {
                  col: values && values.id_change > 0 ? 4 : 2,
                  name: 'type_action',
                  label: language.TYPE_ACTION,
                  type: 'btnGroup',
                  required: true,
                  colorInactive: 'primary',
                  colorActive: 'primary',
                  options: actionsOptions,
                  disabled: (values && values.type_gmud == 2 && typeTemp == false && page == 'edit' || values?._status === "Analyze") ? true : false
                },
                {
                  col: 2,
                  name: 'outage',
                  label: language.OUTAGE,
                  type: 'btnGroup',
                  colorInactive: 'primary',
                  colorActive: 'primary',
                  options: [{ label: language.NO, value: 0 }, { label: language.YES, value: 1 }],
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 2,
                  name: 'history',
                  label: language.APPROVAL_HISTORY,
                  type: 'btnGroup',
                  colorInactive: 'primary',
                  colorActive: 'primary',
                  options: [{ label: 'Mostrar', value: 0 }],
                  style: { marginTop: 10 },
                  visible: values ? Object.keys(values).length > 0 : false,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 2,
                  name: 'approve',
                  label: language.APPROVE,
                  type: 'btnGroup',
                  colorInactive: 'primary',
                  colorActive: 'primary',
                  options: [{ label: language.NO, value: 0 }, { label: language.YES, value: 1 }],
                  visible: typeTemp == true ? true : false,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 4,
                  type: 'element',
                  content: <b></b>,
                },
                {
                  type: 'element',
                  content: <hr />,
                },
                {
                  col: 3,
                  type: 'autocomplete',
                  name: 'fk_id_company',
                  label: `${language.COMPANY}`,
                  method: 'POST',
                  route: `${APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: `${language.COMPANY}`,
                  textinit: values && values.company ? values.company : '',
                  fieldvaluedb: 'id_company',
                  fieldlabeldb: 'name',
                  idinit: values ? values.fk_id_company : 0,
                  routeAll: `${APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.COMPANY}/count`,
                  required: true,
                  loadOnFocus: true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'select',
                  name: 'fk_id_contract',
                  label: language.CONTRACT,
                  options: options.contractOptions(),
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'autocomplete',
                  name: 'manager',
                  label: `${language.CHANGE_MANAGER}`,
                  textlabel: `${language.CHANGE_MANAGER}`,
                  textinit: values && values.manager_text ? values.manager_text : `${language.CHANGE_MANAGER}`,
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/${company || 0
                    }`,
                  fieldquery: 'search',
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: values && values.id_manager ? values.id_manager : 0,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: { all_fk_id_company: company || 0 },
                  methodGetAll: 'POST',
                  detail: true,
                  required: typeTemp ? false : true,
                  showEmail: true,
                  loadOnFocus: true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'autocomplete',
                  name: 'solicitant',
                  label: `${language.REQUESTER}`,
                  textlabel: `${language.REQUESTER}`,
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/${company || 0
                    }`,
                  fieldquery: 'search',
                  textinit: values ? values.solicitants : '',
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: values && values.solicitant ? values.solicitant : 0,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: { all_fk_id_company: company || 0 },
                  methodGetAll: 'POST',
                  detail: true,
                  required: typeTemp ? false : true,
                  showEmail: true,
                  loadOnFocus: true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'date',
                  name: 'start_date',
                  label: language.START_DATE,
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  name: 'initial_hour',
                  label: language.START_HOUR,
                  type: 'time',
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'date',
                  name: 'end_date',
                  label: language.END_DATE,
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  name: 'final_hour',
                  label: language.END_HOUR,
                  type: 'time',
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title="Tickets"
                      subTitle={language.SELECT_ONE_OR_MORE_TICKETS_TO_LINK_TO_GMUD}
                    />
                  ),
                  type: 'autocomplete',
                  name: 'tickets',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${APIEndpoints.TICKET}/filter/all`,
                  routeGetCount: `${APIEndpoints.TICKET}/filter/all?count=0`,
                  fieldquery: 'search',
                  textinit:
                    values && values.tickets_labels
                      ? values.tickets_labels
                      : '',
                  fieldvaluedb: 'id_tickets',
                  fieldlabeldb: 'name',
                  idinit: values && values.tickets ? values.tickets : 0,
                  routeAll: `${APIEndpoints.TICKET}/filter/all?`,
                  filterAll: { fk_id_company: company || 0 },
                  methodGetAll: 'POST',
                  required: false,
                  showEmail: false,
                  isMulti: true,
                  loadOnFocus: true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={language.DEPARTMENTS_SUBDEPARTMENTS}
                      subTitle={language.SELECT_THE_AREA_INVOLVED_IN_GMUD}
                    />
                  ),
                  type: 'autocomplete',
                  name: 'departments',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: !company || company === 0 ? null : `${APIEndpoints.DEPARTMENT}/filter/`,
                  filter: { fk_id_company: company || 0 },
                  routeAll: !company || company === 0 ? null : `${APIEndpoints.DEPARTMENT}/filter?`,
                  filterAll: { fk_id_company: company || 0 },
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit:
                    values && values.departments_labels
                      ? values.departments_labels
                      : '',
                  fieldvaluedb: 'id_department',
                  fieldlabeldb: 'name',
                  idinit:
                    values && values.departments ? values.departments : 0,
                  methodGetAll: 'POST',
                  required: false,
                  showEmail: false,
                  isMulti: true,
                  loadOnFocus: true,
                  disabled: !company || company === 0,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={`${language.IC_CONFIGURATION_ITEM} ${typeTemp ? '' : '*'}`}
                      subTitle={language.SELECT_THE_CI_CONFIGURATION_ITEM_INVOLVED_IN_THE_GMUD}
                    />
                  ),
                  type: 'autocomplete',
                  name: 'devices',
                  label: ``,
                  textlabel: ``,
                  method: 'POST',
                  route: `${APIEndpoints.DEVICE}/filter/`,
                  routeGetCount: `${APIEndpoints.DEVICE}/filter?count=0`,
                  fieldquery: 'search',
                  textinit:
                     values && values.devices_labels
                       ? values.devices_labels
                       : '',
                  fieldvaluedb: 'id_device',
                  fieldlabeldb: 'name',
                  idinit: values && values.devices ? values.devices : 0,
                  routeAll: `${APIEndpoints.DEVICE}/filter?`,
                  filterAll: { fk_id_company: company || 0 },
                  methodGetAll: 'POST',
                  detail: true,
                  required: typeTemp ? false : true,
                  showEmail: false,
                  isMulti: true,
                  loadOnFocus: true
                },
                {
                  col: 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={`${language.TECHNICAL_GROUPS} *`}
                      subTitle=
                      {params.change_group_tech_approve == true ? <b style={{ color: 'red' }}>{language.SELECTED_TECHNICAL_GROUP_WILL_APPROVE_THE_GMUD}</b>
                        : language.SELECT_THE_TECHNICAL_GROUPS_INVOLVED_IN_THE_GMUD}


                    />
                  ),
                  type: 'autocomplete',
                  name: 'techgroups',
                  method: 'POST',
                  textlabel: '',
                  route: `${APIEndpoints.GROUP}/all?type=1&`,
                  routeAll: `${APIEndpoints.GROUP}/all?type=1&`,
                  fieldquery: 'search',
                  filter: { type_search: 1 },
                  filterAll: { type_search: 1 },
                  textinit:
                    values && values.techgroups_labels
                      ? values.techgroups_labels
                      : '',
                  fieldvaluedb: 'id_group_users',
                  fieldlabeldb: 'name',
                  idinit:
                    values && values.techgroups ? values.techgroups : 0,
                  methodGetAll: 'POST',
                  required: true,
                  isMulti: true,
                  loadOnFocus: true
                },
                {
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={language.ATTACHMENTS}
                      subTitle={language.SELECT_THE_FILES}
                    />
                  ),
                  col: 6,
                  type: 'file',
                  name: 'file',
                  label: language.ATTACH
                },
                {
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={`${language.SELECT_FORM} ${typeTemp ? '*' : ''} `}
                      subTitle={language.SELECT_FORM_ASSOCIATED_WITH_GMUD}
                    />
                  ),
                  col: 6,
                  name: 'fk_id_template_forms',
                  label: '',
                  type: 'select',
                  options: templatesForm.map((i) => ({
                    value: i.id_template_forms,
                    label: i.name,
                  })),
                  required: params.enable_required_template_gmud ? true : false
                },
                {
                  col: 12,
                  type: 'textEditor',
                  name: 'benefits',
                  required: true,
                  label: language.DESCRIPTION,
                  placeholder: language.WRITE_THE_DESCRIPTION_HERE,
                },
                {
                  col: 5,
                  type: 'element',
                  visible: (groupCompany && groupCompany.name) ? true : false,
                  content: <b></b>,
                }, {
                  col: 5,
                  visible: (groupCompany && groupCompany.name) ? true : false,
                  type: 'element',
                  content: <b></b>,
                },
                {
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={language.AUTOMATIC_APPROVER_GROUPS}
                      subTitle={language.AUTOMATIC_APPROVER_GROUPS}
                    />
                  ),
                  type: 'autocomplete',
                  name: 'ts',
                  method: 'POST',
                  route: `${APIEndpoints.GROUP}/all?type=3&`,
                  routeAll: `${APIEndpoints.GROUP}/all?type=3&`,
                  fieldquery: 'search',
                  filter: { type_search: 3 },
                  filterAll: { type_search: 3 },
                  textinit:
                    (groupCompany && groupCompany.name) || ''
                  ,
                  label: '',
                  textlabel: (groupCompany && groupCompany.name) || '',
                  fieldvaluedb: 'id_group_users',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  methodGetAll: 'POST',
                  required: false,
                  isMulti: false,
                  disabled: true,
                  visible: (groupCompany && groupCompany.name) ? true : false,
                  col: (groupCompany && groupCompany.name) ? 2 : 0,
                  showSearchButtom: false,
                  loadOnFocus: true,
                },
              ]
              : [
                {
                  col: 4,
                  type: 'element',
                  content: <b></b>,
                },
                {
                  col: values && values.id_change > 0 ? 4 : 2,
                  name: 'type_action',
                  label: language.TYPE_ACTION,
                  type: 'btnGroup',
                  required: true,
                  colorInactive: 'primary',
                  colorActive: 'primary',
                  options: actionsOptions,
                  disabled: (values && values.type_gmud == 2 && createToTemplate == true && page == 'edit' || values?._status === "Analyze") ? true : false
                },

                {
                  col: 2,
                  name: 'outage',
                  label: language.OUTAGE,
                  type: 'btnGroup',
                  colorInactive: 'primary',
                  colorActive: 'primary',
                  options: [{ label: language.NO, value: 0 }, { label: language.YES, value: 1 }],
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 2,
                  name: 'history',
                  label: language.APPROVAL_HISTORY,
                  type: 'btnGroup',
                  colorInactive: 'primary',
                  colorActive: 'primary',
                  options: [{ label: language.SHOW, value: 0 }],
                  style: { marginTop: 10 },
                  visible: values ? Object.keys(values).length > 0 : false,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 4,
                  type: 'element',
                  content: <b></b>,
                },
                {
                  type: 'element',
                  content: <hr />,
                },
                {
                  col: 3,
                  type: 'autocomplete',
                  name: 'fk_id_company',
                  label: `${language.COMPANY}`,
                  method: 'POST',
                  route: `${APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: `${language.COMPANY}`,
                  textinit: values && values.company ? values.company : '',
                  fieldvaluedb: 'id_company',
                  fieldlabeldb: 'name',
                  idinit: values ? values.fk_id_company : 0,
                  routeAll: `${APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.COMPANY}/count`,
                  required: true,
                  loadOnFocus: true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'select',
                  name: 'fk_id_contract',
                  label: language.CONTRACT,
                  options: options.contractOptions(),
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'autocomplete',
                  name: 'manager',
                  label: `${language.CHANGE_MANAGER}`,
                  textlabel: `${language.CHANGE_MANAGER}`,
                  textinit: values && values.manager_text ? values.manager_text : `${language.CHANGE_MANAGER}`,
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/${company || 0
                    }`,
                  fieldquery: 'search',
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: values && values.id_manager ? values.id_manager : 0,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: { all_fk_id_company: company || 0 },
                  methodGetAll: 'POST',
                  detail: true,
                  required: typeTemp ? false : true,
                  showEmail: true,
                  loadOnFocus: true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'autocomplete',
                  name: 'solicitant',
                  label: `${language.REQUESTER}`,
                  textlabel: `${language.REQUESTER}`,
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/${company || 0
                    }`,
                  fieldquery: 'search',
                  textinit: values ? values.solicitants : '',
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: values && values.solicitant ? values.solicitant : 0,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: { all_fk_id_company: company || 0 },
                  methodGetAll: 'POST',
                  detail: true,
                  required: typeTemp ? false : true,
                  showEmail: true,
                  loadOnFocus: true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'date',
                  name: 'start_date',
                  label: language.START_DATE,
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  name: 'initial_hour',
                  label: language.START_HOUR,
                  type: 'time',
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  type: 'date',
                  name: 'end_date',
                  label: language.END_DATE,
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 3,
                  name: 'final_hour',
                  label: language.END_HOUR,
                  type: 'time',
                  required: typeTemp ? false : true,
                  disabled: values?._status === "Analyze" ? true : false
                },

                {
                  col: 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title="Tickets"
                      subTitle={language.SELECT_ONE_OR_MORE_TICKETS_TO_LINK_TO_GMUD}
                    />
                  ),
                  type: 'autocomplete',
                  name: 'tickets',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${APIEndpoints.TICKET}/filter/all`,
                  routeGetCount: `${APIEndpoints.TICKET}/filter/all?count=0`,
                  fieldquery: 'search',
                  textinit:
                    values && values.tickets_labels
                      ? values.tickets_labels
                      : '',
                  fieldvaluedb: 'id_tickets',
                  fieldlabeldb: 'name',
                  idinit: values && values.tickets ? values.tickets : 0,
                  routeAll: `${APIEndpoints.TICKET}/filter/all?`,
                  filterAll: { fk_id_company: company || 0 },
                  methodGetAll: 'POST',
                  required: false,
                  showEmail: false,
                  isMulti: true,
                  loadOnFocus: true,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={language.DEPARTMENTS_SUBDEPARTMENTS}
                      subTitle={language.SELECT_THE_AREA_INVOLVED_IN_GMUD}
                    />
                  ),
                  type: 'autocomplete',
                  name: 'departments',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: !company || company === 0 ? null : `${APIEndpoints.DEPARTMENT}/filter/`,
                  filter: { fk_id_company: company || 0 },
                  routeAll: !company || company === 0 ? null : `${APIEndpoints.DEPARTMENT}/filter?`,
                  filterAll: { fk_id_company: company || 0 },
                  routeGetCount: !company || company === 0 ? null : `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit:
                    values && values.departments_labels
                      ? values.departments_labels
                      : '',
                  fieldvaluedb: 'id_department',
                  fieldlabeldb: 'name',
                  idinit:
                    values && values.departments ? values.departments : 0,
                  methodGetAll: 'POST',
                  required: false,
                  showEmail: false,
                  isMulti: true,
                  loadOnFocus: true,
                  disabled: !company || company === 0,
                  disabled: values?._status === "Analyze" ? true : false
                },
                {
                  col: 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={`${language.IC_CONFIGURATION_ITEM} ${typeTemp ? '' : '*'}`}
                      subTitle={language.SELECT_THE_CI_CONFIGURATION_ITEM_INVOLVED_IN_THE_GMUD}
                    />
                  ),
                  type: 'autocomplete',
                  name: 'devices',
                  label: ``,
                  textlabel: ``,
                  method: 'POST',
                  route: `${APIEndpoints.DEVICE}/filter/`,
                  routeGetCount: `${APIEndpoints.DEVICE}/filter?count=0`,
                  fieldquery: 'search',
                  textinit:
                    values && values.devices_labels
                      ? values.devices_labels
                      : '',
                  fieldvaluedb: 'id_device',
                  fieldlabeldb: 'name',
                  idinit: values && values.devices ? values.devices : 0,
                  routeAll: `${APIEndpoints.DEVICE}/filter?`,
                  filterAll: { fk_id_company: company || 0 },
                  methodGetAll: 'POST',
                  detail: true,
                  required: typeTemp ? false : true,
                  showEmail: false,
                  isMulti: true,
                  loadOnFocus: true
                },
                {
                  col: 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={`${language.TECHNICAL_GROUPS} * `}
                      subTitle=
                      {params.change_group_tech_approve == true ? <b style={{ color: 'red' }}>{language.SELECTED_TECHNICAL_GROUP_WILL_APPROVE_THE_GMUD}</b>
                        : language.SELECT_THE_TECHNICAL_GROUPS_INVOLVED_IN_THE_GMUD}


                    />
                  ),
                  type: 'autocomplete',
                  name: 'techgroups',
                  method: 'POST',
                  textlabel: '',
                  route: `${APIEndpoints.GROUP}/all?type=1&`,
                  routeAll: `${APIEndpoints.GROUP}/all?type=1&`,
                  fieldquery: 'search',
                  filter: { type_search: 1 },
                  filterAll: { type_search: 1 },
                  textinit:
                    values && values.techgroups_labels
                      ? values.techgroups_labels
                      : '',
                  fieldvaluedb: 'id_group_users',
                  fieldlabeldb: 'name',
                  idinit:
                    values && values.techgroups ? values.techgroups : 0,
                  methodGetAll: 'POST',
                  required: true,
                  isMulti: true,
                  loadOnFocus: true
                },
                {
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={language.ATTACHMENTS}
                      subTitle={language.SELECT_THE_FILES}
                    />
                  ),
                  col: 6, type: 'file', name: 'file', label: language.ATTACH,
                  removeFile: f => ondeletefile(f.id_change_file),
                  onClick: (f) => ondonwFile_(f.id_change_file, f.file),
                },
                {
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={`${language.SELECT_FORM} ${typeTemp ? '*' : ''} `}
                      subTitle={language.SELECT_FORM_ASSOCIATED_WITH_GMUD}
                    />
                  ),
                  col: 6,
                  name: 'fk_id_template_forms',
                  label: '',
                  type: 'select',
                  options: templatesForm.map((i) => ({
                    value: i.id_template_forms,
                    label: i.name,
                  })),
                  required: params.enable_required_template_gmud ? true : false
                },

                {
                  col: 12,
                  type: 'textEditor',
                  name: 'benefits',
                  required: true,
                  label: language.DESCRIPTION,
                  placeholder: language.WRITE_THE_DESCRIPTION_HERE,
                },
                {
                  col: 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={`${language.APPROVERS} ${typeTemp ? '' : '*'} `}
                      subTitle={language.SELECT_APPROVERS}
                    />
                  ),
                  type: 'autocomplete',
                  name: 'key_users',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/${company || 0
                    }`,
                  fieldquery: 'search',
                  textinit:
                    values && values.key_users_labels
                      ? values.key_users_labels
                      : '',
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit:
                    values && values.key_users_id ? values.key_users_id : 0,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: { all_fk_id_company: company || 0 },
                  methodGetAll: 'POST',
                  required: false,
                  showEmail: true,
                  isMulti: true,
                  loadOnFocus: true
                },
                {
                  col: (groupCompany && groupCompany.name) ? 4 : 6,
                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={`${language.APPROVERS_GROUPS} ${typeTemp ? '' : '*'}`}
                      subTitle={language.SELECT_GMUD_APPROVER_GROUPS}
                    />
                  ),
                  type: 'autocomplete',
                  name: 'sponsorgroups',
                  method: 'POST',
                  route: `${APIEndpoints.GROUP}/all?type=3&`,
                  routeAll: `${APIEndpoints.GROUP}/all?type=3&`,
                  fieldquery: 'search',
                  filter: { type_search: 3 },
                  filterAll: { type_search: 3 },
                  textinit:
                    values && values.sponsorgroups_labels
                      ? values.sponsorgroups_labels
                      : '',
                  fieldvaluedb: 'id_group_users',
                  fieldlabeldb: 'name',
                  idinit:
                    values && values.sponsorgroups ? values.sponsorgroups : 0,
                  methodGetAll: 'POST',
                  required:
                    params.change_group_tech_approve == true ? false :
                      groupRequired == false ? false : (groupCompany && groupCompany.name ? false : true)
                  ,
                  isMulti: true,
                  loadOnFocus: true,
                },
                {

                  beforeContent: (
                    <MyHeader
                      className="-mt-3"
                      title={language.AUTOMATIC_APPROVER_GROUPS}
                      subTitle={language.AUTOMATIC_APPROVER_GROUPS}
                    />
                  ),
                  type: 'element',
                  content: <div>{groupCompany && groupCompany.name || ''}</div>,
                  visible: (groupCompany && groupCompany.name) ? true : false,
                  col: (groupCompany && groupCompany.name) ? 2 : 0
                },

                {
                  type: 'element',
                  content: <hr className="-mb-1" />,
                },
              ]
          }
          onChangeField={(f, v) => {
            if (f.name === 'fk_id_company') {
              SetCompany(v);
              groupCompany_(v)
              changeContract(v);
            } else if (f.name === 'fk_id_contract') {
              SetContract(v);
            } else if (f.name === 'fk_id_template_forms') {

              setFormsTemplate(
                templatesForm.find((item) => item.id_template_forms == v)
              );
            } else if (f.name == 'outage') {
              setOutage(v == 0 ? false : 1)
            }
            else if (f.name === 'key_users') {
              setgroupRequired(false)
            } else if (f.name == 'devices') {
              setModalOutage(true);
              getDeviceOutage(v);
            } else if (f.name == 'history') {
              setModalHistory(v == 0)
            }
          }}
          onSubmit={(v) => {
            verifyBlackout(v, formstemplate.html || (values && values.html || ' '))
          }}
          loading={'post-' + APIEndpoints.CHANGE}
          button={{
            fluid: true,
            label: language.NEXT,
            size: 1,
            style: {
              height: 40,
            },
          }}
        />
        <br></br>
        <div></div>
        <div className="itemTicketDetail">
          <p id={'template'}>
            {formshtml(decryp(formstemplate.html || (values && values.html || ' ')))}
          </p>
        </div>
        <Dialog
          open={modalJustify}
          onClose={() => setModalJustify(false)}
          contentStyle={{ height: '80%', maxWidth: 'none' }}
          fullWidth={true}
          maxWidth="lg"
          autoScrollBodyContent={true}
          title={''}
        >
          <div className="container" style={{ padding: '20px 0' }}>
            <p dangerouslySetInnerHTML={{ __html: log }}></p>
          </div>
        </Dialog>
        {modalHistory && (
          <Dialog
            open={true}
            onClose={() => setModalHistory(false)}
            title={language.HISTORY_APPROVALS}
            maxWidth="xl"
          >
            <Grid container spacing={16} className="mt-3">
              <Grid item xs={12}>
                <div className="_table">
                  <CommonTable
                    paper={false}
                    mini
                    bar={false}
                    pagination={false}
                    orderColumn={false}
                    col={[
                      {
                        key: 'dt_register',
                        label: language.DATE_REGISTER,
                        thConfig: true,
                        style: { width: '23.75%' },
                      },
                      {
                        key: 'tech',
                        label: language.APPROVER,
                        thConfig: true,
                        style: { width: '23.75%' },
                      },
                      {
                        key: 'user_exec',
                        label: language.PERFORMED,
                        thConfig: true,
                        style: { width: '23.75%' },
                      },
                      {
                        key: 'action',
                        label: 'Ação',
                        thConfig: true,
                        style: { width: '23.75%' },
                      },
                      {
                        key: 'action',
                        label: 'Ação',
                        thConfig: true,
                        style: { width: '23.75%' },
                      },
                      {
                        key: 'dt_aprovacao',
                        label: language.APPROVAL_DATE,
                        thConfig: true,
                        style: { width: '23.75%' },
                      },
                      {
                        key: 'reason',
                        label: language.JUSTIFICATION,
                        thConfig: true,
                        style: { width: '10.75%' },
                      }
                    ]}
                    data={values.revision_hist_edit && values.revision_hist_edit.map((h) => ({
                      dt_register: moment(h.dt_cad).format('DD/MM/YYYY HH:mm'),
                      tech: h.group || h.name,
                      user_exec: h.user_exec,
                      action: h.dt_up == null ? 'Pendente' : h.approved == true ? 'Aprovado' : 'Rejeitado',
                      dt_aprovacao:
                        h.dt_up == null
                          ? language.PENDING
                          : moment(h.dt_up).format('DD/MM/YYYY HH:mm'),
                      reason: (
                        <i
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setLog(h.log);
                            setModalJustify(true);
                          }}
                          class="fas fa-eye"
                        ></i>
                      )
                    }))
                      .concat()}
                  />
                </div>
              </Grid>
            </Grid>
          </Dialog>
        )}
      </div>
    </Fragment>
  );
}
