export default {
  _ANALYZE: 'Análise',
  _CONTRACT: 'Contrato',
  _PROGRESS: 'Progresso',
  _REQUEST: 'Requisição',
  _SERVICE: 'Serviço',
  _TEMPLATE: 'TEMPLATE',
  ABSTRACT: 'Resumo',
  ACCEPT: 'Aceitar',
  ACCEPT_SUB_CALLS_OTHER_GROUPS: 'Aceita sub chamados  de outros grupos',
  ACCEPTABLE: 'Aceitável',
  ACCEPTED: 'Aceito',
  ACCESS: 'Acesso',
  ACCESS_CONTROL: 'Controle de Acesso',
  ACCESS_OPTION_VIEW_REPORTS: 'Acesse esta opção para visualizar todos Relatórios',
  ACCESS_OPTION_VIEW_THE_REPORTS: 'Acesse esta opção para visualizar o Relatório',
  ACCESS_REMOTE: 'Acesso remoto',
  ACCOUNT: 'Conta',
  ACCOUNT_MANAGER: 'Gerente de contas',
  ACCOUNTED_HOURS_WILL_NOT_BE_DELETED: 'Horas contabilizadas não serão excluidas',
  ACCUMULATED: 'Acumulado',
  ACCUMULATED_TOTAL: 'Total acumulado',
  ACCUMULATED_VALUE: 'Valor acumulado',
  ACCUMULATED_VALUES: 'Valores acumulados',
  ACN: 'ACN',
  ACTION: 'Ação',
  ACTION_NOT_ALLOWED_GMUD_FROZEN: 'Ação não permitida, GMUD congelada',
  ACTIONS: 'Ações',
  ACTIONS_TICKET: 'Ações Ticket',
  ACTIVE: 'Ativo',
  ACTIVE_TIME: 'Tempo Ativo',
  ACTIVE_PLURAL: 'Ativos',
  ACTIVITIES: 'Atividades',
  ACTIVITIES_EXCEEDED_HOURS: 'Atividades (horas excedidas)',
  ACTIVITIES_HOURS_PERFORMED: 'Atividades (horas realizadas)',
  ACTIVITIES_IN_BACKLOG: 'Atividades em backlog',
  ACTIVITIES_IN_DOING: 'Atividades em progresso',
  ACTIVITIES_IN_DONE: 'Atividades concluídas',
  ACTIVITIES_IN_STOPPED: 'Atividades paradas',
  ACTIVITIES_IN_TODO: 'Atividades em a fazer',
  ACTIVITIES_IN_VALIDATION: 'Atividades em validação',
  ACTIVITIES_PLANNED_HOURS: 'Atividades (horas planejadas)',
  ACTIVITIES_RELATED_TO_PROJECT_YOU_INVOLVED_IN: 'Atividades relacionadas ao projeto que você está envolvido',
  ACTIVITY: 'Atividade',
  ACTIVITY_CLOSED_IN_MONTH: 'Atividades encerrados',
  ACTIVITY_DATE: 'Data da atividade',
  ACTIVITY_DESCRIPTION: 'Descrição das Atividades',
  ACTIVITY_DID_NOT_REACH_100_PERCENT: 'Atividade não atingiu 100%',
  ACTIVITY_EFFORT: 'Esforço da Atividade',
  ACTIVITY_EVOLUTION: 'Evolução da Atividade',
  ACTIVITY_GROUP_NAME: 'Nome do grupo de atividade',
  ACTIVITY_IN_APRREARS: 'Atividades em atraso',
  ACTIVITY_IN_ARREARS: 'Atividades em atraso ',
  ACTIVITY_IN_EXECUTION: 'Atividades em execução',
  ACTIVITY_IN_GMUD: 'Atividade em gmud',
  ACTIVITY_NAME: 'Nome da atividade',
  ACTIVITY_NEXT_EXPIRATION: 'Atividades em atraso',
  ACTIVITY_PENDENT: 'Existem atividades pendentes',
  ACTIVITY_PER_USER: 'Atividade por usuário',
  ACTIVITY_REGISTER: 'Necessário cadastrar atividades',
  ACTIVITY_STARTED_IN_MONTH: 'Atividades iniciados no mês',
  ACTIVITY_STATUS: 'Status da atividade',
  ACTIVITY_SUMMARY: 'Resumo da Atividade',
  ACTIVITY_TAGS: 'Tags da atividade',
  ACTIVITY_TIME: 'Tempo da atividade',
  ACTIVITY_WITHOUT_START: 'Atividade cadastrada sem data início!',
  ACTUAL_AVAILABILITY: 'Disponibilidade real',
  ACTUAL_END_DATE: 'Data Fim Real',
  ACTUAL_START_DATE: 'Data Inicio Real',
  AD_USER: 'Usuário AD',
  ADD: 'Adicionar',
  ADD_A_COMMENT: 'Adicionar um comentário',
  ADD_A_NEW_RESULT: 'Adicionar um novo resultado',
  ADD_A_SUBACTIVITY: 'Adicionar uma subatividade',
  ADD_ACTIVITIES: 'Adicionar Atividades',
  ADD_ACTIVITIES_TO_CATEGORY: 'Adicionar atividades à categoria',
  ADD_ACTIVITY: 'Adicionar Atividade',
  ADD_APPROVAL_TO_GROUP: 'Adicionar Aprovador ao Grupo',
  ADD_ASSIGNMENT: 'Adicionar Nova Tarefa',
  ADD_BLACKOUT: 'Adicionar Blackout',
  ADD_CATALOG: 'Adicionar Catálogo',
  ADD_CATALOG_TO_CATEGORY: 'Adicionar Catálogo de Serviço à Categoria',
  ADD_CATEGORIZATION: 'Adicionar Categorização',
  ADD_CATEGORY: 'Adicionar Nova Categoria',
  ADD_CHANGE_TEMPLATE: 'Adicionar template de mudança',
  ADD_COMPANY: 'Adicionar Empresa Contratada',
  ADD_COMPLETION_TEMPLATE: 'Adicionar Template de finalização de chamado',
  ADD_CONTRACT: 'Adicionar Contrato',
  ADD_CREDENTIALS: 'Adicionar Credenciais',
  ADD_DEPARTMENT: 'Adicionar Departamento',
  ADD_EVENT: 'Adicionar Evento',
  ADD_EVIDENCE: 'Adicionar Evidência',
  ADD_EXPECTED_RESULT: 'Adicionar Resultado Esperado',
  ADD_FORM: 'Adicionar Formulário',
  ADD_GLOBAL: 'Adicionar Global',
  ADD_GROUP: 'Adicionar Grupo',
  ADD_GROUPING: 'Adicionar agrupamento',
  ADD_IN_STOCK: 'Registrar no Estoque',
  ADD_KNOWLEDGE_BASE: 'Cadastrar Nova Base de Conhecimento',
  ADD_LOCATION: 'Adicionar Localidade',
  ADD_MAIL: 'Adicionar Email',
  ADD_MILESTONE: 'Adicionar Categoria',
  ADD_MONITORING_GROUP: 'Adicionar Grupo de Monitoramento',
  ADD_NEW_BLACKOUT: 'Adicionar Novo Blackout',
  ADD_NEW_BUILD: 'Adicionar nova Build',
  ADD_NEW_DEVICE: 'Adicionar Novo Dispositivo',
  ADD_NEW_ITEM: 'Adicionar novo item',
  ADD_NEW_PASSWORD: 'Cadastrar nova senha',
  ADD_NEW_PASSWORD2: 'Adicionar nova senha',
  ADD_NEW_TYPE_CONTRACT: 'Adicionar Novo Tipo de Contrato',
  ADD_OBSERVER: 'Adicionar Observadores',
  ADD_OTHER_COSTS: 'Adicionar outros custos',
  ADD_OUTSOURCED: 'Adicionar Fornecedor',
  ADD_REQUEST_FORM: 'Adicionar Formulário de Solicitação',
  ADD_REQUESTER: 'Adicionar Solicitante',
  ADD_RISKS_AND_ACTIVITIES: 'Adicionar Riscos e Grupos de Atividades',
  ADD_RULE: 'Adicionar regra',
  ADD_SERVICE: 'Adicionar Serviços',
  ADD_SERVICE_PORTAL: 'Configurar Portal de Serviço',
  ADD_SLA: 'Adicionar SLA',
  ADD_SOLICITANT: 'Adicinar Solicitante',
  ADD_SOLICITANT_TO_GROUP: 'Adicionar Solicitante ao Grupo',
  ADD_SPONSOR: 'Cadastrar Novo Responsável',
  ADD_SPRINT: 'Adicionar Sprint',
  ADD_SUBJECT: 'Adicionar assunto',
  ADD_TASK: 'Adicionar Nova Tarefa',
  ADD_TASK_ADD: 'Adicionar Atividade',
  ADD_TASK2: 'Adicionar Tarefa',
  ADD_TECH_TO_GROUP: 'Adicionar Técnico ao Grupo',
  ADD_TECHS: 'Adicionar Técnico',
  ADD_THE_ACTIVITY: 'Adicionar a atividade',
  ADD_THIRD_LEVEL_CATEGORY: 'Adicionar Categoria de Terceiro Nível',
  ADD_TO_SPRINT: 'Adicionar a Sprint',
  ADD_USER: 'Adicionar Usuário',
  ADD_USER_TO_DEPARTMENT: 'Adicionar Usuário ao Departamento',
  ADD_USER_TO_GROUP: 'Adicionar Usuário ao Grupo',
  ADD_USER_TO_NOTIFICATION_GROUP: 'Adicionar usuário ao grupo de notificaçãoes',
  ADD_USERS_TO_PROJECT: 'Adicionar Usuários ao Projeto',
  ADDED_COSTS: 'Custos adicionados',
  ADDITIONAL_INFORMATION: 'Informações Adicionais',
  ADDITIONAL_RECIPIENTS: 'Destinatários Adicionais',
  ADDITIONAL_RECIPIENTS_HOLDER: 'Use virgula "," para separar os endereços de email',
  ADDITIONALINFO: 'Informação adicional',
  ADDRESS: 'Endereço',
  ADHERENCE: 'Aderência',
  ADHERENT: 'Aderente',
  ADMIN: 'Administrador',
  ADVANCE: 'Avançar',
  ADVANCED_SEARCH: 'Busca Avançada',
  AFFECTED_AREA: 'Área afetada',
  AFFECTED_PRODUCT: 'Produto afetado',
  AFTER: 'Depois',
  AFTER_DEADLINE: 'Depois do prazo',
  AFTER_POST_IMPLEMENTATION_REVIEW: 'Após revisão pós-implementação',
  AGENT: 'Agente',
  AGENT_DOWNLOAD: 'Download Agent',
  AGENT_VERSION: 'Versão do Agent',
  ALERT: 'Alerta',
  ALERT_ATTACH_SAVE_CHECK: 'O anexo foi adicionado com sucesso, para salvar clique no botão salvar no final dos checks',
  ALERT_CENTER: 'Central de alerta',
  ALERT_CONTRACTOR_APROVE_REQUEST: 'Apenas o responsável do contrato tem permissão para aprovar a solicitação!',
  ALERT_DELETE_SUCCESS: 'Dados Removidos com sucesso',
  ALERT_INSERT_SUCCESS: 'Dados inseridos com sucesso',
  ALERT_MANAGEMENT: 'Gestão de alertas',
  ALERT_NO_RESULT_FOUND: 'Nenhum resultado encontrado',
  ALERT_TICKET: 'não atendido',
  ALERT_UPDATE_SUCCESS: 'Dados atualizados com sucesso',
  ALERT_WANT_GENERATE_DETAILED_GMUD: 'Deseja gerar uma GMUD detalhado? Aconselhamos o completo. Se deseja gerar uma GMUD simples e rápida escolha a opção standard',
  ALERTS: 'Alertas',
  ALERTS_GROUP: 'Grupo de Alertas',
  ALIAS: 'Alias',
  ALL: 'Todos',
  ALL_ADDED_ACTIVITIES: 'Todas as atividades foram adicionadas',
  ALL_DEVICES: 'Todos os dispositivos',
  ALLOCATED: 'Alocado',
  ALLOCATED_HOURS: 'Horas Alocadas',
  ALLOCATION: 'Alocação',
  ALLOCATION_AND_DELIVERIES: 'Alocação e entregas',
  ALLOCATION_OF_TEAM: 'Alocação da equipe',
  ALLOW_CREATE_SUB_CALLS: 'Permitir criar subchamados',
  ALLOW_CUSTOMIZE_PRIORITY: 'Permitir customização da prioridade',
  ALLOW_EDITING_OF_TECH_GMUD_AFTER_APPROVAL: 'Permitir edição do técnico da gmud após aprovação',
  ALLOW_SENDING_EMAIL: 'Permitir envio de email',
  ALLOWANCE: 'Abonar',
  ALLOWANCE_READY: 'Ticket abonados com sucesso',
  ALLOWANCE_SELECTED: 'Tickets selecionados',
  ALLOWANCE_SLA: 'Abono SLA',
  ALLOWED_EXTENSIONS: 'Extensões permitidas',
  ALSO_FINALIZE_THE_SUBCONTRACTS: 'Também finalizar os subcontratos',
  ALSO_REMOVE_THE_SUBCONTRACTS: 'Também remover os subcontratos',
  ALTERNATE_BOSS: 'Chefe Alternativo',
  ALTERNATIVE_EMAIL: 'Email alternativo',
  ALTERNATIVE_LEADER: 'Líder Alternativo',
  AMOUNT: 'Valor Total',
  AMOUNT_OF_HOURS: 'Quantidade de horas',
  AMOUNT_OF_MILESTONES: 'Quantidade de atividades',
  AMOUNT_OF_RISKS: 'Quantidade de riscos',
  AN_HOUR: 'uma hora',
  AN_INTERNAL_ERROR_OCCURRED_ON_THE_SERVER: 'Ocorreu um erro interno no servidor',
  ANALITIC: 'Analítico',
  ANALYSIS: 'Analise',
  ANALYSIS_OF_INFORMATION_CALL: 'Análise de Informações dos Chamados',
  ANALYSIS_OF_INFORMATION_GSD: 'Análise de Informações de GSD',
  ANALYSIS_PROJECT_MANAGEMENT: 'Analise de gestão de projetos',
  ANALYST: 'Analista',
  ANALYST_CALLS: 'Chamados de Analistas',
  ANALYSTS: 'Analistas',
  ANALYZE: 'Analisar',
  ANALYZE_CHANGE: 'Analizar Mudança',
  ANALYZE_DEVICE_MONITORING: 'Análise dos monitoramentos de dispositivos',
  ANALYZING: 'Analisando',
  AND: 'e',
  ANNOTATION: 'Annotation',
  ANSWER: 'Resposta',
  ANSWER_QUESTION: 'Responder Pendência',
  ANSWERED_CALLS_RESOLVED_AND_CLOSED: 'Chamados atendidos (Resolvidos e Fechados)',
  ANSWERS: 'Respostas',
  ANTICIPATED: 'Antecipado',
  APPLICABLE: 'Aplicável',
  APPROVAL: 'Aprovação',
  APPROVAL_CARRIED_OUT_SUCCESSFULLY: 'Aprovação realizada com sucesso',
  APPROVAL_CENTER: 'Central de Aprovações',
  APPROVAL_DATE: 'Data da Aprovação',
  APPROVAL_DETAILS: 'Detalhes da Aprovação',
  APPROVAL_GROUP: 'Grupo de Aprovadores',
  APPROVAL_HISTORY: 'Histórico de aprovações',
  APPROVAL_REQUIRED: 'Necessário aprovação',
  APPROVAL_SUCCESSFULLY_COMPLETED: 'Aprovação realizada com sucesso',
  APPROVALS: 'Aprovações',
  APPROVE: 'Aprovar',
  APPROVE_ATTACHMENTS: 'Aprovar anexos',
  APPROVE_EVIDENCE: 'Aprovar evidências',
  APPROVE_KNOWLEDGE: 'Aprovar Criação/Alteração da base de conhecimento',
  APPROVE_MATURITY: 'Aprovar maturidade',
  APPROVE_REQUESTS: 'APROVAR SOLICITAÇÕES',
  APPROVED: 'Aprovado',
  APPROVER: 'Aprovador',
  APPROVERS: 'Aprovadores',
  APPROVERS_GROUP: 'Grupo de Aprovadores',
  APPROVERS_GROUPS: 'Grupos de Aprovadores',
  APPROVING_GROUP: 'Grupo de aprovação',
  ARCHITECTURE: 'Arquitetura',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COST: 'Você tem certeza que deseja excluir este custo?',
  AREA_BENEFITED_FROM_THE_PROJECT: 'Área beneficiada pelo projeto',
  ASK: 'Pergunta',
  ASKS: 'Perguntas',
  ASSESSMENT: 'Suporte',
  ASSESSMENTS: 'Suportes',
  ASSESSMENTS_LIST: 'Lista de Suportes',
  ASSET_INVOLVED: 'Ativo envolvido',
  ASSET_MANAGEMENT: 'Administração de Ativos',
  ASSET_MANAGER: 'Asset Manager',
  ASSET_TYPE: 'Tipo de Ativo',
  ASSIGN: 'Atribuir',
  ASSIGN_CALLS_ONLY_TECH_IS_FREE: 'Atribuir chamados apenas se o técnico estiver livre',
  ASSIGNED_GROUP: 'Grupo designado',
  ASSIGNED_TECH_STOP_RESPONSE_SLA: 'Técnico atribuído, parar SLA de resposta',
  ASSOCIATE: 'Associar',
  ASSOCIATE_COMPANY: 'Associar Empresa',
  ASSOCIATE_DEPARTMENT: 'Associar Departamento',
  ASSOCIATE_IN: 'Associado em',
  ASSOCIATE_PREDECESSOR_TASKS: 'Associar Tarefas Predecessoras',
  ASSOCIATE_PROJECT_ACTIVITIES: 'Associar atividades de projeto',
  ASSOCIATE_TASK_TEMPLATE: 'Associar Template de Tarefas',
  ASSOCIATE_TASKS: 'Associar Tarefas',
  ASSOCIATE_TECH_CONTRACT: 'Associar Técnico x contratos',
  ASSOCIATE_TECH_X_COMPANY: 'Associar Técnico x Empresa',
  ASSOCIATE_TICKET: 'Ticket Associado',
  ASSOCIATE_TICKETS: 'Associar Tickets',
  ASSOCIATE_TO_TEMPLATE: 'Associar ao Template',
  ASSOCIATE_TO_TICKET: 'ASSOCIAR AO TICKET',
  ASSOCIATE_USER: 'Associar Usuário',
  ASSOCIATE_USER_CONTRACT: 'Associar Usuário x contratos',
  ASSOCIATE_USER_X_COMPANY: 'Associar Usuário x Empresa',
  ASSOCIATE_WITH_GP: 'Associar com GP',
  ASSOCIATE_WITH_TK: 'Associar com TK',
  ASSOCIATED_ACTIVITY: 'Atividade associada',
  ASSOCIATED_ITEMS: 'Itens associados',
  ASSOCIATED_TASKS: 'Tarefas associadas',
  ASSOCIATED_TICKETS: 'Tickets associados',
  ASSOCIATING_TICKET_CLOSE: 'Ao associar os ticket os mesmos irão ser fechados',
  ASSOCIATION_ITEM_TO_PROJECT: 'Associar item a projeto',
  ASSOCIATION_ITEM_TO_PROJECT_ACTIVITY: 'Associar item atividade de um  projeto',
  ASSOCIATION_ITEM_TO_PROJECT_OR_MANUAL: 'Associe o item selecionado a um #Projeto, Tempo médio de Atendimento, Tempo de Categorização ou Manual',
  ASSOCIATION_TICKETS: 'Associação de Tickets',
  AT: 'Às',
  ATTACH: 'Anexar',
  ATTACH_DATE: 'Anexar data',
  ATTACH_EVIDENCE: 'Anexar Evidência',
  ATTACH_FILE: 'Anexar arquivo',
  ATTACH_FILE_ERROR: 'Este arquivo possui um formato inválido ou é maior que o permitido.',
  ATTACH_FILES: 'Anexar arquivos',
  ATTACH_FILES_HERE: 'Anexe arquivos aqui',
  ATTACHED_EDIT: 'Editou anexos',
  ATTACHMENT: 'Anexo',
  ATTACHMENTS: 'Anexos',
  ATTACH_FILES: 'Anexar arquivos',
  ATTACH_FILE_ERROR:
    'Este arquivo possui um formato inválido ou é maior que o permitido.',
  ATTACH_FILES_HERE: 'Anexe arquivos aqui',
  ATTACHED_EDIT: 'Editou anexos',
  ATTEND: 'Atender',
  ATTENDANCE: 'Atendimento',
  ATTENDANCE_AVERAGE: 'MEDIA DE ATENDIMENTO',
  ATTENDANCE_DEADLINE: 'Prazo Atendimento',
  ATTENDANCE_GROUP: 'Grupo Atendimento',
  ATTENDANCE_STOPPED: 'Chamado parado',
  ATTENDANT: 'Atendente',
  ATTENDED: 'Atendidos',
  ATTENTION: 'Atenção!',
  AUDIT: 'Auditoria',
  AUDIT_CATALOG_MANAGEMENT_BODY: 'Auditoria de catalogo',
  AUDIT_COMPANY_MANAGEMENT_BODY: 'Auditoria de empresa',
  AUDIT_FLOW_APPROBATION_GMUD: 'Auditoria dos fluxos de aprovação da GMUD',
  AUDIT_FORM: 'Auditoria dos formulários',
  AUDIT_USERS_MANAGEMENT_BODY: 'Auditoria de usuários',
  AUTHOR: 'Autor',
  AUTOMATIC_ANSWER: 'Resposta Automática',
  AUTOMATIC_APPROVER_GROUPS: 'Grupos de Aprovadores Automático',
  AUTOMATIC_ASSIGN_TICKET_AFTER: 'Atribuir ticket automaticamente depois de',
  AUTOMATIC_CLOSING: 'Fechamento Automático',
  AUTOMATION: 'Automação',
  AVAILABILITY: 'Disponibilidade',
  AVAILABILITY_MANAGEMENT: 'Gestão de Disponibilidade',
  AVAILABLE_CLASSES: 'Classes disponíveis',
  AVALIATION_SENT: 'Obrigado, a pesquisa de satisfação foi enviada com sucesso.',
  AVALIATION_TABLE: 'Tabela de Avaliação',
  AVALIATION_TICKET: 'Avaliação do chamado',
  AVALIATION_TICKET_RESOLUTION_QUALITY: 'De 0 a 10, qual a sua avaliação a respeito da qualidade de solução do chamado?',
  AVALIATION_TICKET_RESOLUTION_TIME: 'De 0 a 10, qual a sua avaliação a respeito do tempo de solução do chamado?',
  AVALIATION_TICKET_TEAM: 'De 0 a 10, qual foi a sua experiência com a nossa equipe de atendimento?',
  AVERAGE: 'Média',
  AVERAGE_AVAILABILITY: 'Média de disponibilidade',
  AVERAGE_CATEGORIZATION_TIME: 'Tempo Médio de Categorização',
  AVERAGE_OF_THE_LAST_TWELVE_MONTHS: 'Média dos últimos 12 meses',
  AVERAGE_SERVICE_TIME: 'Tempo médio de atendimento',
  AVERAGE_TIME: 'Tempo médio',
  AVERAGES_OF_EACH_STANDARD: 'Médias de cada norma',
  AWAITING_EXECUTION: 'Aguardando execução',
  BACK: 'Voltar',
  BACKDATING_IS_NOT_ALLOWED: 'Não é permitido retroceder!',
  BACKLOG: 'BackLog',
  BACKLOG_ACTIVITY: 'Backlog de Atividades',
  BACKLOG_PROJECTS: 'Backlog de projetos',
  BACKUP: 'Backup',
  BACKUP_LIST: 'Backup List',
  BACKUP_MANAGEMENT: 'Gestão de Backup',
  BACKUP_SIDE_DISH: 'Acompanhamento de Backup',
  BACKUP_TYPE: 'Tipo de Backup',
  BALANCE: 'Saldo',
  BALANCE_SCORE_CARD: 'Balanced Score Card',
  BASE: 'Base',
  BASIC_INFO: 'Informações Básicas',
  BASIC_TICKET_INFORMATION: 'Informações básicas do ticket',
  BEFORE: 'Antes',
  BEFORE_DEADLINE: 'Antes do prazo',
  BENEFITED_AREA_PROJECT: 'Área Beneficiada do Projeto',
  BENEFITS: 'Benefícios',
  BILLING_DATE: 'Data de Faturamento',
  BILLING_DATE_MONTH: 'Mês de Faturamento',
  BILLING_DAY: 'Dia de Faturamento',
  BILLING_TYPE: 'Tipo de Faturamento',
  BIND_TICKET: 'Vincular Ticket',
  BIOS_MANUFACTURER: 'Fabricante da bios',
  BIOS_NAME: 'Nome da BIOS',
  BIOS_SERIAL_NUMBER: 'Número de série da bios',
  BIOS_VERSION: 'Versão da BIOS',
  BLOCK_TICKET_WITH_TASKS_PENDENT: 'Bloquear Modificação de Tickets com Tarefas Pendentes',
  BOARD_DESIRE: 'Desejo da Diretoria',
  BOARD_MANUFACTURER: 'Fabricante de placas',
  BOARD_PRODUCT: 'Produto de tabuleiro',
  BOARD_SERIAL_NUMBER: 'Número de série da placa',
  BOARD_VERSION: 'Versão da placa',
  BOSS: 'Chefe',
  BSC: 'BSC',
  BSC_IN: 'Indicadores',
  BSC_INDICATOR: 'Indicador',
  BSC_METAS: 'Metas',
  BSC_OB: 'Objetivo Corporativo',
  BSC_PERS: 'Perspectiva',
  BSC_TI: 'Objetivo da TI',
  BUDGETED_AMOUNT: 'Valor orçado',
  BUDGETED_DEVIATION: 'Desvio orçamentário',
  BUILD: 'Build',
  BURST: 'Estourados',
  BUSINESS_AREA: 'Área de negócio',
  BUSINESS_CONTINUITY_MANAGEMENT: 'Gerenciamento de Continuidade de Negócios',
  BY: 'Por',
  BY_AREA: 'Por área',
  BY_CATEGORIZATION: 'Por categorização',
  BY_CLOSING: 'Ao fechar',
  BY_CONSUMPTION: 'Por consumo',
  BY_DATE: 'Por data',
  BY_DEPARTMENT: 'Por departamento',
  BY_ID: 'Por Id',
  BY_MONTH: 'Por mês',
  BY_OPENING: 'Ao abrir',
  BY_TECHNICAL_GROUP_WITH_CONSUMED_HOURS: 'POR GRUPO TÉCNICO COM HORAS CONSUMIDAS',
  BY_TYPE: 'Por tipo',
  BY_VALUE: 'Por valor',
  CAB_ACCEPT: 'Aprovar CAB',
  CAB_ALERT: 'CAB salvo com sucesso',
  CAB_ANALYSIS: 'Análise do CAB',
  CAB_ATTACH: 'Anexar ATA',
  CAB_EVENTS: 'Eventos do CAB',
  CAB_REJECT: 'Rejeitar CAB',
  CAB_REJECTED: 'CAB Rejeitado',
  CAB_SCHEDULING: 'Agendamento CAB',
  CADASTRE_MADE: 'Cadastro Realizado',
  CADASTRE_TIME: 'Hora Cadastro',
  CALENDAR: 'Calendário',
  CALENDAR_GMUD: 'Calendário GMUD',
  CALENDAR_OF_ACTIVITIES: 'Calendário de atividades',
  CALL: 'Chamado',
  CALL_CLOSING: 'Fechamento dos chamados',
  CALL_EVALUATION: 'Avaliação de chamados',
  CALL_NUMBER: 'Número do Chamado',
  CALL_QUANTITY_GRAPH: 'Gráfico de quantidade de chamadas',
  CALL_REJECTED: 'Chamado Rejeitado',
  CALL_REPORTING: 'Relatório dos chamados',
  CALL_TREND_PER_DAY: 'Tendência de chamados por dia mês',
  CALLED_BY_ANALYST: 'Chamados Por Analista',
  CALLED_PROVIDER: 'Provedor Chamado',
  CALLED_TYPE: 'Tipo Chamado',
  CALLS: 'Chamados',
  CALLS_OF_MONTH: 'Chamados do mês',
  CALLS_PER_MONTH: 'Chamados no mês',
  CANCEL: 'Cancelar',
  CANCEL_ACTIVITY: 'Cancelar Atividade',
  CANCEL_CALL: 'Cancelar Chamado',
  CANCELED: 'Cancelado',
  CANCELED_PROJECTS: 'Projetos Cancelados',
  CANNOT_BE_ASSOCIATED_DIFFERENT_COMPANIES: 'Item não pode ser associado, empresas diferente!',
  CANVAS: {
    '1_TO_5': 'De 1 à 5',
    ACTIONS_SUPPORT_COMPANY_STRATEGY: 'Ações que apoem a estratégia da empresa',
    ACTIVITY_PRD: 'Atividade Predecessora',
    BOARD_DIRECTORS: 'Diretoria',
    BUSINESS_PARTHER_CONTRACTS: 'Contratos de parceiros de empresa (cadeia de valor)',
    COMMUNICATION_RESULTS: 'Comunicação e Resultados',
    CRITICAL_PROCESS_IT_SUPPORTS: 'Processos críticos que a TI suporta de impacto direto',
    CRITICAL_SERVICES: 'Serviços Críticos',
    EXPENSES: 'Despesas',
    HOW_RESULTS_MONITORED: 'Como serão acompanhados e apresentados os resultados',
    ID_ACESS_REMOTE: 'Id Acesso Remoto',
    INDICATOR_TYPES: 'Tipos de indicadores',
    INVESTMENT: 'Investimento',
    INVESTMENTS: 'Investimentos',
    IT_MODEL_CANVAS: 'Modelo de Tela de TI',
    IT_OBJECTIVE: 'Objetivo de TI',
    ITEMS_IMPACTS_BUSSINES: 'Impeditivos para os objetivos estratégicos da empresa',
    ITEMS_SUPPORT_GOALS: 'Itens que apoiam nos objetivos da empresa',
    PLANED_REQUIRED_EXPENSES: 'Despesas planejadas / necessárias',
    PLANNED_INVESTIMENTS: 'Investimentos planejados / necessários',
    PRIORITIZATION: 'Priorizações',
    RISK_MAPPING: 'Mapeamento de riscos'
  },
  CANVAS_: 'Canvas',
  CAPACITY: 'Capacidade',
  CAPACITY_MEMORY: 'Capacidade da memória',
  CAPACITY_PLAN: 'Plano de Capacidade',
  CATALOG: 'Catálogo',
  CATALOG_LIST: 'Lista de Subcategoria',
  CATALOG_TASK: 'Categoria de Terceiro Nível',
  CATEGORIES: 'Categorias',
  CATEGORIZATION: 'Categorização',
  CATEGORIZATION_DATA_FINAL: 'Data de categorização - final',
  CATEGORIZATION_DATA_INITIAL: 'Data de categorização - Inicial',
  CATEGORIZATION_DATE: 'Data da Categorização',
  CATEGORIZATION_LIST: 'Lista de Categorização',
  CATEGORIZATION_TIME: 'Tempo de Categorização',
  CATEGORIZATIONS: 'Categorizações',
  CATEGORIZE: 'Categorizar',
  CATEGORIZE_SELECTED: 'CATEGORIZAR SELECIONADOS',
  CATEGORY: 'Categoria',
  CATEGORY_ACTIVITY: 'Grupo de Atividade',
  CATEGORY_DESCRIPTION: 'É um elemento utilizado principalmente para classificar e facilitar a ordem dos Grupos.',
  CATEGORY_LIST: 'Lista de Categorias',
  CAUSE: 'Causa',
  CELL_PHONE: 'Telefone Celular',
  CHANGE: 'Mudanças',
  CHANGE_ADDED_SUCCESSFULLY: 'Mudança Adicionada com Sucesso',
  CHANGE_DATA: 'Alterar Dados',
  CHANGE_DATA_SUCCESS: 'Dados Alterados com Sucesso',
  CHANGE_HISTORY: 'Histórico de alteração',
  CHANGE_LIST: 'Lista de Mudanças',
  CHANGE_LOCATION: 'Mudar localidade',
  CHANGE_MANAGEMENT: 'Gestão de Mudanças',
  CHANGE_MANAGER: 'Gestor de processos de mudanças',
  CHANGE_OPEN: 'Abrir Mudança',
  CHANGE_PASSWORD: 'Alterar senha',
  CHANGE_PASSWORD_SUCCESS: 'Senha Alterada com Sucesso',
  CHANGE_REGISTER_MANAGER: 'Gestor de registros de mudanças',
  CHANGE_RISK_ALERT: 'Risco da mudança cadastrado com sucesso',
  CHANGE_SORTING: 'Trocar ordenação',
  CHANGE_STATUS: 'Alterar Status',
  CHANGE_TECH_GROUP: 'Alterar grupo técnico',
  CHANGE_TECHNICIAN: 'Alterar Técnico',
  CHANGE_TEMPLATE: 'Template de Mudanças',
  CHANGE_VISUALIZATION: 'Trocar visualização',
  CHANNEL: 'Canal',
  CHANNELS: 'Canais',
  CHANNELS_MANAGEMENT: 'Gestão de Canais',
  CHARACTERS: 'Caracteres',
  CHARGE_INTERVAL: 'Cobrar intervalo',
  CHARGE_TRANSFER: 'Cobrar translado',
  CHAT: 'Chat',
  CHAT_HISTORY: 'Histórico de chat',
  CHECK: 'Checar',
  CHECK_ALL: 'Marca  Todos',
  CHECK_FIELD_SIZES: 'Verifique o tamanho dos campos',
  CHECK_YOUR_EMAIL_BOX: 'Verifique sua caixa de e-mail',
  CHECKED: 'Checado',
  CHOOSE_ICON: 'Escolher Ícone',
  CHOOSE_ITENS: 'Escolha os Itens',
  CIO: 'CIO',
  CIO_COCKPIT: 'CIO Cockpit',
  CITY: 'Cidade',
  CLASSIFICATION: 'Classificação',
  CLEAR: 'Limpar',
  CLEAR_FILTER: 'Limpar Filtro',
  CLEAR_TECH_AFTER_REOPEN_TICKET: 'Limpar técnico após reabrir o ticket',
  CLICK_ICON_SEE_ATTENDANCE: 'Clique no ícone para ver os detalhes dos atendimentos feitos',
  CLIENT: 'Cliente',
  CLIENTS: 'Clientes',
  CLIENTS_REGISTER: 'Cadastro de Clientes',
  CLOCK_SPEED: 'Velocidade do relógio',
  CLONE: 'Clonar Device',
  CLONE2: 'Clonar',
  CLOSE: 'Fechar',
  CLOSE_TICKET: 'Fechar o ticket',
  CLOSED: 'Encerrado',
  CLOSED_PLURAL: 'Encerrados',
  CLOSED_PROJECT: 'Projeto Fechado',
  CLOSING: 'Fechamento',
  CLOSING_DATE: 'Data de fechamento',
  CMDB: {
    APPLICATION: 'Aplicação',
    ASSET: 'Ativo',
    BACKUP: 'Backup',
    BATCHJOB: 'Trabalho em Lote',
    CI_BASE: 'Base de IC',
    CI_BUSINESS_SERVICE: 'Serviço de Negócio De IC',
    DATABASE: 'Banco de Dados',
    ENDPOINT: 'Endpoint',
    IP: 'IP',
    MONITORING: 'Monitoramento',
    REFERENCE_ID: 'Id de Referência',
    SERVER: 'Servidor'
  },
  CMDB_: 'CMDB',
  CMDB_APP_NAME: 'Name da Applicação',
  CMDB_APP_PORT: 'Porta da Applicação',
  CMDB_ASSET_COLLOCATION: 'Colocação de Ativos',
  CMDB_ASSET_DC: 'Ativo DC',
  CMDB_ASSET_OWNER: 'Proprietário do Ativo',
  CMDB_ASSET_TAG: 'Etiquete do Ativo',
  CMDB_ASSET_TYPE: 'Tipo de Ativo',
  CMDB_BATCHJOB_CONF_DIR: 'Diretório de configuração',
  CMDB_BATCHJOB_CONF_FILE: 'Arquivo de configuração',
  CMDB_BATCHJOB_INS_DIR: 'Diretório de instalação',
  CMDB_BATCHJOB_INT_REF: 'Referência de integração',
  CMDB_BATCHJOB_JUSTIFY: 'Justificativa',
  CMDB_BATCHJOB_RUN_PROC_COM: 'Comando do processo de execução',
  CMDB_BATCHJOB_RUN_PROC_COM_HASH: 'Hash de comando do processo de execução',
  CMDB_BATCHJOB_RUN_PROC_KEY_PARAM: 'Parâmetros-chave do processo de execução',
  CMDB_BATCHJOB_RUN_PROC_KEY_PARAM_HASH: 'Hash de parâmetros chave do processo de execução',
  CMDB_BATCHJOB_TCP_PORTS: 'Portas TCP',
  CMDB_BKP_CLIENT_HOST: 'Host Do Cliente De Backup',
  CMDB_BKP_JOB_TYP: 'Tipo De Trabalho De Backup',
  CMDB_BKP_MASTER_CLIENT: 'Servidor Mestre De Backup',
  CMDB_BKP_SCHEDULES: 'Agendamentos De Backup',
  CMDB_CI_BASE_ACN: 'IC Base ACN',
  CMDB_CI_BASE_ASSIGN_GROUP: 'Grupo de Atribuição Base de IC',
  CMDB_CI_BASE_CDPT: 'CDPT Base de IC',
  CMDB_CI_BASE_CI_TYPE: 'CI Base Tipo',
  CMDB_CI_BASE_CODE: 'Código Base do IC',
  CMDB_CI_BASE_ENV: 'Ambiente base de IC',
  CMDB_CI_BASE_FQDN: 'IC Base Fqdn',
  CMDB_CI_BASE_LOCATION: 'Localização da base do IC',
  CMDB_CI_BASE_OPER_DATE: 'Data Operacional Base do IC',
  CMDB_CI_BASE_OPER_STATUS: 'Status Operacional da Base do IC',
  CMDB_CI_BASE_SUP_GROUP: 'Grupo de suporte da base de IC',
  CMDB_CI_BASE_SUPPORTED: 'Base de IC compatível',
  CMDB_CI_BASE_VERSION: 'Versão base do IC',
  CMDB_CI_BS_BUSINESS_CRITICALITY: 'Criticidade Do Serviço De Negócios De IC',
  CMDB_CI_BS_BUSINESS_IMPACT: 'Impacto Do Serviço de Negócio De IC',
  CMDB_CI_BS_BUSINESS_PROCESS: 'Processo De Serviço de Negócio De IC',
  CMDB_CI_BS_SERVICE_NAME: 'Nome Do Serviço De Negócio De IC',
  CMDB_CI_STATUS: 'Status do CI',
  CMDB_DB_INSTANCE: 'Instância De Banco De Dados',
  CMDB_DB_TCP: 'Porta Tcp Do Banco De Dados',
  CMDB_ENDPOINT_PORT: 'Porta De Ponto Final',
  CMDB_ENDPOINT_PROTOCOL: 'Protocolo De Ponto Final',
  CMDB_IP_MANAGED_GROUP: 'Grupo Gerenciado De Ip',
  CMDB_IP_OWNER: 'Proprietário Do Ip',
  CMDB_IP_TYPE: 'Tipo De Ip',
  CMDB_MON_POLICY_ID: 'Id Da Política De Monitoramento',
  CMDB_MON_TOOL: 'Ferramenta De Monitoramento',
  CMDB_REF_ID: 'Id Referência',
  CMDB_REF_TOOL: 'Ferramenta De Referência',
  CMDB_SERIAL_NUMBER: 'Número de Série do Ativo',
  CMDB_SRV_CPU: 'Cpu Do Servidor',
  CMDB_SRV_DISK_SPACE: 'Espaço Em Disco Do Servidor',
  CMDB_SRV_HOST: 'Host Do Servidor',
  CMDB_SRV_HOST_ID: 'Id Do Host Do Servidor',
  CMDB_SRV_IS_VIRTUAL: 'O Servidor É Virtual',
  CMDB_SRV_LOCALIZATION: 'Localização Do Servidor',
  CMDB_SRV_OBJECT_ID: 'Id Do Objeto Do Servidor',
  CMDB_SRV_OS: 'Sistema Operacional Do Servidor',
  CMDB_SRV_RAM: 'Ram Do Servidor',
  COBIT: 'COBIT',
  CODE: 'Código',
  CODE_PEP: 'Código PEP',
  CODE_RESOLUTION_NAME: 'Nome do código de resolução',
  CODE_RESOLUTION_VALUE: 'Valor do código de resolução',
  CODE_SAP: 'Código SAP',
  COIN: 'Moeda',
  COIN_LIST_AFGHAN: 'Afegane',
  COIN_LIST_ARUBAN_FLORIN: 'Florim de Aruba',
  COIN_LIST_AZERBAIJANI_MANAT: 'Manat do Azerbaijão',
  COIN_LIST_BAHAMIAN_DOLLAR: 'Dólar das Bahamas',
  COIN_LIST_BAHRAINI_DINAR: 'Dinar do Bahrein',
  COIN_LIST_BALBOA: 'Balboa (O dólar americano USD é a moeda corrente no país)',
  COIN_LIST_BARBADIAN_DOLLAR: 'Dólar de Barbados',
  COIN_LIST_BELARUSIAN_RUBLE: 'Rublo bielorrusso',
  COIN_LIST_BELIZE_DOLLAR: 'Dólar do Belize',
  COIN_LIST_BERMUDIAN_DOLLAR: 'Dólar bermudense',
  COIN_LIST_BOLIVAR: 'Bolívar soberano venezuelano',
  COIN_LIST_BOLIVAR2: 'Bolívar Soberano venezuelano',
  COIN_LIST_BRUNEI_DOLLAR: 'Dólar do Brunei',
  COIN_LIST_BURUNDIAN_FRANC: 'Franco do Burundi',
  COIN_LIST_CANADIAN_DOLLAR: 'Dólar canadense',
  COIN_LIST_CAYMAN_ISLANDS_DOLLAR: 'Dólar das Ilhas Cayman',
  COIN_LIST_COLON: 'Colon da Costa Rica',
  COIN_LIST_CONGOLESE_FRANC: 'Franco congolês',
  COIN_LIST_CONVERTIBLE_MARK: 'Marco convertível',
  COIN_LIST_CORDOBA_NICARAGUAN: 'Cordoba Oro',
  COIN_LIST_CZECH_KRONE: 'Coroa checa',
  COIN_LIST_DANISH_KRONE: 'Coroa dinamarquesa',
  COIN_LIST_FALKLAND_ISLANDS_POUND: 'Libra das Malvinas',
  COIN_LIST_FIJIAN_DOLLAR: 'Dólar das Fiji',
  COIN_LIST_GIBRALTAR_POUND: 'Libra de Gibraltar',
  COIN_LIST_GUINEAN_FRANC: 'Franco da Guiné',
  COIN_LIST_GUYANESE_DOLLAR: 'Dólar da Guiana',
  COIN_LIST_HUNGARIAN_FORINT: 'Forint',
  COIN_LIST_ICELANDIC_KRONE: 'Coroa islandesa',
  COIN_LIST_IRANIAN_RIAL: 'Rial iraniano',
  COIN_LIST_IRAQI_DINAR: 'Dinar iraquiano',
  COIN_LIST_JAMAICAN_DOLLAR: 'Dólar jamaicano',
  COIN_LIST_KENYAN_SHILLING: 'Xelim queniano',
  COIN_LIST_KUWAITI_DINAR: 'Dinar do Kuwaiti',
  COIN_LIST_MAROCCAN_DIRHAM: 'Dirham marroquino',
  COIN_LIST_MEXICAN_INVESTIMENT_UNIT: 'Unidade Mexicana de Investimento',
  COIN_LIST_MOLDOVAN_LEU: 'Leu moldávio',
  COIN_LIST_NAMIBIAN_DOLLAR: 'Dólar da Namíbia',
  COIN_LIST_NEW_ZEALAND_DOLLAR: 'Dólar da Nova Zelândia',
  COIN_LIST_NORWEGIAN_KRONE: 'Coroa norueguesa',
  COIN_LIST_QATARI_RIYAL: 'Rial do Qatar',
  COIN_LIST_ROMANIAN_LEU: 'Leu romeno',
  COIN_LIST_RWANDAN_FRANC: 'Franco do Ruanda',
  COIN_LIST_SERBIAN_DINAR: 'Dinar Sérvio',
  COIN_LIST_SEYCHELLOIS_RUPEE: 'Rupia das Seychelles',
  COIN_LIST_SINGAPORE_DOLLAR: 'Dólar de Singapura',
  COIN_LIST_SOLOMON_ISLANDS_DOLLAR: 'Dólar das Ilhas Salomão',
  COIN_LIST_SOMALI_SHILLING: 'Xelim somali',
  COIN_LIST_SOUTH_KOREAN_WON: 'Won sul-coreano',
  COIN_LIST_SRI_INDIAN_RUPEE: 'Rupia indiana',
  COIN_LIST_SRI_LANKAN_RUPEE: 'Rupia do Sri Lanka',
  COIN_LIST_SUCRE: 'Sucre (O dólar americano USD é a moeda corrente)',
  COIN_LIST_SUCRE2: 'Sucre (O dólar americano USD é a moeda corrente no país)',
  COIN_LIST_SUDANESE_DINAR: 'Dinar sudanês',
  COIN_LIST_SURINAMESE_DOLLAR: 'Dólar do Suriname',
  COIN_LIST_SWEDISH_KRONE: 'Coroa Sueca',
  COIN_LIST_SWISS_FRANC: 'Franco suíço',
  COIN_LIST_SYRIAN_POUND: 'Libra da Síria',
  COIN_LIST_TANZANIAN_SHILLING: 'Xelim da Tanzânia',
  COIN_LIST_TRINIDAD_AND_TOBAGO_DOLLAR: 'Dólar de Trindade e Tobago',
  COIN_LIST_TUNISIAN_DINAR: 'Dinar tunisino',
  COIN_LIST_UGANDAN_SHILLING: 'Xelim ugandês',
  COIN_LIST_UKRAINIAN_HRYVNIA: 'Grívnia',
  COIN_LIST_UNIT_OF_ACCOUNT: 'Unidade de Fomento (código de fundos)',
  COIN_LIST_URUGUAYAN_PESO: 'Peso uruguaio',
  COIN_LIST_URUGUAYAN_PESO_IN_INDEXED_UNITS: 'Peso uruguaio em Unidades Indexadas',
  COIN_LIST_WIR_EURO: 'WIR euro (moeda complementar)',
  COIN_LIST_WIR_FRANC: 'WIR franc (moeda complementar)',
  COIN_LIST_YEMENI_RIAL: 'Rial iemenita',
  COIN_LIST_ZIMBABWEAN_DOLLAR: 'Dólar zimbabuano',
  COIN_MANAGEMENT: 'Gestão de Moedas',
  COLLECTION_HISTORY: 'Histórico de coleta',
  COLOR: 'Cor',
  COLORS_CUSTOMIZATION_TICKET_PRIORITY_URGENCY_IMPACT: 'Customização das cores de Prioridade/Urgência/Impacto nos tickets',
  COMERCIAL_PHONE: 'Telefone Comercial',
  COMMENT: 'Comentários',
  COMMENT_DETAILS: 'Detalhes de Comentários',
  COMMENTAR: 'Comentar',
  COMMENTARY: 'Comentário',
  COMMUNICATION_AND_OPERATIONS_MANAGEMENT: 'Gestão de Comunicação e de Operações',
  COMMUNITY: 'Community',
  COMPANIES: 'Empresas',
  COMPANY: 'Empresa',
  COMPANY_CAB_GLOBAL: 'Empresa global de CAB',
  COMPANY_CHANGE: 'Trocar Empresa',
  COMPANY_CONTRACTED: 'Empresa Contratada',
  COMPANY_DESCRIPTION: 'Empresa é uma unidade económico-social, integrada por elementos humanos e materiais.',
  COMPANY_DETAIL: 'Registre seu horário de funcionamento por módulo',
  COMPANY_DOES_NOT_HAVE_SMTP_CONFIGURATION_SELECTED: 'Empresa não possui configuração de SMTP selecionada',
  COMPANY_FILTER_CONTRACT: 'Filtro de empresa e contrato',
  COMPANY_FILTER_DEVICE: 'Filtro de empresa e dispositivo',
  COMPANY_FILTER_STANDARDS: 'Filtro de empresa e normas',
  COMPANY_HOUR: 'Horário de Funcionamento da Empresa',
  COMPANY_HOUR_DETAIL: 'Cadastrar horário de funcionamento da empresa por modulo.',
  COMPANY_LIST: 'Lista de Empresas',
  COMPANY_LOGO: 'Logo da empresa',
  COMPANY_OBJECTIVE: 'Objetivo da empresa',
  COMPARATION: 'Comparação',
  COMPL_INFO: 'Informações Complementares',
  COMPLEMENT: 'Complemento',
  COMPLETE: 'Completa',
  COMPLETED: 'Concluídas',
  COMPLETED_LATE: 'Concluído com atraso',
  COMPLETED_PROJECT: 'Projetos Concluídos',
  COMPLETED_SPRINTS: 'Sprints finalizadas',
  COMPLETED_TICKETS: 'Tickets Finalizados',
  COMPLETION_DATE: 'Data de Conclusão',
  COMPLEXITY: 'Complexidade',
  COMPLEXITY_AND_COST: 'Complexidade e custo',
  COMPONENT: 'Componentes',
  COMPUTER_NAME: 'Nome do Computador',
  CON: 'Concluído',
  CONCLUDE: 'Concluir',
  CONCLUDED: 'Concluído',
  CONCLUDED_CANCEL: 'Concluído Cancelado',
  CONCLUDED_DATE: 'Data de conclusão',
  CONCLUDED_FAIL: 'Concluído com falha',
  CONCLUDED_PART: 'Concluído Parcialmente',
  CONCLUDED_SUCCESS: 'Concluído com sucesso',
  CONCLUDES_THE_PREVIOUS_ACTIVITIES_TO_THIS: 'Conclua as atividades antecessoras à esta',
  CONCLUI: 'Concluído',
  CONDITION: 'Condição',
  CONFIDENTIALITY: 'Confidencialidade',
  CONFIG: 'Configurações',
  CONFIG_ALTERNATE_BOSS_BODY: 'Informe o chefe alternativo dos usuários',
  CONFIG_ANSWERS_BODY: 'Crie respostas automáticas e facilite o service desk',
  CONFIG_AUTOMATION_BODY: 'Automação das demandas',
  CONFIG_CAB_EVENTS_BODY: 'Crie eventos para o CAB',
  CONFIG_CATEGORIES_BODY: 'Direcione seu catálogo de serviço',
  CONFIG_CHANGE_TEMPLATE_BODY: 'Crie templates para as mudanças',
  CONFIG_CHANNELS_BODY: 'Gestão de canais',
  CONFIG_COIN_MANAGEMENT_BODY: 'Configure moedas para usar nos contratos',
  CONFIG_COMPANIES_BODY: 'Crie, edite e/ou exclua empresas',
  CONFIG_COMPANY_LOGO_BODY: 'Anexe aqui a logo da sua empresa',
  CONFIG_CONTRACT_BODY: 'Gerencie aqui os contratos de sua empresa',
  CONFIG_DEPARTMENT_BODY: 'Crie, edite e/ou exclua departamentos',
  CONFIG_DEVICE_BODY: 'Cadastre os dispositivos da empresa',
  CONFIG_DEVICE_TYPE_BODY: 'Crie, edite e/ou exclua os tipos de dispositivo',
  CONFIG_EMAIL_TEMPLATES_2_BODY: 'Cadastre aqui o os modelos de e-mail',
  CONFIG_EMAIL_TEMPLATES_BODY: 'Cadastre aqui o os modelos de e-mail',
  CONFIG_FILE_EXTENSIONS_BODY: 'Configure as extensões de arquivos suportadas na plataforma',
  CONFIG_FORMS_BODY: 'Crie formulários customizados para atender o ser negócio',
  CONFIG_GENERAL_PARAMETERS_BODY: 'Configure Parâmetros de acesso na plataforma',
  CONFIG_GROUPS_BODY: 'Crie o(s) grupo(s) de atendimento para sua empresa',
  CONFIG_HELP_DESK_BODY: 'Configure os tutoriais da Central de Ajuda',
  CONFIG_LDAP_BODY: 'Integre o seu AD na plataforma Sensr.IT',
  CONFIG_LOCATION_BODY: 'Crie, edite e/ou exclua localidades',
  CONFIG_MAIL_POLICY_BODY: 'Configure o email de comunicação da plataforma',
  CONFIG_OPENING_HOURS_BODY: 'Parametrize o início e o término de atendimento da plataforma',
  CONFIG_OUTAGE_BODY: 'Cadastro do Outage',
  CONFIG_PANEL: 'Painel de Configurações',
  CONFIG_PARAM_ADMIN_ATTENDANCE: 'Contabilização de Atendimentos por Administradores',
  CONFIG_PARAM_ADMIN_HISTORIC_REQUEST: 'Gestor visualizar apenas seus chamados',
  CONFIG_PARAM_ALLOW_EDIT_FORM_CATEGORIZATION: 'Permitir a edição de formulário na tela de categorização',
  CONFIG_PARAM_APPROVE_GROUP_USERS: 'Aprovar Solicitação utilizando grupo de aprovadores',
  CONFIG_PARAM_APPROVE_REQUEST_CHIEF: 'Permitir Gestor Aprovar seus proprios chamados',
  CONFIG_PARAM_ASSOCIATE_REMOTE_ACCESS_TICKET: 'Associar acesso remoto à ticket',
  CONFIG_PARAM_ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS: 'Tickets associados devem acompanhar status do chamado principal',
  CONFIG_PARAM_AVALIATION_TICKETS: 'Avaliar NPS',
  CONFIG_PARAM_CALL_OPENING: 'Habilitar abertura de chamados na página de login',
  CONFIG_PARAM_CAUSE_PROBLEM: 'Habilitar Causa Problema',
  CONFIG_PARAM_CHANGE_GROUP_TECH_APPROVE: 'Associação Automática de Grupo técnico como aprovador GMUD',
  CONFIG_PARAM_COUNT_HOURS_TECH_CLIENT: 'Contabilizar horas por técnicos do tipo cliente',
  CONFIG_PARAM_COUNT_TICKET_BROKEN_ONCE: 'Contar chamados estourados apenas uma vez',
  CONFIG_PARAM_EMAIL_RESPONSE_CHAT: 'Habilitar Resposta no Chat/Email',
  CONFIG_PARAM_ENABLE_CHANGE_STATUS_REASON: 'Habilitar motivo na mudança de status de Ticket',
  CONFIG_PARAM_ENABLE_CREATE_PROBLEM_WHEN_RESOLVE_CRISIS: 'Habilitar criação de problema quando resolver crise',
  CONFIG_PARAM_ENABLE_DESCRIPTION_EDITING: 'Habilitar edição da descrição',
  CONFIG_PARAM_ENABLE_LOGS_DEVICE_GMUD_TICKET: 'Habilitar gravação de logs para Ticket e GMUD que contém dispositivos vinculados',
  CONFIG_PARAM_ENABLE_MULTIPLE_SELECTION: 'Habilitar seleção de todos os solicitantes e aprovadores na criação de OS',
  CONFIG_PARAM_ENABLE_REQUIRED_TEMPLATE_GMUD: 'Habilitar obrigatoriedade de seleção de formulários em GMUD',
  CONFIG_PARAM_ENABLE_SERVICE_PORTAL_BY_COMPANIES: 'Habilitar Portal de Serviços por Empresas',
  CONFIG_PARAM_EVALUATE_TICKET: 'Habilitar avaliação de chamados no ticket',
  CONFIG_PARAM_GMUD_CLOSING_OF_GMUD: 'Abertura de Problema ao fechar GMUD com Falha',
  CONFIG_PARAM_GMUD_PARTIAL_TICKET_OPEN_CATEGORY: 'Categoria para abertura de Ticket',
  CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY: 'Categoria para abertura de Ticket',
  CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_SECOND: 'Categoria do Segundo Nível para abertura de Ticket',
  CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_THIRD: 'Categoria do Teceiro Nível para abertura de Ticket',
  CONFIG_PARAM_GOOGLE_LOGIN: 'Habilitar Integração via Google',
  CONFIG_PARAM_HOURS_DAY: 'Quantidade de horas de trabalho de 1 dia para mensurar as atividades em dias',
  CONFIG_PARAM_LINKING_MULTI_COMPANY_TICKETS: 'Víncula chamados entre múlti-empresas',
  CONFIG_PARAM_MICROSOFT_LOGIN: 'Habilitar Integração via Microsoft',
  CONFIG_PARAM_MULT_CATALOG: 'Habilitar Múltiplos Catálogos',
  CONFIG_PARAM_OPEN_TICKET_WHEN_GMUD_FAILS: 'Abir Ticket de Problema quando GMUD finalizar com Falha',
  CONFIG_PARAM_READJUSTMENT_CONTRACT: 'Reajuste no contrato obrigatório',
  CONFIG_PARAM_REQUEST_BY_PORTAL: 'Abrir solicitações pelo portal',
  CONFIG_PARAM_REQUEST_CLOSED_TICKETS: 'Quantidade de dias para fechar solicitações com status de Aprovação',
  CONFIG_PARAM_REQUEST_SOLICITANT_SELECT: 'Selecionar o Solicitante',
  CONFIG_PARAM_SEND_EMAIL_CATEGORIZATION: 'Enviar emails ao categorizar',
  CONFIG_PARAM_SEND_EMAIL_CREATE_TASK_IN_TICKET: 'Mandar email ao criar tarefa no ticket',
  CONFIG_PARAM_SEND_EMAIL_GROUP_TECH: 'Habilitar E-mail Alterar grupo técnico',
  CONFIG_PARAM_SEND_MAIL_OBSERVERS: 'Habilitar E-mail Observador(es)',
  CONFIG_PARAM_SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION: 'Mostrar solicitações rejeitadas na tela de categorização',
  CONFIG_PARAM_SUBTICKET_CLOSED_VERIFY: 'Chamados podem ser finalizados independente de ter subchamados em aberto',
  CONFIG_PARAM_TECHNICAL_EVALUATION_REGISTRATION: 'Ativa avaliação de chamado pelo técnico',
  CONFIG_PARAM_TICKET_CLOSE_DAYS: 'Quantidade de dias para fechar chamados automaticamente',
  CONFIG_PARAM_TICKET_CLOSED_TASKS: 'Fechar o chamado apenas quando todas as tarefas  estiverem concluidas',
  CONFIG_PARAM_TICKET_CONTRACT_SOLICITANT: 'Abertura de ticket  solicitant  dispositivo global',
  CONFIG_PARAM_TICKET_PARTIAL_CLOSING: 'Abertura de Solicitação quando atividade de GMUD fechar com falha',
  CONFIG_PARAM_TICKET_SIMPLIFIED: 'Abertura de ticket simplificado',
  CONFIG_PARAM_TIME_REFRESH: 'Tempo para atualizar a página de tickets em minutos',
  CONFIG_PARAM_TIME_REFRESH_VISAO: 'Tempo para atualizar a página da visão gerencial em minutos',
  CONFIG_PARAM_TOOLIP_ENABLE_REQUIRED_TEMPLATE_GMUD: 'Habilitar obrigatoriedade de seleção de formulários em GMUD',
  CONFIG_PARAM_TOOLTIP_ADMIN_ATTENDANCE: 'Habilitando este parâmetro, serão contabilizados no contrato as horas de atendimento realizados por Administradores.',
  CONFIG_PARAM_TOOLTIP_ADMIN_HISTORIC_REQUEST: 'Ao habilitar este parâmetro  o gestor do departamento irá visualiza apenas seus chamados',
  CONFIG_PARAM_TOOLTIP_ALLOW_EDIT_FORM_CATEGORIZATION: 'Ao habilitar este parâmetro os formulários  na tela de categorização poderão ser editados',
  CONFIG_PARAM_TOOLTIP_APPROVE_GROUP_USERS: 'Habilitando este parâmetro a aprovação de solicitações',
  CONFIG_PARAM_TOOLTIP_ASSOCIATE_REMOTE_ACCESS_TICKET: 'Habilitando este parâmetro, acessos remotos não serão contabilizados no sintético e no relatório de técnicos',
  CONFIG_PARAM_TOOLTIP_ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS: 'Habilitando este parâmetro, ao associar um ou mais tickets ao um ticket principal, os  mesmos não serão encerrados, porem ao atualizar o status do ticket principal, o status do(s) ticket(s) filho(s) também serão atualizados',
  CONFIG_PARAM_TOOLTIP_COUNT_HOURS_TECH_CLIENT: 'Habilitando este parâmetro serão contabilizadas as horas dos tickets atendidos por técnicos do tipo cliente',
  CONFIG_PARAM_TOOLTIP_COUNT_TICKET_BROKEN_ONCE: 'Ao habilitar esse parâmetro, os chamados que estourarem sempre serão contados no mês em que estouraram',
  CONFIG_PARAM_TOOLTIP_ENABLE_MULTIPLE_SELECTION: 'Habilitar seleção de todos os solicitantes e aprovadores na criação de OS',
  CONFIG_PARAM_TOOLTIP_EVALUATE_TICKET: 'Ao habilitar esse parâmetro, o solicitante poderá avaliar o chamado.',
  CONFIG_PARAM_TOOLTIP_GMUD_LEADERS_APPROVERS: 'A ativação deste parâmetro permite que os líderes de usuários e seus líderes alternativos aprovem fluxos de trabalho de GMUD.',
  CONFIG_PARAM_TOOLTIP_GMUD_WORKFLOW: 'Habilitando este parâmetro, é possível criar um fluxo de aprovadores para uma GMUD a partir de condições estabelecidas.',
  CONFIG_PARAM_TOOLTIP_LINKING_MULTI_COMPANY_TICKETS: 'Habilitando este parâmetro, serão será possível víncular chamados entre múlti-empresas.',
  CONFIG_PARAM_TOOLTIP_REQUEST_BY_PORTAL: 'Habilitando este parâmetro a abertura de solicitações da tela de My Experience será feita pelo novo portal de autoatendimento',
  CONFIG_PARAM_TOOLTIP_SEND_EMAIL_CATEGORIZATION: 'Ao habilitar este parâmetro será enviado um email ao categorizar um chamado.',
  CONFIG_PARAM_TOOLTIP_SEND_EMAIL_CREATE_TASK_IN_TICKET: 'Ao habilitar este parâmetro toda vez que uma tarefa for criada dentro de um ticket, o grupo técnico responsável receberá um email informando',
  CONFIG_PARAM_TOOLTIP_SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION: 'Ao habilitar este parâmetro as solicitações que foram canceladas serão mostradas na tela de categorização',
  CONFIG_PARAM_TOOLTIP_SUBTICKET_CLOSED_VERIFY: 'Chamados podem ser finalizados independente de ter subchamados em aberto',
  CONFIG_PARAM_TOOLTIP_TECHNICAL_EVALUATION_REGISTRATION: 'Habilitando este parâmetro ao finalizar o ticket na tela de Gestão de Ticket o Técnico poderá colocar avaliações cadastradas',
  CONFIG_PARAM_VISION_REQUEST: 'Quantidade de dias para apresentar na visão gerencial',
  CONFIG_PASSWORD_VAULT_BODY: 'Organize as suas senhas',
  CONFIG_POSITIONS_BODY: 'Crie, edite e/ou exclua cargos',
  CONFIG_PROFILE_BODY: 'Edite seu perfil, altere suas credenciais sempre que necessário',
  CONFIG_PROGRESS_STATUS_BODY: 'Crie seus status para o andamento do ticket',
  CONFIG_PROJECT_TEMPLATE_BODY: 'Cadastre um template para ser utilizado no projeto',
  CONFIG_RISK_CHANGE_BODY: 'Crie perguntas para avaliar os riscos da Mudança',
  CONFIG_SEND_MAIL: 'Configuração de envio de email',
  CONFIG_SLA_PARAMS_BODY: 'Cadastro do sla',
  CONFIG_SLA_POLICY_BODY: 'Cadastre aqui o SLA de atendimento do contrato',
  CONFIG_SLA_WORKDAY_BODY: 'Horas trabalhadas',
  CONFIG_SNMP_BODY: 'Cadastre os dispositivos através de protocolo de rede',
  CONFIG_SPRINT_ACTIVITY_EFFORT_BODY: 'Configure os esforços das atividades de uma Sprint',
  CONFIG_SQUAD_BODY: 'Crie, edite e/ou exclua SQUADs',
  CONFIG_SUBCATEGORIES_BODY: 'Organize em grupos as atividades para atendimento',
  CONFIG_SUBDEPARTMENT_BODY: 'Crie, edite e/ou exclua subdepartamentos',
  CONFIG_THIRD_LEVEL_CATEGORY_BODY: 'Cadastre todas as atividades para atendimento',
  CONFIG_USERS_BODY: 'Crie, edite e/ou exclua usuários na plataforma',
  CONFIG_USERS_PERMISSIONS: 'Crie, edite e/ou exclua permissões de usuários na plataforma',
  CONFIGURATION_MODULE: 'Módulo de Configuração',
  CONFIGURE_COLUMNS: 'Configurar Colunas',
  CONFIGURE_MULTIPLE_TICKETS: 'Configurar Multi Chamados',
  CONFIGURE_RELATIONSHIP_TREE: 'Configurar Árvore de Relacionamentos',
  CONFIGURE_RESOLUTION_CATEGORY: 'Configure as Categorias de Resolução',
  CONFIGURE_RESOLUTION_CODE: 'Configure os Códigos de Resolução',
  CONFIRM: 'Confirmar',
  CONFIRM_APPROVAL: 'Confirmar aprovação',
  CONFIRM_CHANGE: 'Deseja Abrir a Mudança?',
  CONFIRM_CLOSE_ACESS_SUPPORT: 'Deseja fechar o acesso remoto de suporte?',
  CONFIRM_CLOSE_ASSESSMENT: 'Tem certeza que deseja finalizar este atendimento?',
  CONFIRM_CRISES: 'Crise confirmada?',
  CONFIRM_DELETE: 'Você quer mesmo deletar este item?',
  CONFIRM_DELETE_BODY: 'Após deletar este item você não poderá mais recuperá-lo, esteja certo de que queira deleta-lo antes de prosseguir.',
  CONFIRM_DELETE_BUSINESS_AREA: 'Deseja Excluir área de negocio?',
  CONFIRM_DELETE_COMPANY: 'Atenção: Ao deletar a  EMPRESA, será excluido todos os itens relacionados a ela, deseja mesmo deletar?',
  CONFIRM_DELETE_ITEM: 'Deseja excluir esse item',
  CONFIRM_DETACH: 'Tem certeza que deseja remover este anexo?',
  CONFIRM_DIALOG: 'Você Tem Certeza Que Deseja Salvar?',
  CONFIRM_DISABLE: 'Você quer mesmo desabilitar este item?',
  CONFIRM_ENABLE: 'Você quer mesmo habilitar este item?',
  CONFIRM_PASSWORD: 'Confirmar nova senha',
  CONFIRM_SAVE_ITEM: 'Deseja salvar este item?',
  CONFIRM_START_SERVICE: 'Deseja iniciar esse serviço?',
  CONFIRM_STOP_MONITOR: 'Tem certeza que deseja parar de monitorar esse dispositivo?',
  CONFIRM_TICKETS_ASSESSMENT: 'Selecione abaixo os tickets finalizados neste atendimento',
  CONFIRM_UPDATE_AGENT: 'Tem certeza que deseja atualizar o agente deste dispositivo?',
  CONFIRMATION: 'Confirmação',
  CONNECT: 'Conectar',
  CONNECTION: 'Conexão',
  CONSULT_APPROVALS: 'Consultar Aprovações',
  CONSULT_HISTORIC: 'Consultar histórico',
  CONSULT_INTEGRATION_CALL: 'Consultar Integração',
  CONSULTANT: 'Consultor',
  CONSULTED: 'Consultado',
  CONSUMED: 'Consumido',
  CONSUMED_HOURS: 'Horas consumidas',
  CONSUMED_TIME: 'Hora consumida',
  CONSUMED_VALUE: 'Valor consumido',
  CONSUMED_VALUES: 'Valores consumidos',
  CONSUMER_SERVICE_SYSTEM: 'Sistema de Atendimento ao Consumidor',
  CONSUMPTION_OF_FORECAST_REALIZED_AND_CONSUMED: 'Consumo do previsto, Realizado e consumido',
  CONTACT: 'Contato',
  CONTACTS: 'Contatos',
  CONTAIN: 'Contém',
  CONTEST: 'Contestar',
  CONTINUE_PROJECT: 'Continuar Projeto',
  CONTRACT: 'Contrato',
  CONTRACT_CONSUMPTION: 'Consumo do contrato',
  CONTRACT_DESCRIPTION: 'Gestão de Contratos eficiêntes que permite controlar e monitorar as horas de terceiros.',
  CONTRACT_DETAILS: 'Detalhes do Contrato',
  CONTRACT_FILTER: 'Filtrar por contrato',
  CONTRACT_INVOLVED: 'Contrato envolvido',
  CONTRACT_LIST: 'Lista de Contratos',
  CONTRACT_MANAGEMENT: 'Gestão de Contratos',
  CONTRACT_OVERVIEW: 'Visão Geral do Contrato',
  CONTRACT_SUPORT: 'Suporte contrato',
  CONTRACT_TITLE: 'Cadastro de Contrato',
  CONTRACT_TYPE: 'Tipos de Contrato',
  CONTRACT_TYPE_LIST: 'Lista de tipos de contrato',
  CONTRACT_VALUE: 'Valor do Contrato',
  CONTRACTED: 'Contratado',
  CONTRACTED_HOUR: 'Valor Hora Contratada',
  CONTRACTED_HOURS: 'Horas Contratadas',
  CONTRACTOR: 'Contratante',
  CONTRACTS: 'Contratos',
  CONTRACTS_FILTER: 'Filtro Por Contratos',
  CONTRACTS_MANAGEMENT: 'Gestão de Contratos',
  CONTRACTUAL_BILLING: 'Faturamento contratual',
  CONTRIBUTION_TO_BUSINESS: 'Contribuição para o Negócio',
  CONTROL: 'Controle',
  CORE: 'CORES',
  CORRECTION: 'Correção',
  CORRECTIVE: 'Corretiva',
  CORRELATION: 'Correlação',
  CORRELATION_DISPLAY: 'Exibição de Correlação',
  COST: 'Custo',
  COST_CENTER: 'Centro de Custo',
  COST_DESCRIPTION: 'Descrição de custo',
  COST_PER_HOUR: 'Custo por hora',
  COST_PER_PROJECT: 'Custo por projeto',
  COST_TITLE: 'Título do custo',
  COST_VALUE: 'Valor de custo',
  COUNT: 'Contém',
  COUNTRIES: 'Países',
  COUNTRY: 'País',
  COUNTRY_AFGHANISTAN: "Afeganistão",
  COUNTRY_ALBANIA: "Albânia",
  COUNTRY_ALGERIA: "Argélia",
  COUNTRY_AMERICAN_SAMOA: "Samoa Americana",
  COUNTRY_ANDORRA: "Andorra",
  COUNTRY_ANGOLA: "Angola",
  COUNTRY_ANGUILLA: "Anguilla",
  COUNTRY_ANTIGUA_AND_BARBUDA: "Antígua e Barbuda",
  COUNTRY_ARGENTINA: "Argentina",
  COUNTRY_ARMENIA: "Armênia",
  COUNTRY_ARUBA: "Aruba",
  COUNTRY_ASCENSION_ISLAND: "Ilha de Ascensão",
  COUNTRY_AUSTRALIA: "Austrália",
  COUNTRY_AUSTRIA: "Austria",
  COUNTRY_AZERBAIJAN: "Azerbaijão",
  COUNTRY_BAHAMAS: "Bahamas",
  COUNTRY_BAHRAIN: "Bahrein",
  COUNTRY_BANGLADESH: "Bangladeche",
  COUNTRY_BARBADOS: "Barbados",
  COUNTRY_BELARUS: "Belorússia",
  COUNTRY_BELGIUM: "Bélgica",
  COUNTRY_BELIZE: "Belize",
  COUNTRY_BENIN: "Benin",
  COUNTRY_BERMUDA: "Bermuda",
  COUNTRY_BHUTAN: "Butão",
  COUNTRY_BOLIVIA: "Bolívia",
  COUNTRY_BOSNIA_AND_HERZEGOVINA: "Bósnia e Herzegovina",
  COUNTRY_BOTSWANA: "Botsuana",
  COUNTRY_BRAZIL: "Brasil",
  COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: "Território Britânico do Oceano Índico",
  COUNTRY_BRITISH_VIRGIN_ISLANDS: "Ilhas Virgens Britânicas",
  COUNTRY_BRUNEI: "Brunei",
  COUNTRY_BULGARIA: "Bulgária",
  COUNTRY_BURKINA_FASO: "Burquina Faso",
  COUNTRY_BURUNDI: "Burundi",
  COUNTRY_CAMBODIA: "Camboja",
  COUNTRY_CAMEROON: "Camarões",
  COUNTRY_CANADA: "Canada",
  COUNTRY_CAPE_VERDE: "Cabo Verde",
  COUNTRY_CAYMAN_ISLANDS: "Ilhas Cayman",
  COUNTRY_CENTRAL_AFRICAN_REPUBLIC: "República Centro-Africana",
  COUNTRY_CHAD: "Chade",
  COUNTRY_CHILE: "Chile",
  COUNTRY_CHINA: "China",
  COUNTRY_CHRISTMAS_ISLAND: "Ilhas Christmas",
  COUNTRY_COCOS_ISLANDS: "Ilhas Cocos (Keeling)",
  COUNTRY_CODE: 'Código do país',
  COUNTRY_COLOMBIA: "Colômbia",
  COUNTRY_COMOROS: "Ilhas Comores",
  COUNTRY_CONGO: "Congo",
  COUNTRY_COOK_ISLANDS: "Ilhas Cook",
  COUNTRY_COSTA_RICA: "Costa Rica",
  COUNTRY_CROATIA: "Croácia",
  COUNTRY_CUBA: "Cuba",
  COUNTRY_CYPRUS: "Chipre",
  COUNTRY_CZECH_REPUBLIC: "República Tcheca",
  COUNTRY_DENMARK: "Dinamarca",
  COUNTRY_DJIBOUTI: "Jibuti",
  COUNTRY_DOMINICA: "Domínica",
  COUNTRY_DOMINICAN_REPUBLIC: "República Dominicana",
  COUNTRY_ECUADOR: "Equador",
  COUNTRY_EGYPT: "Egito",
  COUNTRY_EL_SALVADOR: "El Salvador",
  COUNTRY_EQUATORIAL_GUINEA: "Guiné Equatorial",
  COUNTRY_ERITREA: "Eritréia",
  COUNTRY_ESTONIA: "Estônia",
  COUNTRY_ESWATINI: "Suazilândia",
  COUNTRY_ETHIOPIA: "Etiópia",
  COUNTRY_FALKLAND_ISLANDS: "Ilhas Malvinas (Falkland)",
  COUNTRY_FAROE_ISLANDS: "Ilhas Faroés",
  COUNTRY_FIJI: "Fiji",
  COUNTRY_FINLAND: "Finlândia",
  COUNTRY_FRANCE: "França",
  COUNTRY_FRENCH_GUIANA: "Guiana Francesa",
  COUNTRY_FRENCH_POLYNESIA: "Polinésia Francesa",
  COUNTRY_GABON: "Gabão",
  COUNTRY_GAMBIA: "Gâmbia",
  COUNTRY_GAZA_STRIP: "Faixa de Gaza",
  COUNTRY_GEORGIA: "Geórgia",
  COUNTRY_GERMANY: "Alemanha",
  COUNTRY_GHANA: "Gana",
  COUNTRY_GIBRALTAR: "Gibraltar",
  COUNTRY_GREECE: "Grécia",
  COUNTRY_GREENLAND: "Groenlândia",
  COUNTRY_GRENADA: "Granada",
  COUNTRY_GUADELOUPE: "Guadeloupe",
  COUNTRY_GUAM: "Guão",
  COUNTRY_GUATEMALA: "Guatemala",
  COUNTRY_GUINEA: "Guiné",
  COUNTRY_GUINEA_BISSAU: "Guiné-Bissau",
  COUNTRY_GUYANA: "Guiana",
  COUNTRY_HAITI: "Haiti",
  COUNTRY_HONDURAS: "Honduras",
  COUNTRY_HONG_KONG_SAR: "Hong Kong S.A.R.",
  COUNTRY_HUNGARY: "Hungria",
  COUNTRY_ICELAND: "Islândia",
  COUNTRY_INDIA: "India",
  COUNTRY_INDONESIA: "Indonésia",
  COUNTRY_IRAN: "Irã",
  COUNTRY_IRAQ: "Iraque",
  COUNTRY_IRELAND: "Irlanda",
  COUNTRY_ISLE_OF_MAN: "Ilha de Man",
  COUNTRY_ISRAEL: "Israel",
  COUNTRY_ITALY: "Itália",
  COUNTRY_IVORY_COAST: "Côte dIvoire (Costa do Marfim)",
  COUNTRY_JAMAICA: "Jamaica",
  COUNTRY_JAPAN: "Japão",
  COUNTRY_JARVIS_ISLAND: "Ilha Jarvis",
  COUNTRY_JORDAN: "Jordânia",
  COUNTRY_KAZAKHSTAN: "Cazaquistão",
  COUNTRY_KENYA: "Quênia",
  COUNTRY_KIRIBATI: "Kiribati",
  COUNTRY_KUWAIT: "Kuwait",
  COUNTRY_KYRGYZSTAN: "Quirguistão",
  COUNTRY_LAOS: "Laos",
  COUNTRY_LATVIA: "Letônia",
  COUNTRY_LEBANON: "Líbano",
  COUNTRY_LESOTHO: "Lesoto",
  COUNTRY_LIBERIA: "Libéria",
  COUNTRY_LIBYA: "Líbia",
  COUNTRY_LIECHTENSTEIN: "Listenstaine",
  COUNTRY_LITHUANIA: "Lituânia",
  COUNTRY_LUXEMBOURG: "Luxemburgo",
  COUNTRY_MACAU: "Macau",
  COUNTRY_MADAGASCAR: "Madagascar",
  COUNTRY_MALAWI: "Malávi",
  COUNTRY_MALAYSIA: "Malasia",
  COUNTRY_MALDIVES: "Maldivas",
  COUNTRY_MALI: "Mali",
  COUNTRY_MALTA: "Malta",
  COUNTRY_MARSHALL_ISLANDS: "Ilhas Marshall",
  COUNTRY_MARTINIQUE: "Martinica",
  COUNTRY_MAURITANIA: "Mauritânia",
  COUNTRY_MAURITIUS: "Maurício",
  COUNTRY_MAYOTTE: "Mayotte",
  COUNTRY_MEXICO: "Mexico",
  COUNTRY_MICRONESIA: "Micronésia",
  COUNTRY_MONACO: "Mônaco",
  COUNTRY_MONGOLIA: "Mongólia",
  COUNTRY_MONTENEGRO: "Montenegro",
  COUNTRY_MONTSERRAT: "Montserrat",
  COUNTRY_MOROCCO: "Marrocos",
  COUNTRY_MOZAMBIQUE: "Moçambique",
  COUNTRY_MYANMAR: "Burma",
  COUNTRY_MYANMAR: "Myanmar",
  COUNTRY_NAMIBIA: "Namíbia",
  COUNTRY_NAURU: "Nauru",
  COUNTRY_NEPAL: "Nepal",
  COUNTRY_NETHERLANDS: "Países Baixos",
  COUNTRY_NETHERLANDS_ANTILLES: "Antillhas Holandesas",
  COUNTRY_NEW_CALEDONIA: "Nova Caledônia",
  COUNTRY_NEW_ZEALAND: "Nova Zelândia",
  COUNTRY_NICARAGUA: "Nicarágua",
  COUNTRY_NIGER: "Níger",
  COUNTRY_NIGERIA: "Nigéria",
  COUNTRY_NIUE: "Niue",
  COUNTRY_NORFOLK_ISLAND: "Ilhas Norfolk",
  COUNTRY_NORTH_MACEDONIA: "Macedônia",
  COUNTRY_NORTHERN_CYPRUS: "República Turca de Chipre do Norte",
  COUNTRY_NORTHERN_MARIANA_ISLANDS: "Marianas Setentrionais",
  COUNTRY_NORWAY: "Noruega",
  COUNTRY_OMAN: "Omã",
  COUNTRY_PAKISTAN: "Paquistão",
  COUNTRY_PALAU: "Palau",
  COUNTRY_PANAMA: "Panamá",
  COUNTRY_PAPUA_NEW_GUINEA: "Papua-Nova Guiné",
  COUNTRY_PARAGUAY: "Paraguai",
  COUNTRY_PERU: "Peru",
  COUNTRY_PHILIPPINES: "Filipinas",
  COUNTRY_PITCAIRN_ISLAND: "Ilha Pitcaim",
  COUNTRY_POLAND: "Polônia",
  COUNTRY_PORTUGAL: "Portugal",
  COUNTRY_PUERTO_RICO: "Porto Rico",
  COUNTRY_QATAR: "Catar",
  COUNTRY_REUNION: "Reunion",
  COUNTRY_ROMANIA: "Romênia",
  COUNTRY_RUSSIA: "Rússia",
  COUNTRY_RWANDA: "Ruanda",
  COUNTRY_SAINT_HELENA: "Santa Helena",
  COUNTRY_SAINT_KITTS_AND_NEVIS: "São Cristóvão e Névis",
  COUNTRY_SAINT_LUCIA: "Santa Lúcia",
  COUNTRY_SAINT_PIERRE_AND_MIQUELON: "St Pierre e Miquelon",
  COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: "São Vicente e Granadinas",
  COUNTRY_SAMOA: "Samoa",
  COUNTRY_SAN_MARINO: "São Marinho",
  COUNTRY_SAO_TOME_AND_PRINCIPE: "São Tomé e Príncipe",
  COUNTRY_SAUDI_ARABIA: "Arábia Saudita",
  COUNTRY_SENEGAL: "Senegal",
  COUNTRY_SERBIA: "Serbia",
  COUNTRY_SERBIA_AND_MONTENEGRO: "Sérvia e Montenegro",
  COUNTRY_SEYCHELLES: "Seicheles",
  COUNTRY_SIERRA_LEONE: "Serra Leoa",
  COUNTRY_SINGAPORE: "Singapura",
  COUNTRY_SLOVAKIA: "Eslováquia",
  COUNTRY_SLOVENIA: "Eslovênia",
  COUNTRY_SOLOMON_ISLANDS: "Ilhas Salomão",
  COUNTRY_SOMALIA: "Somália",
  COUNTRY_SOUTH_AFRICA: "Africa do Sul",
  COUNTRY_SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS: "Ilhas Geórgia do Sul e Sandwich do Sul",
  COUNTRY_SOUTH_KOREA: "Coreia do Sul",
  COUNTRY_SOUTH_SANDWICH_ISLANDS: "Ilhas Sandwich do Sul",
  COUNTRY_SPAIN: "Espanha",
  COUNTRY_SRI_LANKA: "Sri Lanka",
  COUNTRY_SUDAN: "Sudão",
  COUNTRY_SURINAME: "Suriname",
  COUNTRY_SVALBARD_AND_JAN_MAYEN: "Ilhas Svalbard e Jan Mayen",
  COUNTRY_SWEDEN: "Suécia",
  COUNTRY_SWITZERLAND: "Suiça",
  COUNTRY_SYRIA: "Síria",
  COUNTRY_TAIWAN: "Formosa",
  COUNTRY_TAJIKISTAN: "Tadjiquistão",
  COUNTRY_TANZANIA: "Tanzânia",
  COUNTRY_THAILAND: "Tailândia",
  COUNTRY_TIMOR_LESTE: "Timor Leste",
  COUNTRY_TOGO: "Togo",
  COUNTRY_TOKELAU: "Tokelau",
  COUNTRY_TONGA: "Tonga",
  COUNTRY_TRINIDAD_AND_TOBAGO: "Trinidad e Tobago",
  COUNTRY_TRISTAN_DA_CUNHA: "Tristão da Cunha",
  COUNTRY_TUNISIA: "Tunísia",
  COUNTRY_TURKEY: "Turquia",
  COUNTRY_TURKMENISTAN: "Turcomenistão",
  COUNTRY_TURKS_AND_CAICOS_ISLANDS: "Ilhas Turks e Caicos",
  COUNTRY_TUVALU: "Tuvalu",
  COUNTRY_UGANDA: "Uganda",
  COUNTRY_UKRAINE: "Ucrânia",
  COUNTRY_UNITED_ARAB_EMIRATES: "Emirados Árabes Unidos",
  COUNTRY_UNITED_KINGDOM: "Reino Unido",
  COUNTRY_UNITED_STATES: "USA",
  COUNTRY_URUGUAY: "Uruguai",
  COUNTRY_US_VIRGIN_ISLANDS: "Ilhas Virgens Americanas",
  COUNTRY_UZBEKISTAN: "Uzbequistão",
  COUNTRY_VANUATU: "Vanuatu",
  COUNTRY_VATICAN_CITY: "Santa Sé",
  COUNTRY_VENEZUELA: "Venezuela",
  COUNTRY_VIETNAM: "Vietnã",
  COUNTRY_WALLIS_AND_FUTUNA: "Ilhas Wallis e Futuna",
  COUNTRY_WEST_BANK: "Cisjordânia",
  COUNTRY_WESTERN_SAHARA: "Sahara do Oeste",
  COUNTRY_YEMEN: "Iêmen",
  COUNTRY_YUGOSLAVIA: "Jugoslávia",
  COUNTRY_ZAIRE: "Zaire",
  COUNTRY_ZAMBIA: "Zâmbia",
  COUNTRY_ZIMBABWE: "Zimbábue",
  CPF: 'CPF',
  CPF_CNPJ_ALREADY_EXISTIS: 'CPF/CNPJ já existe na base',
  CPU: 'CPU',
  CPU_CAPTION: 'Legenda da CPU',
  CPU_USAGE: 'Uso da CPU',
  CREATE: 'Criado',
  CREATE_ATTENDANCE: 'Criar Atendimento',
  CREATE_EDIT_AND_OR_EXCLUDE_RELATIONSHIP_TYPES: 'Criar, editar e/ou excluir tipos de relacionamento',
  CREATE_OR_ADD_TAGS_ACTIVITY: 'Crie ou adicione tags à esta atividade',
  CREATE_OR_ADD_THIS_ACTIVITY_TO_AN_ACTIVITY_GROUP: 'Crie ou adicione esta atividade a um grupo de atividade',
  CREATE_PRIORITY_CUSTOM_GLOBAL_SETTING: 'Criar Configuração Global de Customização de prioridade',
  CREATE_SUB_CALL: 'Criar Sub Chamado',
  CREATE_TAG: 'Criar Tag',
  CREATE_TASK_FLOW: 'Criar Fluxo de Tarefas',
  CREATE_TEMPLATE_BASED_GMUD: 'Criar template baseado na GMUD?',
  CREATE_WORKFLOW: 'Criar Workflow',
  CREATE_YOUR_OWN_MASTER_PASSWORD: 'Crie sua senha mestre',
  CREATED: 'Criado',
  CREATED_IN: 'Criado em',
  CREATOR: 'Criador',
  CRISIS_GROUP: 'Grupo de Crises',
  CRISIS_IS_CONFIRMED: 'Crise está confirmada',
  CRISIS_IS_UNCONFIRMED: 'Crise encontra-se não confirmada',
  CRISIS_PLURAL: 'Crises',
  CRITICAL: 'Crítico',
  CRITICAL_UNIT: 'Unidade Crítica',
  CRITICALITY: 'Criticidade',
  CURRENCY: 'R$',
  CURRENT: 'Atual',
  CURRENT_MATURITY: 'Maturidade atual',
  CURRENT_MONTH_AVAILABILITY: 'Disponibilidade mês atual',
  CUST: 'Custo',
  CUSTOMER_DATA: 'Dados do Cliente',
  CUSTOMIZE_PRIORITY: 'Customizar prioridade',
  CUSTOMIZED: 'Customizada',
  DAILY: 'Diária',
  DANGER: 'Perigo',
  DANGEROUS: 'PERIGOSO',
  DASH: {
    SLA_BURST_CLOSED: 'SLA estourado dos fechados'
  },
  DASHBOARD: 'Dashboard',
  DASHBOARD_G: 'Visão Gerencial',
  DASHBOARD_PAGE: {
    ATTENDANCE: 'Gestão de atendimento',
    DM: 'Gestão de Demandas',
    GCF: 'Gestão de Contratos e Fornecedores',
    GI: 'Gestão de Infraestrutura',
    GSD: 'Gestão de Service Desk',
    SUB_TITLE: 'Painel de Informações',
    TITLE: 'Dashboard Tickets'
  },
  DATA_LOG: 'Data log',
  DATA_SENT_SUCCESSFULLY: 'Dados enviados com sucesso',
  DATABASE_SUPPORT: 'Suporte a Banco de Dados',
  DATAMAT: {
    mat: ['', 'Inicial / Ad Hoc', ' Repetível, mas intuitivo', 'Processos Definidos', 'Gerenciados e Medidos', 'Otimizado']
  },
  DATE: 'Data',
  DATE_ATTENDANCE: 'Data atendimento',
  DATE_CAB: 'Data CAB',
  DATE_CAD: 'Data de Cadastro',
  DATE_CLOSED_RELATED: 'Data de Conclusão do Registro Relacionado',
  DATE_DATE_FINAL: 'Data - Final',
  DATE_DATE_INITIAL: 'Data - Inicial',
  DATE_END_RELATED_GMUD: 'Data final de execução da Mudança',
  DATE_FOR_THE_ACTIVITY: 'Data para a atividade',
  DATE_INITIAL_STEP: 'Data Ini. Etapa',
  DATE_LANG: {
    dayAbbreviation: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    dayFullList: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
    dayList: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    monthList: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    monthLongList: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  },
  DATE_LAST_CHANGE: 'Data Últ Alteração',
  DATE_NORMALIZATION: 'Data da Normalização',
  DATE_OPTIONS: 'Opções de Data',
  DATE_OUTSIDE_PROJECT_PERIOD: 'Data fora do período do projeto',
  DATE_REGISTER: 'Data cadastro',
  DATE_REMOVED: 'Data Removido',
  DATE_START_PRODUCTION: 'Data Ativar em produção',
  DATE_START_RELATED: 'Data de Cadastro do Registro Relacionado',
  DATE_START_RELATED_GMUD: 'Data inicial de execução da Mudança',
  DATE_TARGET: 'Data Alvo',
  DATE_TIME: 'Data e Hora',
  DATE_WITH: 'Por Data de',
  DAY: 'Dia',
  DAYS: 'dias',
  DAYS_AUTO_CLOSE: 'Dias para o fechamento automático',
  DAYS_IN_MONTH: 'Dias no mês',
  DC: 'DC',
  DEADLINE: 'Prazo final',
  DECIMAL_PLACES: 'Casas decimais',
  DECREASING: 'Em Drecréscimo',
  DEFAULT: 'Padrão',
  DEFAULT_CHANNEL_TICKETS: 'Canal default nos tickets',
  DEFAULT_COMPANY_CRISIS: 'Empresa padrão em crises',
  DEFAULT_COMPANY_PROJETS: 'Empresa default nos projetos',
  DEFAULT_GROUP_FALE: 'Grupo padrão FALE',
  DEFINE_LIKE: 'Definir como',
  DELAYED: 'Atrasado',
  DELETE: 'Excluir',
  DELETE_ITEM: 'Exclui item',
  DELETE_ONE_OR_MORE_ITEM: 'Remover um ou mais itens',
  DELETE_PERSPECTIVE: 'Excluir a Perspectiva',
  DELETE2: 'Deletar',
  DELIVERY_DATE: 'Data de entrega',
  DELIVERY_MANAGER: 'Gerente de entrega',
  DEMAND_MANAGEMENT: 'Gestão de Demandas',
  DEMANDS: 'Demandas',
  DEPARTMENT: 'Departamento',
  DEPARTMENT_BOSS: 'Chefe de Departamento',
  DEPARTMENT_DESCRIPTION: 'Departamento designa-se a uma divisão ou um segmento distinto de uma empresa.',
  DEPARTMENT_INVOLVED: 'Departamentos Envolvidos',
  DEPARTMENT_LIST: 'Lista de Departamentos',
  DEPARTMENT_NAME: 'Nome do departamento',
  DEPARTMENT_STATUS_CHART: 'Gráfico de status por departamentos',
  DEPARTMENT_TABLE: 'Tabela de departamento',
  DEPARTMENTS: 'Departamentos',
  DEPARTMENTS_SUBDEPARTMENTS: 'Departamentos/SubDepartamentos',
  DEPARTURE_DATE: 'Data de partida',
  DESCRIBE_ANALYZE_PROBLEM: 'Análise: Descreva a analise realizada',
  DESCRIBE_THE_GOALS_OF_THIS_PROJECT: 'Descreva os objetivos deste projeto',
  DESCRIBE_THE_RISKS_INVOLVED_IN_THIS_PROJECT: 'Descreva os riscos que envolvem este projeto',
  DESCRIBE_THE_RISKS_THAT_SURROUND_THIS_PROJECT: 'Descreva os riscos que cercam este projeto',
  DESCRIPTION: 'Descrição',
  DESCRIPTION_CANNOT_BE_BLANK: 'Descrição não pode ficar em branco',
  DESCRIPTION_OF_CONTRACT: 'Descrição do contrato',
  DESIGNATED_ANALYST: 'Analista Designado',
  DESIGNATION_HISTORY: 'Histórico de Designação',
  DESIRED_MATURITY: 'Maturidade desejada',
  DESKTOP: 'Desktop',
  DETAILED_VIEW: 'Visão Detalhada',
  DETAILS: 'Detalhes',
  DETAILS_OF_THE_CALLS_MADE: 'Detalhes dos atendimentos feitos',
  DETRACTORS: 'Detratores',
  DEVELOP: 'Desenvolvimento',
  DEVELOPMENT: 'Desenvolvimento',
  DEVICE: 'Dispositivo',
  DEVICE_ASSOCIATED_WITH_ANOTHER_GMUD: 'Dispositivo associado a outra GMUD',
  DEVICE_ATTACH: 'Anexar Dispositivo',
  DEVICE_ATTACH_WARNING: 'Todos os dispositivos disponíveis já estão anexados a este contrato',
  DEVICE_CONF: 'Itens de Configuração',
  DEVICE_CONFIG: 'Configurações do Dispositivo ',
  DEVICE_CONFIGURATION: 'Configuração Dispositivo',
  DEVICE_DESCRIPTION: 'Um conjunto de condições que um ticket precisa satisfazer para que a política de SLA seja aplicada.',
  DEVICE_IC: 'Dispositivo IC',
  DEVICE_INFO: 'Informações do dispositivo',
  DEVICE_LIST: 'Lista de Dispositivos',
  DEVICE_MAP: 'Mapa dos dispositivos',
  DEVICE_MONITORING: 'Monitoramento de dispositivo',
  DEVICE_NAME: 'Nome do dispositivo',
  DEVICE_STATUS: 'Estado dos Dispositivos',
  DEVICE_TABLE: 'Tabela de dispositivo',
  DEVICE_TITLE: 'Cadastro de Dispositivos',
  DEVICE_TYPE: 'Tipo de Dispositivo',
  DEVICE_TYPE_DESCRIPTION: 'Cadastro de Lista de Dispositivos',
  DEVICE_TYPE_IC: 'Tipo de Dispositivo IC',
  DEVICES: 'Dispositivos',
  DEVICES_GROUP: 'Grupo de Dispositivos',
  DEVICES_GROUP_DETAIL: 'Detalhes do Grupo de Devices',
  DEVICES_SERVERS_IMPACTED: 'Dispositivos (servidores) afetados',
  DIFFERENCE_BETWEEN_EXPECTED_AND_CONSUMED: 'Diferença entre Previsto e Consumido',
  DIFFERENT_FROM: 'Diferente de',
  DIRECT_LEADER: 'Líder Direto',
  DIRECTOR: 'Diretor',
  DISABLE: 'Desabilitar',
  DISABLE_AUTOMATIC_LOADING: 'Desabilitar o carregamento automático',
  DISABLE_FIELDS: 'Desabilitar Campos',
  DISABLE_TEMPLATE_LIST: 'Desabilitar na lista de templates',
  DISABLED: 'Desabilitada',
  DISCARD: 'Descarte',
  DISCHECK_ALL: 'Desmarca Todos',
  DISCOVERY: 'Descoberta',
  DISK: 'Discos',
  DISK_HEADER: 'Cabeçalho do disco',
  DISK_SECTOR: 'Setor de disco',
  DISK_TRACKS: 'Faixas de discos',
  DISK_USAGE: {
    boot: 'Uso de Disco /boot',
    home: 'Uso de Disco /home',
    repo: 'Uso de Disco /repo',
    root: 'Uso de Disco /',
    tmp: 'Uso de Disco /tmp',
    tools: 'Uso de Disco /tools',
    u01: 'Uso de Disco /u01',
    usr: 'Uso de Disco /usr',
    var: 'Uso de Disco /var',
    varLog: 'Uso de Disco /var/log'
  },
  DISTRIBUTE_TICKETS_AUTOMATICALLY: 'Distribuir chamados de forma automatica',
  DISTRICT: 'Estado',
  DISTRO: 'Distro',
  DIVISION: 'Divisão',
  DIVISIONS: 'Divisões',
  DO_YOU_REALLY_WANT_TO_APPROVE_DOCUMENTATION: 'Deseja realmente Aprovar a Documentação?',
  DO_YOU_REALLY_WANT_TO_APPROVE_MATURITY: 'Deseja realmente Aprovar Maturidade?',
  DO_YOU_REALLY_WANT_TO_DELETE_THE_SPRINT: 'Deseja Realmente excluir a SPRINT ',
  DO_YOU_REALLY_WANT_TO_DELETE_THIS_FAVORITE: 'Você quer mesmo excluir este favorito?',
  DO_YOU_WANT_REMOVE_THIS_ITEM: 'Deseja remover esse item?',
  DO_YOU_WANT_TO_CHANGE_THE_LANGUAGE: 'Deseja alterar a linguagem?',
  DO_YOU_WANT_TO_CHANGE_YOUR_TIMEZONE: 'Deseja alterar o seu timezone?',
  DO_YOU_WANT_TO_CREATE_THE_PROJECT_FROM_A_TEMPLATE: 'Deseja criar o projeto a partir de um Template?',
  DO_YOU_WANT_TO_PUT_ACTIVITY_IN_PROGRESS: 'Deseja Colocar atividade em andamento?',
  DO_YOU_WANT_TO_REMOVE_ACTIVITY: 'Deseja remover atividade?',
  DO_YOU_WANT_TO_REMOVE_SUB_ACTIVITY: 'Deseja remover a sub-atividade?',
  DO_YOU_WANT_TO_REMOVE_THE_EXPECTED_RESULT: 'Deseja remover o resultado esperado?',
  DO_YOU_WANT_TO_REMOVE_THIS_COST: 'DESEJA REMOVER ESTE CUSTO?',
  DOC: 'Documento',
  DOCS: 'Documentos',
  DOING: 'Fazendo',
  DOMAIN: 'Domínio',
  DONT_COUNT: 'Não contém',
  DOWNLOAD: 'Baixar',
  DOWNLOAD_ATTACHMENT: 'Download Anexo',
  DOWNLOAD_CSV: 'Baixar CSV',
  DOWNLOAD_JPEG: 'Baixar imagem JPEG',
  DOWNLOAD_MIDI: 'Baixar MIDI',
  DOWNLOAD_PDF: 'Baixar documento PDF',
  DOWNLOAD_PNG: 'Baixar imagem PNG',
  DOWNLOAD_READY: 'Download Realizado Com Sucesso!',
  DOWNLOAD_SVG: 'Baixar imagem SVG',
  DOWNLOAD_XLS: 'Baixar XLS',
  DOWNTIME: 'Tempo de Indisponibilidade',
  DRAG_AND_DROP_AN_IMAGE_FILE_HERE_OR_CLICK: 'Arraste e solte um arquivo de imagem aqui ou clique',
  DRAG_THE_FILE: 'Clique no Ícone ao Lado para Anexar',
  DT_CAD: 'Data de abertura',
  DUPLICATE: 'Duplicar',
  DUPLICATE_FIELDS_WORKFLOW: 'Não pode haver níveis repetidos',
  ECONOMY: 'Economia',
  EDIT: 'Editar',
  EDIT_ASSIGNMENT: 'Editar Tarefas Adicionadas',
  EDIT_BLACKOUT: 'Editar Blackout',
  EDIT_BUILD: 'Editar Build',
  EDIT_CATALOG: 'Editar Catálogo de Serviços',
  EDIT_CATEGORY: 'Editar Categoria',
  EDIT_CHANGE: 'Editar Mudança',
  EDIT_COMPANY: 'Editar Empresa',
  EDIT_CONFIGURATION: 'Editar Configurações',
  EDIT_CONTRACT: 'Editar Contrato',
  EDIT_CRISIS_CONFIRMATION: 'Editar confirmação de crise',
  EDIT_DEPARTMENT: 'Editar Departamento',
  EDIT_DEVICE: 'Editar Dispositivo',
  EDIT_EXPECTED_RESULT: 'Editar Resultado Esperado',
  EDIT_FORM: 'Editar Formulário',
  EDIT_GROUP: 'Editar Grupo',
  EDIT_MAIL: 'Editar Email',
  EDIT_NEW_TASK: 'Editar nova tarefa',
  EDIT_OR_ADD: 'Editar ou Adicionar',
  EDIT_OUTSOURCED: 'Editar Fornecedor',
  EDIT_POSITION: 'EDITAR CARGO',
  EDIT_SERVICE_ORDER: 'Editar Ordem de Serviço',
  EDIT_SLA: 'Editar SLA',
  EDIT_SOLICITANT: 'Editar Solicitante',
  EDIT_SPONSOR: 'Editar Responsável',
  EDIT_SUB_ACTIVITIES: 'Editar Sub-Atividade',
  EDIT_SUBDEPARTMENT: 'Editar Subdepartamento',
  EDIT_TAG: 'Editar Tag',
  EDIT_TASK: 'Editar Tarefa',
  EDIT_TECH: 'Editar Técnico',
  EDIT_TICKETS: 'Editar Tickets',
  EDITING_OF_TECH_GMUD_AFTER_APPROVAL: 'Edição do técnico da gmud após aprovação',
  EDITING_SUBACTIVITY_STATUS: 'Edição do status da subatividade',
  EDITION: 'Edição',
  EDITION_OF_SUB_ACTIVITY_STATUS: 'Edição do status da sub-atividade',
  EDITOR: 'Editor',
  EDITPROJECT: 'Editar Projeto',
  EFFECTIVENESS_ANALYSIS: 'Análise de Eficácia',
  EFFICIENCY: 'Eficiência',
  EFFORT: 'Esforço',
  EFFORT_VALUE: 'Valor do esforço',
  EMAIL: 'E-mail',
  EMAIL_ALREADY_REGISTERED: 'E-mail já cadastrado',
  EMAIL_BODY: 'Corpo do e-mail',
  EMAIL_POLICY_DESCRIPTION: 'Rotina para cadastrar email',
  EMAIL_SUCCESSFULLY_RESENT: 'E-mail reenviado com sucesso',
  EMAIL_TEMPLATES: 'Modelos de E-mail',
  EMAILS_SENT_SUCCESSFULLY: 'E-mails enviados com sucesso',
  EMERGENCI_CHART_TECH: 'Gráfico de urgência por Técnicos',
  EMERGENCY: 'Emergência',
  EMPTY: 'Vazio',
  ENABLE: 'Habilitar',
  ENABLE_ASSET_INVOLVED_TICKET_CREATION: 'Habilitar Ativo Envolvido na criação de tickets',
  ENABLE_AUTOMATIC_LOADING: 'Habilitar o carregamento automático',
  ENABLE_COMPANY_CATALOG_GROUPING: 'Habilitar agrupamento de catálogo de serviços',
  ENABLE_CRISIS_TICKET_TYPE: 'Habilitar tipo de ticket Crise',
  ENABLE_CRITICAL_INCIDENT_TICKET_TYPE: 'Habilitar tipo de ticket de Incidente Crítico',
  ENABLE_EVENT_TICKET_TYPE: 'Habilitar tipo de ticket Evento',
  ENABLE_GENERAL_APPROVE_REPROVE_BUTTON_GMUD: 'Habilitar regras para exibição dos botões (Aprovar / Reprovar) em gestão de mudanças',
  ENABLE_GMUD_APPROVERS_WORKFLOW: 'Habilitar fluxo de aprovadores de GMUD',
  ENABLE_GMUD_FORMS_FILTER: 'Habilitar filtro de formulários na GMUD',
  ENABLE_GMUD_LEADERS_AS_APPROVERS: 'Habilitar Líder e Líder alternativo de um usuário como aprovador de GMUD',
  ENABLE_NOTIFICATION_CENTER: 'Habilitar Central de Notificações',
  ENABLE_REQUIREMENT_CHANNEL_TICKET: 'Habilitar obrigatoriedade de canais em tickets',
  ENABLE_SSO: 'Habilitar SSO',
  ENABLE_TICKET_LISTING_FOR_ASSOCIATED_COMPANIES: 'Habilitar listagem de tickets de empresas associadas',
  ENABLE_TICKET_LISTING_FOR_THE_DEPARTMENT: 'Habilitar listagem de tickets para o departamento',
  ENABLED: 'Habilitada',
  END: 'Final',
  END_: 'Término',
  END_CALL: 'Finalizar Chamado ',
  END_DATE: 'Data de término',
  END_DATE_AND_TIME: 'Data e hora final',
  END_DATE_BREAK: 'Data Final do Intervalo ',
  END_HOUR: 'Hora Final',
  END_OF_PROJECT: 'Término do projeto',
  END_OUTAGE: 'Fim Outage',
  END_RECURRENCE: 'Fim da recorrência',
  END_SELECTED_TICKETS: 'Finalizar chamados selecionados',
  END_TIME: 'Hora final',
  END_TIME_BREAK: 'Hora Final do Intervalo',
  ENDPOINT_MANAGEMENT: 'Gestão de Endpoint',
  ENDS_WITH: 'Termina com',
  ENTER: 'Entrar',
  ENTER_DESCRIPTION: 'Informe a descrição',
  ENTER_END_DATE_CHANGE: 'Informe qual será a data de término desta mudança.',
  ENTER_EVOLUTION: 'Informe Evolução',
  ENTER_TEMPLATE_BELOW: 'Informe o template abaixo',
  ENTER_THE_CORRECT_AMOUNT_OF_ACTIVITY_EFFORT: 'Informe o valor correto do esforço da atividade',
  ENTER_THE_CORRECT_TICKET_EFFORT_VALUE: 'Informe o valor correto do esforço do ticket',
  ENTER_THE_END_DATE_OF_THE_SPRINT: 'Informe a data final da sprint',
  ENTER_THE_END_TIME_OF_THE_SPRINT: 'Informe a hora final da sprint',
  ENTER_THE_NAME_OF_THE_SPRINT: 'Informe o nome da Sprint',
  ENTER_THE_START_DATE: 'Informe qual será a data de início',
  ENTER_THE_START_DATE_OF_THE_SPRINT: 'Informe a data inicial da sprint',
  ENTER_THE_START_TIME_OF_THE_SPRINT: 'Informe a hora inicial da sprint',
  ENTER_TICKET_PRIORITY: 'Informe a prioridade do ticket',
  ENTER_VALID_TIME_VALUE: 'Informe um valor válido no tempo',
  ENTRY_DATE: 'Data de Entrada',
  EQUAL_TO: 'Igual a',
  EQUIPELOCATION: 'ALOCAÇÃO DA EQUIPE',
  EQUIPMENT: 'Equipamento',
  ERASE: 'Apagar',
  ERRO_PJ_AC: 'Existem atividades cadastradas com data de início inferior a data do projeto',
  ERROR_CODE: {
    E001: 'Senha Antiga inválida',
    E002: 'Nova senha não pode ser igual a atual',
    E007: 'Error no fluxo',
    E008: 'Favor Atualizar / Cadastrar seus dados na plataforma',
    E009: 'Revisão já submetida',
    E010: 'Vídeos não encontrados',
    E011: 'Usuario já cadastrado',
    E012: 'Data final não pode ser menor que a data inicial',
    E019: 'Atividade vinculada não foi concluida, Favor Verificar no detalhe da atividade',
    E020: 'Valor não permitido',
    E404: 'Rota inexistente',
    E500: 'Erro Interno no Servidor'
  },
  ERROR_LOADING_CURRENT_SMTP_CONFIGURATION: 'Erro ao carregar configuração atual de SMTP',
  ERROR_LOADING_SMTP_LIST: 'Erro ao carregar lista de SMTP',
  ERROR_PASSWORD_NOT_ALLOWED: 'A senha não atende aos critérios necessários.',
  ERROR_PROCESSING: 'Erro no processamento',
  ERROR_REQUEST: 'Solicitação com Erro',
  ERROR_SELECTING_SMTP: 'Erro ao selecionar SMTP',
  ESCALATION_INFORMATION: 'Informação de Escalada',
  ESTIMATED_COMPLETION_DATE: 'Data Estimada Para Término',
  ESTIMATED_TIME: 'Tempo Estimado',
  EVALUATE: 'Avaliar OS',
  EVALUATE_CALL: 'Avaliar Chamado',
  EVALUATE1: 'Avaliar',
  EVALUATION_REGARDING_ATTENDANCE_TEAM: 'de 0 a 10, qual foi a sua experiência com a nossa equipe de atendimento?',
  EVALUATION_REGARDING_CALL_RESOLUTION_QUALITY: 'de 0 a 10, qual a sua avaliação a respeito da qualidade de solução do chamado?',
  EVALUATION_REGARDING_CALL_RESOLUTION_TIME: 'de 0 a 10, qual a sua avaliação a respeito do tempo de solução do chamado?',
  EVALUTION: 'Avaliação',
  EVENT: 'Evento',
  EVENT_CAB_ALERT: 'Evento CAB cadastrado com sucesso',
  EVENTS: 'Eventos',
  EVIDENCE: 'Evidência',
  EVIDENCE_DESCRIPTION: 'Descrição da Evidência',
  EVIDENCES: 'Evidências',
  EVOLU: 'Evolução do Projeto',
  EVOLUTION: 'Evolução',
  EVOLUTION_DATE: 'Data da evolução',
  EVOLUTION_KPIS: 'Kpi’s de evolução',
  EVOLUTION_OF_ACTIVITIES: 'Evolução das Atividades',
  EVOLUTION_OF_TESTS: 'Evolução dos testes',
  EVOLUTION_OF_TICKETS: 'EVOLUÇÃO DOS TICKETS',
  EVOLUTION_PERCENTAGE: 'Percentual de Evolução',
  EVOLUTION_STATUS: 'Status de evolução',
  EVOLUTION_TESTS_PLANS: 'Evolução do Plano de Testes',
  EVOLUTIONARY: 'Evolutivo',
  EXCEEDED: 'Excedido',
  EXECUTED: 'Executado',
  EXECUTION: 'Execução',
  EXECUTION_ANNEXES: 'Anexos de execução',
  EXECUTOR: 'Executor',
  EXPECTED_COST: 'Custo Previsto',
  EXPECTED_END: 'Termino Previsto',
  EXPECTED_RESULT: 'Resultado Esperado',
  EXPECTED_START: 'Inicio previsto',
  EXPENSE: 'Despesa',
  EXPENSES: 'Despesas',
  EXPERIENCE: 'Experiência',
  EXPIRATION_DATE: 'Data de expiração',
  EXPIRE: 'Venceram',
  EXPIRE_THIS_WEEK: 'Venceram essa semana',
  EXPIRE_TODAY: 'Venceram hoje',
  EXPIRED: 'Vencimentos',
  EXPIRED_SLA: 'SLA Estourados',
  EXPIRY_DATE: 'Data de Vencimento',
  EXPORT: 'Exportar',
  EXPORT_COMPANIES: 'Exportar Empresas',
  EXPORT_DATE: 'Export Dados',
  EXPORT_DEVICES: 'Exportar Dispositivos',
  EXPORT_EXCEL: 'Exportar excel',
  EXPORT_INVENTORY: 'Exportar Inventário',
  EXPORT_LOCATION: 'Exportar localidade',
  EXPORT_SOFTWARE: 'Export Softwares',
  EXPORT_TASKS: 'Tarefas de exportação',
  EXPORT_TO_EXCEL: 'Exportar para Excel',
  EXTEND_OS: 'Estender OS',
  EXTENSION: 'Extensão',
  EXTERNAL: 'Externo',
  FACE_TO_FACE: 'Presencial',
  FACTORY: 'Fábrica',
  FAIL: 'Reprovar',
  FAILURE: 'Falha',
  FARM: 'Fazenda',
  FAVORITES: 'Favoritos',
  FIELD: 'Campo',
  FIELD_EXTENSION: 'Extensão (Adicionar .* para liberar todas as extensões de arquivos)',
  FIELD_TYPE: 'Tipo de campo',
  FILE: 'Arquivo',
  FILE_EXTENSIONS: 'Extensões de arquivos',
  FILE_NAME: 'Nome do Arquivo',
  FILE_SYSTEM: 'Sistema de arquivos',
  FILE_TYPE: 'Tipo do Arquivo',
  FILES: 'Arquivos',
  FILL_IN_ALL_FIELDS: 'Preencha todos os campos',
  FILL_REQUIRED: 'Preencha os Campos Obrigatórios!',
  FILL_THE_FIELD: 'Preencha o campo',
  FILTER: 'Filtro',
  FILTER_BY: 'Filtrar por',
  FILTER_CONDITION: 'Condição de Filtro',
  FINAL_DATE: 'Data Final',
  FINAL_HOUR: 'Hora Final',
  FINALIZE: 'Finalizar',
  FINALIZE_CONTRACT: 'Finalizar Contrato',
  FINANCIAL: 'Financeiro',
  FINANCIAL_ANALYSIS_OF_CONTRACT: 'Análise financeira do contrato',
  FINANCIAL_FEEDBACK: 'Retorno Financeiro',
  FINANCIAL_SUMMARY: 'Resumo Financeiro',
  FINISH_DONE: 'Termino Realizado',
  FINISH_GMUD: 'Finalizar GMUD',
  FINISH_THE_TASK_BEFORE_MOVING_IT_TO_THE_FINISHED_COLUMN: 'Conclua a tarefa antes de movimenta-la para a coluna finalizada',
  FINISHED: 'Finalizado',
  FINISHED_CALL: 'Chamada finalizada',
  FINISHED_CANCEL: 'Finalizado Cancelado',
  FINISHED_FAIL: 'Finalizado com falha',
  FINISHED_GMUD: 'GMUD finalizadas',
  FINISHED_PARTIALLY: 'Finalizado Parcial',
  FINISHED_SUCCESS: 'Finalizado com sucesso',
  FIRMWARE_VERSION: 'Versão do FIRMWARE',
  FIRST_NEED_FINALIZE_SUBCALLS: 'Necessário finalizar primeiro os Sub Chamados',
  FIVE_WHYS_METHODOLOGY: 'Metodologia dos 5 Porquês',
  FLAG: 'Bandeira',
  FOLDER_ID: 'Id da pasta',
  FOLLOY_DAY: 'Acompanhamento do dia',
  FOLLOY_MONTH: 'Acompanhamento do mês',
  FOLLOY_WEEK: 'Acompanhamento da semana',
  FOR_SLA: 'Para SLA',
  FORECAST_TIME: 'Tempo Previsto',
  FORESEEN: 'Previsto',
  FORGOT_PASS: 'Esqueceu a senha?',
  FORM_FIELD: 'Campo do formulário',
  FORM_FIELDS: 'Campos do formulário',
  FORM_FIELDS_CANNOT_BE_BLANK: 'Campos do formulário não podem ficar em branco',
  FORM_TEMPLATE: 'Template de Formulário',
  FORMS: 'Formulários',
  FORTNIGHTLY: 'Quinzenal',
  FREE: 'Livre',
  FREE_SPACE: 'Espaço livre',
  FREE_TIMES: 'Horas Livres',
  FROM: 'De',
  FULL: 'Full',
  FULL_NAME: 'Nome Completo',
  FULL_SCREEN: 'Tela Cheia',
  GANTT: 'GANTT',
  GANTT_GRAPH: 'Gráfico de Gantt',
  GCF: 'GCF',
  GENERAL: 'Geral',
  GENERAL_DATE: 'Dados Gerais',
  GENERAL_INF: 'Informações Gerais',
  GENERAL_INFRASTRUCTURE_RISK: 'Risco Geral de Infraestrutura',
  GENERAL_PARAMETERS: 'Parâmetros Gerais',
  GENERAL_SERVICE_DESK_REPORT: 'Relatório geral de service desk',
  GENERAL_SETTINGS: 'Configurações gerais',
  GENERAL_STATUS_OF_THE_PROJECT: 'Status geral do projeto',
  GENERAL_USER_CREATION: 'Criação de Usuário Geral',
  GENERAL_USER_PERMISSIONS_CREATION: 'Criação de Permissões de Usuário',
  GENERAL_USER_PERMISSIONS_EDITION: 'Edição de Permissões de Usuário',
  GENERATE: 'Gerar',
  GENERATE_NEW_CHANGE: 'Gerar um nova mudança',
  GENERATE_NEW_CHANGE_TEMPLATE: 'Gerar um novo template de mudança',
  GENERATE_REPORT: 'Gerar relatório',
  GENERATED_PLURAL: 'Gerados',
  GENERATES_COSTS: 'Gera Custos',
  GI: 'GI',
  GLOBAL: 'Global',
  GLOBAL_DATA_ON_RISKS_OF_CHANGE_NOT_REGISTERED: 'Dados globais de riscos de mudança não cadastrados',
  GMUD: 'GMUD',
  GMUD_AND_CAB_RISKS_SUCCESSFULLY_REGISTERED: 'Riscos da GMUD e CAB cadastrados com sucesso',
  GMUD_APPROVE: 'Você deseja aprovar esta GMUD?',
  GMUD_BACK_TO_EDITION: 'Voltar GMUD para edição',
  GMUD_CONCLUDE: 'Você deseja concluir esta GMUD?',
  GMUD_FLOW: 'Fluxo de Aprovação de GMUD',
  GMUD_FLOW_INFOS: 'Crie e gerencie fluxos de aprovação para GMUD',
  GMUD_IN_CAB: 'GMUD em CAB(diário)',
  GMUD_INFORMATION: 'INFORMAÇÕES DA GMUD',
  GMUD_MANAGER: 'Apenas o gestor da GMUD!',
  GMUD_REGISTRATION: 'Cadastro GMUD',
  GMUD_REJECT: 'Rejeitar GMUD',
  GMUD_RESEND_EMAIL: 'Reenviar e-mail para aprovação',
  GMUD_RISKS_SUCCESSFULLY_REGISTERED: 'Riscos da GMUD cadastrados com sucesso',
  GMUD_RUNNING: 'GMUD em execução',
  GMUD_STATUS: {
    ANALYZING: 'Analisando',
    CANCELED: 'Cancelada',
    CLOSE: 'Fechada',
    CLOSED: 'Encerrada',
    CONCLUDED: 'Concluída',
    CONCLUDED_CANCEL: 'Concluída Cancelada',
    CONCLUDED_FAIL: 'Concluída com falha',
    CONCLUDED_PART: 'Concluída Parcialmente',
    CONCLUDED_SUCCESS: 'Concluída com sucesso',
    CREATED: 'Criada',
    FINISHED: 'Finalizada',
    FINISHED_CANCEL: 'Finalizada Cancelada',
    FINISHED_FAIL: 'Finalizada com falha',
    FINISHED_PARTIALLY: 'Finalizada Parcialmente',
    FINISHED_SUCCESS: 'Finalizada com sucesso',
    OPENED: 'Aberta',
    POST_IMPLEMENTATION_REVIEW: 'Revisada pós-implementação',
    REJECTED: 'Rejeitada'
  },
  GMUD_STATUS_DOES_NOT_ALLOW_RUNNING_TASKS: 'Situação da GMUD não permite executar tarefas',
  GMUD_STATUS_WILL_BE_SET_AS_OPEN: `O status da GMUD será setado como "Aberto"`,
  GMUD_SUCCESSFULLY_FINISHED: 'GMUD finalizada com sucesso',
  GMUD_TYPE: 'Tipo de GMUD',
  GMUD_WORKFLOW_EDITION: 'Edição de Fluxo de Aprovação',
  GMUD_WORKFLOW_REGISTRATION: 'Cadastro de Fluxo de Aprovação',
  GO_TO: 'Ir para',
  GO_TO_ACTIVITY: 'Ir para atividade',
  GOAL: 'Meta',
  GP: 'GP',
  GPU_ADAPTER: 'Adaptador da GPU',
  GPU_CAPTION: 'Legenda da GPU',
  GRAPH_BY_STATUS: 'Gráfico por status',
  GRAPH_CIO: 'Total de Tickets Abertos',
  GRAPH_DETAILS: 'Detalhes do gráfico',
  GRAPH_STATUS_AVALATION: 'Gráfico de status por Avaliação',
  GRAPH_STATUS_TECH: 'Gráfico de status por Grupo Técnico',
  GRAPH_TYPE_ACTION: 'Gráfico por Tipo de ação',
  GRC: 'GRC',
  GREAT: 'EXCELENTE',
  GROUP: 'Grupo',
  GROUP_BROKEN: 'Grupo de Quebra',
  GROUP_DESCRIPTION: 'Grupo de Usuário com perfil de Técnico e Solicitantes.',
  GROUP_DESCRIPTION2: 'Descrição do Grupo',
  GROUP_GMUD: 'Grupos de Mudanças',
  GROUP_LEADER: 'Líder do grupo',
  GROUP_LIST: 'Lista de Grupos',
  GROUP_NAME: 'Nome do grupo',
  GROUP_REQUIRED_FIELD_CONDITIONAL: 'Empresa ou parâmetro global é obrigatório.',
  GROUP_TECH: 'Grupo de Técnico',
  GROUPING_NAME: 'Nome do agrupamento',
  GROUPS: 'Grupos',
  GROUPTECH_TABLE: 'Tabela Grupo Técnico',
  GROWING: 'Em Crescimento',
  GROWTH: 'Em Crescimento',
  GSD: 'GSD',
  GSD_INFORMATIONS: 'Informações de GSD',
  HARDWARE: 'Hardware',
  HARDWARE_LIST: 'Lista de Dispositivos',
  HAVE_AN_EVALUATION_OF_AT_LEAST_8_OF_SATISFACTION_IN_USING_THE_EVOLUTION_SOLUTION: 'Ter avaliação de ao menos 8.0 de satisfação de uso da solução de evoluções',
  HEALTHY: 'SAUDÁVEL',
  HELP_DESK: 'Central de Ajuda',
  HIDE: 'Recolher',
  HIGH: 'Alto',
  HISTORIC: 'Histórico',
  HISTORIC_APPROVAL_CENTER: 'Histórico da Central de Aprovações',
  HISTORIC_ATEND_CLIENTS: 'Histórico de Atendimento de Clientes',
  HISTORIC_CALL: 'Histórico de chamados ',
  HISTORIC_CHANGES: 'Histórico de alterações',
  HISTORIC_GP: 'Historico das ações do projeto',
  HISTORIC_LIST: 'Listagem do Histórico',
  HISTORIC_SLA: 'Histórico de SLA',
  HISTORIC_SLA_SERIES: 'Histórico de SLA em Série',
  HISTORY: 'Histórico',
  HISTORY_APPROVALS: 'Histórico Aprovações',
  HISTORY_BACK_TO_EDITION: 'Histórico de edições',
  HISTORY_OF_REJECTIONS_AND_CANCELLATIONS: 'HISTÓRICO DE REJEIÇÕES E CANCELAMENTO',
  HOLIDAY: 'Feriado',
  HOLIDAY_NAME: 'Nome feriado',
  HOLIDAYS: 'Feriados',
  HOST: 'Nome do Host',
  HOUR: 'Hora',
  HOUR_EXCEEDED_VALUE: 'Valor da Hora Excedida',
  HOUR_NORMALIZATION: 'Hora da Normalização',
  HOUR_VALUE: 'Valor da Hora',
  HOUR_VALUE_ADDITIONAL: 'Valor da Hora Adicional',
  HOUR_VALUE_TECH: 'Valor da Hora Técnica',
  HOURLY: 'Por hora',
  HOURS: 'Horas',
  HOURS_CONSUMED: 'Horas Consumidas',
  HOURS_CONSUMED_BY_TECHNICIAN_GROUP_ONLY_INCLUDES_TICKET: 'Horas consumidas por Grupo técnico (Inclui Apenas TICKET)',
  HOURS_CONSUMED_PER_TOTAL_TEAM: 'Horas consumidas por equipe total',
  HOURS_CONTRACTED: 'Horas Contratadas',
  HOURS_CONTRACTED_VS_CONSUMED: 'Horas Contratadas Pelas Horas Consumidas',
  HOURS_EXCEEDED: 'Horas Excedidas',
  HOURS_PER_ANALYST: 'Horas por analista',
  HOURS_USERS: 'Horas por Usuário',
  HOURS_WORKED: 'Horas realizadas',
  HOVER_OVER_COLUMN: 'Passe o mouse em cima das colunas para ver os detalhes',
  HOW_MANY_HOURS: 'Quantas horas?',
  HRS_CON: 'HRS/CON',
  IC_ALREADY_INCLUDED_IN_THE_TICKET: 'IC já incluído no ticket',
  IC_CONFIGURATION_ITEM: 'IC (Item de Configuração)',
  IC_CONTACT_APP_GROUP: 'Grupo de aplicativos',
  IC_CONTACT_CLIENT_KEY_USER_IT: 'Cliente - Usuário-chave de TI',
  IC_CONTACT_CRISI_COMPANY_REPRESENTATIVES: 'Representantes Especiais',
  IC_CONTACT_CRITICAL_SERVICE_GROUP: 'Grupo de serviços críticos',
  IC_CONTACT_CUSTOMER_GROUP_THAT_SHOULD_RECEIVE_SMS_IN_CASE_CRISIS_OR_DEGRADATION: 'Equipe de restauração virtual e principais solucionadores',
  IC_CONTACT_CUSTOMER_KEY_USER_BUSINESS: 'Cliente – Usuário-chave da empresa',
  IC_CONTACT_CUSTOMER_REPRESENTATIVES: 'Representantes do cliente',
  IC_CONTACT_INFRA_GROUP: 'Grupo Infra',
  IC_CONTACT_RESPONSIBLE_FOR_CRITICAL_SERVICE: 'Responsável por Serviços Críticos',
  IC_CONTACT_SPECIAL_GROUP_MUST_RECEIVE_SMS_IN_CASE_CRISIS_OR_DEGRADATION: 'Equipe de restauração virtual e principais solucionadores',
  IC_CONTACT_VIRTUAL_RESTORATION_TEAM_AND_KEY_RESOLVERS: 'Equipe de restauração virtual e principais solucionadores',
  IC_RELATIONSHIP: 'Relacionamento de IC',
  IC_SUCCESSFULLY_INCLUDED: 'IC incluído com sucesso',
  IC_WITHIN_BLACKOUT_RANGE_WANT_TO_CONTINUE: 'IC dentro do intervalo de blackout, deseja continuar?',
  IC_WITHIN_OUTAGE_RANGE_WANT_TO_CONTINUE: 'IC dentro do intervalo de Outage, deseja continuar?',
  ICON: 'Ícone',
  ICS_WITH_LESS_AVAILABILITY_OF_CURRENT_MONTH: "Ic's com menor disponibilidade do mês corrente",
  id: 'ID',
  ID: 'ID',
  ID_COMPANY: 'Id Empresa',
  ID_CORRELATION: 'ID Correlação',
  ID_DISPLAY: 'ID Display',
  ID_INTEGRATION: 'ID Integração',
  ID_PROJECT: 'ID Projeto',
  ID_TICKET: 'ID Do Ticket',
  ID_TICKET_CREATION_REQUEST: 'Id do Ticket da solicitação de criação',
  IDENTIFICATION_NUMBER: 'Número de identificação',
  IDS: 'IDs',
  IF_YES_HOW_MANY_DAYS: 'Se sim, com quantos dias?',
  IM_SURE: 'Tenho certeza!',
  IMAGE: 'Imagem',
  IMPACT: 'Impacto',
  IMPACT_CHART_TECH: 'Gráfico de impacto por Técnicos',
  IMPACTED_SERVICES: 'Serviços Impactados',
  IMPACTED_SYSTEM: 'Sistema Impactado',
  IMPEDITIVE: 'Impeditivo',
  IMPORT: 'Importar',
  IMPORTANT: 'Importante',
  IMPROVEMENT: 'Melhoria',
  IN: 'Em',
  IN_APPROVAL: 'Em aprovação',
  IN_ARREARS: 'Em atraso',
  IN_ATTENDANCE: 'Em atendimento',
  IN_HOURS: 'Em horas',
  IN_LIST: 'Na lista',
  IN_PAUSE: 'Em Pausa',
  IN_PROGRESS: 'Em progresso',
  IN_THE_LAST_SEMESTER: 'No último semestre',
  IN_THE_MONTH: 'No mês',
  IN_THE_YEAR: 'No ano',
  IN_TIME: 'Em Tempo',
  INACTIVE: 'Inativo',
  INCIDENT: 'Incidente',
  INCIDENT_MANAGEMENT: 'Gestão de Incidente',
  INCIDENTS: 'Incidentes',
  INCLUDE_CHAT_HISTORY: 'Incluir histórico do Chat',
  INCLUDE_FORM_FIELDS: 'Incluir campos do formulário',
  INCLUDE_LOWERCASE: 'Incluir minúsculas',
  INCLUDE_NUMBERS: 'Incluir números',
  INCLUDE_QTD_CHARACTERS: 'Quantidade de caracteres',
  INCLUDE_SYMBOLS: 'Incluir símbolos',
  INCLUDE_TECHNICAL_NOTES: 'Incluir notas técnicas',
  INCLUDE_TICKET_HISTORY: 'Incluir histórico do Ticket',
  INCLUDE_UPPERCASE: 'Incluir maiúsculas',
  INCORRECT_ORDER: 'Ordem incorreta!',
  INCREMENTAL: 'Incremental',
  INDEX: 'Índice',
  INDICATORS: 'Indicadores',
  INDIVIDUALS: 'Individuo',
  INFO_DATA_BELOW: 'Informe os dados abaixo',
  INFORM_A_PERSON_IN_CHARGE: 'Informe um responsável',
  INFORM_ALL_FIELDS: 'Informe todos os campos do formulário!',
  INFORM_AN_APPROVER: 'Informe um aprovador',
  INFORM_DATE: 'Informar data',
  INFORM_REASON: 'Informe o motivo',
  INFORM_REQUIRED_FIELDS: 'Informe os campos obrigatórios',
  INFORM_THE_CONTRACT_INVOLVED_IN_THIS_ACTIVITY: 'Informe o contrato envolvido nessa atividade',
  INFORM_THE_EFFORT_IN_DAYS_TO_BE_CARRIED_OUT: 'Informe qual será o esforço em dias a ser realizado',
  INFORM_THE_PRIORITY_OF_THE_ACTIVITY: 'Informe a prioridade da atividade',
  INFORM_THE_PROJECT_PARTICIPANTS_AND_THEIR_HOURLY_VALUE: 'Informe os participantes do projeto e seu valor hora',
  INFORM_THE_REASON: 'Informe a justificativa',
  INFORM_THE_REASON_FOR_THE_GMUD: 'Informe o Motivo da GMUD',
  INFORM_THE_SPRINT_COMPANY: 'Informe a empresa da Sprint',
  INFORM_THE_SPRINT_REQUESTER: 'Informe o solicitante da Sprint',
  INFORM_WHAT_TIME_THE_CHANGE_SHOULD_START: 'Informe qual será o horário em que a mudança deve iniciar',
  INFORM_WHAT_WILL_BE_EFFORT_HOURCS_CARRIED: 'Informe qual será o esforço em horas a ser realizado.',
  INFORM_WHAT_WILL_BE_START_DATE_CHANGE: 'Informe qual será a data de início desta mudança',
  INFORM_WHAT_WILL_BE_THE_EFFORT_IN_HOURS_TO_BE_CARRIED_OUT: 'Informe qual será o esforço em horas a ser realizado',
  INFORMATION: 'Informação',
  INFORMATION_ASSETS: 'Ativos de informação',
  INFORMATION_TECHNOLOGY: 'Tecnologia da Informação',
  INFORMATIONS: 'Informações',
  INFORMED: 'Informado',
  INFRATRUCTURE_MANAGEMENT: 'Gestão de Infraestrutura',
  INI_DATE_BREAK: 'Data Inicial do Intervalo',
  INI_TIME_BREAK: 'Horário Inicial do Intervalo',
  INIT_OUTAGE: 'Início Outage',
  INITIAL_CAUSE_ANALYSIS: 'Análise Causa Raiz',
  INITIAL_DATE: 'Data Inicial',
  INITIAL_HOUR: 'Hora Inicial',
  INITIAL_TIME: 'Tempo Inicial',
  INITIATE_TICKET_SERVICE_ONLY_BY_USER_BELONGING_TO_THE_TECHNICAL_GROUP: 'Iniciar atendimento de tickets somente por usuário pertencente ao grupo técnico',
  INITIATIVES: 'Iniciativa',
  INNOVATION: 'Inovação',
  INPUT: 'Entrada',
  INSTALATION_DATE: 'Data de instalação',
  INSTALL_DATE: 'Data de instalação',
  INSTALLATION_TYPE: 'Tipo de instalação',
  INSTRUCTION: 'Instrução',
  INSTRUCTIONS: 'Instruções',
  INTANGIBLES: 'Intangível',
  INTEGRATION: 'Integração',
  INTEGRATION_DATA: 'Dados de Integração',
  INTEGRITY: 'Integridade',
  INTERACTION_CHAT_PUT_ATTENDANCE: 'Interação chat colocar em atendimento',
  INTERNAL: 'Interno',
  INTERVAL: 'Intervalo',
  INVALID_CONTRACT_DATE: 'Data do contrato inválida',
  INVALID_PASSWORD: 'Senha incorreta',
  INVENTORY: 'Inventário',
  INVENTORY_LIST: 'Lista de Inventários',
  INVENTORY_REPORT: 'Relatório de inventário',
  INVOICE: 'Nota Fiscal',
  INVOLVED_APPLICANTS: 'Solicitantes Envolvidos',
  INVOLVED_AREAS: '*Áreas Envolvidas',
  INVOLVED_IN_THE_CHANGE: 'Envolvidos na mudança',
  INVOLVED_IN_THE_PROJECT: 'Usuários envolvidos',
  INVOLVED_OUTSOURCED: '*Terceiros Envolvidos',
  INVOLVED_PLURAL: 'Envolvidos',
  INVOLVED_TECH: '*Técnicos Envolvidos',
  IP: 'IP',
  IS_GREATER_OR_EQUAL_TO: 'É maior o igual a',
  IS_LESS_THAN: 'É menor que',
  IS_LESS_THAN_OR_EQUAL_TO: 'É menor o igual a',
  IS_NOT_NULL: 'Não é Nulo',
  IS_NULL: 'É Nulo',
  ISO_9001: 'ISO 9001',
  ISO_20000: 'ISO 20000',
  ISO_27001: 'ISO 27001',
  ISO_37001: 'ISO 37001',
  ISO_INFORMATION: 'Informações da ISO',
  IT_BUDGET_CONTROL: 'Controle de Orçamento de TI',
  IT_EVOLUTION_MANAGEMENT: 'Gestão de Evolução de TI',
  IT_IS_NOT_NECESSARY_TO_INFORM: 'não é necessário informar',
  IT_MANAGEMENT: 'Gerenciamento de TI',
  ITEM: 'Item',
  ITEMS: 'Itens',
  ITEMS_ASSOCIATED_WITH_ANOTHER_SPRINT: 'Itens Associados a outra sprint',
  ITEMS_IN_SERVICE_FOR_YOU: 'Itens em atendimento por você',
  ITEMS_LISTING: 'Listagem dos Itens',
  ITEMS_WAITING_FOR_APPROVALS_AND_EVALUATIONS: 'Itens aguardando aprovações e avaliações',
  ITS_GREATER_THAN: 'É maior que',
  ITSM: 'ITSM',
  JURIDIC: 'Jurídica',
  JUSTIFICATION: 'Justificativa',
  JUSTIFICATION_OF_TERMINATION: 'Justificativa da Finalização',
  JUSTIFIED: 'Justificado',
  KANBAN_COLUMN: 'Columa do Kanban',
  KANBAN_GRAPH: 'Gráfico KANBAN',
  KEY: 'Chave',
  KEYS: 'Chaves',
  KNOWLEDGE_BASE: 'Base de Conhecimento',
  KNOWLEDGE_BASE_LIST: 'Listagem de Soluções',
  KNOWN_ERROR: 'Erro conhecido',
  LABELS: 'Labels',
  LANDLINE: 'Telefone Fixo',
  LANG: {
    EN: 'Inglês',
    ES: 'Espanhol',
    PT_BR: 'Português'
  },
  LANGUAGE: 'Idioma',
  LANGUAGE_TIMEZONE_CHANGES_NOTICE: 'As alterações de idioma e fuso horário só serão aplicadas após o próximo login.',
  LANGUAGE_TIMEZONE_CHANGES_NOTICE: "As alterações de idioma e fuso horário só serão aplicadas após o próximo login.",
  "LANGUAGE_TIMEZONE_CHANGES_NOTICE": "As alterações de idioma e fuso horário só serão aplicadas após o próximo login.",
  LAST_ACTION: 'Última ação',
  LAST_BOOT_TIME: 'Ultima inicialização',
  LAST_UPDATE: 'Última Atualização em',
  LAST_UPDATE_DATE: 'Data da última atualização',
  LAST_UPDATES: 'Últimas atualizações',
  LASTNAME: 'Sobrenome',
  LATE: 'Atrasado',
  LATE_ACTIVITIES: 'Atividades Atrasadas',
  LDAP: 'LDAP',
  LDPA: 'LDPA',
  LEARNING: 'Aprendizado',
  LEAVE_A_BRIEF_DESCRIPTION_ABOUT_THIS_CHANGE: 'Deixe uma breve descrição sobre esta mudança',
  LEAVE_A_BRIEF_DESCRIPTION_OF_THE_BENEFITS_OF_THIS_CHANGE: 'Deixe uma breve descrição sobre os benefícios desta mudança',
  LEAVE_A_BRIEF_DESCRIPTION_OF_THIS_COST: 'Deixe uma breve descrição sobre este custo',
  LEAVE_BRIEF_DESCRIPTION_ABOUT_PROJECT: 'Deixe uma breve descrição sobre este projeto',
  LEAVE_MONITOR: 'Deixar de Monitorar',
  LEGAL_OBLIGATION: 'Obrigação legal',
  LEGEND: 'Legenda',
  LET_US_KNOW_WHAT_THE_END_DATE_WILL_BE: 'Informe qual será a data de término',
  LET_US_KNOW_WHAT_TIME_THE_CHANGE_SHOULD_END: 'Informe qual será o horário em que a mudança deve terminar',
  LEVEL: 'Nível',
  LGPD: 'LGPD',
  LICENSE: 'Licença',
  LIMIT_DATE_TIME_PAUSE_SLA: 'A data e hora informada é anterior à data e hora atual!',
  LIMIT_TIME_PAUSE_SLA: 'Criar tempo limite para reiniciar ticket em pausa?',
  LIMIT_TIME_PAUSE_SLA_REQUIRED: 'Obrigar a informar tempo limite de pausa',
  LINK: 'Link',
  LINK_ACTIVITY: 'Anexar atividade',
  LINK_REGISTRATION: 'Cadastro de Link',
  LIST: 'Lista',
  LIST_ALLOWANCE: 'Lista de tickets para abonar',
  LIST_BY_DEVICES: 'Listar Por Dispositivos',
  LIST_BY_GROUPS: 'Listar por grupos',
  LIST_OF_ASSOCIATED_ITEMS: 'List of Associated Items',
  LIST_OF_ATTENDANCES: 'Lista de Atendimentos',
  LIST_OF_COUNTRIES: 'Lista de países',
  LIST_OF_MONITORED_COMPANIES: 'Lista de Empresas Monitoradas',
  LIST_ONLY_CUSTOMER_TYPE_COMPANIES_IN_THE_COMPANY_FIELD_WHEN_OPENING_THE_TICKET: 'Listar somente empresas do tipo cliente no campo empresa na abertura do ticket',
  LOAD: 'Carregando, Por favor aguarde...',
  LOAD_MORE: 'Carregar mais',
  LOAD_VIDEO: 'Aguarde... Estamos gerando seu vídeo',
  LOAN: 'Empréstimo',
  LOCATION: 'Localização',
  LOCATION_DESCRIPTION: 'Cadastrar informações da localidade',
  LOCATION_LANGUAGE: 'pt-BR',
  LOCATION_TITLE: 'Localidade',
  LOG: 'Logs',
  LOG_FIM_DATE: 'Data fim log',
  LOG_INI_DATE: 'Data início log',
  LOGGED_IN_USER: 'Usuário Logado',
  LOGICAL_CONDITIONS: 'Condições Lógicas',
  LOGOFF: 'Sair',
  LOOK_FOR_SUPPLIERS_TICKETS_IN_RELATIONSHIP: 'Buscar tickets de fornecedores em Relacionamento',
  LOST: 'Perdeu',
  LOW: 'Baixo',
  MACADRESS: 'Mac',
  MAIL_DEFAULT: 'Se ativado a empresa sera vinculada a novos emails',
  MAIL_DEFAULT: 'Se ativado a empresa sera vinculada a novos emails',
  MAIL_HOST: 'Host',
  MAIL_HOST_VALUE: 'Host Value',
  MAIL_LIST: 'Lista de Email',
  MAIL_MAIL: 'Mail',
  MAIL_PASSWORD: 'Password',
  MAIL_POLICY: 'Configuração Email',
  MAIL_POP: 'Pop',
  MAIL_PORT: 'Port',
  MAIL_PORT_VALUE: 'Port Value',
  MAIL_SMTP: 'Smtp',
  MAIL_TLS: 'Tls',
  MAIL_TLS_VALUE: 'Tls Value',
  MAIN: 'Principal',
  MAIN_MODULES: 'Módulos Principais',
  MAIN_USER: 'Usuário Principal',
  MAINTAIN_ORIGINAL_CATALOG_OF_REQUESTS: 'Manter catálogo original das solicitações',
  MAKE_YOUR_REQUEST_HERE: 'Faça aqui sua requisição',
  MANAGE: 'Gestor',
  MANAGE_CHANGE_GROUPS: 'Gerencie os grupos de mudanças',
  MANAGEMENT_ALLOWANCE: 'SLA Gestão de Abono Ticket',
  MANAGEMENT_DASHBOARD: 'DashBoard Gerencial',
  MANAGEMENT_INFORMATION_TABLE: 'Tabela de Informações Gerenciais',
  MANAGEMENT_OF_DEMANDS_MOVING: 'GESTÃO DE DEMANDAS/MUDANÇAS',
  MANAGEMENT_TICKET: 'Gestão Ticket',
  MANAGEMENT_VIEW: 'Visão Gerencial',
  MANAGEMENT_VISION: 'Visão Gerencial',
  MANAGER: 'Gestor',
  MANAGER_COORDINATOR: 'Gerente/Coordenador',
  MANAGER_GMUD: 'Gestor de Mudança',
  MANAGER_LINE: 'Gerênciamento de filas',
  MANAGER_OF: 'Gestão de',
  MANUAL: 'Manual',
  MANUFACTURER: 'Fabricante',
  MAP: 'Mapa',
  MATURITY: 'Maturidade',
  MATURITY_LEVEL: 'Nível de maturidade',
  MATURITY_MUST_CONTAIN_AN_ACTIVITY_OR_TICKET_THAT_IS_100_PERCENT_COMPLETE: 'Maturidade precisar conter uma atividade ou ticket com 100% concluído!',
  MAXATTACH: 'O anexo não pode ser maior que 20MB',
  MAXIMUM: 'Máximo',
  MEASURE_EFFORT_IN_DAYS: 'Medir o esforço em dias?',
  MEASURE_IN_DAIYS: 'Medir em dias',
  MEDIUM: 'Médio',
  MEM: 'MEM',
  MEMBERS: 'Membros',
  MEMORY: 'Memória',
  MEMORY_MANUFACTURER: 'Fabricante de memória',
  MEMORY_PART_NUMBER: 'Número de peça da memória',
  MEMORY_SERIAL_NUMBER: 'Número de série da memória',
  MEMORY_USAGE: 'Uso de Memória(GB)',
  MENU: 'Menu',
  MESSAGE: 'Mensagem',
  MIGRATE_GM: 'Migrar GM',
  MIGRATE_GP: 'Migrar GP',
  MIGRATE_TICKET: 'Migrar Ticket',
  MIGRATE_TO_A_NEW_PROJECT: 'Migrar para um novo projeto',
  MIGRATE_TO_A_PROJECT_ACTIVITY: 'Migrar para atividade de um projeto',
  MIGRATE_TO_CRISIS: 'Migrar para Crise/Degração',
  MIGRATE_TO_GMUD: 'Migrar para GMUD',
  MIGRATE_TO_OS: 'Migrar para OS',
  MIGRATE_TO_PROBLEM: 'Migrar para Problema',
  MIGRATE_TO_PROJECT: 'Migrar para projeto',
  MILESTONE: 'Grupo de Atividades',
  MINIMUM: 'Mínimo',
  MINUTES: 'minutos',
  MINUTES_SUCCESSFULLY_RECORDED: 'Ata registrada com sucesso',
  MODE: 'Modo',
  MODEL: 'Modelo',
  MODIFICATION: 'Modificação',
  MODIFICATION_DATE: 'Data da modificação',
  MODIFICATION_USER: 'Usuário que modificou',
  MODULE: 'Módulo',
  MONETARY_TYPE: 'Tipo monetário',
  MONITOR: 'Monitoramento',
  MONITOR_DEVICE: 'Monitorar Novo Dispositivo',
  MONITORING: 'Monitorando',
  MONITORING_DEVICES_AND_LINKS: 'Monitoramento de Devices e Links',
  MONITORING_GROUP: 'Grupo de monitoramento',
  MONTH: 'Mês',
  MONTH_SELECTED: 'Mês selecionado',
  MONTH_VALUE: 'Valor do Mês',
  MONTHLY: 'Mensal',
  MONTHS: 'Meses',
  MORE: 'Mais',
  MORE_INFORMATION: 'Mais informações',
  MORE_THAN_10MB: 'ultrapassa os 10mb permitidos',
  MORE_THAN_15MB: 'ultrapassa os 15mb permitidos',
  MORE_THAN_40MB: 'ultrapassa os 40mb permitidos',
  MOVIMENT: 'Movimentação',
  MULTI_CALL: 'Multi Chamado',
  MULTI_TICKETS: 'Multi chamados',
  MUST_COMPLETE_ALL_ACTIVITIES: 'Necessário concluir todas atividades',
  MY_DEMANDS: 'Minhas Demandas',
  MY_EXPERIENCE: 'Minha Experiência',
  MY_TICKETS: 'Meus tickets',
  N_CONTRACT: 'N Contrato',
  N1_NAME: 'Nome do Nível N1',
  N1_VALUE: 'Valor do Nível N1',
  N2_NAME: 'Nome do Nível N2',
  N2_VALUE: 'Valor  do Nível N2',
  N3_NAME: 'Nome do Nível N3',
  N3_VALUE: 'Valor do Nível N3',
  N4_NAME: 'Nome do Nível N4',
  N4_VALUE: 'Valor do Nível N4',
  NAME: 'Nome',
  NAME_CONTRACT: 'Nome do Contrato',
  NAME_FORM: 'Nome do formulário',
  NAME_IS_IN_USE: 'Nome encontra-se em uso',
  NATURE: 'Natureza',
  NEEDS_APPROVAL: 'Necessita de Aprovação',
  NEGOTIABLE: 'Negociável',
  NEIGHBORHOOD: 'Bairro',
  NETWORK: 'Rede',
  NEVER: 'Nunca',
  NEW: 'Nova',
  NEW_ASSESSMENT: 'Novo Suporte',
  NEW_ASSIGNMENT: 'Adicionar Nova Tarefa ao Catálogo de Serviço',
  NEW_CALL: 'Novo Chamado',
  NEW_CALLS: 'Novos chamados',
  NEW_CATALOG: 'Novo Catálogo',
  NEW_CATEGORY: 'Nova Categoria',
  NEW_CATEGORY_ACTIVITY: 'Nova Categoria de Atividade',
  NEW_CHANGE: 'Cadastrar Nova Mudança',
  NEW_COMPANY: 'Nova Empresa',
  NEW_CONTRACT: 'Novo Contrato',
  NEW_DEPARTMENT: 'Novo Departamento',
  NEW_DEVICE: 'Novo Dispositivo',
  NEW_FORM: 'Novo formulário',
  NEW_GROUP: 'Novo Grupo',
  NEW_GROUP_OF: 'Novo Grupo de',
  NEW_LOCATION: 'Nova localidade',
  NEW_MASTER_PASSWORD: 'Crie a senha mestre do cofre',
  NEW_MILESTONE: 'Nova Categoria',
  NEW_OUTSOURCED: 'Novo Fornecedor',
  NEW_PASSWORD: 'Nova senha',
  NEW_PLURAL: 'Novos',
  NEW_POSITION: 'Novo cargo',
  NEW_PROJECT: 'Cadastrar Novo Projeto',
  NEW_REGISTRATION: 'Deseja realizar mais um cadastro?',
  NEW_REQUEST: 'Nova solicitação',
  NEW_REQUESTER: 'Novo Solicitante',
  NEW_RISK: 'Novo Risco',
  NEW_SERVICE_ORDER: 'Nova Ordem de Serviço',
  NEW_SOLUTION: 'Nova Solução',
  NEW_SPONSOR: 'Novo Responsável',
  NEW_STANDARD_ITEM: 'Novo item da norma',
  NEW_SUBDEPARTMENT: 'Novo Subdepartamento',
  NEW_TASK: 'NOVA TAREFA',
  NEW_TASK_TEMPLATE: 'Novo template de tarefa',
  NEW_TECH: 'Novo Técnico',
  NEW_TEST: 'Novo Teste',
  NEW_TICKET: 'Novo Ticket',
  NEW_TREATMENT: 'Novo tratamento',
  NEXT: 'Próximo',
  NEXT_EXPIRATION: 'Próximo vencimento',
  NEXT_PAGE: 'Próxima página',
  NO: 'Não',
  NO_ALERTS: 'Nenhum alerta até o momento',
  NO_ATTENDANCE: 'Nenhum atendimento até o momento',
  NO_COMMENTS: 'Não há Comentários Registrados',
  NO_CONTRACTS: 'Nenhum contrato até o momento',
  NO_DEPARTMENT_HEADS: 'Não há chefes de departamento disponíveis',
  NO_DEVICES_CAPACITY_PLAN: 'Nenhum dispositivos até o momento',
  NO_DEVICES_MONITOR: 'Nenhum dispositivos adicionado ao monitoramento até o momento',
  NO_OPTIONS: 'Sem Opções',
  NO_RECURRENCE: 'Sem recorrência',
  NO_REG_FIND: 'No Record Found',
  NO_REGISTERED_USER: 'Nenhum Registro Encontrado',
  NO_STARTED: 'Não iniciado',
  NO_TICKETS: 'Nenhum ticket até o momento',
  NON_CRITICAL: 'Não crítico',
  NONE: 'Nenhum',
  NONE_SO_FAR: 'Nenhum até o momento',
  NORMAL: 'Normal',
  NOT: 'Não',
  NOT_ALLOWED_EVOLVE_CALL_WITH_PENDING_APPROVAL: 'Não é permitido evoluir o chamado com Tarefas pendentes de aprovação!',
  NOT_APPROVED: 'Não Aprovado',
  NOT_ASSOCIATED_WITH_ACTIVITIES: 'Não associado a atividades',
  NOT_ATTRIBUTED: 'Não Atribuído',
  NOT_AUTHORIZED: 'Não Autorizado',
  NOT_CLOSE_TICKET: 'Não fechar o ticket',
  NOT_COMPLETED_PLURAL: 'Não concluídos',
  NOT_EXECUTED: 'Não executado',
  NOT_FINISHED: 'Não Finalizado',
  NOT_GROWTH: 'Não houve crescimento',
  NOT_MONITORING: 'Não monitorando',
  NOT_PERMANENT: 'Não permanente',
  NOT_REVISED: 'Não Revisado',
  NOTES_ACTIVITY: 'Anotações da Atividade',
  NOTES_TICKETS_AS_TABLE: 'Usar histórico do ticket como tabela',
  NOTHING_REGISTERED: 'Sem Registros',
  NOTIFICATION: 'Notificação',
  NOTIFICATION_CENTER: 'Central de Notificações',
  NOTIFICATION_CENTER_DESCRIPTION: 'Crie regras e politícas de envio de notificações',
  NOTIFICATION_CENTER_TAGS: 'Tags de notificações',
  NOTIFICATION_CENTER_TAGS_DESCRIPTION: 'Crie tags para serem usadas como variáveis no envio das notificações',
  NOTIFICATION_CONDITION: 'Condições de Notificação',
  NOTIFICATION_GROUP: 'Grupo de Notificações',
  NOTIFICATION_REGISTRATION: 'Cadastro de Notificações',
  NOTIFICATION_HISTORY: 'Histórico de notificação',
  NOTIFICATION_SUBJECT_HOLDER: 'Use as mesmas tags (variaveis) da mensagem aqui',
  NUMBER: 'Número',
  NUMBER_CALLS: 'Quantidade de Chamados',
  NUMBER_HOURS_PER_DAY: 'Quantidade de horas por dia',
  NUMBER_OF_ACTIVITIES: 'Número de atividades',
  NUMBER_OF_CALLS_PER_ANALYST: 'Quantidade de Chamados por Analista',
  NUMBER_OF_CLOSED_TICKETS_PER_TECHNICIAN_PER_DAY: 'Quantidade de tickets fechados por técnico por dia',
  NUMBER_OF_DEVICES: 'Quantidade de Dispositivos',
  NUMBER_OF_OPEN_ACTIVITIES: 'Número de atividades abertas',
  NUMBER_OF_OPEN_TASKS: 'NÚMERO DE TAREFAS ABERTAS',
  NUMBER_OF_SERVERS_MONITORED: 'Número de Servidores Monitorados',
  NUMBER_OF_SERVERS_MONITOREDDASH: 'itens monitorados',
  NUMBER_OF_TICKETS_PER_TECHNICIAN: 'Quantidade de tickets por técnico',
  NUMBERS_CORE: 'Núcleo dos números',
  NUMBERS_OF_LEVELS: 'Número de níveis de resolução',
  OBJECT_PRIORITIZATION: 'Priorização de Objetos',
  OBJECTIVE: 'Objetivo',
  OBJECTIVE_OS: 'Objetivo da Ordem de Serviço',
  OBLIGATION_REASON_CHANGE_TECH_GROUP_TECH: 'Obrigatoriedade de Motivo na troca de grupo técnico/técnico',
  OBSERVATION: 'Observação',
  OBSERVATIONS: 'Observações',
  OBSERVER: 'Observadores',
  OBSERVER_ADDED_SUCCESSFULLY: 'Observador adicionado com sucesso',
  of: 'of',
  OF: 'de',
  OF_THE_IT: 'da TI',
  OFF_THE_LIST: 'Fora da lista',
  OFFICE: 'Escritório',
  OK: 'Ok',
  OKR_ACTIVITIES: 'Atividades de OKR',
  OKR_EVOLUTION: 'Evolução OKR',
  OLA: 'OLA',
  OLD_PASSWORD: 'Senha Antiga',
  ON_ALERT: 'Em alerta',
  ON_LATE: 'Em atraso',
  ON_PROGRESS: 'Em andamento',
  ON_TIME: 'No prazo',
  ONLY_APPROVERS_CAN_ACCESS_THIS_OPTION: 'Apenas os Aprovadores podem acessar essa opção',
  ONLY_LINKS: 'Somente Links',
  ONLY_RESPONSIBLE_FOR_FILE_CAN_DELETE: 'Apenas o responsável  do arquivo pode excluir',
  ONLY_SEND: 'Apenas envio',
  ONLY_SERVERS: 'Somente Servers',
  ONLY_THE_CHANGE_MANAGER_CAN_EDIT: 'Apenas o Gestor da Mudança pode realizar edição',
  ONLY_THE_PERSON_IN_CHARGE_CAN_PERFORM_THIS_ACTION: 'Somente a pessoa responsável pode executar esta ação',
  ONLY_THE_PROJECT_MANAGER_CAN_PERFORM_THIS_ACTION: 'Apenas o Gestor do projeto pode executar essa ação',
  ONLY_THE_PROJECT_MANAGER_OR_ADMINISTRATOR_CAN_PERFORM_THIS_ACTION: 'Apenas o Gestor  do projeto ou Administrador  pode executar essa ação',
  OPEN: 'Abrir',
  OPEN_PROJECT: 'Projeto Aberto',
  OPEN_REQUESTS: 'Solicitações Abertas',
  OPEN_TICKET: 'Ticket Aberto',
  OPENED: 'Aberto',
  OPENING: 'Abertura',
  OPENING_DATA_FINAL: 'Data de Abertura - Final',
  OPENING_DATA_INITIAL: 'Data de Abertura - Inicial',
  OPENING_DATE: 'Data de abertura',
  OPENING_HOURS: 'Horários de funcionamento',
  OPENS: 'Abertos',
  OPERATING_TIME: 'Tempo de operação',
  OPERATION_NOT_PERFORMED: 'Operação não realizada.',
  OPERATION_NOT_PERFORMED_MAX_20_USERS: 'Operação não realizada, no máximo 20 usuários',
  OPERATION_NOT_PERFORMED_ONLY_THE_APPROVER_CAN_PERFORM_THIS_ACTION: 'Operação não realizada, somente o aprovador pode realizar esta ação',
  OPERATION_PERFORMED_SUCCESSFULLY: 'Operação realizada com sucesso',
  OPERATIONAL_SYSTEM: 'Sistema Operacional',
  OPERATOR: 'Operador',
  OPTIONAL: 'Opcional',
  OPTIONAL_KEYBOARD: 'Teclado Opcional',
  OR: 'ou',
  ORDER: 'Ordem',
  ORGANIZATION: 'Organização',
  ORIGIN: 'Origem',
  ORIGIN_FIM_DATE: 'Data fim origem',
  ORIGIN_ID: 'ID origem',
  ORIGIN_ID_REAL: 'ID origem real',
  ORIGIN_INI_DATE: 'Data início origem',
  ORIGIN_NAME: 'Nome origem',
  ORIGIN_TABLE: 'Tabela origem',
  ORIGIN_TYPE: 'Tipo origem',
  OS: 'OS',
  OS_RELEASE: 'Release do SO',
  OS_VERSION: 'Versão do SO',
  OTHER_PROJECT_COSTS: 'Outros custos do projeto',
  OUTAGE: 'Outage',
  OUTAGE_FIM_DATE: 'Data fim outage',
  OUTAGE_ID: 'ID Outage',
  OUTAGE_INI_DATE: 'Data início outage',
  OUTAGE_OUTSIDE_GMUD_RANGE: 'Outage fora do intervalo da GMUD',
  OUTAGES: 'Outages',
  OUTPUT: 'Saída',
  OUTSOURCED: 'Fornecedor',
  OUTSOURCED_COMPANY: 'Empresa Terceirizada',
  OUTSOURCED_DESCRIPTION: 'Pessoa contratada para prestar serviços mediante ao contratado.',
  OUTSOURCED_LIST: 'Lista de Fornecedores',
  OVERDUE_TASKS: 'Tarefas Atrasadas',
  OVERVIEW: 'Visão Geral',
  PACKAGE_NAME: 'Nome do pacote',
  PANEL_DASH: 'Painel Tático',
  PANEL_PMO: 'Painel PMO',
  PARAMETERS: 'Parâmetros',
  PARTIAL: 'Parcial',
  PARTICIPANT: 'Participante',
  PARTICIPANT_REQUEST: 'Solicitações de Participantes',
  PARTICIPANTS: 'Participantes',
  PASSIVATORS: 'Passivadores',
  PASSIVE: 'Passivo',
  PASSIVES: 'Passivos',
  PASSWORD: 'Senha',
  PASSWORD_ASSISTANT: 'Assistente de senhas',
  PASSWORD_ASSISTANT_NEW: 'Assistente de criação de senhas',
  PASSWORD_CHANGE_NEXT_LOGON: 'Trocar a senha no próximo login',
  PASSWORD_CURRENT: 'Senha atual',
  PASSWORD_GENERATE: 'Gerar senha',
  PASSWORD_QUESTION: 'Perguntas de segurança',
  PASSWORD_QUESTION_DESCRIPTION: 'Crie perguntas de segurança para recuperação de senha',
  PASSWORD_RULE_LABEL: 'Sua senha precisa ter ao menos',
  PASSWORD_RULE_LOWERCASE_CHAR: 'Um caractere minúsculo',
  PASSWORD_RULE_NUMBER: 'Um número',
  PASSWORD_RULE_SPECIAL_CHAR: 'Um caractere especial',
  PASSWORD_RULE_UPPERCASE_CHAR: 'Um caractere maiúsculo',
  PASSWORD_SAVE: 'Salvar senha',
  PASSWORD_SIMPLE: 'Senha simples',
  PASSWORD_TYPE: 'Tipo da senha',
  PASSWORD_VAULT: 'Cofre de Senha',
  PATRIMONY: 'Patrimônio',
  PAUSE_PROJECT: 'Pausar Projeto',
  PAUSED: 'Em pausa',
  PAUSED_TIME: 'Tempo em pausa',
  PENDING: 'Pendente',
  PENDING_ACTIVITIES: 'Atividades Pendentes',
  PENDING_APPROVALS: 'Aprovações pendentes',
  PENDING_TICKET_LIST: 'Lista de ticket pendentes',
  PEOPLE: 'Pessoas',
  PER: 'Por',
  PER_PANEL: 'Por painel',
  PER_TEAM: 'Por time',
  PERCENTAGE: 'Percentual',
  PERCENTAGE_INDEX: 'Índice %',
  PERCENTAGE_OF: 'Percentual de',
  PERCENTAGE_OF_MATURITY: 'Percentual de maturidade',
  PERFORM_AUDIT_OF_EQUIPMENT_FOR_REPLACEMENT_UNDER_WARRANTY: 'Realizar auditoria de equipamentos para reposição em garantia',
  PERFORM_TEST_REGISTERED_EMAIL: 'Realizar teste com e-mail cadastrado',
  PERFORMANCE_OF_PROJECTS: 'Nível de satisfação sobre entrega',
  PERFORMED: 'Executou',
  PERIOD: 'Período',
  PERIOD_AND_PRIORITY: 'PERÍODO E PRIORIDADE',
  PERMANENT: 'Permanente',
  PERSONAL_DATA: 'Dados pessoais',
  PERSPECTIVE: 'Perspectiva',
  PERSPECTIVE_OVERALL_STATUS: 'Status geral da perspectiva',
  PHASE_ORDER: 'Ordem da Etapa',
  PHASES: 'Etapas',
  PHONE: 'Telefone',
  PHONE_CALL: 'Telefone',
  PHYSICAL_SECURITY_AND_ENVIRONMENT: 'Segurança Física e Meio Ambiente',
  PHYSICS: 'Física',
  PLANNED: 'Planejado',
  PLANNED_ACTIVITIES: 'Atividades Planejadas',
  PLANNED_AVAILABILITY: 'Disponibilidade planejada',
  PLANNED_HOURS: 'Horas planejadas',
  PLANNED_MATURITY: 'Maturidade planejada',
  PLANNING: 'Planejamento',
  PLATFORM: 'Plataforma',
  PLEASE_PROVIDE_A_BRIEF_JUSTIFICATION_FOR_THIS_CHANGE: 'Deixe uma breve justificativa sobre esta mudança',
  PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA: 'Aguarde estamos processando os dados',
  POP_PORT: 'Porta Pop',
  POPULATE_MAP: 'Preencher Mapa',
  PORT: 'Porta',
  PORTAL_CUSTOMIZATION: 'Personalização do Portal',
  POSITION: 'Cargo',
  POSITIONS: 'Cargos',
  POST_IMPLEMENTATION_REVIEW: 'Revisão Pós-implementação',
  POSTAL_CODE: 'CEP',
  POSTED_HOURS: 'Horas lançadas',
  PREDECESSOR_ACTIVITIES: 'Atividades Antecessoras',
  PREDECESSOR_TASK: 'Tarefa predecessora',
  PREDECESSOR_TASKS: 'Tarefas predecessoras',
  PREDECESSORS: 'PREDECESSORES',
  PREOCUTIONS: 'Preocupações',
  PREVENTIVE: 'Preventivo',
  PREVIOUS: 'Anterior',
  PREVIOUS_PAGE: 'Página anterior',
  PRIMARY: 'Primário',
  PRINT: 'Imprimir',
  PRINT_CHART: 'Imprimir gráfico',
  PRINT_PMO: 'Imprimir PMO',
  PRIORITIES: 'Prioridades',
  PRIORITIZATION: {
    HIGH: 'Alto (a)',
    MEDIUM: 'Médio (a)',
    SMALL: 'Baixo (a)',
    VERY_HIGH: 'Muito Alto (a)',
    VERY_SMALL: 'Muito Baixo (a)'
  },
  PRIORITIZATION_MATRIX: 'Matriz de Priorização',
  PRIORITIZATION1: 'Priorização',
  PRIORITY: 'Prioridade',
  PRIORITY_CHART_TECH: 'Gráfico de prioridade por Técnicos',
  PRIORIZATION: 'PRIORIZAÇÃO',
  PRIVATE: 'Privativa',
  PROBABILITY: 'Probabilidade',
  PROBLEM: 'Problema',
  PROBLEM_IDENTIFICATION: 'Identificação do problema',
  PROBLEM_MANAGEMENT: 'Gestão de Problema',
  PROBLEM_NAME: 'Nome do problema',
  PROBLEMS: 'Problemas',
  PROBLEMS_SOLVED: 'Problemas resolvidos',
  PROCESS: 'Processo',
  PROCESSES: 'Processos',
  PROCESSES_IMPROVEMENT: 'Melhoria de Processos',
  PROCESSOR: 'Processador ',
  PRODUCT_CATALOG: 'Catalogo de Produtos',
  PRODUCTIVE: 'Produtivas',
  PRODUCTIVE_HOURS: 'Horas Produtivas',
  PROFILE: 'Perfil',
  PROFILE_PICTURE: 'Foto do Perfil',
  PROFILE_TYPE: 'Tipo de Perfil',
  PROGRESS: 'Em Progresso',
  PROGRESS_: 'Progresso',
  PROGRESS_STATUS: 'Status do andamento',
  PROJECT: 'Projeto',
  PROJECT_BENEFIT: 'Beneficios do projeto',
  PROJECT_BOOK: 'Book de projetos',
  PROJECT_CANCELED: 'Projeto Cancelado',
  PROJECT_CLOSE: 'Fechar projeto',
  PROJECT_CLOSED_IN_MONTH: 'Projetos encerrados',
  PROJECT_DELETED_SUCCESSFULLY: 'Projeto deletado com sucesso!',
  PROJECT_DETAIL: 'Detalhes do Projeto',
  PROJECT_ID: 'ID do Projeto',
  PROJECT_IN_ARREARS: 'projetos em atraso',
  PROJECT_IN_EXECUTION: 'Projetos em execução',
  PROJECT_LIST: 'Lista de Projetos',
  PROJECT_MANAGEMENT: 'Gestão de Projetos',
  PROJECT_MANAGEMENT_SPRINT: 'GESTÃO DE PROJETOS / SPRINT',
  PROJECT_MANAGER: 'Gestor do Projeto',
  PROJECT_NAME: 'Nome do projeto',
  PROJECT_PORTFOLIO: 'Portfólio de projetos',
  PROJECT_PRIORITIZATION: 'Priorização de Projetos',
  PROJECT_PRIORITIZATION_MAP: 'Mapa de Priorização de Projetos',
  PROJECT_PRIORITY: 'Prioridade do projeto',
  PROJECT_PROGRESS: 'ANDAMENTO DOS PROJETOS',
  PROJECT_STARTED_IN_MONTH: 'Projetos iniciados no mês',
  PROJECT_STATUS: {
    DOING: 'Fazendo',
    DONE: 'Finalizado',
    STOPPED: 'Impeditivo',
    TO_DO: 'A Fazer',
    VALIDATION: 'Validação'
  },
  PROJECT_TABLE: 'Tabela de Projetos',
  PROJECT_TEMPLATE: 'Template de Projetos',
  PROJECTENT: 'ENTREGA DE PROJETOS',
  PROJECTION_OF_ACTIVITIES: 'Projeção das Atividades',
  PROJECTS: 'Projetos',
  PROJECTS_AWAITING_EXECUTION: 'Projetos aguardando execução',
  PROJECTS_COMPLETED_LATE: 'Projetos concluídos com atraso',
  PROJECTS_GOAL: 'Objetivo do projeto',
  PROJECTS_STARTED_LATE: 'Projetos iniciados com atraso',
  PROMOTERS: 'Promotores',
  PROVIDER: 'Fornecedores',
  PROVIDER_CLIENT: 'Provedor Cliente',
  PROVIDER_TIVIT: 'Provedor Tivit',
  PROVIDER2: 'Fornecedor',
  PROVISIONED: 'Provisionado',
  PROVISIONED_ITEMS: 'Itens provisionados',
  PUNCTUATION: 'Pontuação',
  QUANTITIES: 'Quantidades',
  QUANTITY: 'Quantidade',
  QUANTITY_OF_TICKETS: 'QUANTITATIVO DE TICKETS',
  QUANTITY_PER_MONTH: 'Quantidade Por Mês',
  QUANTITY_PERIOD: 'Quantidade / Período',
  QUARTER: 'Trimestre',
  QUERY_MADE: 'Consulta realizada',
  QUERY_NOT_REALIZE: 'Consulta não realizada, valor ultrapassa 31 dias',
  QUESTION: 'Questão',
  QUOTE: 'Cotação',
  RANK: 'Rank',
  RANKING: 'Ranking',
  RANKING_TOP_10: 'RANKING - TOP 10',
  RAPID_ITEM_APPROVAL_AND_OR_EVALUATION: 'Aprovação e/ou avaliação rápida de item',
  RATING_OF_THIS_PROJECT: 'Avaliação deste projeto',
  RATION_CONSUMPTION_VALUE: 'Relação de valor e consumo',
  RDP: 'RDP',
  READ: 'Leitura',
  READJUSTMENT: 'Reajuste',
  READJUSTMENTS: 'Reajustes',
  REAL: 'Real',
  REAL_ID: 'ID Real',
  REALIZED: 'Realizado',
  REALIZED_COST: 'Custo Realizado',
  REALIZED_TIME: 'Tempo Realizado',
  REALIZED_VALUE: 'Valor realizado',
  REASON: 'Motivo',
  REASON_FOR_REJECTION: 'MOTIVO DA REJEIÇÃO',
  REASON_REQUIRED: 'Motivo é obrigatório',
  REASON_STATUS: 'Motivo do Status',
  REASSIGNMENT_COUNT: 'Contagem de reatribuições',
  RECEIVED: 'Recebeu',
  RECIPIENTS: 'Destinatários',
  RECLASSIFY_ATTENDANCE: 'Reclassificar Atendimento',
  RECORD: 'Gravar',
  RECORD_UNDERACTIVITY: 'Registrar sub-atividade',
  RECOVER: 'Recover',
  RECOVERY_PASSWORD: {
    ALTERNATIVE_EMAIL: 'Adicione um email alternativo',
    ALTERNATIVE_EMAIL_DESCRIPTION: 'Adicione um email alternativo ou selecione algumas perguntas e informe respostas para ajudar a proteger sua conta. Se você esquecer sua senha, poderá usar essas informações para redefini-la.',
    ALTERNATIVE_EMAIL_INFO: 'Adicionar um email alternativo é uma ótima maneira de proteger sua conta. Se você esquecer sua senha, poderá receber um link para redefini-la no seu email alternativo.',
    CHALLENGE_RECOVERY: 'Recuperar senha usando perguntas de segurança',
    CHOOSE_RECOVERY_METHOD: 'Escolha uma das formas de recuperação.',
    EMAIL_RECOVERY: 'Recuperar senha usando o e-mail ou e-mail alternativo',
    INCORRECT_ANSWERS: 'Respostas incorretas. Tente novamente.',
    NO_QUESTIONS_FOUND: 'Não há perguntas de segurança cadastradas.',
    PASSWORD_RECOVERY_QUESTIONS: 'Perguntas de recuperação de senha',
    PROTECT_ACCOUNT: 'Proteja sua conta',
    SECURITY_QUESTION: 'Pergunta de segurança',
    SELECT_SECURITY_QUESTIONS: 'Selecione perguntas de segurança',
    SELECT_SECURITY_QUESTIONS_INFO: 'Selecionar perguntas de segurança é outra maneira de proteger sua conta. Quando você esquecer sua senha, poderá responder às perguntas de segurança para redefini-la.',
    THANK_YOU: 'Obrigado por solicitar a recuperação de senha. Em breve, você receberá um email com um link seguro para redefinir sua senha.'
  },
  RECURRENCE: 'Recorrência',
  RECURRENCE_DATE: 'Data de recorrência',
  RECURRENCE_OUTAGES: 'Outages recorrentes',
  RECURRENCE_STOP: 'Excluir recorrência',
  RECURRENCE_TYPE: {
    DAILY: 'Diariamente',
    MONTHLY: 'Mensalmente',
    NO_RECURRENCE: 'Sem recorrência',
    QUARTERLY: 'Trimestral',
    SEMESTER: 'Semestral',
    WEEKLY: 'Semanalmente',
    YEARLY: 'Anualmente'
  },
  REDEFINE_PASS: 'Redefinir senha',
  REDO_RISK_ANALYSIS: 'Refazer Análise de Risco',
  REFERENCE_MONTH: 'Mês de Referência',
  REFERENCE_YEAR: 'Ano de Referência',
  REFRESH_PAGE: 'Atualizar Página',
  REFUSE: 'Recusar',
  REFUSED: 'Recusado',
  REGISTER: 'Cadastros',
  REGISTER_ACTIVITIES: 'Registrar Atividades',
  REGISTER_ACTIVITIES_GROUP: 'Cadastro de Grupos de Atividades',
  REGISTER_ACTIVITIES_RISK: 'Necessário cadastrar pelo menos uma (atividade e RollBack), e um  risco',
  REGISTER_BUILD: 'Cadastrar Build',
  REGISTER_BUSINESS: 'Cadastrar área de negocio',
  REGISTER_COMPANY_LOGO: 'Cadastrar logo da empresa',
  REGISTER_DATA: 'Dados do Cadastro',
  REGISTER_DELETED: 'Registro deletado',
  REGISTER_EVOLUTION: 'Registrar Evolução',
  REGISTER_HOLIDAYS: 'Cadastrar feriados',
  REGISTER_HOUR: 'Horário de Registro',
  REGISTER_ITEM_FOR_BUILD: 'Cadastrar Item para build',
  REGISTER_LOCATION_USER: 'No cadastro de usuários somente informar a localização',
  REGISTER_NEW_MILESTONE: 'Novo Grupo de Atividades',
  REGISTER_NEW_USER: 'Criar novo usuário',
  REGISTER_OS: 'Cadastrar OS',
  REGISTER_PHASE: 'Cadastrar Etapas',
  REGISTER_PHASES: 'Cadastro de Etapas',
  REGISTER_PROJECT: 'Cadastrar Projeto',
  REGISTER_PROJECT_OR_OS: 'Cadastrar Projeto ou OS',
  REGISTER_SAVE: 'Registro salvo com sucesso!',
  REGISTER_SERVICE_ORDER: 'Cadastrar Nova Ordem de Serviço',
  REGISTER_STATUS_ALLOWANCE: 'Cadastre os status de tickets para abonar',
  REGISTER_SUB_ACTIVITIES: 'Cadastrar Sub-Atividade',
  REGISTER_TAG: 'Cadastrar Tag',
  REGISTER_TECHS: 'Cadastro de Técnicos',
  REGISTER_THIRD_LEVEL_CATEGORY: 'Cadastrar Categoria de Terceiro Nível',
  REGISTERED_USER: 'Usuário registrado',
  REGISTRATION: 'Matrícula',
  REGISTRATION_DATE: 'Data do cadastro',
  REGISTRATIONS: 'Registros',
  REJECT: 'Rejeitar',
  REJECT_SELECTED: 'REJEITAR SELECIONADOS',
  REJECTED: 'Reprovado',
  REJECTED_THE_GMUD: 'Rejeitou a GMUD',
  RELATION_TICKETS: 'Tipo de relacionamento',
  RELATIONS: 'Relacionamentos',
  RELATIONS: 'Relacionamentos',
  RELATIONSHIP: 'Relacionamento',
  RELATIONSHIP_WITH: 'Relacionamento com',
  RELEASE_DATE_OF: 'Data de Lançamento',
  RELOAD_ALL: 'Recarregar todos',
  REMOTE: 'Remoto',
  REMOTE_ACCESS: 'Acesso remoto',
  REMOVE: 'Excluir',
  REOPEN: 'Reabrir',
  REOPEN_COUNT: 'Contagem de Reabertura',
  REOPEN_TICKET: 'Reabrir o Ticket',
  REOPENED: 'Reaberto',
  REOPENS_COUNT: 'Contagem de Reabertura',
  REPORT_AT_LEAST_ONE_EVENT_TO_THE_CAB: 'Informar ao menos um evento para o CAB',
  REPORT_EVIDENCE: 'Reportar Evidência',
  REPORT_OR_COST_PROVIDED_FOR_THE_REALIZATION_OF_THIS_PROJECT: 'Relatório ou custo fornecido para a realização deste projeto',
  REPORT_OR_NAME: 'Relatório ou nome',
  REPORT_OR_VALUE_CONSUMED: 'Informe o valor consumido',
  REPORT_THE_TIME_SPENT: 'Informe o tempo gasto',
  REPORT_WHAT_IT_WILL_BE_AT_THE_END_OF_THIS_MOVE: 'Relatar o que será no final deste movimento',
  REPORT_WHAT_IT_WILL_BE_AT_THE_START_DATE_OF_THIS_MOVE: 'Informe como será na data de início desta movimentação',
  REPORTING_CALL_ANALYSIS: 'Analise do relatório do chamados',
  REPORTS: 'Relatórios',
  REPORTS_AUDIT: 'Relatórios - Auditoria',
  REPORTS_CONTRACT_ANL: 'Relatórios - Visão geral do contrato - Analítico',
  REPORTS_CONTRACT_SINT: 'Relatórios - Visão geral do contrato - Sintético',
  REPORTS_DEVICE: 'Relatórios - Dispositivos',
  REPORTS_FOR_TECH: 'Relatórios por Técnicos',
  REPORTS_FOR_TECH_GROUP: 'Relatórios por Grupos Técnicos',
  REPORTS_TICKETS: 'Relatórios - Tickets',
  REPORTS_TICKETS_OS: 'Relatórios - Ticket e OS',
  REPRESENTATIVE: 'Representante',
  REPROCESS_SLA: 'Reprocessar SLA',
  REQUEST: 'Solicitação',
  REQUEST_ATTACHMENTS: 'Anexos da solicitação',
  REQUEST_DESCRIPTION: 'Descrição da solicitação',
  REQUEST_DETAIL: 'Detalhes da Solicitação',
  REQUEST_INFO: 'Informações da Solicitação',
  REQUEST_MANAGEMENT: 'Gestão de Solicitação',
  REQUEST_OPENED_SUCCESSFULLY: 'Solicitação Aberta com Sucesso',
  REQUEST_RESOLUTION: 'Resolução da Solicitação',
  REQUEST_TEMPLATE: 'Template de Solicitação',
  REQUEST_TIME: 'Tempo de Resposta',
  REQUEST_TITLE: 'Título da solicitação',
  REQUESTED_APPROVAL: 'Chamado em Aprovação',
  REQUESTER: 'Solicitante',
  REQUESTER_DESCRIPTION: 'Quem realiza as solicitações de algum problema interno/externo.',
  REQUESTER_GROUP: 'Grupo de Solicitantes',
  REQUESTER_LIST: 'Lista de Solicitantes',
  REQUESTER_SERVICE_DESK: 'Solicitações Service Desk',
  REQUESTER_TIME: 'Tempo de Solicitação',
  REQUESTER_TYPE: 'Tipo de Solicitações',
  REQUESTERS: 'Solicitações',
  REQUESTERS_LIST: 'Lista de Solicitações',
  REQUESTERSDASH: 'Categorização',
  REQUESTING_TECHNICIAN: 'Técnico Solicitante',
  REQUESTS: 'Solicitações',
  REQUESTS_TO_APPROVE: 'Solicitações para aprovar',
  REQUIRED: 'Obrigatório',
  REQUIRED_FIELDS: 'Campos obrigatórios',
  REQUIRES_APPROVAL_EVERYONE: 'Exige aprovação por todos',
  RESCHEDULE: 'Reagendar',
  RESET: 'Resetar',
  RESOLUTION: 'Resolução',
  RESOLUTION_CATEGORY: 'Categorias de Resolução',
  RESOLUTION_CODE: 'Códigos de Resolução',
  RESOLUTION_DESCRIPTION: 'Descrição da Resolução',
  RESOLUTION_TIME: 'Tempo de Resolução',
  RESOLVE: 'Resolver',
  RESOLVED: 'Resolvido',
  RESOLVED_AND_FINISHED: 'Resolvido e finalizado',
  RESPONSE_TIME: 'Tempo de Resposta',
  RESPONSIBLE: 'Responsável',
  RESPONSIBLE_DEPARTMENT: 'Departamento responsável',
  RESPONSIBLE_FOR_APPROVAL: '*Responsáveis Pela Aprovação',
  RESPONSIBLE_FOR_EXECUTION_THE_TASK: 'Responsável pela execução da tarefa',
  RESPONSIBLE_FOR_TRATMENT: 'Responsável Pelo Tratamento',
  RESPONSIBLE_GROUP: 'Grupo Responsável',
  RESPONSIBLE_NOT_REGISTERED: 'Resposável não cadastrado favor acessar configurações -> lista de empresas -> adicionar departamento -> escolha o chefe de departamento',
  RESPONSIBLES: 'Responsáveis',
  RESULT: 'Resultado',
  RESUME_CALL: 'Retome o chamado',
  RESUME_TICKET: 'Retomar Ticket',
  RETROACTIVE_BREAK: 'Pausa Retroativa',
  RETROSPECTIVE_START: 'Inicio Retroativo',
  RETURN: 'Retornar',
  RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_ATTACHMENT: 'Retornar o chamado para "Em Atendimento" caso o solicitante adicione um anexo',
  RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_CHAT: 'Retornar o chamado para "Em Atendimento" caso o solicitante interaja no chat',
  RETURN_PLAN: 'Plano de Retorno',
  REVERT: 'Reverter',
  REVIEW: 'Revisão',
  REVIEW_PERIOD: 'Período de revisão',
  REVISED: 'Revisado',
  REVISION_DATE: 'Data de revisão',
  REVISION_DAY: 'Dia de revisão',
  REVISION_REQUIRED: 'Revisão requirida',
  REVISIONS_HISTORY: 'Histórico de revisões',
  RISK: 'Risco',
  RISK_AND_COMPLIANCE_MANAGEMENT: 'Gestão de riscos e compliances',
  RISK_CHANGE: 'Riscos da Mudança',
  RISK_DESCRIPTION: 'Descrição do Risco',
  RISK_ENVIRONMENTAL: 'Risco de ambiente',
  RISK_IF_NOT_DONE: 'Risco se não for feito',
  RISK_M_ANALYSIS: 'Análise de gestão de riscos',
  RISK_MATRIX: 'Matriz de riscos',
  RISK_MONITORING: 'Acompanhamento dos Riscos',
  RISK_NAME: 'Nome do Risco',
  RISK_TYPE: 'Tipo de risco',
  RISKC: {
    AC: 'Baixo',
    CRITICAL: 'Médio',
    IN: 'Alto'
  },
  RISKS: 'Risco',
  RISKS_AND_COMPLIANCE: 'Risco e Compliance',
  RISKS_INVOLVED: 'Riscos Envolvidos',
  RISKS_MANAGEMENT: 'Gestão de Riscos',
  RISKS_MANAGEMENT_COMPLIANCE: 'Gestão de Riscos e Compliance',
  RISKS_NO_TEST: 'Não há testes realizados desta empresa',
  RISKSS: 'Riscos',
  RISKY: 'ARRISCADO',
  ROLE: 'Nível',
  ROLLBACK: 'Rollback',
  ROLLBACK_PENDING_ACTIVITIES: 'Existem atividades de rollback pendentes',
  ROOT_PREVIOUS_LEVEL: 'Raiz / Nível Anterior',
  ROW: 'Filas',
  ROW_LIST: 'Lista de Filas',
  ROW_SIZE_TABLE: 'Linhas por Página:',
  ROWS_PER_PAGE: 'Linhas por página',
  RULE: 'Regra',
  RULE_SERVICE_LEVEL: 'Regra de nível de serviço',
  RUN_REMOTE_ACCESS: 'Realizar Acesso Remoto',
  RUNNING: 'Em execução',
  RUNNING_SPRINTS: 'Sprints em Execução',
  RUNTIME: 'Tempo de execução',
  SAFETY_PILAR: 'Pilar de Segurança',
  SAMPLE_SERVICES: 'Serviços de Amostra',
  SAT: 'Nível de Satisfação sobre entrega',
  SATISFACTION: {
    BAD: 'Ruim',
    GOOD: 'Bom',
    GREAT: 'Ótimo',
    UNSATISFIED: 'Sem Satisfação'
  },
  SAVE: 'Salvar',
  SAVE_CHANGES: 'Salvar Alterações',
  SAVE_FORM: 'Salvar Formulário',
  SCALE: 'Escala',
  SCHEDULE: 'Pauta',
  SCHEDULING: 'Agendamento',
  SCHEDULING_SINGLE: 'Agendamento Único',
  SCM: 'SCM',
  SCOPE: 'Escopo do Projeto',
  SCRIPT: "Roteiro",
  SCRIPT: "Roteiro",
  SEARCH: 'Buscar',
  SEARCH_BY_CALL: 'Buscar por chamado',
  SEARCH_BY_CATEGORY: 'Buscar por categoria',
  SEARCH_BY_ID: 'Buscar pelo ID',
  SEARCH_FOR: 'Pesquisar',
  SEARCH_FOR_DEVICE_OR_SERVICES: 'Pesquisar por dispositivo ou serviços',
  SEARCH_GROUP: 'Procurar Grupo',
  SEARCH_THE_CATALOG: 'Buscar no catálogo',
  SECONDS: ' Segundos',
  SECTION_OF_THE_STANDARD: 'Seção da norma',
  SECURITY_HR: 'Segurança de RH',
  SECURITY_ORGANIZATION: 'Organização da Segurança',
  SECURITY_POLICY: 'Política de Segurança',
  SEE_ACTIVITIES: 'Ver atividades',
  SEE_FINALIZATION: 'Ver Finalização',
  SEE_PERCENTAGE_COLOR: 'Veja a porcentagem pelo status por cor',
  SEE_RPI: 'Ver RPI',
  SEGMENT: 'Segmento',
  SEGMENTATION_OF_THE_OPERATING_SYSTEM: 'SEGMENTAÇÃO DO SISTEMA OPERACIONAL',
  SELECT: 'Selecione',
  SELECT_A_CHANNEL: 'Selecione um canal',
  SELECT_A_CONTRACT: 'Selecione um contrato!',
  SELECT_A_DEPARTMENT: 'Selecione um departamento',
  SELECT_A_FILE: 'Selecione um arquivo',
  SELECT_A_MAIN_CATEGORY: 'Selecione uma categoria principal',
  SELECT_A_PROJECT_TYPE: 'Selecione um tipo de projeto',
  SELECT_A_RISK_TYPE: 'Selecione um tipo de risco',
  SELECT_A_SUBCATEGORY: 'Selecione uma subcategoria',
  SELECT_A_THIRD_LEVEL_CATEGORY: 'Selecione uma categoria de terceiro nível',
  SELECT_ACTIVITIES: 'Selecione as Atividades',
  SELECT_ACTIVITY: 'Selecione Atividade',
  SELECT_ALL: 'Selecionar Todos',
  SELECT_ALL_AREAS_THAT_ARE_INVOLVED_IN_THIS_CHANGE: 'Selecione todas as áreas que estão envolvidas nesta mudança',
  SELECT_ALL_THE_AREAS_THAT_ARE_INVOLVED_IN_THIS_MOVE: 'Selecione todas as áreas que estão envolvidas neste movimento',
  SELECT_ALL_THE_AREAS_THAT_WILL_BE_BENEFITED_BY_THIS_PROJECT: 'Selecione todas as áreas que serão beneficiadas por este projeto',
  SELECT_APPROVERS: 'Selecione os Aprovadores',
  SELECT_COMPANY: 'Selecione a Empresa',
  SELECT_COMPANY_CONTRACT: 'Selecione a empresa e o contrato para gerar o relatório',
  SELECT_COMPANY_STANDARD_REPORT: 'Selecione a empresa, norma e escolha as datas para gerar o relatorio',
  SELECT_DEVICE: 'Selecione o Dispositivo',
  SELECT_FILE: 'Selecione uma imagem',
  SELECT_FORM: 'Selecione Formulário',
  SELECT_FORM_ASSOCIATED_WITH_GMUD: 'Selecione o formulário associado a GMUD',
  SELECT_GMUD_APPROVER_GROUPS: 'Selecione os Grupos de Aprovadores da GMUD',
  SELECT_HOW_MANY_POINTS_WILL_BE_AWARDED_TO_THIS_PROJECT: 'Selecione quantos pontos serão atribuídos a este projeto',
  SELECT_IMAGE: 'Selecione uma imagem',
  SELECT_ONE_BELOW: 'Selecione um abaixo',
  SELECT_ONE_OF_THE_ACTIONS_BELOW_TO_DETERMINE_THE_TYPE_OF_CHANGE: 'Selecione uma das ações abaixo para determinar o tipo de mudança',
  SELECT_ONE_OF_THE_ACTIONS_BELOW_TO_DETERMINE_THE_TYPE_OF_PROJECT: 'Selecione uma das ações abaixo para determinar o tipo de projeto',
  SELECT_ONE_OR_MORE_TICKETS_TO_LINK_TO_GMUD: 'Selecione um ou mais tickets para vincular a GMUD',
  SELECT_OR_DEPARTMENT: 'Selecione o Departamento',
  SELECT_OR_FILTER_TYPE: 'Selecionar ou filtrar tipo',
  SELECT_PRIORIZATION_MAKING_CHANGE: 'Selecione a priorização para a realização desta mudança.',
  SELECT_PROJECT: 'Selecione o Projeto',
  SELECT_PROJECT_TO_SAVE_DUPLICATE_ACTIVITY: 'Selecione o projeto para salvar a atividade duplicada',
  SELECT_REQUESTER: 'Selecione solicitante',
  SELECT_SUPPLIER: 'Seleciona Fornecedor',
  SELECT_TEMPLATE: 'Selecione um Template',
  SELECT_THE_AREA_INVOLVED_IN_GMUD: 'Selecione a Area envolvida na GMUD',
  SELECT_THE_CI_CONFIGURATION_ITEM_INVOLVED_IN_THE_GMUD: 'Selecione o IC (Item de Configuração) envolvido na GMUD',
  SELECT_THE_COMPANY_INVOLVED_IN_THE_PROJECT: 'Selecione a empresa envolvida neste projeto',
  SELECT_THE_COMPANY_THAT_WILL_RECEIVE_THE_SERVICE: 'Selecione a empresa que receberá o atendimento',
  SELECT_THE_DAY: 'Selecione o dia',
  SELECT_THE_DEVICES_THAT_WILL_BE_ASSOCIATED_WITH_THIS_CHANGE: 'Selecione os dispositivos que serão associados a esta mudança',
  SELECT_THE_FILES: 'Selecione os arquivos',
  SELECT_THE_MANAGER_THAT_WILL_BE_ASSOCIATED_WITH_THIS_CHANGE: 'Selecione o Gestor que estará associado a esta mudança',
  SELECT_THE_MANAGER_WHO_WILL_BE_ASSOCIATED_WITH_THIS_PROJECT: 'Selecione o Gestor que estará associado a este projeto',
  SELECT_THE_PREDECESSOR_ACTIVITY_FOR_THIS_ACTIVITY: 'Selecione a atividade predecessora para esta atividade',
  SELECT_THE_PRIORITY_FOR_THE_REALIZATION_OF_THE_PROJECT: 'Selecione a prioridade para a realização do projeto',
  SELECT_THE_REQUESTER_OF_THIS_CHANGE: 'Selecione o solicitante desta mudança',
  SELECT_THE_RISK_FOR_THE_REALIZATION_OF_THE_PROJECT_LOW_MEDIUM_HIGH: 'Selecione o risco para a realização do projeto (Baixo, Médio, Alto)',
  SELECT_THE_SERVICES_THAT_WILL_BE_ASSOCIATED_WITH_THIS_CHANGE: 'Selecione os serviços que serão associados a esta mudança',
  SELECT_THE_TECHNICAL_GROUPS_INVOLVED_IN_THE_GMUD: 'Selecione os Grupos Técnicos envolvidos na GMUD',
  SELECT_THE_TECHNICIAN_WHO_WILL_BE_RESPONSIBLE_FOR_THE_APPROVAL: 'Selecione o Técnico que será responsável pela aprovação',
  SELECT_THE_TECHNICIANS_WHO_WILL_BE_INVOLVED_IN_THIS_CHANGE: 'Selecione os técnicos que estarão envolvidos nesta mudança.',
  SELECT_THE_TECHNICIANS_WHO_WILL_BE_INVOLVED_IN_THIS_PROJECT: 'Selecione os técnicos que estarão envolvidos neste projeto',
  SELECT_THE_TICKETS_THAT_YOU_WANT_TO_ASSOCIATE_WITH_THIS_MOVE: 'Selecione os tickets que deseja associar a este movimento',
  SELECT_THE_TICKETS_YOU_WANT_TO_ASSOCIATE_WITH_THIS_CHANGE: 'Selecione os tickets que deseja associar a esta mudança',
  SELECT_TICKET_TYPE: 'Selecione o tipo de ticket',
  SELECT_USER: 'Selecione o Usuário',
  SELECT_USERS_INVOLVED_IN_THIS_PROJECT: 'Selecione os usuários envolvidos neste projeto',
  SELECT_WEIGHT_ACTIVITY: 'Selecione o peso desta atividade.',
  SELECT_WHICH_CONTRACT_WILL_BE_ASSOCIATED_WITH_THIS_PROJECT: 'Selecione qual contrato será associado a este projeto',
  SELECTED_TECHNICAL_GROUP_WILL_APPROVE_THE_GMUD: 'Grupo Técnico selecionado aprovará a GMUD',
  SELF_SERVICE_PORTAL: 'Portal de Auto-Atendimento',
  SELFSERVICE: 'Autoatendimento',
  SEMESTER: 'Semestre',
  SEND: 'Enviar',
  SEND_EMAIL_TO_RESPONSIBLE_PERSONS: 'Enviar Email Para os Responsáveis',
  SEND_REASON_TO_CHAT: 'Enviar motivo para o chat',
  SEND_REPLY: 'Enviar Resposta',
  SEND_THE_ANSWERS: 'Deseja Enviar as Respostas ?',
  SERIAL: 'Série',
  SERIAL_NUMBER: 'Número de série',
  SERIAL_SO: 'Serial Controle licença',
  SERVER: 'Server',
  SERVER_NAME: 'Nome do Servidor',
  SERVER_REGISTRATION: 'Cadastro de Servidor',
  SERVERS: 'Servidores',
  SERVICE: 'Serviços',
  SERVICE_CATALOG: 'Subcategoria',
  SERVICE_CATALOG_DESCRIPTION: 'É um documento que consiste na descrição de serviços previamente analisados e aprovados.',
  SERVICE_DESK: 'Gestão de Service Desk',
  SERVICE_DISCOVERY: 'Descoberta de serviço',
  SERVICE_INFORMATION: 'Informações de Serviço',
  SERVICE_LIST: 'Lista de Serviços',
  SERVICE_NAME: 'Nome do Serviço',
  SERVICE_ORDER: 'Ordem de Serviço',
  SERVICE_ORDER_DETAIL: 'Detalhes da Ordem de Serviço',
  SERVICE_ORDER_LIST: 'Lista de Ordem de Serviço',
  SERVICE_ORDERS: 'Ordens de Serviço',
  SERVICE_PORTAL: {
    CATALOG: 'Catálogo',
    CATEGORY: 'Categoria',
    COMPANY: 'Empresa',
    FORM_FIELD_ACTION_BUTTON_BG_COLOR: 'Cor do Fundo',
    FORM_FIELD_ACTION_BUTTON_COLOR: 'Cor da Fonte',
    FORM_FIELD_BASE_BUTTON_BG_COLOR: 'Cor do Fundo',
    FORM_FIELD_BASE_BUTTON_COLOR: 'Cor da Fonte',
    FORM_FIELD_BRAND_LEFT: 'Distância da Esquerda',
    FORM_FIELD_BRAND_LOGO: 'Logo',
    FORM_FIELD_BRAND_PREVIEW: 'Visualização',
    FORM_FIELD_BRAND_TOP: 'Distância do Topo',
    FORM_FIELD_CATALOG_BG_COLOR: 'Cor do Fundo',
    FORM_FIELD_CATALOG_COLOR: 'Cor da Fonte',
    FORM_FIELD_CATALOG_TITLE_COLOR: 'Cor da Fonte do Título',
    FORM_FIELD_CATALOG_TITLE_SIZE: 'Tamanho da Fonte do Título (PX)',
    FORM_FIELD_CSS_TEXT: 'Texto CSS',
    FORM_FIELD_HEADER_BG_COLOR: 'Cor do Fundo',
    FORM_FIELD_HEADER_BG_IMG: 'Imagem do Fundo',
    FORM_FIELD_HEADER_BG_PREVIEW: 'Visualização',
    FORM_FIELD_HEADER_COLOR: 'Cor da Fonte',
    FORM_FIELD_HEADER_COLOR_HOVER: 'Cor da Fonte (hover)',
    FORM_FIELD_HOMEPAGE_BG_COLOR: 'Cor do Fundo',
    FORM_FIELD_HOMEPAGE_BG_IMG: 'Imagem do Fundo',
    FORM_FIELD_HOMEPAGE_BG_PREVIEW: 'Visualização',
    FORM_FIELD_HOMEPAGE_COLOR: 'Cor da Fonte',
    FORM_FIELD_IVI_CODE: 'Script IVI (Chat Bot)',
    FORM_FIELD_LAYOUT_FLUID: 'Layout Fluído',
    FORM_FIELD_SUBHEADER_BG_COLOR: 'Cor do Fundo',
    FORM_FIELD_SUBHEADER_COLOR: 'Cor da Fonte',
    FORM_GROUP_ACTION_BUTTON: 'Botões de Ação',
    FORM_GROUP_BASE_BUTTON: 'Botões',
    FORM_GROUP_BRAND: 'Logo',
    FORM_GROUP_CATALOG: 'Catálogo de Serviço',
    FORM_GROUP_CSS: 'CSS Customizado',
    FORM_GROUP_HEADER: 'Header',
    FORM_GROUP_HOMEPAGE: 'Página Principal',
    FORM_GROUP_LAYOUT: 'Layout',
    FORM_GROUP_MENU: 'Configurações de Menu',
    FORM_GROUP_SUBHEADER: 'Sub Header',
    FORM_GROUP_TAB: 'Configurações de Abas',
    IN_CATEGORIZATION: 'Em Categorização',
    MENU_APPROVAL_CENTER: 'Minhas aprovações',
    MENU_CHANGE_PASSWORD: 'Reset/Desbloqueio de Senha',
    MENU_EXCLAMATION: 'Para alterar o menu, salve a personalização.',
    MENU_FAQ: 'Perguntas Frequentes',
    MENU_HOME: 'Página Inicial',
    MENU_SEARCH: 'Pesquisa',
    MENU_TICKET: 'Minhas Solicitações',
    MOST_USED: 'Serviços mais usados',
    PAGE_FILTERS_NOT_FOUND: 'Não há registros para o filtro selecionado',
    PAGE_NOT_RESULTS_FOUND: 'Nenhum resultado encontrado.',
    PAGE_SETTINGS_NOT_FOUND: 'Configurações não encontrado.',
    PAGE_SETTINGS_NOT_FOUND_DETAILS: 'Desculpe, não foi possível encontrar os dados da empresa associada à sua conta. Por favor, entre em contato com o suporte para obter assistência.',
    SELECT: 'Selecionar',
    SELECT_CATEGORY: 'Selecione uma categoria',
    SELECT_SUBCATEGORY: 'Selecione uma subcategoria',
    SELECT_TASK: 'Selecione uma tarefa',
    SUBCATEGORY: 'Subcategoria',
    TAB_CONFIGURATION_ITEMS: 'Aba itens de configuração',
    TAB_DESIGNATION_HISTORY: 'Aba histórico de designação',
    TAB_DETAIL: 'Aba de Detalhes',
    TAB_RELATION_TYPE: 'Aba tipo de relacionamento',
    TAB_SLA_HISTORY: 'Aba histórico de sla',
    TASK: 'Tarefa'
  },
  SERVICE_PORTAL: 'Portal de Serviços',
  SERVICE_SLA: 'SLA de Atendimento',
  SERVICE_TABLE: 'Tabela de serviço',
  SERVICE_TECH: 'Técnico em Atendimento',
  SERVICE_TIME: 'Tempo de Atendimento',
  SERVICE_TIME_DETAIL: 'Detalhamento do Atendimento',
  SERVICES: 'Serviços',
  SETTINGS_NOT_FOUND: 'Configurações não encontradas',
  SEVERITY: 'Severidade',
  SHOW: 'Mostrar',
  SHOW_IC_COMPANY: 'Mostrar IC de todas as empresas no cadastro de dispositivos',
  SHOW_IC_COMPANY: 'Mostrar IC de todas as empresas no cadastro de dispositivos',
  SHOW_INTERATION_TICKET_CHANGE: 'Habilitar para cada Empresa a opção de alteração de status para "Em Atendimento" quando o solicitante interagir',
  SHOW_INTERATION_TICKET_CHANGE: 'Habilitar para cada Empresa a opção de alteração de status para "Em Atendimento" quando o solicitante interagir',
  SHOW_MORE: 'Exibir mais',
  SIDE_DISH: 'Acompanhamento',
  SIDE_DISH_CALENDARY: 'Calendário de Acompanhamento',
  SIGN_IN: 'Realize login',
  SITUATION: 'Situação',
  SIZE: 'Tamanho',
  SIZE_DISK: 'Tamanho do disco',
  SLA: 'SLA',
  SLA_ACTIVE_CATEGORIZATION: 'Sla ativo na categorização',
  SLA_AGREED: 'SLA acordado',
  SLA_ATTACH: "Adicionar SLA'S",
  SLA_ATTACH_WARNING: "Todos os SLA'S disponíveis já estão anexados a este contrato",
  SLA_BROKEN_OUT: 'SLA Estourado',
  SLA_CONDITION: 'Condições do SLA',
  SLA_DATE: 'Data do SLA',
  SLA_FIXED: 'Sla Fixo',
  SLA_GLOBAL_LIST_ERROR: 'Lista global de SLA de tipo início não encontrada',
  SLA_HOLIDAY: 'SLA Feriados',
  SLA_LIST: 'Lista de SLAs',
  SLA_MANAGEMENT: 'Gerenciamento SLA',
  SLA_NAME: 'Nome do SLA',
  SLA_PARAM_NOT_FOUND: 'Parâmetro de SLA não encontrado',
  SLA_PARAMS: 'SLA Parametrização',
  SLA_POLICY: 'Políticas de SLA',
  SLA_POLICY_DESCRIPTION: 'Um conjunto de condições que um ticket precisa satisfazer para que a política de SLA seja aplicada.',
  SLA_PROBLEM_STEP_ANALYSIS: 'SLA para análise de eficácia',
  SLA_PROBLEM_STEP_CORRECTION: 'SLA para correção',
  SLA_PROBLEM_STEP_RESOLUTION: 'SLA para resolução',
  SLA_PROBLEM_STEP_ROOT_CAUSE: 'SLA para análise de causa raiz',
  SLA_PROBLEM_STEPS: 'SLA para etapas do problema',
  SLA_PROGRESS_GRID: 'Andamento',
  SLA_REGISTRATION: 'Cadastro de SLA',
  SLA_REPROCESSING: 'Reprocessamento de SLA',
  SLA_REPROCESSING_DESCRIPTION: 'Solicite o reprocessamento de SLA',
  SLA_RULE_INCOMPATIBLE: 'Regra de SLA incompatível com a empresa',
  SLA_RULES_NO_MATCH: 'Não há regras de SLA compatíveis',
  SLA_STATUS: {
    DELAYED: 'Atrasado',
    IN_TIME: 'Em Tempo'
  },
  SLA_TIME: 'Tempo do SLA',
  SLA_TYPE: 'Tipo de SLA',
  SLA_WORKDAY: 'SLA Jornada de Trabalho',
  SMS: 'Email/Chamado',
  SMTP_PORT: 'Porta smtp',
  SMTP_SELECTED_SUCCESSFULLY: 'SMTP selecionado com sucesso',
  SNMP: 'SNMP',
  SNMP_LIST: 'Lista de SNMPs',
  SNMP_REGISTRATION: 'Cadastro de SNMP',
  SO_BUILD_NUMBER: 'Número de compilação da ordem de serviço',
  SO_CAPTION: 'Legenda da ordem de serviço',
  SO_VERSION: 'Versão da ordem de serviço',
  SOFTWARE: 'Software',
  SOFTWARE_ASSETS: 'Ativos de software',
  SOFTWARE_LIST: 'Lista de Programas',
  SOLICITACAO: 'Requisição',
  SOLICITANT: 'Solicitante',
  SOLICITATION_FORM: 'Formulário de Abertura de Solicitante',
  SOLICITATION_DATE: 'Data da Solicitação',
  SOLICITATION_HOUR: 'Hora da Solicitação',
  SOLUTION: 'Soluções',
  SOLUTION_DETAIL: 'Detalhes da Solução',
  SOLUTION_DEVELOPMENT: 'Desenvolvimento da solução',
  SOLUTION_TIME: 'Tempo de Solução',
  SOLVED: 'Resolvido',
  SPEAK: 'Falar',
  SPENT: 'Gasto',
  SPONSOR: 'Responsável',
  SPONSOR_DESCRIPTION: 'Cadastrar Responsável pelo Contrato',
  SPONSOR_LIST: 'Lista de responsáveis',
  SPRINT: 'Sprint',
  SPRINT_ACTIVITY_EFFORT: 'Esforço das atividades da Sprint',
  SPRINT_EFFORT: 'Esforço da Sprint',
  SPRINT_NAME: 'Nome da Sprint',
  SPRINTS_CREATED: 'Sprints Criadas',
  SRD: 'SRD',
  SSH: 'SSH',
  SSH_KEY: 'Chave SSH',
  STABLES: 'Estáveis',
  STAGE_NAME: 'Nome Etapa',
  STAGE_RESPONSIBLE: 'Resp. Etapa',
  STAGE_TOTAL_TIME: 'Tempo Total Etapa',
  STANDARD: 'Standard',
  STANDARD_DETAIL: 'Detalhe Norma',
  STANDARDS: 'Normas',
  START: 'Iniciar',
  START_: 'Início',
  START_ASSESSMENT: 'Iniciar Suporte',
  START_ATTENDANCE: 'Iniciar Atendimento',
  START_DATE: 'Data inicial',
  START_DATE_AND_TIME: 'Data e hora inicial',
  START_DATE_CANNOT_BE_GREATER_THAN_END_DATE: 'Data de início não pode ser maior que a de finalização',
  START_DATE_TIME: 'Data Ini. Atend.',
  START_DONE: 'Início Realizado',
  START_HOUR: 'Hora de Inicio',
  START_MODE: 'Modo de inicialização',
  START_NAME: 'Nome inicial',
  START_OF_ACTIVITY: 'Início da atividade',
  START_OF_THE_PROJECT: 'Inicio do projeto',
  START_RECURRENCE: 'Início Recorrência',
  START_WITH: 'Inicia com',
  STARTED: 'Iniciado',
  STATE: 'Estado',
  STATE_DEADLINE: 'Prazo Etapa',
  STATUS: 'Status',
  STATUS_ACTIVITY: 'Situação da Atividade',
  STATUS_CHANGE: 'Alteração de status',
  STATUS_CHART_BY_DEVICE: 'Gráfico de status por dispositivo',
  STATUS_CHART_BY_SERVICE: 'Gráfico de status por serviço',
  STATUS_CHART_BY_TECH: 'Gráfico de status por técnico',
  STATUS_LOG_ANALYZE: 'Analisando',
  STATUS_LOG_CAB: 'CAB',
  STATUS_LOG_CANCELLED: 'Cancelado',
  STATUS_LOG_CLOSED: 'Fechado',
  STATUS_LOG_CONCLUDED: 'Concluído',
  STATUS_LOG_CONCLUDED_CANCEL: 'Concluído Cancelado',
  STATUS_LOG_CONCLUDED_FAIL: 'Concluído com Falha',
  STATUS_LOG_CONCLUDED_PARTIAL: 'Concluído Parcialmente',
  STATUS_LOG_CORRETIVA: 'Corretiva',
  STATUS_LOG_CREATE: 'Criado',
  STATUS_LOG_EMERGENCIA: 'Emergência',
  STATUS_LOG_FINISH_CANCEL: 'Finalizado Cancelado ',
  STATUS_LOG_FINISH_FAIL: 'Finalizado com Falha',
  STATUS_LOG_FINISH_PARTIAL: 'Finalizado Parcialmente',
  STATUS_LOG_FINISHED: 'Finalizado',
  STATUS_LOG_INCIDENT: 'Incidente',
  STATUS_LOG_LIBERATION: 'Liberação',
  STATUS_LOG_NORMAL: 'Normal',
  STATUS_LOG_OPEN: 'Aberto',
  STATUS_LOG_PADRAO: 'Padrão',
  STATUS_LOG_PROBLEM: 'Problema',
  STATUS_LOG_PROGRESS: 'Em Progresso',
  STATUS_LOG_REJECTED: 'Rejeitado',
  STATUS_LOG_ROLLBACK: 'Rollback',
  STATUS_LOG_SOLICITATION: 'Solicitação',
  STATUS_NOT_ALLOWED: 'Status não permitido',
  STATUS_OF_PROJECT: 'Portfólio do projeto',
  STATUS_OF_TASK: 'Status da Tarefa',
  STATUS_OF_TICKET: 'Status do Ticket',
  STATUS_OF_WAITING: 'Status do Aguardando',
  STATUS_SHOULD_SELECT_VENDOR: 'Esse status deverá selecionar um fornecedor?',
  STATUS_SHOULD_SELECT_VENDOR_REQUIRED: 'Obrigar a informar um fornecedor',
  STATUS_TICKET_ALLOWANCE: 'SLA Status de ticket para abonar',
  STATUS_WILL_AUTOMATICALLY_CLOSE_TICKET: 'Esse status fechará o ticket automaticamente?',
  STATUS_WILL_STOP_SLA: 'Esse status parará o SLA?',
  STEP: 'Etapa',
  STOCK: 'Estoque',
  STOCK_APPROVER: 'Aprovador',
  STOCK_DEPARTAMENT: 'Departamento',
  STOCK_DT: 'Data',
  STOCK_IN: 'Entrada de Produto',
  STOCK_IN_HIST: 'Movimentação',
  STOCK_INF_IN: 'Estoque Entrada',
  STOCK_INF_OUT: 'Estoque Saída',
  STOCK_LISTING: 'Listagem de Estoque',
  STOCK_MANAGEMENT: 'Gestão de Estoque',
  STOCK_MODEL: 'Modelo',
  STOCK_NAME: 'Nome',
  STOCK_OUT: 'Saida de Produto',
  STOCK_POLICY: 'Cadastro de Estoque',
  STOCK_QTDINPUT: 'Estoque',
  STOCK_REASON: 'Motivo',
  STOCK_SPONSO: 'Responsável',
  STOCK_STOCK_ERROR: 'Valor ultrapassa o estoque atual',
  STOCK_TYPE: 'Tipo',
  STOCK_TYPE_INOUT: 'Movimentação',
  STOCK_USER: 'Reponsável',
  STOP_ATTENDANCE: 'Parar Atendimento',
  STOP_DATE: 'Data de Parada',
  STOP_MONITORING: 'Deixar de monitorar',
  STRATEGIC: 'Estratégico',
  STRATEGIC_ALIGMENT: 'Alinhamento Estratégico',
  STRATEGIC_PARTNERS: 'Parceiros Estratégicos',
  STREET: 'Rua',
  SUB_ACTIVITIES: 'Sub-Atividades',
  SUB_ACTIVITY_DELETED_SUCCESSFULLY: 'Sub-atividade excluida com sucesso',
  SUB_CALL: 'Sub Chamado',
  SUB_CALLS: 'Sub Chamados',
  SUB_CATEGORY: 'Sub Categoria',
  SUB_INSTITUTION: 'Sub instituição',
  SUBACTIVITIES: 'Sub Atividades',
  SUBCATEGORIES: 'Subcategorias',
  SUBCATEGORY: 'Subcategoria',
  SUBDEPARTMENT: 'Subdepartamento',
  SUBDEPARTMENT_BOSS: 'Chefe de Subdepartamento',
  SUBDEPARTMENT_DESCRIPTION: 'Subdepartamento designa-se a uma divisão dentro de um departamento.',
  SUBDEPARTMENT_LIST: 'Lista de Subdepartamentos',
  SUBJECT: 'Assunto',
  SUBJECT_OF_THE_CALL: 'Assunto do chamado',
  SUBJECTS_LIST: 'Lista de Assuntos',
  SUBJECTS_REGISTER: 'Cadastro de Assuntos',
  SUBMENU: 'Submenu',
  SUBMENU: 'Submenu',
  SUBSTITUTION: 'Substituição',
  SUCCESS: 'Sucesso',
  SUCCESS_PASSWORD: 'Senha criada com sucesso',
  SUCCESSFULLY_DELETED_RESULT: 'Resultado excluído com sucesso',
  SUCCESSFULLY_UPDATED: 'Atualizado com sucesso',
  SUGGESTION: 'Sugestão',
  SUGGESTIONS: 'Sugestões',
  SUPPORT_CONTACT: 'Horas diferenciadas de contrato',
  SURPRISE_WITH_THE_IMPROVEMENTS: 'Surpreender com as melhorias',
  SURVEILLANCE: 'Monitoramento',
  SWITCH_TO_DEFAULT: 'Alternar para padrão',
  SWITCH_TO_NEW_SCREEN: 'Alternar para tela nova',
  SWITCH_TO_SUBCATEGORY: 'Alternar para SubCategoria',
  SWITCH_TO_THIRD_LEVEL_CATEGORY: 'Alternar para categorias de terceiro nível',
  SYNTHETIC: 'Sintético',
  SYNTHETIC_DETAILS: 'Detalhes sintético',
  SYSTEM: 'Sistema',
  SYSTEM_DEVELOPMENT_AND_MAINTENANCE: 'Desenvolvimento e Manutenção de Sistema',
  SYSTEM_MANUAL: 'Manual do Sistema',
  SYSTEM_VERSION: 'Versão do Sistema',
  TABLE: 'Tabela',
  TAG: 'Tags',
  TAG_DESCRIPTION: 'As tags são palavras que servem como etiqueta e ajudam na hora de organizar informações.',
  TAG_LIST: 'Lista de Tags',
  TAG_NAME: 'Nome da Tag',
  TAG_TAGNAME_DOES_NOT_BELONG_TO_EVENT: 'Tag tagName não pertence ao evento',
  TARGET: 'Alvo',
  TASK: 'Categoria Terceiro Nível',
  TASK_CREATED_SUCCESSFULLY: 'Tarefa criada com sucesso',
  TASK_DESCRIPTION: 'Uma tarefa é uma atividade ou um trabalho que há a fazer.',
  TASK_FLOW: 'Fluxo de Tarefa',
  TASK_FORM: 'Formulário da Tarefa',
  TASK_LIST: 'Lista de Tarefas',
  TASK_LIST_TEMP: 'Lista de Templates',
  TASK_MANAGEMENT: 'Gestão de Tarefas',
  TASK_NAME: 'Nome da Tarefa',
  TASK_STATUS: {
    APPROVED: 'Aprovado',
    CANCELED: 'Cancelado',
    DONE: 'Concluido',
    IN_PROGRESS: 'Andamento',
    PENDING: 'Pendente Aprovação',
    REJECTED: 'Rejeitado',
    STARTED: 'Iniciado',
    WAITING: 'Aguardando'
  },
  TASK_TEMP: 'Formulário',
  TASK_TEMPLATE: 'Template de Tarefas',
  TASK_TOTALS: 'Tarefas total',
  TASK2: 'Tarefa',
  TASKS: 'Tarefas',
  TASKS_LIST: 'Lista de Tarefas',
  TASKS_PER_PERIOD: 'TAREFAS POR PERÍODO',
  TASKS_TO_APPROVE: 'Tarefas para aprovar',
  TEAM_ALLOCATION: 'Alocação de equipe',
  TEAM_AVERAGE: 'Média da equipe',
  TECH: 'Técnico',
  TECH_ATTACH: 'Anexar Técnico',
  TECH_ATTACH_WARNING: 'Todos os técnicos disponíveis já estão anexados a este contrato',
  TECH_BY_HOURS: 'Técnicos pelas horas',
  TECH_BY_VALUE: 'Técnicos pelo valor',
  TECH_DESCRIPTION: 'Quem realiza as solicitações de algum problema interno/externo.',
  TECH_DETAILS: 'Detalhes do técnico',
  TECH_GROUP: 'Grupo Técnico',
  TECH_GROUP_COMPANY: 'Grupo técnico da empresa',
  TECH_LIST: 'Lista de Técnicos',
  TECH_TABLE: 'Tabela de técnicos',
  TECH_TYPE: 'Tipo de Técnico',
  TECHIN: 'O técnico finalizou as atividades da OS, aguardando aprovação',
  TECHNICAL_ACCOUNT_MANAGER: 'Gerente de contas técnico',
  TECHNICAL_COST: 'Custo técnico',
  TECHNICAL_FORM: 'FORMULÁRIO TÉCNICO',
  TECHNICAL_GROUP_COUNT: 'Contagem de grupo técnico',
  TECHNICAL_GROUPS: 'Grupos Técnicos',
  TECHNICAL_NOTES: 'Notas Técnicas',
  TECHNICAL_REPORT: 'Relatório Técnico',
  TECHNICIAN_BY_VALUES_CONSUMED_TOTAL: 'Técnico por valores consumidos total',
  TECHNICIAN_PER_HOURS_CONSUMED_TOTAL: 'Técnico por  horas consumidas total',
  TECHS: 'Técnicos',
  TELEPHONE_EXTENSION: 'Ramal',
  TEMPERATURE: 'Temperatura',
  TEMPLATE: 'Template de solicitação',
  TEMPLATE_ADD_CATALOG: 'Adicionar template de solicitação  ',
  TEMPLATE_DESCRIPTION: 'Cadastre templates de solicitação',
  TEMPLATE_S_CATALOG: 'Selecione o template de solicitação ',
  TEMPLATE_SUBJECT_CLOSE: 'Selecione o Formulario de encerramento',
  TEMPLATE_SUBJECT_OPEN: 'Selecione o Formulario de abertura',
  TEMPLATES: 'Templates',
  TERM: 'Prazo',
  TERM_CHANGE: 'Termo de mudança',
  TERM_CLOSURE: 'Termo de encerramento',
  TERM_OPENING: 'Termo de abertura',
  TERMINAL: 'Terminal',
  TERMINATE_CONTRACT: 'Encerrar Contrato',
  TEST: 'Teste',
  TESTS: 'Testes',
  TESTS_PLAN: 'Plano de Testes',
  THE_JUSTIFICATION_FIELD_IS_REQUIRED_FOR_APPROVAL: 'O campo justificativa é necessário para aprovação',
  THE_JUSTIFICATION_FIELD_REQUIRED_FOR_APPROVAL: 'O campo justificativa é necessario para aprovação.',
  THE_TECHNICIAN_NOT_ALLOWED_CATEGORIZE_CALLS_GROUP_THAT_DOES_NOT_BELONG: 'O técnico não tem permissão para categorizar chamados, do grupo técnico que não pertence!',
  THE_TICKET_WILL_BE_CLOSED_DO_YOU_REALLY_WANT_TO_JOIN: 'O ticket será encerrado , deseja realmente associar?',
  THEME: 'Tema',
  THEME_REGISTER: 'Cadastro de Tema',
  THERE_ARE_NO_RECORDS_TO_DELETE: 'Não há registros para excluir',
  THERE_CHANGE_WORKFLOW: 'Existem mudanças em análise com esse workflow',
  THERE_IS_ONLY_ONE_RECORD_MODIFY_THE_CURRENT_ONE: 'Existe apenas um registro, modifique o atual',
  THERE_WAS_AN_ERROR_SAVING_THE_FIELDS: 'Ocorreu um erro ao salvar os campos',
  THIRD_LEVEL: 'Terceiro Nível',
  THIRD_LEVEL_CATEGORIES: 'Categorias de terceiro nível',
  THIRD_LEVEL_CATEGORY: 'Categoria de Terceiro Nível',
  THIRDED_LEVEL: '3° Nivel',
  THIS_ACTION_WILL_ERASE_ALL_PREVIOUS_RECORDS_TO_RE_ANALYZE_THE_RISKS_OF_THIS_CHANGE: 'Esta ação apagará todos os registros anteriores para analisar novamente os riscos desta mudança',
  THIS_ACTIVITY_HAS_BEEN_COMPLETED_IT_CANNOT_BE_EDITED: 'Esta atividade foi concluída, não pode ser editada',
  THIS_ACTIVITY_RECORDED_AUDITED: 'Esta atividade é gravada e auditada',
  THIS_GMUD_WILL_OBLIGATORILY_GO_TO_THE_CAB_FOR_APPROVAL: 'Essa GMUD irá obrigatoriamente para aprovação do CAB. Favor informar a data CAB.',
  THIS_MONTH: 'Este mês',
  THIS_TICKET_WAS_CREATED_USING_MULTI_TICKETS: 'Esse chamado foi criado usando o multi chamados',
  THREAT: 'Ameaça',
  THREE_HOURS: 'três horas',
  TICKET: 'Ticket',
  TICKET_ALLOWANCE: 'Tickets Abonados',
  TICKET_AUTOMATION_REGISTRATION: 'Cadastro de automação de ticket',
  TICKET_CONTAINS_RULES: 'Ticket possui regras',
  TICKET_DETAIL: 'Detalhar Ticket',
  TICKET_DETAILS: 'Detalhes do Ticket',
  TICKET_DOES_NOT_CONTAIN_RULES: 'Ticket não possui regras',
  TICKET_LIST: 'Lista de Tickets',
  TICKET_MANAGEMENT: 'Gestão de Tickets',
  TICKET_NOT_FOUND: 'Ticket não encontrado',
  TICKET_OPEN_TODAY: 'Ticket aberto hoje',
  TICKET_PRIORITY: {
    CRITICAL: 'Crítico',
    HIGH: 'Alta',
    MEDIUM: 'Média',
    SMALL: 'Baixa',
    URGENT: 'Urgente'
  },
  TICKET_RESOLUTION: {
    CANNOT_REPRODUCE: 'Não pode ser reproduzido',
    DONE: 'Pronto',
    DUPLICATE: 'Duplicado',
    FIXED: 'Concertado',
    INCOMPLETE: 'Incompleto',
    NOT_RESOLVED: 'Não Resolvidos',
    RESOLVED: 'Resolvidos',
    WONT_FIX: 'Não consertaremos'
  },
  TICKET_STATE_NOT_ALLOWED: 'Estado do Ticket não permitido para reprocessamento',
  TICKET_STATUS: {
    APPROVE: 'Aprovados',
    APPROVED: 'Aprovado',
    CANCELLED: 'Cancelado',
    CANCELLEDS: 'Cancelados',
    CLOSED: 'Fechados',
    CLOSEDDETAIL: 'Encerrar',
    FINALIZED: 'Finalizados',
    IN_APPROVAL: 'Em aprovação',
    IN_PROGRESS: 'Em Atendimento',
    NEW: 'Novos Tickets',
    OPEN: 'Aberto',
    OPENS: 'Abertos',
    REJECTED: 'Rejeitado',
    REOPEN: 'Reabertos',
    REOPENED: 'Reaberto',
    RESOLVED: 'Resolvido',
    SOLVED: 'Resolvidos',
    TASKS: 'Recorrentes',
    WAITING: 'Aguardando'
  },
  TICKET_SUBJECT: 'Assunto do ticket',
  TICKET_SUCCESSFULLY_EVALUATED: 'Chamado avaliado com sucesso',
  TICKET_TASKS: 'TAREFAS DO TICKET',
  TICKET_TELEMETRY: 'TELEMETRIA DE TICKETS',
  TICKET_TIME: 'Tempo Ticket',
  TICKET_TITLE: 'TÍTULO DO TICKET',
  TICKET_TYPE_LABEL: 'Tipo de Ticket',
  TICKET_TYPES: {
    CHANGE: 'Mudança',
    CRISIS: 'Crise',
    CRITICAL_INCIDENT: 'Incidente Crítico',
    EVENT: 'Evento',
    INCIDENT: 'Incidente',
    LIBERATION: 'Pedido de Liberação',
    PROBLEM: 'Problema',
    SOLICITATION: 'Solicitação'
  },
  TICKET_VIEW: 'Visualização de ticket',
  TICKET_WITHOUT_SLA: 'Tickets sem SLA',
  TICKET_WORKFLOW_OF_CHANGE: {
    ANALYZED: 'Analisado',
    DEVELOPED: 'Desenvolvido',
    FINALIZED: 'Finalizado',
    IN_ANALYSIS: 'Em Análise',
    IN_DEVELOPMENT: 'Em desenvolvimento',
    IN_TEST: 'Em teste',
    OPEN: 'Aberto',
    REJECTED: 'Rejeitado',
    TESTED_WITH_ERRORS: 'Testado com erros',
    TESTED_WITHOUT_ERRORS: 'Testado sem erros'
  },
  TICKET_HISTORY: 'Histórico do Ticket',
  TICKETCONHIST: 'Histórico Tickets',
  TICKETS: 'Tickets',
  TICKETS_CATEGORY_MONTH: 'Tickets por subcategoria no mês',
  TICKETS_STATUS: 'Estado dos tickets',
  TICKETS_TECH_MONTH: 'Tickets por técnicos no mês',
  TICKETS_TO_CATEGORIZE: 'Tickets para categorizar',
  TIME: 'Tempo',
  TIME_CONSUMED: 'Tempo Consumido',
  TIME_DETAIL: 'Detalhes de Tempo',
  TIME_SPENT: 'Tempo Gasto',
  TIME_TO_RESOLUTION: 'Tempo de Resolução',
  TIME_TO_RESPONSE: 'Tempo de Resposta',
  TIME_TOTAL_STEP: 'Tempo Total Etapa',
  TIMEOUT_PRICE: 'Valor Hora Excedida',
  TIMEZONE: 'Fuso horário',
  TIN: 'CNPJ',
  TITLE: 'Título',
  TO_CHARGE: 'Carregar',
  TO_DO: 'A Fazer',
  TO_MEET: 'Atender',
  TO_MONITOR: 'Monitorar',
  TO_REGISTER: 'Cadastrar',
  TO_REMOVE: 'Remover',
  TO_SEND: 'Enviar',
  TODAY: 'Hoje',
  TOGGLE_TO_GRAY: 'Alternar para cinza',
  TOKEN: 'Token',
  TOP_10_CATEGORIES: 'Top 10 Categorias',
  TOP_10_COMPANIES: 'Top 10 Empresas',
  TOP_10_CONSUMPTION: 'Top 10 Consumo',
  TOP_10_DEPARTAMENT: 'Top 10 Departamento',
  TOP_10_DEPARTAMENTS: 'Top 10 Departamentos',
  TOP_TEN_RECENTE: 'Top 10 mais recente',
  TOPIC: 'Tópico',
  TOPICS_REGISTER: 'Cadastro de Tópicos',
  TOTAL: 'Total',
  TOTAL_ACTIVITIES: 'Total de atividades',
  TOTAL_ACTIVITIES_COMPLETED: 'Total de atividades completadas',
  TOTAL_AMOUNT_HOURS: 'Total de horas',
  TOTAL_APPROVED: 'Total aprovado',
  TOTAL_APPROVERS: 'Total de Aprovadores',
  TOTAL_BY_COMPANY: 'Total por Empresa',
  TOTAL_CALLS: 'total de chamados',
  TOTAL_CALLS_CURRENT_MONTH: 'Total chamados (mês corrente)',
  TOTAL_CONTRACTS: 'Total de contratos',
  TOTAL_CORPORATE_OBJECTIVES: 'Total de objetivos corporativos',
  TOTAL_COST: 'Custo total',
  TOTAL_COST_CARE: 'Custo total do atendimento',
  TOTAL_FACE_TO_FACE_HOURS: 'Total de horas presenciais',
  TOTAL_GMUD: 'Total de GMUD (mês corrente)',
  TOTAL_GOALS: 'Total de objetivos',
  TOTAL_HOURS_CONSUMED: 'Total de Horas Consumidas',
  TOTAL_IC_MONITORING: 'Total de IC`s monitorando',
  TOTAL_KEYS: 'Total de chaves',
  TOTAL_MONTH: 'Total no mês',
  TOTAL_OUT_OF_DATE_TICKETS: 'Total de tickets fora do prazo',
  TOTAL_PEOPLE_INVOLVED: 'Total de pessoas envolvidas',
  TOTAL_REJECTED: 'Total rejeitado',
  TOTAL_REMOTE_HOURS: 'Total de horas remotas',
  TOTAL_SUM_MORE_100: 'A soma total dos itens ultrapassa 100%',
  TOTAL_TICKET: 'TOTAL DO TICKET',
  TOTAL_TICKET_TIME: 'Tempo Total do Ticket',
  TOTAL_TICKET_TIME_BETWEEN_OPENING_AND_RESOLUTION: 'Tempo total do ticket, é o tempo da abertura da solicitação a sua resolução',
  TOTAL_TICKETS: 'Total de tickets',
  TOTAL_TICKETS_CLOSED_PER_DAY: 'Total de Tickets Fechados por Dia',
  TOTAL_TICKETS_ON_TIME: 'Total de tickets no prazo',
  TOTAL_TIME_ATTENDANCE: 'Tempo Total Atend.',
  TOTAL_VALUE_IN_THE_PROJECT: 'Valor total do projeto',
  TOTAL_VALUE_PROJECT_IN_MONTH: 'Valor total dos projetos no mês',
  TOWER: 'Torre',
  TOWER: 'Torre',
  TRAINING: 'Treinamento',
  TRANSFERRED: 'Translado',
  TREATMENT: 'Tratamento',
  TREND: 'Tendência',
  TREND_ANALYSIS_BY_DEVICE: 'Análise de Tendência por Device',
  TREND_LINE: 'Linha de Tendência',
  TRIGGER: 'Acionar',
  TWO_HOURS: 'duas horas',
  TYPE: 'Tipo',
  TYPE_ACTION: 'Tipo de Ação',
  TYPE_CLIENT: 'Tipo de Cliente',
  TYPE_DEVICE: 'Tipo dispositivo',
  TYPE_DEVICE_ADD: 'Adicionar tipo  dispositivo',
  TYPE_DEVICE_EDIT: 'Editar tipo  dispositivo',
  TYPE_MASTER_PASSWORD: 'Insira a senha mestre do cofre',
  TYPE_OF_ACTIVITY: 'Tipo de Atividade',
  TYPE_OF_APPROVAL: 'Tipo de Aprovação',
  TYPE_OF_APPROVER: 'Tipo de Aprovador',
  TYPE_OF_CAPITAL: 'Tipo de Capital',
  TYPE_OF_DEMAND: 'Tipo de Demanda',
  TYPE_OF_MONITORING: 'Tipo de Monitoramento',
  TYPE_OF_SERVICE: 'Tipo de atendimento',
  TYPE_PASSWORD_ENTER: 'Digite a senha para entrar',
  TYPE_PROJECT: 'Tipo de Projeto',
  TYPE_PROJECT_ITEMS: [{
    color: 'info',
    icon: 'fas fa-compass',
    label: 'Estratégico',
    value: 1
  }, {
    color: 'purple',
    icon: 'fas fa-dumbbell',
    label: 'Melhoria',
    value: 2
  }, {
    color: 'primary',
    icon: 'fas fa-wrench',
    label: 'Corretiva',
    value: 3
  }, {
    color: 'success',
    icon: 'fas fa-chart-line',
    label: 'Evolutiva',
    value: 4
  }, {
    color: 'danger',
    icon: 'fas fa-gavel',
    label: 'Obrigação Legal',
    value: 5
  }],
  TYPE_PROVIDER: 'Tipo de Provedor',
  TYPE_RELATIONSHIP: 'Tipo de associação',
  TYPE_SLA_STATUS_CANCELED: 'Cancelado',
  TYPE_SLA_STATUS_CONCLUDED: 'Concluído',
  TYPE_SLA_STATUS_IN_PROGRESS: 'Em Andamento',
  TYPE_SLA_STATUS_PAUSE: 'Pausa',
  TYPE_SLA_STATUS_RESUMPTION: 'Retomada',
  TYPE_SLA_STATUS_START: 'Inicio',
  TYPE_TO_SEARCH_OR_CLICK_MAGNIFYING_GLASS: 'Digite para pesquisar ou clique na lupa para visualizar todos.',
  TYPE_YOUR_EMAIL: 'Informe seu email',
  TYPE_YOUT_EMAIL: 'Escreva seu email',
  UNABLE_TO_EVALUATE: 'Não foi possivel avaliar',
  UNAVAILABLE: 'Indisponível',
  UNFREEZE_SUCCESSFULLY_COMPLETED: 'Descongelamento realizado com sucesso',
  UNINFORMED: 'Não informado',
  UNPLANNED_ACTIVITIES: 'Atividades não planejadas',
  UNREGISTERED_USER: 'Usuário não cadastrado',
  UNRESOLVED_PROBLEMS: 'Problemas não resolvidos',
  UNSTABLE: 'Instável',
  UNTIL: 'até',
  UPDATE: 'Atualizar',
  UPDATE_AGENT: 'Atualizar Agente',
  UPDATE_ITEM_LIST: 'Lista de Itens da Atualização',
  UPDATE_TICKET_LIST_SCREEN: 'Atualização da tela listagem de tickets',
  UPDATE_TICKET_MANAGEMENT_VIEW_SCREEN: 'Atualização da tela Visão Gerencial de tickets',
  UPDATE_USER_INFO: 'Alterar Dados do Usuário',
  UPDATED: 'Atualizado',
  UPDATED_CALL: 'Atualizou o Chamado',
  UPDATED_FIELDS: 'Campos atualizados',
  UPDATES: 'Atualizações',
  UPLOAD_ERROR: 'Informe o contrato para upload!',
  UPLOAD_FILE: 'Upload de Arquivo',
  UPTIME: 'Uptime',
  URGENCY: 'Urgência',
  URL: 'URL',
  USE_APPROVAL_CENTER: 'Usar Central de Aprovações',
  USE_CUSTOM_FORM_FOR_CHANGE_MANAGEMENT_CAB: 'Usar formulário personalizado para CAB',
  USE_IN_GMUD: 'Usar na GMUD',
  USE_IN_TICKET: 'Usar no ticket',
  USE_MULTI_FILTER_TICKET: 'Usar Filtro Múltiplo na Tela de Tickets',
  USE_ONLY_GROUP_TECH_IN_CREATE_GMUD_ACTIVITY: 'Ao habilitar, mostrará somente grupos técnicos para selecionar na tela de criação de atividade de mudança.',
  USE_ONLY_GROUP_TECH_IN_CREATE_GMUD_ACTIVITY: 'Ao habilitar, mostrará somente grupos técnicos para selecionar na tela de criação de atividade de mudança.',
  USE_RESOLUTION_CATEGORY: 'Usar categorias de resolução',
  USE_SERVICE_PORTAL_ONLY: 'Somente Portal de Serviço',
  USE_STATUS_SUB_CALLS: 'Utilizar Status nos Sub chamados',
  USE_TECH_GROUP_IN_GMUD_ACTIVITY: 'Usar grupos técnicos nas atividades de Mudança',
  USED: 'Usado',
  USED_FOR: 'Usado para',
  USER: 'Usuário',
  USER_APPROVED: 'Usuário que aprovou',
  USER_CAD: 'Usuário que aprovou',
  USER_DOES_NOT_BELONG_TO_THE_TECHNICAL_GROUP: 'Usuário não pertence ao grupo técnico',
  USER_DOES_NOT_HAVE_PERMISSION: 'Usuário não tem permissão para executar essa ação',
  USER_HAS_ALREADY_APPROVED: 'Usuário já realizou aprovação',
  USER_HAS_ALREADY_MADE_APPROVAL: 'O usuário já fez a aprovação',
  USER_HAS_ALREADY_PERFORMED_THIS_ACTION: 'Usuário já executou essa ação',
  USER_IS_NOT_PART_OF_THE_ACTIVITY: 'Usuário não faz parte da atividade, contate o gestor do projeto',
  USER_IS_NOT_PART_OF_THE_APPROVAL_GROUP: 'Usuário não faz parte do grupo aprovador',
  USER_IS_NOT_PART_OF_THE_APPROVER_GROUP: 'O usuário não faz parte do grupo de aprovadores',
  USER_IS_NOT_RESPONSIBLE_REGISTERED_IN_THE_TASK: 'Usuário não é o responsável cadastrado na tarefa',
  USER_MANAGEMENT: 'Gestão de Usuários',
  USER_NOT_ALLOWED: 'Usuário não permitido',
  USER_NOT_ALLOWED_TO_CATEGORIZE: 'Usuário sem permissão para categorizar!',
  USER_REGISTERED: 'Usuário cadastrou',
  USER_REGISTRATION: 'Cadastro de Usuário',
  USER_VIP: 'Este é um usuário de categoria VIP',
  USER_WHO_REJECTED: 'Usuário que rejeitou',
  USER_WILL_BE_INVOLVED_IN_THE_PROJECT: 'O usuário _user_ será envolvido no projeto!',
  USERNAME: 'Nome de Usuário',
  USERS: 'Usuários',
  USERS_CREATION: 'Criação de Usuários',
  USERS_INVOLVED: 'Usuários Envolvidos',
  USERS_PERMISSIONS: 'Permissões de Usuários',
  UST: 'Unidade de Suporte Técnico',
  UTILIZATION: 'Utilização',
  VALIDATE_RELATIONSHIP: 'Validar Relacionamento',
  VALIDATE_RELATIONSHIP_BETWEEN_CI: 'Validate do relacionamento entre CI',
  VALIDATION: 'Validação',
  VALIDATIONS_APPROVALS: 'Validações/Aprovações',
  VALUE: 'Valor',
  VALUE_OF_BENEFITS: 'Valor do Benefício',
  VALUES: 'Valores',
  VARIATION: 'Variação',
  VENDOR: 'Marca',
  VERSION: 'Versão',
  VERY: 'Muito',
  VIEW: 'Visualizar',
  VIEW_ATTACHMENT: 'Visualizar Versão',
  VIEW_COSTS: 'Visualizar Custos',
  VIEW_FULL_SCREEN: 'Ver gráfico em tela cheia',
  VIEW_FULL_TICKET: 'Ver ticket completo',
  VIEW_LINE: 'Visualizar filas',
  VIEW_OF_SELECTED_CONTRACT: 'Visão do contrato selecionado',
  VIEW_PROJECTS: 'Visualizar Projetos',
  VIP: 'VIP',
  VIRTUAL_MEMORY: 'Memória virtual',
  VISUALIZATION_OF_TASKS: 'Visualização de tarefas',
  VISUALIZE: 'Visualizar',
  VOLUME_NAME: 'Nome do volume',
  VOLUME_SERIAL_NUMBER: 'Número de série do volume',
  VOTES: 'Votos',
  VULNERABILITY: 'Vulnerabilidade',
  VULNERABILITY_MANAGEMENT: 'Gestão de vulnerabilidade',
  WAIT_GENERATING_REPORT: 'Aguarde gerando relatório',
  WAITING: 'Aguardando',
  WAITING_FOR_PREDECESSORS: 'AGUARDANDO PREDECESSORES',
  WANT_TO_CLOSE_TICKET_WHEN_MIGRATING_TO_OS: 'Deseja fechar o ticket ao migrar para OS?',
  WARNING: 'Atenção',
  WARNING_DELETE_COMPANY: 'Atenção: Ao deletar a  EMPRESA, será excluido todos os itens relacionados a ela, deseja mesmo deletar?',
  WARNING_DELETE_ITEM: 'Deseja realmente excluir este item?',
  WARNING_DELETE_ITEM_SELECTED: 'Deseja realmente excluir  o  item selecionado ?',
  WARNING_DELETE_LOCATION: 'Atenção: Deseja mesmo deletar esta localidade?',
  WARNING_SUBACTIVITY_OUTSIDE_PROJECT_PERIOD: 'Sub-atividade fora do período do projeto',
  WARNING_WANT_CANCEL_PROJECT: 'PROJETO JÁ INICIADO, DESEJA CANCELAR O PROJETO?',
  WARNING_WANT_REMOVE_PROJECT: 'DESEJA REMOVER O PROJETO?',
  WARRANTY: 'Garantia',
  WEEK: 'Semana',
  WEEKLY: 'Semanalmente',
  WEIGHT: 'Peso',
  WEIGHT_CAB: 'Peso CAB',
  WELCOME_PAGE: {
    CONTRACTS_MANAGEMENT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    INFRATRUCTURE_MANAGEMENT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    RISKS_MANAGEMENT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    SERVICE_DESK: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    SUBTITLE: 'Escolha uma opção abaixo para começar',
    TITLE: 'Seja Bem-Vindo'
  },
  WHANT_TO_APPROVE: 'Você tem certeza da Aprovação ?',
  WHAT_YOUR_SUBCATEGORY: 'Qual sua subcategoria?',
  WHEN_PERFORMING_THE_LANGUAGE_CHANGE_YOU_WILL_BE_DISCONNECTED: 'Ao realizar a alteração da linguagem, você será desconectado e precisa realizar o login novamente.Você deseja continuar?',
  WHEN_YOU_CHANGE_YOUR_TIMEZONE_YOU_WILL_BE_DISCONNECTED: 'Ao realizar a alteração do seu timezone, você será desconectado e precisa realizar o login novamente.Você deseja continuar?',
  WHICH_KANBAN_COLUMN_SPRINT_WILL_STATUS_MATCH: 'Qual coluna do kanban na sprint este status corresponderá?',
  WHY: 'Por que',
  WISH: 'DESEJAR',
  WISHE: 'Desejo',
  WISHES: 'Desejos',
  WORKDAY: 'Jornada de trabalho',
  WORKED_TICKETS: 'Tickets Trabalhados',
  WORKFLOW: 'Fluxo de Trabalho',
  WORKFLOW_OF_CHANGE: 'Fluxo de mudanças',
  WRITE: 'Escrita',
  WRITE_STATUS_DESCRIPTION: 'Escreva a descrição do status',
  WRITE_STATUS_NAME: 'Escreva o nome do status',
  WRITE_THE_BENEFIT_HERE: 'Escreva o benefício aqui',
  WRITE_THE_DESCRIPTION_HERE: 'Escreva a descrição aqui',
  YEAR: 'Ano',
  YEAR_OPTIONS: ['2017', '2018', '2019', '2020', '2021', '2022', '2023'],
  YEAR_VALUE: 'Valor do Ano',
  YEARLY: 'Anual',
  YES: 'Sim',
  YOU_ARE_CALLER: 'Você é o solicitante do chamado!',
  YOU_ARE_NOT_PART_OF_THE_GROUP_REVISION: 'Você não faz parte do grupo com permissão para revisar esta GMUD',
  YOU_ARE_NOT_PART_OF_THE_TECH_GROUP: 'Você não faz parte do grupo técnico da atividade',
  YOU_DO_NOT_HAVE_ACCESS_TO_CREATE_USERS: 'Você não possui acesso a criação de usuários',
  YOU_HAVE_BEEN_ADDED_OBSERVER: 'Você foi incluído como observador!',
  YOUR_FAVORITES: 'Seus favoritos',
  YOUR_POINT_AVERAGE_NPS: 'Sua média DE PONTOS (NPS)',
  YOUR_REPORT_WILL_BE_GENERATED_IN_A_FEW_SECONDS: 'Seu relatório será gerado em alguns segundos'
}