import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import Dialog from '../../common/Dialog';
import AutomationMainForm from './AutomationMainForm';
import Options from './options';
import AutomationList from './AutomationList';
import { Constants } from '@data';
import { updateAutomationDTO } from './AutomationService';

@observer
export default class AutomationContainer extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      pageAction: 'list',
      values: {},
      valuesTable: [],
    };
    this.handlePage = this.handlePage.bind(this);
    this.setValues = this.setValues.bind(this);
    this.setValue = this.setValue.bind(this);
    this.onLoadDataTable = this.onLoadDataTable.bind(this);
    this.onSubmitDelete = this.onSubmitDelete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    let { language, spinner, toggleAlert } = this.store;

    spinner = true;
    this.action
      .execute('post', this.APIEndpoints.CONTRACT + '/list', 'contracts')
      .then(() => {
        spinner = false;
      })
      .catch(() => {
        spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });

    spinner = true;
    this.action
      .execute('get', this.APIEndpoints.CATEGORY, 'categories')
      .then(() => {
        spinner = false;
      })
      .catch(() => {
        spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });

    this.onLoadDataTable();
  }

  handlePage(pageAction, values = {}) {
    this.setState({ values });
    this.setState({ pageAction });
  }

  setValues(values) {
    this.setState({ values });
  }

  setValue(key, value) {
    this.state.values[key] = value;
    this.setState({ values: this.state.values });
  }

  onLoadDataTable() {
    let { language, spinner, toggleAlert } = this.store;
    spinner = true;
    this.action
      .execute('get', `${Constants.APIEndpoints.AUTOMATION}`, 'automations')
      .then((res) => {
        this.setState({ valuesTable: res.data });
        spinner = false;
      })
      .catch(() => {
        this.handlePage('list');
        spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });
  }

  onSubmitDelete(id_automation) {
    let { language, spinner, toggleAlert } = this.store;
    spinner = true;
    this.action
      .execute(
        'delete',
        `${Constants.APIEndpoints.AUTOMATION}/${id_automation}`,
        'automations',
        { id_automation },
        'fk_id_automation'
      )
      .then(() => {
        this.onLoadDataTable();
        toggleAlert(true, language.ALERT_DELETE_SUCCESS, 'success');
      })
      .catch(() => {
        this.handlePage('list');
        spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });
  }

  onSubmit(v) {
    let { language, spinner, toggleAlert } = this.store;
    const method = v.id_action_automation_detail ? 'put' : 'post';
    spinner = true;
    this.action
      .execute(
        method,
        Constants.APIEndpoints.AUTOMATION +
          (method === 'put' ? `/${v.id_automation}` : ''),
        'automations',
        method === 'put' ? updateAutomationDTO(v) : v,
        method === 'put' ? 'fk_id_automation' : ''
      )
      .then(() => {
        this.onLoadDataTable();
        this.handlePage('list');
        spinner = false;
        toggleAlert(
          true,
          method === 'post'
            ? language.ALERT_INSERT_SUCCESS
            : language.ALERT_UPDATE_SUCCESS,
          'success'
        );
      })
      .catch(() => {
        this.handlePage('list');
        spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });
  }

  render() {
    let {
      language,
      contracts,
      categories,
      catalogs,
      users,
      spinner,
      toggleAlert,
    } = this.store;

    let options = Options({
      language,
      contracts,
      categories,
      users,
      catalogs,
    });

    return (
      <Fragment>
        <Dialog
          open={
            this.state.pageAction === 'create' ||
            this.state.pageAction === 'edit'
          }
          title={language.TICKET_AUTOMATION_REGISTRATION}
          monitor_scroll
          maxWidth="xl"
        >
          <AutomationMainForm
            values={this.state.values}
            setValue={this.setValue}
            onSubmit={this.onSubmit}
            options={options}
            execute={this.action.execute}
            spinner={spinner}
            toggleAlert={toggleAlert}
            APIRoot={this.APIRoot}
            language={language}
            contracts={contracts}
          />
        </Dialog>

        <AutomationList
          language={language}
          handlePage={this.handlePage}
          onLoadDataTable={this.onLoadDataTable}
          onSubmitDelete={this.onSubmitDelete}
          valuesTable={this.state.valuesTable}
        />
      </Fragment>
    );
  }
}
