import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import SolutionForm from './SolutionForm';
import SolutionList from './SolutionList';
import SolutionDetail from './SolutionDetail';
import DownloadFiles from '../../util/DownloadFiles';
import { testRoute } from '@common/Func';
import Animate from '@common/Animate';
@observer
export default class SolutionContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      files: [],

      fliesnk: [],
      fileError: ''
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.ondonwFile = this.ondonwFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }
  onDrop(g) {
    let files_ = [...this.state.fliesnk];
    Array.from(g).forEach((f) => {
      files_.push(f);
    });
    this.setState({ files: files_, fliesnk: files_ });
  }
  removeFile(item) {
    var filesArray = [];
    Array.from(this.state.fliesnk).forEach((f) => {
      if (f.name != item.name) {
        filesArray.push(f);
      }
    });
    this.state.fliesnk = filesArray;
    this.setState({ files: filesArray });

    if (item.id_attach > 0) {
      this.action
        .execute(
          'delete',
          this.APIEndpoints.SOLUTION + '/file/' + item.id_attach,
          '',
          null,
          'id_solution',
          null
        )
        .then((g) => {});
    }
  }

  ondonwFile(id, file) {
    DownloadFiles(
      this.APIEndpoints.SOLUTION + '/download/' + id + '&' + file,
      file
    );
  }

  onFormSubmit(values) {
    let filesSend = [];
    let cont = 0;
    let fi = this.state.files.filter((c) => c.size > 0);

    if (fi.length > 0) {
      let total = Array.from(fi).length;
      Array.from(fi).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
        promise.then((result) => {
          if (file.size >= 10000000) {
            this.store.toggleAlert(
              true,
              `${this.store.language.FILE} ${file.name} ${this.store.language.MORE_THAN_10MB}`,
              'error'
            );
            this.setState({ sendFile: false });
            filesSend = [];
            this.state.files = [];
            cont = 0;
          } else {
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;

            if (total === cont) {
              values.file = filesSend;
              values.type_origin = 1;
              this.action
                .execute('post', this.APIEndpoints.SOLUTION, 'solutions', values)
                .then((res) => {
                  this.store.solutions = res.data;
                  this.store.toggleAlert(
                    true,
                    this.store.language.ALERT_INSERT_SUCCESS,
                    'success'
                  );
                  setTimeout(
                    function () {
                      this.redirect('/dash/solution');
                    }.bind(this),
                    1000
                  );
                });
            }
          }
        });
      });
    } else {
      values.files = [];
      values.type_origin = 1;
      this.action
        .execute('post', this.APIEndpoints.SOLUTION, 'solutions', values, null)
        .then((g) => {
          this.redirect('/dash/solution');
        });
    }
  }

  render() {
    let {
      categories,
      language,
      solutions,
      type_solution,
      companies,
      departments,
    } = this.store;
    let { page } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;
    const PAGE = history.location.pathname;
    var values;
    if (testRoute(['/dash/solution/view', '/dash/solution/edit'], PAGE)) {
      values = history.location.state;
      if (!values) {
        history.goBack();
        return null;
      }
    }

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        {testRoute(
          [
            '/dash/solution',
            '/dash/gsd',
            '/dash/ticketmanagement',
            '/dash/portal',
            '/dash',
          ],
          PAGE
        ) && (
          <Animate>
            <SolutionList
              ondonwFile={this.ondonwFile}
              language={language}
              redirect={redirect}
              solutions={solutions}
              company={this.props.company || ''}
              subcategory={this.props.subcategory || ''}
            />
          </Animate>
        )}

        {testRoute('/dash/solution/view', PAGE) && (
          <Animate>
            <SolutionDetail
              ondonwFile={this.ondonwFile}
              values={values}
              ticket=""
              files={this.state.files}
              language={language}
              history={history}
              redirect={redirect}
            />
          </Animate>
        )}

        {testRoute(['/dash/solution/new', '/dash/solution/edit'], PAGE) && (
          <Animate>
            <SolutionForm
              ondonwFile={this.ondonwFile}
              files={this.state.files}
              removeFile={this.removeFile}
              onDrop={this.onDrop}
              categories={categories}
              values={values}
              language={language}
              onFormSubmit={this.onFormSubmit}
              type_solution={type_solution}
              companies={companies}
              departments={departments}
              redirect={redirect}
            />
          </Animate>
        )}
      </CommonPage>
    );
  }
}
