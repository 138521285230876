import React, { Component, Fragment } from 'react';

import { Chip, Divider, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';

import AppBar from '@common/AppBar2';
import CommonForm2 from '@common/CommonForm2';
import Constants from '@data/Constants';
import CommonDelete from '../../common/CommonDelete';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import Actions from '../../data/Actions';
import { ClosingGMUD } from './components/ClosingGMUD';
import { ClosingTicket } from './components/ClosingTicket';

export const formatSelectOptions = (
  options,
  attributeName,
  attributeValue,
  convertValueToNumber = false,
) => {
  const normalizedOptions = options.map((option) => ({
    value: parseInt(option[attributeValue]),
    label: option[attributeName],
  }));

  return normalizedOptions;
};

@observer
export default class GeneralParametersConfig extends Component {
  constructor() {
    super();
    this.state = {};
    this.action = new Actions();
    this.onCreateCompanyCrisis = this.onCreateCompanyCrisis.bind(this);
    this.onDeleteCompanyCrisis = this.onDeleteCompanyCrisis.bind(this);
    this.initializeCompanyCrisis = this.initializeCompanyCrisis.bind(this);
  }

  componentDidMount() {
    this.initializeCompanyCrisis();

    this.action
      .execute('get', Constants.APIEndpoints.TIMEZONE, '')
      .then((response) => {
        const { data } = response;
        this.setState({
          timezoneOptions: formatSelectOptions(data, 'name', 'id_timezone'),
        });
      });

    this.action
      .execute('post', Constants.APIEndpoints.GROUP + '/filter', '')
      .then((response) => {
        const { data } = response;
        this.setState({
          groupFALEOptions: formatSelectOptions(data, 'name', 'id_group_users'),
        });
      });
  }

  onDeleteCompanyCrisis() {
    const companyToDelete = this.state.companyCrisisDelete.id_company;
    this.action
      .execute(
        'delete',
        Constants.APIEndpoints.GENERAL_PARAMETERS +
          '/company/crisis/' +
          companyToDelete,
      )
      .then(() => {
        this.setState({
          companiesCrisis: this.state.companiesCrisis.filter(
            (c) => c.id_company !== companyToDelete,
          ),
          companyCrisisDelete: undefined,
        });
      });
  }

  onCreateCompanyCrisis(data) {
    this.action
      .execute(
        'post',
        Constants.APIEndpoints.GENERAL_PARAMETERS + '/company/crisis',
        '',
        data,
      )
      .then(() => {
        this.initializeCompanyCrisis();
      });
  }

  initializeCompanyCrisis() {
    this.action
      .execute(
        'get',
        Constants.APIEndpoints.GENERAL_PARAMETERS + '/company/crisis',
        '',
      )
      .then((response) => {
        const { data } = response;
        this.setState({ companiesCrisis: data });
      });
  }

  render() {
    let { language, redirect, channels } = this.props;
    let { timezoneOptions, groupFALEOptions } = this.state;
    let values = [];
    let valuesvisao = [];
    let valuesDays = [];
    let valuesCompany = [];
    let valueChannel = [];
    let valuesGmudOpenProblemTicket = [];
    let valuesColors = [];

    let valueGroupDefaultFALE = 0;
    if (this.props.general_parameters) {
      var general_parameters = {
        ...this.props.general_parameters,
        ALLOW_EDITING_OF_TECH_GMUD_AFTER_APPROVAL: this.props.general_parameters
          ? this.props.general_parameters
              .allow_editing_of_tech_gmud_after_approval
          : false,
        CALL_OPENING: this.props.general_parameters
          ? this.props.general_parameters.call_opening
          : false,
        MICROSOFT_LOGIN: this.props.general_parameters
          ? this.props.general_parameters.microsoft_login
          : false,
        MULT_CATALOG: this.props.general_parameters
          ? this.props.general_parameters.mult_catalog
          : false,
        GOOGLE_LOGIN: this.props.general_parameters
          ? this.props.general_parameters.google_login
          : false,
        SEND_MAIL_OBSERVERS: this.props.general_parameters
          ? this.props.general_parameters.send_mail_observers
          : false,
        CAUSE_PROBLEM: this.props.general_parameters
          ? this.props.general_parameters.cause_problem
          : false,
        EMAIL_RESPONSE_CHAT: this.props.general_parameters
          ? this.props.general_parameters.email_response_chat
          : false,
        SEND_EMAIL_GROUP_TECH: this.props.general_parameters
          ? this.props.general_parameters.send_email_group_tech
          : false,
        TIME_REFRESH: this.props.general_parameters
          ? this.props.general_parameters.TIME_REFRESH
          : '',
        TIME_REFRESH_VISAO: this.props.general_parameters
          ? this.props.general_parameters.TIME_REFRESH_VISAO
          : '',
        APPROVE_REQUEST_CHIEF: this.props.general_parameters
          ? this.props.general_parameters.approve_request_chief
          : false,
        READJUSTMENT_CONTRACT:
          this.props.general_parameters.readjustment_contract,
        REQUEST_SOLICITANT_SELECT: this.props.general_parameters
          ? this.props.general_parameters.request_solicitant_select
          : false,
        AVALIATION_TICKETS: this.props.general_parameters
          ? this.props.general_parameters.avaliation_tickets
          : false,
        TICKET_SIMPLIFIED: this.props.general_parameters
          ? this.props.general_parameters.ticket_simplified
          : false,
        ASSOCIATE_REMOTE_ACCESS_TICKET: this.props.general_parameters
          ? this.props.general_parameters.associate_remote_access_ticket
          : false,
        ADMIN_ATTENDANCE: this.props.general_parameters
          ? this.props.general_parameters.admin_attendance
          : false,
        LINKING_MULTI_COMPANY_TICKETS: this.props.general_parameters
          ? this.props.general_parameters.linking_multi_company_tickets
          : false,
        ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS: this.props
          .general_parameters
          ? this.props.general_parameters
              .associated_tickets_must_track_main_ticket_status
          : false,
        COUNT_HOURS_TECH_CLIENT: this.props.general_parameters
          ? this.props.general_parameters.count_hours_tech_client
          : false,
        REQUEST_BY_PORTAL: this.props.general_parameters
          ? this.props.general_parameters.request_by_portal
          : false,
        TECHNICAL_EVALUATION_REGISTRATION: this.props.general_parameters
          ? this.props.general_parameters.technical_evaluation_registration
          : false,

        APPROVE_GROUP_USERS: this.props.general_parameters
          ? this.props.general_parameters.approve_group_users
          : false,

        EVALUATE_TICKET: this.props.general_parameters
          ? this.props.general_parameters.evaluate_ticket
          : false,

        CHANGE_GROUP_TECH_APPROVE: this.props.general_parameters
          ? this.props.general_parameters.change_group_tech_approve
          : false,
        SUBTICKET_CLOSED_VERIFY: this.props.general_parameters
          ? this.props.general_parameters.subticket_closed_verify
          : false,

        ADMIN_HISTORIC_REQUEST: this.props.general_parameters
          ? this.props.general_parameters.admin_historic_request
          : false,

        SEND_EMAIL_CATEGORIZATION: this.props.general_parameters
          ? this.props.general_parameters.send_email_categorization
          : false,
        ALLOW_EDIT_FORM_CATEGORIZATION: this.props.general_parameters
          ? this.props.general_parameters.allow_edit_form_categorization
          : false,
        SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION: this.props.general_parameters
          ? this.props.general_parameters
              .show_request_cancelled_in_categorization
          : false,
        SEND_EMAIL_CREATE_TASK_IN_TICKET: this.props.general_parameters
          ? this.props.general_parameters.send_email_create_task_in_ticket
          : false,
        COUNT_TICKET_BROKEN_ONCE: this.props.general_parameters
          ? this.props.general_parameters.count_ticket_broken_once
          : false,

        ENABLE_MULTIPLE_SELECTION: this.props.general_parameters
          ? this.props.general_parameters.enable_multiple_selection
          : false,

        BLOCK_TICKET_WITH_TASKS_PENDENT: this.props.general_parameters
          ? this.props.general_parameters.block_ticket_with_tasks_pendent
          : false,
        ALLOW_CUSTOMIZE_PRIORITY: this.props.general_parameters
          ? this.props.general_parameters.allow_customize_priority
          : false,
        CLEAR_TECH_AFTER_REOPEN_TICKET: this.props.general_parameters
          ? this.props.general_parameters.clear_tech_after_reopen_ticket
          : false,
        ENABLE_CONTRACT_TECH_LINKED: this.props.general_parameters
          ? this.props.general_parameters.enable_contract_tech_linked
          : false,
        ALLOW_CUSTOMIZE_PRIORITY: this.props.general_parameters
          ? this.props.general_parameters.allow_customize_priority
          : false,
        USE_APPROVAL_CENTER: this.props.general_parameters
          ? this.props.general_parameters.use_approval_center
          : false,
        ENABLED_LOCATION_USER: this.props.general_parameters
          ? this.props.general_parameters.enabled_location_user
          : false,
        USE_TECH_GROUP_IN_GMUD_ACTIVITY: this.props.general_parameters
          ? this.props.general_parameters.use_tech_group_in_gmud_activity
          : false,
        ENABLE_GMUD_APPROVERS_FILTER: this.props.general_parameters
          ? this.props.general_parameters.enable_gmud_approvers_filter
          : false,
        ENABLE_LEADERS_AS_GMUD_APPROVERS: this.props.general_parameters
          ? this.props.general_parameters.enable_leaders_as_gmud_approvers
          : false,
        //  EXTENSION_FILE : this.props.general_parameters.EXTENSION_FILE || ''

        ENABLE_SERVICE_PORTAL_BY_COMPANIES: this.props.general_parameters
          ? this.props.general_parameters.enable_service_portal_by_companies
          : false,
        USE_MULTI_FILTER_TICKET: this.props.general_parameters
          ? this.props.general_parameters.use_multi_filter_ticket
          : false,
        ENABLE_GENERAL_APPROVE_REPROVE_BUTTON_GMUD: this.props
          .general_parameters
          ? this.props.general_parameters
              .enable_general_approve_reprove_button_gmud
          : false,
          ENABLE_LOGS_DEVICE_GMUD_TICKET: this.props
          .general_parameters
          ? this.props.general_parameters
              .enable_logs_device_gmud_ticket
          : false,
        ENABLED_SHOW_ALL_COMPANY: this.props.general_parameters
          ? this.props.general_parameters.enabled_show_all_company
          : false,
        OBLIGATION_REASON_CHANGE_TECH_GROUP_TECH: this.props.general_parameters
          ? this.props.general_parameters
              .obligation_reason_change_tech_group_tech
          : false,
        USE_RESOLUTION_CATEGORY: this.props.general_parameters
          ? this.props.general_parameters.use_resolution_category
          : false,
        ENABLE_ASSET_INVOLVED_TICKET_CREATION: this.props.general_parameters
          ? this.props.general_parameters.enable_asset_involved_ticket_creation
          : false,
        NOTES_TICKETS_AS_TABLE: this.props.general_parameters
          ? this.props.general_parameters.notes_tickets_as_table
          : false,
        ENABLED_APPROVE_KNOWLEDGE: this.props.general_parameters
          ? this.props.general_parameters.enabled_approve_knowledge
          : false,
        ENABLED_TICKET_CHANGE_STATUS: this.props.general_parameters
          ? this.props.general_parameters.enabled_ticket_change_status
          : false,
        ENABLE_NOTIFICATION_CENTER: this.props.general_parameters
          ? this.props.general_parameters.enable_notification_center
          : false,
        ENABLE_EVENT_TICKET_TYPE: this.props.general_parameters
          ? this.props.general_parameters.enable_event_ticket_type
          : false,
        ENABLE_CRISIS_TICKET_TYPE: this.props.general_parameters
          ? this.props.general_parameters.enable_crisis_ticket_type
          : false,
        ENABLE_CRITICAL_INCIDENT_TICKET_TYPE: this.props.general_parameters
          ? this.props.general_parameters.enable_critical_incident_ticket_type
          : false,
        ENABLE_CREATE_PROBLEM_WHEN_RESOLVE_CRISIS: this.props.general_parameters
          ? this.props.general_parameters
              .enable_create_problem_when_resolve_crisis
          : false,

        ENABLE_REQUIREMENT_CHANNEL_TICKET: this.props.general_parameters
          ? this.props.general_parameters.enable_requirement_channel_ticket
          : false,

        ENABLE_DESCRIPTION_EDITING: this.props.general_parameters
          ? this.props.general_parameters.enable_description_editing
          : false,

        ENABLE_CHANGE_STATUS_REASON: this.props.general_parameters
          ? this.props.general_parameters.enable_change_status_reason
          : false,

        LIST_ONLY_CUSTOMER_TYPE_COMPANIES: this.props.general_parameters
          ? this.props.general_parameters.list_only_customer_type_companies
          : false,

          INITIATE_TICKET_SERVICE_ONLY_BY_USER_BELONGING_TO_THE_TECHNICAL_GROUP: this.props.general_parameters
        ? this.props.general_parameters.init_tk_service_only_by_user_belonging_to_technical_group
        : false,

        ENABLE_GMUD_FORMS_FILTER: this.props.general_parameters
          ? this.props.general_parameters.enable_gmud_forms_filter
          : false,

        ASSIGNED_TECH_STOP_RESPONSE_SLA: this.props.general_parameters
          ? this.props.general_parameters.assigned_tech_stop_response_sla
          : false,
          

        LOOK_FOR_SUPPLIERS_TICKETS_IN_RELATIONSHIP: this.props.general_parameters
          ? this.props.general_parameters.look_for_suppliers_tickets_in_relationship
          : false,
      };
      values = {
        TIME_REFRESH: this.props.general_parameters
          ? this.props.general_parameters.time_refresh
          : 0,
        TIMEZONE: this.props.general_parameters
          ? this.props.general_parameters.id_timezone
          : 0,
      };
      valuesvisao = {
        TIME_REFRESH_VISAO: this.props.general_parameters
          ? this.props.general_parameters.time_refresh_visao
          : 0,
      };

      valuesDays = {
        HOURS_DAY: this.props.general_parameters
          ? this.props.general_parameters.hours_day
          : 0,
      };

      valuesCompany = {
        ID_COMPANY_DEFAULT: this.props.general_parameters
          ? this.props.general_parameters.id_company_default
          : 0,
        NAME_COMPANY_DEFAULT: this.props.general_parameters
          ? this.props.general_parameters.name_company_default
          : language.COMPANY,
      };

      valueChannel = {
        fk_id_channel: this.props.general_parameters
          ? this.props.general_parameters.fk_id_channel_default
          : 0,
      };

      valuesGmudOpenProblemTicket = {
        CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY: this.props.general_parameters
          ? this.props.general_parameters.fk_id_category_gmud_problem_ticket
          : 0,
        CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_SECOND: this.props
          .general_parameters
          ? this.props.general_parameters
              .fk_id_catalog_service_gmud_problem_ticket
          : 0,
        CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_THIRD: this.props
          .general_parameters
          ? this.props.general_parameters.fk_id_catalog_task_gmud_problem_ticket
          : 0,
        CONFIG_PARAM_OPEN_TICKET_WHEN_GMUD_FAILS: this.props.general_parameters
          ? this.props.general_parameters.open_ticket_when_gmud_fails
          : false,
      };

      valueGroupDefaultFALE = this.props.general_parameters
        ? this.props.general_parameters.fk_id_group_default_fale
        : 0;

      valuesColors = {
        CRITICAL_TICKET_COLOR: this.props.general_parameters
          ? this.props.general_parameters.critical_ticket_color
          : '',
        HIGH_TICKET_COLOR: this.props.general_parameters
          ? this.props.general_parameters.high_ticket_color
          : '',
        MEDIUM_TICKET_COLOR: this.props.general_parameters
          ? this.props.general_parameters.medium_ticket_color
          : '',
        LOW_TICKET_COLOR: this.props.general_parameters
          ? this.props.general_parameters.low_ticket_color
          : '',
      };
    }

    let params = [];
    if (this.props.params != undefined) {
      params = {
        ticket_close_days: this.props.params.ticket_closed_days,
        vision_request: this.props.params.vision_request,
        request_closed_tickets: this.props.params.request_closed_tickets,
        TICKET_CLOSED_TASKS: this.props.general_parameters
          ? this.props.general_parameters.ticket_closed_tasks
          : false,
      };
    }

    let fields = [
      {
        col: 12,
        type: 'element',
        label: '',
        content: (
          <div>
            <h4 className="title" style={{ color: '#019fc2', marginTop: 20 }}>
              {language.TICKET_MANAGEMENT}
            </h4>
            <Divider style={{ marginBottom: 10 }} />
          </div>
        ),
      },
      {
        label: language.CONFIG_PARAM_CALL_OPENING,
        col: 4,
        name: 'CALL_OPENING',
        type: 'switch',
      },

      {
        label: language.CONFIG_PARAM_MULT_CATALOG,
        col: 4,
        name: 'MULT_CATALOG',
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_SEND_MAIL_OBSERVERS,
        col: 4,
        name: 'SEND_MAIL_OBSERVERS',
        type: 'switch',
      },

      {
        label: language.CONFIG_PARAM_CAUSE_PROBLEM,
        col: 4,
        name: 'CAUSE_PROBLEM',
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_EMAIL_RESPONSE_CHAT,
        col: 4,
        name: 'EMAIL_RESPONSE_CHAT',
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_SEND_EMAIL_GROUP_TECH,
        col: 4,
        name: 'SEND_EMAIL_GROUP_TECH',
        type: 'switch',
      },

      {
        label: language.CONFIG_PARAM_APPROVE_REQUEST_CHIEF,
        col: 4,
        name: 'APPROVE_REQUEST_CHIEF',
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_REQUEST_SOLICITANT_SELECT,
        col: 4,
        name: 'REQUEST_SOLICITANT_SELECT',
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_AVALIATION_TICKETS,
        col: 4,
        name: 'AVALIATION_TICKETS',
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_TICKET_SIMPLIFIED,
        name: 'TICKET_SIMPLIFIED',
        col: 4,
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_ADMIN_ATTENDANCE,
        name: 'ADMIN_ATTENDANCE',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_ADMIN_ATTENDANCE,
      },
      {
        label: language.CONFIG_PARAM_LINKING_MULTI_COMPANY_TICKETS,
        name: 'LINKING_MULTI_COMPANY_TICKETS',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_LINKING_MULTI_COMPANY_TICKETS,
      },
      {
        label:
          language.CONFIG_PARAM_ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS,
        name: 'ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS',
        col: 4,
        type: 'switch',
        tooltip:
          language.CONFIG_PARAM_TOOLTIP_ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS,
      },
      {
        label: language.CONFIG_PARAM_REQUEST_BY_PORTAL,
        name: 'REQUEST_BY_PORTAL',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_REQUEST_BY_PORTAL,
      },
      {
        label: language.CONFIG_PARAM_TECHNICAL_EVALUATION_REGISTRATION,
        name: 'TECHNICAL_EVALUATION_REGISTRATION',
        col: 4,
        type: 'switch',
        tooltip:
          language.CONFIG_PARAM_TOOLTIP_TECHNICAL_EVALUATION_REGISTRATION,
      },
      {
        label: language.CONFIG_PARAM_APPROVE_GROUP_USERS,
        name: 'APPROVE_GROUP_USERS',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_APPROVE_GROUP_USERS,
      },
      {
        label: language.CONFIG_PARAM_SEND_EMAIL_CATEGORIZATION,
        name: 'SEND_EMAIL_CATEGORIZATION',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_SEND_EMAIL_CATEGORIZATION,
      },
      {
        label: language.CONFIG_PARAM_ALLOW_EDIT_FORM_CATEGORIZATION,
        name: 'ALLOW_EDIT_FORM_CATEGORIZATION',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_ALLOW_EDIT_FORM_CATEGORIZATION,
      },
      {
        label: language.CONFIG_PARAM_SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION,
        name: 'SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION',
        col: 4,
        type: 'switch',
        tooltip:
          language.CONFIG_PARAM_TOOLTIP_SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION,
      },
      {
        label: language.CONFIG_PARAM_SEND_EMAIL_CREATE_TASK_IN_TICKET,
        name: 'SEND_EMAIL_CREATE_TASK_IN_TICKET',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_SEND_EMAIL_CREATE_TASK_IN_TICKET,
      },
      {
        label: language.CONFIG_PARAM_ADMIN_HISTORIC_REQUEST,
        name: 'ADMIN_HISTORIC_REQUEST',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_ADMIN_HISTORIC_REQUEST,
      },
      {
        label: language.CONFIG_PARAM_EVALUATE_TICKET,
        name: 'EVALUATE_TICKET',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_EVALUATE_TICKET,
      },
      {
        label: language.CONFIG_PARAM_SUBTICKET_CLOSED_VERIFY,
        name: 'SUBTICKET_CLOSED_VERIFY',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_SUBTICKET_CLOSED_VERIFY,
      },
      {
        label: language.CONFIG_PARAM_COUNT_TICKET_BROKEN_ONCE,
        name: 'COUNT_TICKET_BROKEN_ONCE',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_COUNT_TICKET_BROKEN_ONCE,
        visible:
          this.props.params && this.props.params.sla_params == true
            ? true
            : false,
      },
      {
        label: language.CONFIG_PARAM_ENABLE_MULTIPLE_SELECTION,
        name: 'ENABLE_MULTIPLE_SELECTION',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_ENABLE_MULTIPLE_SELECTION,
      },
      {
        label: language.BLOCK_TICKET_WITH_TASKS_PENDENT,
        col: 4,
        name: 'BLOCK_TICKET_WITH_TASKS_PENDENT',
        type: 'switch',
      },
      {
        label: language.ALLOW_CUSTOMIZE_PRIORITY,
        col: 4,
        name: 'ALLOW_CUSTOMIZE_PRIORITY',
        type: 'switch',
      },
      {
        label: language.CLEAR_TECH_AFTER_REOPEN_TICKET,
        col: 4,
        name: 'CLEAR_TECH_AFTER_REOPEN_TICKET',
        type: 'switch',
      },
      {
        label: language.USE_MULTI_FILTER_TICKET,
        col: 4,
        name: 'USE_MULTI_FILTER_TICKET',
        type: 'switch',
      },
      {
        label: language.USE_RESOLUTION_CATEGORY,
        col: 4,
        name: 'USE_RESOLUTION_CATEGORY',
        type: 'switch',
      },
      {
        label: language.OBLIGATION_REASON_CHANGE_TECH_GROUP_TECH,
        col: 4,
        name: 'OBLIGATION_REASON_CHANGE_TECH_GROUP_TECH',
        type: 'switch',
      },
      {
        label: language.ENABLE_ASSET_INVOLVED_TICKET_CREATION,
        col: 4,
        name: 'ENABLE_ASSET_INVOLVED_TICKET_CREATION',
        type: 'switch',
      },
      {
        label: language.ENABLE_REQUIREMENT_CHANNEL_TICKET,
        col: 4,
        name: 'ENABLE_REQUIREMENT_CHANNEL_TICKET',
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_ENABLE_DESCRIPTION_EDITING,
        col: 4,
        name: 'ENABLE_DESCRIPTION_EDITING',
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_ENABLE_CHANGE_STATUS_REASON,
        col: 4,
        name: 'ENABLE_CHANGE_STATUS_REASON',
        type: 'switch',
      },
      {
        label:
          language.LIST_ONLY_CUSTOMER_TYPE_COMPANIES_IN_THE_COMPANY_FIELD_WHEN_OPENING_THE_TICKET,
        col: 4,
        name: 'LIST_ONLY_CUSTOMER_TYPE_COMPANIES',
        type: 'switch',
      },
      {
        label:
          language.INITIATE_TICKET_SERVICE_ONLY_BY_USER_BELONGING_TO_THE_TECHNICAL_GROUP,
        col: 4,
        name: 'INITIATE_TICKET_SERVICE_ONLY_BY_USER_BELONGING_TO_THE_TECHNICAL_GROUP',
        type: 'switch',
      },
      {
        label:
          language.ASSIGNED_TECH_STOP_RESPONSE_SLA,
        col: 4,
        name: 'ASSIGNED_TECH_STOP_RESPONSE_SLA',
        type: 'switch',
      },
      {
         label: language.LOOK_FOR_SUPPLIERS_TICKETS_IN_RELATIONSHIP,
         col: 4,
         name: 'LOOK_FOR_SUPPLIERS_TICKETS_IN_RELATIONSHIP',
         type: 'switch',
       },
      {
        col: 12,
        type: 'element',
        label: '',
        content: (
          <div>
            <h4 className="title" style={{ color: '#019fc2', margintTop: 20 }}>
              LOGIN
            </h4>
            <Divider style={{ marginBottom: 10 }} />
          </div>
        ),
      },
      {
        label: language.CONFIG_PARAM_MICROSOFT_LOGIN,
        col: 4,
        name: 'MICROSOFT_LOGIN',
        type: 'switch',
      },

      {
        label: language.CONFIG_PARAM_GOOGLE_LOGIN,
        col: 4,
        name: 'GOOGLE_LOGIN',
        type: 'switch',
      },

      {
        col: 12,
        type: 'element',
        label: '',
        content: (
          <div>
            <h4 className="title" style={{ color: '#019fc2', margintTop: 20 }}>
              {language.CONTRACT}
            </h4>
            <Divider style={{ marginBottom: 10 }} />
          </div>
        ),
      },
      {
        label: language.CONFIG_PARAM_READJUSTMENT_CONTRACT,
        col: 4,
        name: 'READJUSTMENT_CONTRACT',
        type: 'switch',
      },

      {
        label: language.CONFIG_PARAM_TICKET_CONTRACT_SOLICITANT,
        name: 'TICKET_CONTRACT_SOLICITANT',
        col: 4,
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_ASSOCIATE_REMOTE_ACCESS_TICKET,
        name: 'ASSOCIATE_REMOTE_ACCESS_TICKET',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_ASSOCIATE_REMOTE_ACCESS_TICKET,
      },

      {
        label: language.CONFIG_PARAM_COUNT_HOURS_TECH_CLIENT,
        name: 'COUNT_HOURS_TECH_CLIENT',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_COUNT_HOURS_TECH_CLIENT,
      },
      {
        label: 'Habilitar técnicos selecionar apenas contratos vinculados',
        col: 4,
        name: 'ENABLE_CONTRACT_TECH_LINKED',
        type: 'switch',
      },
      {
        col: 12,
        type: 'element',
        label: '',
        content: (
          <div>
            <h4 className="title" style={{ color: '#019fc2', margintTop: 20 }}>
              {language.CHANGE_MANAGEMENT}
            </h4>
            <Divider style={{ marginBottom: 10 }} />
          </div>
        ),
      },
      {
        label: language.CONFIG_PARAM_CHANGE_GROUP_TECH_APPROVE,
        name: 'CHANGE_GROUP_TECH_APPROVE',
        col: 4,
        type: 'switch',
      },

      {
        label: language.CONFIG_PARAM_ENABLE_REQUIRED_TEMPLATE_GMUD,
        name: 'ENABLE_REQUIRED_TEMPLATE_GMUD',
        col: 4,
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLIP_ENABLE_REQUIRED_TEMPLATE_GMUD,
      },
      {
        label: language.CONFIG_PARAM_ENABLE_LOGS_DEVICE_GMUD_TICKET,
        col: 4,
        name: 'ENABLE_LOGS_DEVICE_GMUD_TICKET',
        type: 'switch',
      },
      {
        label: language.ENABLE_GENERAL_APPROVE_REPROVE_BUTTON_GMUD,
        col: 4,
        name: 'ENABLE_GENERAL_APPROVE_REPROVE_BUTTON_GMUD',
        type: 'switch',
      },
      {
        label: language.USE_TECH_GROUP_IN_GMUD_ACTIVITY,
        col: 4,
        name: 'USE_TECH_GROUP_IN_GMUD_ACTIVITY',
        type: 'switch',
        tooltip: language.USE_ONLY_GROUP_TECH_IN_CREATE_GMUD_ACTIVITY,
      },
      {
        label: language.ENABLE_GMUD_APPROVERS_WORKFLOW,
        col: 4,
        name: 'ENABLE_GMUD_APPROVERS_FILTER',
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_GMUD_WORKFLOW,
      },
      {
        label: language.ENABLE_GMUD_LEADERS_AS_APPROVERS,
        col: 4,
        name: 'ENABLE_LEADERS_AS_GMUD_APPROVERS',
        type: 'switch',
        tooltip: language.CONFIG_PARAM_TOOLTIP_GMUD_LEADERS_APPROVERS,
      },
      {
        label: language.ALLOW_EDITING_OF_TECH_GMUD_AFTER_APPROVAL,
        col: 4,
        name: 'ALLOW_EDITING_OF_TECH_GMUD_AFTER_APPROVAL',
        type: 'switch',
        tooltip: language.ALLOW_EDITING_OF_TECH_GMUD_AFTER_APPROVAL,
      },
      {
        label: language.ENABLE_GMUD_FORMS_FILTER,
        col: 4,
        name: 'ENABLE_GMUD_FORMS_FILTER',
        type: 'switch',
        tooltip: language.ENABLE_GMUD_FORMS_FILTER,
      },
      // Fabio Morikawa: comentado temporariamente até definições do portal para Brasil
      // LATAM será habilitado via banco
      // {
      //   label: language.CONFIG_PARAM_ENABLE_SERVICE_PORTAL_BY_COMPANIES,
      //   col: 4,
      //   name: 'ENABLE_SERVICE_PORTAL_BY_COMPANIES',
      //   type: 'switch',
      // },
      {
        col: 12,
        type: 'element',
        label: '',
        content: (
          <div>
            <h4 className="title" style={{ color: '#019fc2', marginTop: 20 }}>
              {language.GENERAL_SETTINGS}
            </h4>
            <Divider style={{ marginBottom: 10 }} />
          </div>
        ),
      },
      {
        label: language.USE_APPROVAL_CENTER,
        col: 4,
        name: 'USE_APPROVAL_CENTER',
        type: 'switch',
      },
      {
        label: language.REGISTER_LOCATION_USER,
        col: 4,
        name: 'ENABLED_LOCATION_USER',
        type: 'switch',
      },
      {
        label: language.SHOW_IC_COMPANY,
        col: 4,
        name: 'ENABLED_SHOW_ALL_COMPANY',
        type: 'switch',
      },
      {
        label: language.OBLIGATION_REASON_CHANGE_TECH_GROUP_TECH,
        col: 4,
        name: 'OBLIGATION_REASON_CHANGE_TECH_GROUP_TECH',
        type: 'switch',
      },
      {
        label: language.ENABLE_ASSET_INVOLVED_TICKET_CREATION,
        col: 4,
        name: 'ENABLE_ASSET_INVOLVED_TICKET_CREATION',
        type: 'switch',
      },
      {
        label: language.NOTES_TICKETS_AS_TABLE,
        col: 4,
        name: 'NOTES_TICKETS_AS_TABLE',
        type: 'switch',
      },
      {
        label: language.APPROVE_KNOWLEDGE,
        col: 4,
        name: 'ENABLED_APPROVE_KNOWLEDGE',
        type: 'switch',
      },
      {
        label: language.ENABLE_EVENT_TICKET_TYPE,
        col: 4,
        name: 'ENABLE_EVENT_TICKET_TYPE',
        type: 'switch',
      },
      {
        label: language.ENABLE_CRISIS_TICKET_TYPE,
        col: 4,
        name: 'ENABLE_CRISIS_TICKET_TYPE',
        type: 'switch',
      },
      {
        label: language.ENABLE_CRITICAL_INCIDENT_TICKET_TYPE,
        col: 4,
        name: 'ENABLE_CRITICAL_INCIDENT_TICKET_TYPE',
        type: 'switch',
      },
      {
        label: language.CONFIG_PARAM_ENABLE_CREATE_PROBLEM_WHEN_RESOLVE_CRISIS,
        col: 4,
        name: 'ENABLE_CREATE_PROBLEM_WHEN_RESOLVE_CRISIS',
        type: 'switch',
      },
      {
        label: language.SHOW_INTERATION_TICKET_CHANGE,
        col: 4,
        name: 'ENABLED_TICKET_CHANGE_STATUS',
        type: 'switch',
      },
      {
        label: language.ENABLE_NOTIFICATION_CENTER,
        col: 4,
        name: 'ENABLE_NOTIFICATION_CENTER',
        type: 'switch',
      },
    ];

    let ticketTime = [
      {
        label: language.CONFIG_PARAM_TIME_REFRESH,
        name: 'TIME_REFRESH',
        col: 8,
        type: 'text',
      },
    ];
    let ticketTimevisao = [
      {
        label: language.CONFIG_PARAM_TIME_REFRESH_VISAO,
        name: 'TIME_REFRESH_VISAO',
        col: 8,
        type: 'text',
      },
    ];

    let close_calls = [
      {
        label: language.CONFIG_PARAM_TICKET_CLOSE_DAYS,
        name: 'ticket_close_days',
        col: 8,
        type: 'text',
      },
      {
        label: language.CONFIG_PARAM_VISION_REQUEST,
        name: 'vision_request',
        col: 8,
        type: 'text',
      },
      {
        label: language.CONFIG_PARAM_REQUEST_CLOSED_TICKETS,
        name: 'request_closed_tickets',
        col: 8,
        type: 'text',
      },
      {
        label: language.CONFIG_PARAM_TICKET_CLOSED_TASKS,
        name: 'TICKET_CLOSED_TASKS',
        col: 8,
        type: 'switch',
      },
    ];

    let hoursDay = [
      {
        label: language.CONFIG_PARAM_HOURS_DAY,
        name: 'HOURS_DAY',
        col: 8,
        type: 'switch',
      },
    ];

    let defaultCompany = [
      {
        col: 12,
        type: 'autocomplete',
        name: 'fk_id_company',
        label: language.COMPANY,
        method: 'POST',
        route: `${Constants.APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel: language.COMPANY,
        textinit:
          valuesCompany && valuesCompany.NAME_COMPANY_DEFAULT
            ? valuesCompany.NAME_COMPANY_DEFAULT
            : 'Empresa',
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        idinit: valuesCompany ? valuesCompany.ID_COMPANY_DEFAULT : 0,
        routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
        required: true,
      },
    ];

    const defaultCrisis = [
      {
        col: 12,
        type: 'autocomplete',
        name: 'fk_id_company_crisis',
        label: language.COMPANY,
        method: 'POST',
        route: `${Constants.APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel: language.COMPANY,
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
        required: true,
      },
    ];

    const fieldsColors = [
      {
        type: 'color',
        col: 3,
        name: 'CRITICAL_TICKET_COLOR',
        label: language.TICKET_PRIORITY.CRITICAL,
      },
      {
        type: 'color',
        col: 3,
        name: 'HIGH_TICKET_COLOR',
        label: language.HIGH,
      },
      {
        type: 'color',
        col: 3,
        name: 'MEDIUM_TICKET_COLOR',
        label: language.MEDIUM,
      },
      {
        type: 'color',
        col: 3,
        name: 'LOW_TICKET_COLOR',
        label: language.LOW,
      },
    ];

    const defaultChannel = [
      {
        col: 12,
        type: 'select',
        name: 'fk_id_channel',
        label: language.CHANNEL,
        options: channels.map((item) => ({
          value: item.id_table,
          label: item.description,
        })),
      },
    ];

    let button = { label: language.SAVE };

    let { configurationProps = {} } = this.props;
    let columns = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 10 },
      },
      { key: 'name', label: language.NAME },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 40 },
        jc: 'center',
      },
    ];
    let companiesData = (this.state.companiesCrisis || []).map((c) => ({
      id: c.id_company,
      name: c.name,
      actions: (
        <React.Fragment>
          <Chip
            className="mx-2 muit-chip"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <RaisedButton
                  circleButton
                  icon="far fa-trash-alt"
                  label={language.DELETE}
                  color="danger"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() => this.setState({ companyCrisisDelete: c })}
                />
              </div>
            }
          />
        </React.Fragment>
      ),
    }));

    return (
      <Fragment>
        <div id="GeneralParametersConfig">
          <Paper>
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}>
              <CommonForm2
                values={general_parameters}
                fields={fields}
                onSubmit={this.props.onFormSubmit}
                button={button}
              />
            </div>
          </Paper>
          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar title={language.UPDATE_TICKET_LIST_SCREEN}>
              {configurationProps.control}
            </AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                values={values}
                fields={ticketTime}
                onSubmit={this.props.onFormSubmit}
                button={button}
              />
            </div>
          </Paper>
          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar title={language.UPDATE_TICKET_MANAGEMENT_VIEW_SCREEN}>
              {configurationProps.control}
            </AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                values={valuesvisao}
                fields={ticketTimevisao}
                onSubmit={this.props.onFormSubmit}
                button={button}
              />
            </div>
          </Paper>
          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar title={language.CALL_CLOSING}>
              {configurationProps.control}
            </AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                values={params}
                fields={close_calls}
                onSubmit={this.props.onFormCalls}
                button={button}
              />
            </div>
          </Paper>
          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar
              title={
                language.COLORS_CUSTOMIZATION_TICKET_PRIORITY_URGENCY_IMPACT
              }
            >
              {configurationProps.control}
            </AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                values={valuesColors}
                fields={fieldsColors}
                onSubmit={this.props.onFormSubmit}
                button={button}
              />
            </div>
          </Paper>
          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar title={language.NUMBER_HOURS_PER_DAY}>
              {configurationProps.control}
            </AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                values={valuesDays}
                fields={hoursDay}
                onSubmit={this.props.onFormSubmit}
                button={button}
              />
            </div>
          </Paper>

          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar title="Timezone">{configurationProps.control}</AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                fields={[
                  {
                    type: 'select',
                    name: 'id_timezone',
                    label: 'Timezone',
                    required: true,
                    options: timezoneOptions,
                  },
                ]}
                values={{
                  id_timezone: values.TIMEZONE,
                }}
                onSubmit={this.props.onFormSubmit}
                button={button}
              />
            </div>
          </Paper>

          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar title={language.DEFAULT_COMPANY_PROJETS}>
              {configurationProps.control}
            </AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                values={valuesCompany}
                fields={defaultCompany}
                onSubmit={this.props.onFormSubmit}
                button={button}
              />
            </div>
          </Paper>

          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar title={language.DEFAULT_COMPANY_CRISIS}>
              {configurationProps.control}
            </AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                fields={defaultCrisis}
                onSubmit={this.onCreateCompanyCrisis}
                button={button}
              />
              <CommonTable
                columns={columns}
                data={companiesData}
                index="CompaniesList"
                orderColumn
              />
              {this.state.companyCrisisDelete && (
                <CommonDelete
                  language={language}
                  message={this.state.companyCrisisDelete.name}
                  onDelete={this.onDeleteCompanyCrisis}
                />
              )}
            </div>
          </Paper>

          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar title={language.DEFAULT_CHANNEL_TICKETS}>
              {configurationProps.control}
            </AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                values={valueChannel}
                fields={defaultChannel}
                onSubmit={this.props.onFormSubmit}
                button={button}
              />
            </div>
          </Paper>
          <div style={{ height: 20 }}></div>
          <Paper>
            <AppBar title={language.DEFAULT_GROUP_FALE}>
              {configurationProps.control}
            </AppBar>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <CommonForm2
                fields={[
                  {
                    type: 'select',
                    name: 'fk_id_group_default_fale',
                    label: language.GROUP,
                    options: groupFALEOptions,
                  },
                ]}
                values={{ fk_id_group_default_fale: valueGroupDefaultFALE }}
                onSubmit={this.props.onFormSubmit}
                button={button}
              />
            </div>
          </Paper>

          <ClosingGMUD
            control={configurationProps.control}
            onSubmit={this.props.onFormSubmit}
            values={{ ...general_parameters }}
          />

          <ClosingTicket
            control={configurationProps.control}
            onSubmit={this.props.onFormSubmit}
            values={{ ...general_parameters }}
          />
        </div>
      </Fragment>
    );
  }
}
